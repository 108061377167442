import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoadingAction } from "../../../../../../hooks";
import { TradFinanceRequestContext } from "../../../context";
import {
  findBeneficiaryByRegNumberAndName,
  isDetailStepEditable,
  partnerFieldMapping,
} from "../../../helper";
import { useTradeFinanceDetailsChanges } from "../../../hooks";
import {
  isAmendment,
  isCollection,
  isGuarantee,
  isOutgoingTradeFinance,
} from "../../../tradeFinanceUtils";
import { DetailStepProps, StepId } from "../../../type";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import * as API from "../../../../../../api/tradeFinanceApi";
import { Beneficiary } from "../../../../../../types";
import { collectFields } from "../../../../../../components/Form/helper";
import { RoundedButton } from "../../../../../../components";
import { EditableStep } from "..";
import { SwiftFieldProps } from "../../../../../../components/Fields/types";
import { validateSwiftFieldValue } from "../../../../../../components/Fields/helper";

function PartnerDetailsStep({ changed, layout }: DetailStepProps) {
  const { t } = useTranslation();
  const tfinContext = useContext(TradFinanceRequestContext);
  const {
    tradeFinance,
    clientDetailsLayout,
    loadPartnersAction,
    form,
    createNewPartnerOption,
    setNewPartnerOption,
  } = tfinContext;
  const stepId = StepId.clientDetails;
  const isEditable =
    isDetailStepEditable(tfinContext) &&
    !(isOutgoingTradeFinance(tradeFinance) && isAmendment(tradeFinance));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModifiedPartner, setModifiedPartner] =
    useState<string>("updatePartner");
  const modifyBeneficiary: boolean = form.values.modifyExistingBeneficiary;
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const favBeneficiaryId = parseInt(form.values.favouriteBeneficiary);
  const tfChanges = useTradeFinanceDetailsChanges();
  let otherPartnerNameError: string | null = null;
  let otherPartnerAddressError: string | null = null;

  const loadAllPartnersAction = useLoadingAction(
    () => TFApi.getAllPartner(),
    [favBeneficiaryId]
  );

  const { result: beneficiary } = useLoadingAction<
    Beneficiary | undefined
  >(async () => {
    if (!favBeneficiaryId || favBeneficiaryId === -1) {
      return {} as Beneficiary;
    } else {
      return API.findBeneficiaryById(favBeneficiaryId);
    }
  }, [favBeneficiaryId, isModifiedPartner]);

  async function saveBeneficiary() {
    setIsLoading(true);
    setErrorMessage(false);
    let backendOtherPartner = findBeneficiaryByRegNumberAndName(
      loadAllPartnersAction.result,
      form.values.otherPartnerName,
      form.values.otherPartnerSwiftAddress,
      form.values.otherPartnerRegistrationNumber
    ) as Beneficiary;
    let portalOtherPartner = findBeneficiaryByRegNumberAndName(
      loadPartnersAction.result,
      form.values.otherPartnerName,
      form.values.otherPartnerSwiftAddress,
      form.values.otherPartnerRegistrationNumber
    );

    if (form.values.otherPartnerName) {
      const otherPartnerNameProps = collectFields(clientDetailsLayout).find(
        (field) => field.id === "otherPartnerName"
      ) as SwiftFieldProps;
      if (
        otherPartnerNameProps &&
        !(
          tradeFinance &&
          (tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyBG" ||
            isGuarantee(tradeFinance) ||
            isCollection(tradeFinance))
        )
      ) {
        otherPartnerNameError = validateSwiftFieldValue(
          form.values.otherPartnerName,
          otherPartnerNameProps.validationType,
          otherPartnerNameProps.lines,
          t
        );
        if (otherPartnerNameError) {
          form.setFieldError("otherPartnerName", otherPartnerNameError);
        }
      }
    }

    if (form.values.otherPartnerSwiftAddress) {
      const otherPartnerAddressProps = collectFields(clientDetailsLayout).find(
        (field) => field.id === "otherPartnerSwiftAddress"
      ) as SwiftFieldProps;
      if (
        otherPartnerAddressProps &&
        (isCollection(tradeFinance) ||
          tradeFinance.tradeFinanceType === "tradefin/incomingStandbyLC" ||
          tradeFinance.tradeFinanceType === "tradefin/importLC" ||
          tradeFinance.tradeFinanceType === "tradefin/exportLC")
      ) {
        otherPartnerAddressError = validateSwiftFieldValue(
          form.values.otherPartnerSwiftAddress,
          otherPartnerAddressProps.validationType,
          otherPartnerAddressProps.lines,
          t
        );
        if (otherPartnerAddressError) {
          form.setFieldError(
            "otherPartnerSwiftAddress",
            otherPartnerAddressError
          );
        }
      }
    }

    if (!otherPartnerNameError && !otherPartnerAddressError) {
      //is partner unique?
      if (!backendOtherPartner || modifyBeneficiary) {
        //need partner uniqe check in modify transition
        if (
          backendOtherPartner &&
          modifyBeneficiary &&
          beneficiary &&
          beneficiary.id !== backendOtherPartner.id
        ) {
          setErrorMessage(true);
        } else {
          const newPartner = {} as Beneficiary;
          for (let [tfField, partnerField] of Object.entries(
            partnerFieldMapping
          )) {
            (newPartner as any)[partnerField] = (form.values as any)[tfField];
          }
          const { id } = await API.saveBeneficiary({
            ...newPartner,
            id: modifyBeneficiary ? favBeneficiaryId : undefined,
          });
          if (modifyBeneficiary) {
            setModifiedPartner("savedInFavourites");
            form.setFieldValue("favouriteBeneficiary", id);
          } else {
            form.setFieldValue("favouriteBeneficiary", id);
          }
          setErrorMessage(false);
        }
      } else if (!portalOtherPartner && loadAllPartnersAction.result) {
        //partner exists in backend, but needs to add to portal favourites list
        for (let [tfField, partnerField] of Object.entries(
          partnerFieldMapping
        )) {
          (backendOtherPartner as any)[partnerField] = (form.values as any)[
            tfField
          ];
        }
        const { id } = await API.saveBeneficiary({
          ...backendOtherPartner,
          id: backendOtherPartner.id,
        });
        setErrorMessage(false);
      } else {
        setErrorMessage(true);
      }

      loadPartnersAction.run();
      loadAllPartnersAction.run();
      setNewPartnerOption(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setModifiedPartner("updatePartner");
    if (
      !modifyBeneficiary &&
      favBeneficiaryId &&
      beneficiary &&
      tfChanges.partnerDetailsChanged
    ) {
      for (let [tfField, partnerField] of Object.entries(partnerFieldMapping)) {
        (form.values as any)[tfField] = (beneficiary as any)[partnerField];
      }
      form.setFieldValue("otherPartnerSWIFTCode", undefined);
    }
  }, [modifyBeneficiary]);

  useEffect(() => {
    loadPartnersAction.run();
    setErrorMessage(false);
  }, [form.values.favouriteBeneficiary]);

  useEffect(() => {
    setModifiedPartner("updatePartner");
  }, [
    form.values.otherPartnerRegistrationNumber,
    form.values.otherPartnerName,
    form.values.otherPartnerSwiftAddress,
    form.values.otherPartnerBankSWIFTCode,
    form.values.otherPartnerNonDefinedAddress,
    form.values.otherPartnerAccountNumber,
  ]);

  function buttonDisabled() {
    return (
      (createNewPartnerOption &&
        (!form.values.otherPartnerName ||
          !form.values.otherPartnerSwiftAddress)) ||
      (modifyBeneficiary && isModifiedPartner === "savedInFavourites")
    );
  }
  //console.log(layout);
  return (
    <EditableStep
      stepId={stepId}
      className="partner-details"
      title={t("tradeFinance.clientDetails")}
      changed={changed}
      state={!form.values.favouriteBeneficiary ? "inProgress" : "finished"}
      isEditable={isEditable}
      layout={layout}
      buttons={
        (modifyBeneficiary || createNewPartnerOption) && (
          <div>
            {errorMessage && (
              <div className="message is-warning">
                <div className="message-body">
                  {t("tradeFinance.errors.partnerError")}
                </div>
              </div>
            )}
            <RoundedButton
              label={
                createNewPartnerOption
                  ? t("addToFavourites")
                  : t(isModifiedPartner)
              }
              onClick={() => saveBeneficiary()}
              loading={isLoading}
              disabled={buttonDisabled()}
            />
          </div>
        )
      }
    />
  );
}

export default PartnerDetailsStep;
