// @ts-nocheck
import moment from "moment-timezone";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";

import { DayPickerInputWithIcon, DecorateField } from "..";
import { AppContext } from "../../../App";
import { DateFieldProps } from "../types";

import cs from "date-fns/locale/cs";
import en from "date-fns/locale/en-US";

registerLocale("cs", cs);

moment.tz.setDefault("Europe/Prague");

function DateField(props: DateFieldProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);

  function convertValue(val: Date | null): null | number {
    if (val) {
      return val.getTime();
    } else {
      return null;
    }
  }

  function setValue(val: Date | null) {
    const { id, setError, setValue } = props;
    try {
      if (!props.value && val) {
        //if the value is null(before update). The datepicker onChange is called with a date with system timezone instead of  "moment.tz.setDefault"
        //We generate a new date with the correct timezone
        const fainValue = moment(
          moment(val).local().format("YYYY-MM-DD")
        ).toDate();
        setValue(convertValue(fainValue));
      } else {
        if (val != null) {
          const fainValue = moment(
            moment(val).local().format("YYYY-MM-DD")
          ).toDate();
          setValue(convertValue(fainValue));
        } else {
          const dateValue = convertValue(val);
          setValue(dateValue);
        }
      }

      setError(null);
    } catch (e) {
      setError(t("invalidValue"));
    }
  }

  const { id, value, disabled, onChange } = props;
  const dateValue = value ? moment(value).toDate() : null;
  const fainValue = value ? moment(value).local(true).toDate() : null;

  return DecorateField(
    props,
    <DatePicker
      minDate={props.minDate}
      maxDate={props.maxDate}
      dateFormat={"dd.MM.yyyy"}
      className={"react-datepicker"}
      selected={fainValue}
      disabledKeyboardNavigation={false}
      disabled={disabled}
      onChange={setValue}
      onChangeRaw={onChange}
      customInput={<DayPickerInputWithIcon />}
      showMonthDropdown
      showYearDropdown
      fixedHeight={true}
      dropdownMode="select"
      locale={lang === "cz" ? cs : en}
    />,
    lang
  );
}

export default DateField;
