import classnames from "classnames";
import React, { useContext } from "react";
import { AppContext } from "../../../../App";
import { CompanyOption } from "../types";

function CompaniesPopup({
  companyOptions,
}: {
  companyOptions: CompanyOption[];
}) {
  const appCtx = useContext(AppContext);
  const user = appCtx.userStatus!.user;

  const eventHandler = (option: CompanyOption) => {
    appCtx.changeCompany(option.value);
  };

  return (
    <>
      {companyOptions.map((option) => {
        const companyId: number = user.defaultCompany!.id;
        return (
          <a
            key={option.value}
            className={classnames("dropdown-item", {
              "is-selected": option.value === companyId,
            })}
            onClick={() => eventHandler(option)}
          >
            <label>{option.label}</label>
          </a>
        );
      })}
    </>
  );
}

export default CompaniesPopup;
