import React, { useEffect, useRef, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { RoundedButton } from "..";
import { Modal } from "../..";
import { useModal } from "../../ModalComponent/assets/hooks";
import { ConfirmButtonProps } from "../types";

function ConfirmButton(props: ConfirmButtonProps) {
  const { t } = useTranslation();
  const _mounted = useRef<boolean>(false);
  const [showConfirmation, openConfirmation, closeConfirmation] =
    useModal(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    _mounted.current = true;
    return () => {
      _mounted.current = false;
    };
  }, []);

  function handleClick(e: MouseEvent<any>) {
    if (props.button.props.onClick) {
      openConfirmation();
    }
  }

  function onConfirmed(e: MouseEvent<any>) {
    setLoading(true);

    if (_mounted.current) {
      Promise.resolve(props.button.props.onClick(e))
        .then(() => {
          if (_mounted.current) {
            setLoading(false);
            closeConfirmation();
          }
        })
        .catch(() => {
          setLoading(false);
          closeConfirmation();
        });
    }
  }

  const { confirmation, button } = props;
  return (
    <React.Fragment>
      {React.cloneElement(button, { onClick: handleClick })}
      <Modal
        isOpened={showConfirmation}
        modalCardClass="confirm-dialog"
        hideCloseButton={true}
        onClose={closeConfirmation}
      >
        <div className="box">
          <div className="title is-6">{confirmation}</div>
          <div className="portal-buttons">
            <RoundedButton
              label={t("yes")}
              onClick={onConfirmed}
              loading={loading}
            />
            <RoundedButton label={t("no")} onClick={closeConfirmation} />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmButton;
