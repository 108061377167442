import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  ConfirmButton,
  RoundedButton,
} from "../../../../../../../../components";
import { Delete, Edit } from "../../../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../../../context";
import { DocumentRequirementTableProps } from "../types";

function DocumentRequirementTable({
  documentRequirements,
  editable,
  setDocumentRequirement,
  deleteDocumentRequirement,
  tradeFinanceId,
}: DocumentRequirementTableProps) {
  const { t } = useTranslation();
  const tradFinanceRequestContext = useContext(TradFinanceRequestContext);
  const { setUnsavedTerm } = tradFinanceRequestContext;
  return (
    <div className={"document-requirements-step"}>
      {documentRequirements && documentRequirements.length > 0 && (
        <div className="table-container">
          <table className="doc-requirements-table table portal-table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>{t("documentRequirements.documentLongName")} </th>
                <th>{t("documentRequirements.originals")}</th>
                <th>{t("documentRequirements.copies")}</th>
                {editable && <th>{t("actions")}</th>}
              </tr>
            </thead>

            <tbody>
              {documentRequirements.map((documentRequirement) => (
                <tr key={documentRequirement.id}>
                  <td>
                    {documentRequirement.documentLongName +
                      (documentRequirement.documentsInvoiceNumber
                        ? " (" +
                          documentRequirement.documentsInvoiceNumber +
                          ")"
                        : "")}
                  </td>
                  <td>{documentRequirement.originals}</td>
                  <td>{documentRequirement.copies}</td>
                  {editable && (
                    <td className="row-actions">
                      <RoundedButton
                        className="button is-outlined is-small"
                        icon={<Edit />}
                        label={t("edit")}
                        onClick={() => {
                          setDocumentRequirement(documentRequirement);
                          setUnsavedTerm(true);
                        }}
                      />
                      <ConfirmButton
                        confirmation={t("areYouSureToDelete")}
                        button={
                          <RoundedButton
                            className="button is-outlined is-small"
                            icon={<Delete />}
                            label={t("delete")}
                            onClick={() =>
                              deleteDocumentRequirement(documentRequirement)
                            }
                          ></RoundedButton>
                        }
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default DocumentRequirementTable;
