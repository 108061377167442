// @ts-nocheck
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import {
  UnderwriterConfigItem,
  UnderWriterConfigItemComponentProps,
} from "../types";
import { Event } from "../../../UserConfigurationPage/assets/types";
import classnames from "classnames";
import { LimitField } from "..";
import NumberFormat from "react-number-format";
import { Delete, Plus } from "../../../../icons/Icons";

function UnderWriterConfigItemComponent(
  props: UnderWriterConfigItemComponentProps
) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const {
    item,
    items,
    idx,
    updateItem,
    addItem,
    removeItem,
    limitErrors,
    //setLimitErrors,
  } = props;
  const isMessaging = item.eventName === Event.messaging;

  const isTheOnlyEvent =
    items.filter(
      (itm) => itm.eventName === item.eventName && itm.product === item.product
    ).length === 1;

  function newItem(): UnderwriterConfigItem {
    return {
      ...item,
      limit: null,
      groupA: 0,
      groupB: 0,
      id: Math.random() + "",
    };
  }

  function addNewItem() {
    addItem(newItem());
  }

  const error = limitErrors[item.id];
  const hasError = !!limitErrors[item.id];

  return (
    <>
      <tr className={classnames({ "has-limit-error": hasError })}>
        <td className={"category-name"}>
          {(idx === 0 || items[idx - 1].product !== item.product) &&
            t("userConfig.product." + item.product)}
        </td>
        <td align={"right"}>
          <div className={"step-state"}>
            {t("userConfig.event." + item.eventName)}
          </div>
        </td>
        <td align={"right"}>
          <LimitField {...props} />
        </td>
        <td align={"right"}>
          <div className={classnames("number-spinner is-flex")}>
            <NumberFormat
              displayType={user?.superUser ? "input" : "text"}
              className={classnames({ "input is-small": user?.superUser })}
              decimalSeparator={","}
              decimalScale={0}
              allowNegative={false}
              value={item.groupA || 0}
              onValueChange={(values) =>
                updateItem(item.id, "groupA", values.floatValue || null)
              }
            />

            {user?.superUser && (
              <div className={"spinner-button-container"}>
                <div
                  className={"spinner-button is-plus"}
                  onClick={() =>
                    updateItem(item.id, "groupA", (item.groupA || 0) + 1)
                  }
                >
                  {"+"}
                </div>
                <div
                  className={"spinner-button is-minus"}
                  onClick={() =>
                    updateItem(item.id, "groupA", (item.groupA || 1) - 1)
                  }
                >
                  {"-"}
                </div>
              </div>
            )}
          </div>
        </td>
        <td align={"right"}>
          <div className={"number-spinner is-flex"}>
            <NumberFormat
              displayType={user?.superUser ? "input" : "text"}
              className={classnames({ "input is-small": user?.superUser })}
              decimalSeparator={","}
              decimalScale={0}
              allowNegative={false}
              value={item.groupB || 0}
              onValueChange={(values) =>
                updateItem(item.id, "groupB", values.floatValue || null)
              }
            />
            {user?.superUser && (
              <div className={"spinner-button-container"}>
                <div
                  className={"spinner-button is-plus"}
                  onClick={() =>
                    updateItem(item.id, "groupB", (item.groupB || 0) + 1)
                  }
                >
                  {"+"}
                </div>
                <div
                  className={"spinner-button is-minus"}
                  onClick={() =>
                    updateItem(item.id, "groupB", (item.groupB || 1) - 1)
                  }
                >
                  {"-"}
                </div>
              </div>
            )}
          </div>
        </td>
        {user?.superUser && !isMessaging && (
          <td>
            <div className="is-flex">
              <div
                className="action-button has-green-hover"
                onClick={addNewItem}
              >
                <Plus className={"icon is-small"} />
                {t("underwriter.addNewLimit")}
              </div>
              {!isTheOnlyEvent && (
                <div
                  className="action-button has-red-hover"
                  onClick={() => removeItem(item)}
                >
                  <Delete className={"icon is-small"} />
                  {t("remove")}
                </div>
              )}
            </div>
          </td>
        )}
        {user?.superUser && isMessaging && (
          <td>{error && <strong> {t("underwriter." + error)}</strong>}</td>
        )}
      </tr>
    </>
  );
}

export default UnderWriterConfigItemComponent;
