// @ts-nocheck
import * as React from "react";
import { Clause } from "../../../types";
import { validateMandatoryFields } from "../../../components/Form/helper";
import * as API from "../../../api/tradeFinanceApi";
import { useLoadingAction } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { FormikActions } from "formik";
import { EditForm } from "./assets";
import { PageHeader, Form } from "../../../components";

export default function ClauseDetailsPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();

  const isNew = id === "new";
  const loadClauseAction = useLoadingAction<Clause | undefined>(async () => {
    if (isNew) {
      return {} as Clause;
    } else if (id) {
      return API.findClause(id);
    }
  }, [id]);

  const clause: Clause = loadClauseAction.result as Clause;
  if (!clause) {
    return null;
  }

  async function submitClause(
    values: Clause,
    { setSubmitting, setError }: FormikActions<Clause>
  ) {
    try {
      Object.assign(clause, values);
      const { id: newId } = await API.saveClause({
        ...values,
        id: id === "new" ? undefined : values.id,
      });
      navigateToClauses();
      setSubmitting(false);
    } catch (e) {
      setError(e.message); // FIXME
      setSubmitting(false);
    }
  }

  function navigateToClauses() {
    if (isNew) {
      history.replace(`/portal/clause`);
    } else {
      history.push(`/portal/clause`);
    }
  }

  return (
    <div className="clauses-page">
      <div className="container">
        <PageHeader
          title={t(
            isNew ? "tradeFinance.newClause" : "tradeFinance.editClause"
          )}
        />

        <div className="clause-details box">
          <Form
            initialValues={{ ...clause }}
            enableReinitialize={true}
            key={id}
            validate={(values) =>
              validateMandatoryFields(
                values,
                ["clauseId", "clauseDesc", "dealType", "fieldId", "clauseText"],
                t
              )
            }
            onSubmit={submitClause}
          >
            {(form) => (
              <EditForm form={form} navigateToClauses={navigateToClauses} />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
