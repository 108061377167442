import React from "react";
import { useTranslation } from "react-i18next";
import { ProfileComponent, ProfileUpdateMessage } from "../../..";
import {
  UserNotificationComponent,
  RoundedButton,
} from "../../../../../../components";
import { SaveIcon } from "../../../../../../icons/Icons";
import { NotificationAlertType } from "../../../../../../types";
import { validateEmails } from "../../../helper";
import { useFormRef } from "../../../hooks";
import { BasicDataFormType } from "../types";

const phoneNumberMaxLength = 9;

function BasicDataForm({
  form,
  basicDataProperties,
  companies,
  showMessage,
  setShowMessage,
}: BasicDataFormType) {
  const { t } = useTranslation();
  const { values, handleSubmit, isSubmitting, error } = form;
  const { name, profileImage } = values;
  useFormRef(basicDataProperties.formRef, form);
  if (!companies) {
    return null;
  }

  function setUserNotifications(
    info: NotificationAlertType,
    toDo: NotificationAlertType,
    signature: NotificationAlertType
  ) {
    form.setFieldValue("notificationInformation", info);
    form.setFieldValue("notificationToDo", toDo);
    form.setFieldValue("notificationSignature", signature);
  }

  function validateLength() {
    let phoneNumberLength = form.values.phoneNumber
      ? form.values.phoneNumber.length
      : 0;
    return (
      (phoneNumberLength < phoneNumberMaxLength && phoneNumberLength !== 0) ||
      ([
        values.notificationSignature,
        values.notificationToDo,
        values.notificationInformation,
      ].includes("sms") &&
        phoneNumberLength === 0)
    );
  }

  function isValidForm(): boolean {
    return !validateLength() && validateEmails(form.values.email);
  }

  return (
    <>
      <ProfileUpdateMessage
        showMessage={showMessage}
        onClose={() => setShowMessage(false)}
      />

      <div className={"data-box"}>
        <div className="data-box--title is-flex">{t("profile.basicInfo")}</div>
        <div className={"data-box--body"}>
          <ProfileComponent form={form} />
        </div>
      </div>

      <div className={"data-box"}>
        <div className="data-box--title is-flex">
          {t("profile.notifications")}
        </div>
        <div className={"data-box--body"}>
          <UserNotificationComponent
            {...basicDataProperties}
            setUserNotifications={setUserNotifications}
          />
        </div>
      </div>

      {error && <div className="errors">{error}</div>}
      <RoundedButton
        label={t("saveChanges")}
        style={{ display: "flex", marginLeft: "auto" }}
        loading={isSubmitting}
        icon={<SaveIcon />}
        disabled={!isValidForm() || isSubmitting}
        onClick={handleSubmit}
      />
    </>
  );
}

export default BasicDataForm;
