import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CurrencyNumberFormat } from "../../..";
import { CodeElementComponent } from "../../../../pages";
import { useCodeElement } from "../../../../pages/tradeFinanceHooks";
import { TradFinanceRequestContext } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/context";
import {
  businessEntityDescriptorRefName,
  formatDate,
  isCollection,
  isGuarantee,
  isLetterOfCredit,
  isReceivedTradeFinance,
} from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { Collection, Guarantee } from "../../../../types";
import { EditableText } from "../../../CommonComponents";
import { HeaderComponentDetailsProps } from "../types";

function HeaderComponentDetails({
  tradeFinance,
  updateTradeFinanceTitle,
  isApproved,
}: HeaderComponentDetailsProps) {
  const tradeFinCtx = useContext(TradFinanceRequestContext);
  const { form } = tradeFinCtx;
  const { t } = useTranslation();
  const isReceivedGuarantee =
    tradeFinance.tradeFinanceType === "tradefin/receivedGuarantee";
  const tradeFinanceTypeName = t(
    "report.transactionTypes." +
      businessEntityDescriptorRefName(tradeFinance.businessEntityDescriptorId)
  );

  let guaranteeMainType = "";
  let guaranteeSubtype = "";

  if (isGuarantee(tradeFinance)) {
    guaranteeMainType = (tradeFinance as Guarantee).guaranteeMainType;
    guaranteeSubtype = (tradeFinance as Guarantee).subtype;
  }

  let guaranteeMainTypeName = useCodeElement(
    "tradefin/mainGuaranteeType",
    guaranteeMainType
  );
  let guaranteeSubtypeName = useCodeElement(
    "tradefin/TF_GuaranteeSubtype",
    guaranteeSubtype
  );

  const otherPartnerBankName = getBankName();
  function getBankName() {
    let bank = "";
    if (isGuarantee(tradeFinance)) {
      if (tradeFinance.tradeFinanceType === "tradefin/IssuedGuarantee") {
        if (guaranteeMainType === "counterGuarantee") {
          bank = (tradeFinance as Guarantee).finalGuaranteeBankName;
        } else if (guaranteeMainType === "advisedGuarantee") {
          bank = (tradeFinance as Guarantee).advisingBankName;
        } else {
          bank = "";
        }
      } else {
        bank = tradeFinance.otherPartnerBankName;
      }
    } else {
      bank = tradeFinance.otherPartnerBankName;
    }
    return bank;
  }

  return (
    <div className={"trade-finance-header-component--details box"}>
      <div className={"columns"}>
        <div className={"column is-3"}>
          <div className={"data"}>
            <div className={"caption"}>{t("tradeFinance.myReferenceNo")}</div>
            <div className={"value"}>
              {updateTradeFinanceTitle && form ? (
                <div className={"small-editable-text"}>
                  <EditableText
                    value={form.values.referenceNo || "-"}
                    onChange={updateTradeFinanceTitle}
                    inputClass={"is-small"}
                    disabled={tradeFinance.state === "converted" || isApproved}
                  />
                  {tradeFinance.state !== "converted" &&
                    !tradeFinance.referenceNo && (
                      <div className="has-text-danger">
                        {t("tradeFinance.referenceNo_validation")}
                      </div>
                    )}
                </div>
              ) : (
                <div>{tradeFinance.referenceNo}</div>
              )}
            </div>
          </div>
          <div className={"data"}>
            <div className={"caption"}>{t("tradeFinance.transactionType")}</div>
            <div className={"value"}>{tradeFinanceTypeName}</div>
          </div>
          {isLetterOfCredit(tradeFinance) && (
            <div className={"data"}>
              <div className={"caption"}>{t("tradeFinance.paymentMethod")}</div>
              <div className={"value"}>
                <CodeElementComponent
                  codeBaseId={"tradefin/TF_PaymentMethod"}
                  codeElement={tradeFinance.paymentMethod}
                />
              </div>
            </div>
          )}
          {guaranteeMainTypeName &&
            tradeFinance.tradeFinanceType !== "tradefin/incomingStandbyBG" && (
              <div className={"data"}>
                <div className={"caption"}>{t("tradeFinance.mainType")}</div>
                <div className={"value"}>{guaranteeMainTypeName}</div>
              </div>
            )}
        </div>
        <div className={"column is-3"}>
          <div className={"data"}>
            <div className={"caption"}>{t("tradeFinance.amount")}</div>
            <div className={"value"}>
              <span className={"amount"}>
                <CurrencyNumberFormat
                  displayType={"text"}
                  value={tradeFinance.amount}
                  currency={tradeFinance.currency}
                />
              </span>
              &nbsp;
              <span className={"caption"}>{tradeFinance.currency}</span>
            </div>
          </div>
          {!isGuarantee(tradeFinance) && (
            <div className={"data"}>
              <div className={"caption"}>{t("tradeFinance.balance")}</div>
              <div className={"value"}>
                <span className={"amount"}>
                  <CurrencyNumberFormat
                    displayType={"text"}
                    value={tradeFinance.balance}
                    currency={tradeFinance.currency}
                  />
                </span>
                &nbsp;
                <span className={"caption"}>{tradeFinance.currency}</span>
              </div>
            </div>
          )}
          {isLetterOfCredit(tradeFinance) && (
            <div className={"data"}>
              <div className={"caption"}>
                {t("tradeFinance.availableAmount")}
              </div>
              <div className={"value"}>
                <span className={"amount"}>
                  <CurrencyNumberFormat
                    displayType={"text"}
                    value={tradeFinance.availableAmount}
                    currency={tradeFinance.currency}
                  />
                </span>
                &nbsp;
                <span className={"caption"}>{tradeFinance.currency}</span>
              </div>
            </div>
          )}
          {guaranteeSubtypeName && (
            <div className={"data"}>
              <div className={"caption"}>{t("tradeFinance.subtype")}</div>
              <div className={"value"}>{guaranteeSubtypeName}</div>
            </div>
          )}
        </div>
        <div className={"column is-3"}>
          <div className={"data"}>
            <div className={"caption"}>
              {t("tradeFinance.otherPartnerName")}
            </div>
            <div className={"value"}>{tradeFinance.otherPartnerName}</div>
          </div>
          {otherPartnerBankName && (
            <div className={"data"}>
              <div className={"caption"}>
                {t(
                  !isReceivedGuarantee
                    ? "report.columns.counterPartyBankName"
                    : "report.columns.otherPartnerBankName"
                )}
              </div>
              <div className={"value"}>{otherPartnerBankName}</div>
            </div>
          )}
          {isReceivedTradeFinance(tradeFinance) && (
            <div className={"data"}>
              <div className={"caption"}>
                {t("tradeFinance.issuingBankReference")}
              </div>
              <div className={"value"}>{tradeFinance.externalIdentifier}</div>
            </div>
          )}
        </div>
        <div className={"column is-3"}>
          {isCollection(tradeFinance) ? (
            <div className={"data"}>
              <div className={"caption"}>
                {t("tradeFinance.delieverDocsAgainst")}
              </div>
              <div className={"value"}>
                <CodeElementComponent
                  codeBaseId={"tradefin/TF_ConfOfDocDelivery"}
                  codeElement={
                    (tradeFinance as Collection).conditionsOfDocumentDelivery
                  }
                />
              </div>
            </div>
          ) : (
            <div className={"data"}>
              <div className={"caption"}>{t("tradeFinance.dateOfExpiry")}</div>
              <div className={"value"}>
                {formatDate(tradeFinance.dateOfExpiry)}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderComponentDetails;
