export function validateEmails(emails: string) {
  if (emails) {
    let regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,20})?$/;
    let result = emails.replace(/\s/g, "").split(";");
    for (let i = 0; i < result.length; i++) {
      if (!regex.test(result[i])) {
        return false;
      }
    }
  }
  return true;
}
