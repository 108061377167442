import * as React from "react";
import { useTranslation } from "react-i18next";
import { GroupMember } from "../../../../api/tradeFinanceApi";

function GroupMembers({ groupMembers }: { groupMembers: GroupMember[] }) {
  const { t } = useTranslation();
  const membersOfGroupA = groupMembers
    .filter((m) => m.memberInGroupA)
    .map((m) => m.name)
    .join(",");
  const membersOfGroupB = groupMembers
    .filter((m) => m.memberInGroupB)
    .map((m) => m.name)
    .join(",");
  return (
    <div className="group-members">
      <div>
        <strong>{t("underwriter.membersOfGroupA")}: </strong>
        {membersOfGroupA}
      </div>

      <div>
        <strong>{t("underwriter.membersOfGroupB")}: </strong>
        {membersOfGroupB}
      </div>
    </div>
  );
}

export default GroupMembers;
