import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown, WarningIcon } from "../../../../icons/Icons";
import { UserConfigurationRowProps } from "../types";
import { GetContent } from "./assets";

function UserConfigurationRow({
  userConfig,
  open,
  isExpanded,
}: UserConfigurationRowProps) {
  const { t } = useTranslation();

  const [itemsChanged, setItemsChanged] = useState<boolean>(false);
  const [userChanged, setUserChanged] = useState<boolean>(false);

  return (
    <>
      <div className={"user-config-row"} key={userConfig.id}>
        <div className={"user-config-row-title is-flex"}>
          <strong className="user-name">{userConfig.user.name}</strong>
          {userConfig.superUser && (
            <span className="user-label">
              &nbsp;{`– ${t("userConfig.administrator")}`}
            </span>
          )}

          <div className="actions-column">
            {!isExpanded && (
              <div className="is-small action-button" onClick={open}>
                {t("expand")} <ArrowDown />
              </div>
            )}
            {isExpanded && (itemsChanged || userChanged) && (
              <div className="is-small action-button">
                {t("unsavedChanges")} <WarningIcon />
              </div>
            )}
          </div>
        </div>

        {isExpanded && (
          <GetContent
            userConfig={userConfig}
            setItemsChanged={setItemsChanged}
            setUserChanged={setUserChanged}
          />
        )}
      </div>
    </>
  );
}

export default UserConfigurationRow;
