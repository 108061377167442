import React from "react";
import { PageHeaderProps } from "./assets/types";

export default function PageHeader(props: PageHeaderProps) {
  const { title, headerContent } = props;

  return (
    <div className={"page-header container is-flex"}>
      <div className={"page-title"}>{title}</div>
      <div className={"portal-buttons"}>{headerContent}</div>
    </div>
  );
}
