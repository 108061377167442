import React, { useContext } from "react";
import { TabProps } from "./assets/type";
import { TradeFinanceEventHistoryComponent } from "./assets";
import { TradFinanceRequestContext } from "../../pages/TradeFinanceRequestDetailsPage/assets/context";

function EventHistoryTab({ tradeFinance }: TabProps) {
  const { loadEventHistoryAction, loadMessageHistoryAction } = useContext(
    TradFinanceRequestContext
  );

  if (!loadEventHistoryAction.result) {
    return null;
  } else if (!loadMessageHistoryAction.result) {
    return null;
  }

  return (
    <TradeFinanceEventHistoryComponent
      events={loadEventHistoryAction.result}
      messageEvents={loadMessageHistoryAction.result}
      tradeFinanceId={tradeFinance.id}
    />
  );
}

export default EventHistoryTab;
