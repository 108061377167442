import React, { useContext } from "react";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { BooleanFieldProps, FieldType } from "../types";

function BooleanField(props: BooleanFieldProps) {
  const { id, disabled, value, onChange } = props;
  const { lang } = useContext(AppContext);

  return DecorateField(
    Object.assign({}, props, { type: FieldType.boolean }),
    <input
      id={id}
      disabled={disabled}
      className="checkbox"
      value={value || false}
      checked={value || false}
      type="checkbox"
      onChange={onChange}
    />,
    lang
  );
}

export default BooleanField;
