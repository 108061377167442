// @ts-nocheck
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeaderComponentProps } from "./assets/types";
import { CopyTradeFinanceButton, HeaderComponentDetails } from "./assets";
import {
  getTradeFinanceShortName,
  isAmendment,
  isClaimEvent,
  isGuarantee,
  isLetterOfCredit,
  isOutgoingTradeFinance,
} from "../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TradFinanceRequestContext } from "../../pages/TradeFinanceRequestDetailsPage/assets/context";
import { TradeFinanceCardHeader } from "../../pages/TradeFinanceListPage/assets";

function HeaderComponent({
  updateTradeFinanceTitle,
  tradeFinance,
  isTradeFinanceEditable,
  isApproved,
}: HeaderComponentProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const letterOfCreditClaimEvent =
    isLetterOfCredit(tradeFinance) && isClaimEvent(tradeFinance);
  const { underWritersInfo } = useContext(TradFinanceRequestContext);

  function getPageSubtitle() {
    if (letterOfCreditClaimEvent) {
      let pageSubtitle =
        getTradeFinanceShortName(tradeFinance, t) +
        ": " +
        tradeFinance.identifier;
      return (
        <div className="page-subtitle">
          {t("tradeFinance.for", { subject: pageSubtitle })}
        </div>
      );
    }
    return "";
  }

  return (
    <div className="container trade-finance-header-component page-header">
      <div className="trade-finance-header-component--title">
        <TradeFinanceCardHeader
          tradeFinance={tradeFinance}
          underWritersInfo={underWritersInfo}
          editable={
            isTradeFinanceEditable &&
            !(
              isAmendment(tradeFinance) &&
              isGuarantee(tradeFinance) &&
              isOutgoingTradeFinance(tradeFinance)
            )
          }
          copyFrom={false}
          hideButtons={true}
        />
        {isOutgoingTradeFinance(tradeFinance) &&
          tradeFinance.tradeFinanceType !== "tradefin/outgoingStandbyLC" && (
            <div className="portal-buttons">
              {tradeFinance.state !== "concept" &&
                !letterOfCreditClaimEvent && (
                  <CopyTradeFinanceButton tradeFinance={tradeFinance} />
                )}
            </div>
          )}
      </div>

      {getPageSubtitle()}

      {id !== "new" && tradeFinance.identifier && (
        <HeaderComponentDetails
          tradeFinance={tradeFinance}
          updateTradeFinanceTitle={updateTradeFinanceTitle}
          isApproved={isApproved}
        />
      )}
    </div>
  );
}
export default HeaderComponent;
