import React, { useContext, useState } from "react";
import { ArrowLeft, ArrowRight } from "../../icons/Icons";
import moment, { Moment } from "moment";
import "moment/locale/cs";
import classNames from "classnames";
import { range } from "../../utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DeviceType, useDeviceType } from "../../pages/tradeFinanceHooks";
import { AppContext } from "../../App";
import { useCalendarEvents } from "./assets/hooks";
import { filteredCalendarEvents, getDayStyles } from "./assets/helper";
import { CalendarEventsPopup } from "./assets";
import { AdaptivePopup } from "..";

function CalendarCompact() {
  const device = useDeviceType();
  const { lang } = useContext(AppContext);
  const daysToShow = device === DeviceType.desktop ? 15 : 5;
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Moment>(moment().startOf("day"));
  const [clickedDay, setClickedDay] = useState<Moment | null>();

  const { data, isFetching } = useCalendarEvents(
    startDate,
    startDate.clone().add(daysToShow, "day")
  );

  function next() {
    setStartDate(startDate.clone().add(daysToShow, "day"));
  }

  function prev() {
    setStartDate(startDate.clone().subtract(daysToShow, "day"));
  }

  function closeEventsPopup() {
    setClickedDay(null);
  }

  return (
    <div className="calendar-compact">
      <div className="calendar-compact--days-container">
        <button
          className="calendar-compact--control"
          onClick={prev}
          disabled={isFetching}
        >
          <ArrowLeft />
        </button>
        <div className="calendar-compact--days">
          {range(daysToShow).map((i) => {
            const day = moment(data?.fromDate || startDate).add(i, "day");
            const calendarEventsForDay = filteredCalendarEvents(data, day);

            return (
              <div key={day.toISOString()} className="calendar-compact--day">
                <div
                  onClick={() =>
                    setClickedDay(
                      !calendarEventsForDay || clickedDay?.isSame(day)
                        ? null
                        : day
                    )
                  }
                  className={classNames(
                    "calendar-compact--day-box",
                    getDayStyles(data, day)
                  )}
                >
                  <div className="day-no">{day.date()}</div>
                  <div className="day-name">
                    {day.locale(lang).format("ddd")}
                  </div>
                  <div className="month">{day.locale(lang).format("MMM")}</div>
                </div>

                {clickedDay && calendarEventsForDay && day.isSame(clickedDay) && (
                  <AdaptivePopup
                    active={true}
                    deactivate={() => setClickedDay(null)}
                  >
                    <CalendarEventsPopup
                      calendarEvents={calendarEventsForDay}
                      autoPos={device === DeviceType.desktop}
                      className={classNames({ "is-right": i > daysToShow / 2 })}
                      close={closeEventsPopup}
                    />
                  </AdaptivePopup>
                )}
              </div>
            );
          })}
        </div>
        <button
          className="calendar-compact--control"
          onClick={next}
          disabled={isFetching}
        >
          <ArrowRight />
        </button>
      </div>
      <Link to="/portal/calendar">
        <div className="show-whole-calendar">{t("calendar.wholeCalendar")}</div>
      </Link>
    </div>
  );
}

export default CalendarCompact;
