import classnames from "classnames";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DecorateField, EnlargedTextField } from "..";
import { RoundedButton } from "../..";
import { AppContext } from "../../../App";
import { EnlargeIcon } from "../../../icons/Icons";
import { TextAreaProps } from "../types";

function TextAreaField(props: TextAreaProps) {
  const { lang } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    id,
    disabled,
    value,
    error,
    placeholder,
    rows,
    maxLength,
    onBlur,
    disableEnlarge,
    headerElementsFactory,
  } = props;
  const [isEnlarged, setEnlarged] = useState(false);
  const headerElements = headerElementsFactory
    ? headerElementsFactory(props)
    : null;
  const headerButtons = disableEnlarge ? (
    headerElements
  ) : (
    <div>
      {headerElements}
      <RoundedButton
        transparent={true}
        label={t("enlargeField")}
        onClick={() => setEnlarged(true)}
        icon={<EnlargeIcon />}
      />
    </div>
  );

  function onChange(e: React.ChangeEvent<any>) {
    const val = e.target.value;
    props.setValue(props.format ? props.format(val) : val);
  }

  const Field = DecorateField(
    props,
    <textarea
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      className={classnames("textarea is-small", { "is-danger": !!error })}
      rows={rows}
      cols={maxLength}
      maxLength={maxLength}
      value={value || ""}
      onBlur={onBlur}
      onChange={onChange}
    />,
    lang,
    headerButtons
  );

  if (isEnlarged) {
    return (
      <>
        {Field}
        <EnlargedTextField {...props} onClose={() => setEnlarged(false)} />
      </>
    );
  } else {
    return Field;
  }
}

export default TextAreaField;
