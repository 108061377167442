import React from "react";
import { useTranslation } from "react-i18next";
import {
  CodeBaseField,
  TextAreaField,
  TextField,
  RoundedButton,
} from "../../../../../components";
import { getFieldProps } from "../../../../../components/Form/helper";
import { useCodeBaseAsSelectOptions } from "../../../../../hooks";
import { SaveIcon } from "../../../../../icons/Icons";
import { filterDealTypes } from "../helper";
import { filerFields } from "./assets/helper";
import { EditFormType } from "./assets/types";

function EditForm({ form, navigateToClauses }: EditFormType) {
  const { t } = useTranslation();
  const { values, error, isSubmitting, handleSubmit } = form;
  const fieldsOptions = useCodeBaseAsSelectOptions("tfportal/clauseFields");

  const dealTypeProps = getFieldProps(form, "dealType", t("clause.dealType"), {
    setValue: (dealType: string[]) => {
      form.setFieldValue("dealType", dealType);
      if (fieldsOptions && values.fieldId) {
        const fieldsByDealType = filerFields(fieldsOptions, dealType);
        const newFields = values.fieldId.filter((fieldId) =>
          fieldsByDealType.find((opt) => opt.value === fieldId)
        );
        form.setFieldValue("fieldId", newFields);
      }
    },
  });

  return (
    <>
      <div className="columns">
        <div className="column">
          <TextField
            {...getFieldProps(form, "clauseId", t("clause.clauseId"))}
            required={true}
          />
          <TextField
            {...getFieldProps(form, "clauseDesc", t("clause.clauseDesc"))}
            required={true}
          />
          <CodeBaseField
            codeBaseId="tradefin/TF_productType"
            optionsFilter={filterDealTypes}
            multiSelect={true}
            {...dealTypeProps}
            required={true}
          />
          <CodeBaseField
            codeBaseId="tfportal/clauseFields"
            optionsFilter={(options) => filerFields(options, values.dealType)}
            portalOnly={true}
            multiSelect={true}
            {...getFieldProps(form, "fieldId", t("clause.fieldId"))}
            required={true}
          />
          <TextAreaField
            {...getFieldProps(form, "clauseText", t("clause.clauseText"))}
            required={true}
          />
        </div>
      </div>

      {error && <div className="errors">{error}</div>}

      <div className="buttons has-right-align">
        <RoundedButton label={t("cancel")} onClick={navigateToClauses} />
        <RoundedButton
          label={t("save")}
          disabled={isSubmitting}
          icon={<SaveIcon />}
          loading={isSubmitting}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}

export default EditForm;
