import classNames from "classnames";
import React from "react";
import { DeviceType, useDeviceType } from "../../../pages/tradeFinanceHooks";
import { AdaptivePopupProps } from "../assets/types";

function AdaptivePopup(props: AdaptivePopupProps) {
  const device = useDeviceType();
  const { active, deactivate } = props;

  if (device !== DeviceType.desktop) {
    return (
      <div className={classNames("modal", { "is-active": active })}>
        <div className="modal-background" onClick={() => deactivate()}></div>
        <div className="modal-content box">{props.children}</div>
      </div>
    );
  }

  return <>{props.children}</>;
}
export default AdaptivePopup;
