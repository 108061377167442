import React from "react";
import { useTranslation } from "react-i18next";
import { CurrencyNumberFormat } from "../../..";
import { formatDate } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TransactionDetails } from "../../../../types";

function FinancialDataComp({
  financialData,
}: TransactionDetails & { tradeFinanceType: string }) {
  const { t } = useTranslation();

  if ((financialData || []).length === 0) {
    return null;
  }

  return (
    <div>
      <div className="table--header">
        <div className="table--title">
          {t("transactionDetails.payments.title")}
        </div>
      </div>
      <div className="table-container">
        <table className="table portal-table is-striped is-fullwidth">
          <thead>
            <tr>
              <th align="right">{t("transactionDetails.payments.amount")}</th>
              <th align="right">{t("transactionDetails.payments.date")}</th>
              <th align="right">
                {t("transactionDetails.payments.invoiceNumber")}
              </th>
            </tr>
          </thead>
          <tbody>
            {financialData.map((data, idx) => {
              return (
                <tr key={idx}>
                  <td align="right">
                    <strong>
                      <CurrencyNumberFormat
                        displayType={"text"}
                        value={data.amount}
                        currency={data.currency}
                      />{" "}
                      {data.currency}
                    </strong>
                  </td>
                  <td align="right">{formatDate(data.valueDate)}</td>
                  <td align="right">
                    {data.invoiceNumbers?.map((invoiceNumber, idx2) => {
                      if (invoiceNumber) {
                        return (
                          <span key={idx + "-" + idx2}>
                            {invoiceNumber}
                            <br />
                          </span>
                        );
                      }
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FinancialDataComp;
