// @ts-nocheck
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { AppContext } from "../../../../../../../../App";
import { ConfirmButton } from "../../../../../../../../components";
import { Delete, Edit } from "../../../../../../../../icons/Icons";
import { useCodeElement } from "../../../../../../../tradeFinanceHooks";
import { formatDate } from "../../../../../tradeFinanceUtils";
import { MaturitiesTableRowProps } from "../types";

function MaturityTableRow({
  maturity,
  editable,
  setEditedMaturity,
  deleteMaturity,
}: MaturitiesTableRowProps) {
  const { lang } = useContext(AppContext);
  const { t } = useTranslation();
  const paymentMethodUniv = useCodeElement(
    "tradefin/TF_MaturityPaymentMethod",
    maturity.paymentMethod
  );
  return (
    <tr key={maturity.id}>
      <td>{paymentMethodUniv}</td>
      <td>
        <NumberFormat
          displayType="text"
          thousandSeparator={" "}
          decimalSeparator={","}
          value={maturity.amount}
        />
      </td>
      <td>{formatDate(maturity.dueDate)}</td>
      <td>{maturity.stateName[lang]}</td>
      {editable && (
        <td className="row-actions">
          {maturity.state === "CREATED" && (
            <>
              <button
                className="button is-small is-outlined"
                onClick={() => setEditedMaturity(maturity)}
              >
                <Edit />
                {t("edit")}
              </button>
              <ConfirmButton
                confirmation={t("areYouSureToDelete")}
                button={
                  <button
                    className="button is-small is-outlined"
                    onClick={() => deleteMaturity(maturity)}
                  >
                    <Delete />
                    {t("delete")}
                  </button>
                }
              />
            </>
          )}
        </td>
      )}
    </tr>
  );
}

export default MaturityTableRow;
