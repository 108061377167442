import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { NotificationContext } from "./assets/context";

function Notification() {
  const location = useLocation();
  let { notification, setNotification } = useContext(NotificationContext);

  useEffect(() => {
    setNotification(null);
  }, [location]);

  if (!notification) {
    return null;
  }

  function hideNotification() {
    setNotification(null);
  }

  return (
    <div className="app-notification container" onClick={hideNotification}>
      <div className=" notification is-danger">{notification}</div>
    </div>
  );
}
export default Notification;
