import { UnderWritersInfo } from "../../../../../../../types";

export function getSubtitleClasses(isGroupA: boolean, info: UnderWritersInfo) {
  let actualGroup: string[] = isGroupA
    ? info.underWritersInGroupA
    : info.underWritersInGroupB;

  let targetNumber: number = isGroupA
    ? info.numberOfGroupA
    : info.numberOfGroupB;

  let sumOfUnderwriters =
    info.underWritersInGroupA.length + info.underWritersInGroupB.length;
  let sumOfGroups = info.numberOfGroupA + info.numberOfGroupB;

  if (actualGroup.length === targetNumber) {
    return "subtitle is-gray";
  }
  if (sumOfGroups - 1 === sumOfUnderwriters) {
    return "subtitle is-danger";
  }

  return "subtitle";
}
