import { UserConfig } from "../../../types";

export type UserConfigItemValues = {
  groupA: boolean;
  groupB: boolean;
  draftMaker: boolean;
  dataEntry: boolean;
};

export type UserConfigItem = EventDef & UserConfigItemValues;

export type EventDef = {
  product: Product;
  eventName: Event;
  eventIds: string[]; //mapped tradeFinance event id-s
};

export enum Product {
  BankGuarantee = "BankGuarantee",
  LetterOfCredit = "LetterOfCredit",
  standBy = "standBy",
  documentaryCollection = "documentaryCollection",
}

export enum Event {
  new = "new",
  amendment = "amendment",
  messaging = "messaging",
  preCheck = "preCheck",
  documentsPresentation = "documentsPresentation",
}

export type UserConfigurationRowProps = {
  userConfig: UserConfig;
  open: () => void;
  isExpanded: boolean;
};

export type UserConfigurationContextType = {
  editedUserConfigId: number | null;
  setEditedUserConfigId: (configId: number | null) => void;
  reload: () => Promise<void>;
};
