import { UserConfig } from "../../../types";
import {
  EventDef,
  Product,
  Event,
  UserConfigItemValues,
  UserConfigItem,
} from "./types";

export const deepEqual = require("deep-equal");

export const QUERY_KEY = "useConfigs";

export const defaultValuesInitState: UserConfigItemValues = {
  groupA: false,
  groupB: false,
  dataEntry: true,
  draftMaker: false,
};

export function changeUserConfigItemField(
  item: UserConfigItem,
  field: "groupA" | "groupB" | "dataEntry" | "draftMaker"
) {
  if (field === "groupA" && item.groupA) {
    item.groupB = false;
    item.dataEntry = true;
    item.draftMaker = true;
  } else if (field === "groupB" && item.groupB) {
    item.groupA = false;
    item.dataEntry = true;
    item.draftMaker = true;
  }

  return item;
}

export function convertItemsToUserConfig(
  items: UserConfigItem[],
  userConfig: UserConfig
): UserConfig {
  const finalConfig: UserConfig = {
    ...userConfig,
    groupA: [],
    groupB: [],
    dataEntry: [],
    draftMaker: [],
  };

  items.forEach((item) => {
    if (item.groupA) {
      finalConfig.groupA = [...finalConfig.groupA, ...item.eventIds];
    }

    if (item.groupB) {
      finalConfig.groupB = [...finalConfig.groupB, ...item.eventIds];
    }

    if (item.draftMaker && item.product !== "documentaryCollection") {
      finalConfig.draftMaker = [...finalConfig.draftMaker, ...item.eventIds];
    }

    if (item.dataEntry) {
      finalConfig.dataEntry = [...finalConfig.dataEntry, ...item.eventIds];
    }
  });

  return finalConfig;
}

export function convertUserConfigToItems(
  userConfig: UserConfig
): UserConfigItem[] {
  return eventDefs.map((eventDef) => {
    return {
      ...eventDef,
      groupA:
        userConfig.groupA.length > 0 &&
        eventDef.eventIds.every((evenId) => userConfig.groupA.includes(evenId)),
      groupB:
        userConfig.groupB.length > 0 &&
        eventDef.eventIds.every((evenId) => userConfig.groupB.includes(evenId)),
      draftMaker:
        userConfig.draftMaker.length > 0 &&
        eventDef.eventIds.every((evenId) =>
          userConfig.draftMaker.includes(evenId)
        ),
      dataEntry:
        userConfig.dataEntry.length > 0 &&
        eventDef.eventIds.every((evenId) =>
          userConfig.dataEntry.includes(evenId)
        ),
    } as UserConfigItem;
  });
}

export const eventDefs: EventDef[] = [
  {
    product: Product.BankGuarantee,
    eventName: Event.new,
    eventIds: [
      "tradefin__IssuedGuarantee__event__completeRequestEvent",
      "tradefin__IssuedGuarantee__sendApplication",
    ],
  },
  {
    product: Product.BankGuarantee,
    eventName: Event.amendment,
    eventIds: ["tradefin__IssuedGuarantee__event__amendmentEvent"],
  },
  {
    product: Product.BankGuarantee,
    eventName: Event.messaging,
    eventIds: [
      "tradefin__IssuedGuarantee__event__message",
      "tradefin__receivedGuarantee__event__message",
    ],
  },

  {
    product: Product.LetterOfCredit,
    eventName: Event.new,
    eventIds: [
      "tradefin__importLC__event__completeRequestEvent",
      "tradefin__importLC__sendApplication",
      "tradefin__importLC__event__claimEvent",
    ],
  },
  {
    product: Product.LetterOfCredit,
    eventName: Event.amendment,
    eventIds: [
      "tradefin__importLC__event__amendmentEvent",
      "tradefin__importLC__event__cancelEvent",
    ],
  },
  {
    product: Product.LetterOfCredit,
    eventName: Event.messaging,
    eventIds: [
      "tradefin__importLC__event__message",
      "tradefin__exportLC__event__message",
    ],
  },
  {
    product: Product.LetterOfCredit,
    eventName: Event.preCheck,
    eventIds: [
      "tradefin__exportLC__event__claimEvent",
      "tradefin__exportLC__event__preCheckEvent",
    ],
  },

  {
    product: Product.documentaryCollection,
    eventName: Event.new,
    eventIds: [
      "tradefin__exportCollection__event__completeRequestEvent",
      "tradefin__exportCollection__sendApplication",
    ],
  },
  {
    product: Product.documentaryCollection,
    eventName: Event.messaging,
    eventIds: [
      "tradefin__exportCollection__event__message",
      "tradefin__importCollection__event__message",
    ],
  },

  {
    product: Product.standBy,
    eventName: Event.new,
    eventIds: [
      "tradefin__outgoingStandbyBG__event__completeRequestEvent",
      "tradefin__outgoingStandbyBG__sendApplication",
    ],
  },
  {
    product: Product.standBy,
    eventName: Event.amendment,
    eventIds: [
      "tradefin__outgoingStandbyBG__event__amendmentEvent",
      "tradefin__outgoingStandbyLC__event__amendmentEvent",
    ],
  },
  {
    product: Product.standBy,
    eventName: Event.messaging,
    eventIds: [
      "tradefin__incomingStandbyBG__event__message",
      "tradefin__incomingStandbyLC__event__message",
      "tradefin__outgoingStandbyBG__event__message",
      "tradefin__outgoingStandbyLC__event__message",
    ],
  },
];
