import React from "react";
import { useTranslation } from "react-i18next";
import { CurrencyNumberFormat } from "../../..";
import { formatDate } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TransactionDetails } from "../../../../types";

function ClaimsComp({ claims }: TransactionDetails) {
  const { t } = useTranslation();

  if ((claims || []).length === 0) {
    return null;
  }

  function getEventStateName(eventState: string) {
    if (eventState === "refused" || eventState === "rejected") {
      return t("tradeFinance.eventState.refused");
    } else if (eventState === "approved") {
      return t("tradeFinance.eventState.accepted");
    } else {
      return t("tradeFinance.eventState.received");
    }
  }

  return (
    <div className={"claims"}>
      <div className="table--header">
        <div className="table--title">
          {t("transactionDetails.claims.title")}
        </div>
      </div>
      <div className="table-container">
        <table className="table portal-table is-striped is-fullwidth">
          <thead>
            <tr>
              <th align="right">{t("tradeFinance.claim.claimAmount")}</th>
              <th align="right">{t("tradeFinance.claim.date")}</th>
              <th align="right">{t("tradeFinance.state")}</th>
            </tr>
          </thead>
          <tbody>
            {claims.map((claim, idx) => {
              return (
                <tr key={idx}>
                  <td align="right">
                    <strong>
                      <CurrencyNumberFormat
                        displayType={"text"}
                        value={claim.claimAmount}
                        currency={claim.currency}
                      />{" "}
                      {claim.currency}
                    </strong>
                  </td>
                  <td align="right">{formatDate(claim.date)}</td>
                  <td align="right">{getEventStateName(claim.eventState)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClaimsComp;
