import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentRequirement } from "../../../../../../types";

import { RoundedButton } from "../../../../../../components";
import { Plus, WarningIcon } from "../../../../../../icons/Icons";
import { useHistory, useParams } from "react-router-dom";
import {
  isCollection,
  isTradeFinanceEditable,
} from "../../../tradeFinanceUtils";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import { TradFinanceRequestContext } from "../../../context";
import { Step } from "..";
import { StepId } from "../../../type";
import {
  DocumentRequirementForm,
  DocumentRequirementTable,
  LetterOfCreditDocumentRequirements,
} from "./assets";

function DocumentRequirementsStep() {
  const tradFinanceRequestContext = useContext(TradFinanceRequestContext);
  const {
    tradeFinance,
    loadDocumentRequirementsAction,
    loadDocRequirementTemplates,
    form,
    isUnsavedTerm,
    setUnsavedTerm,
  } = tradFinanceRequestContext;
  const { t } = useTranslation();
  // @ts-ignore
  const { id } = useParams();
  const editable = isTradeFinanceEditable(tradFinanceRequestContext);
  const [documentRequirement, setDocumentRequirement] =
    useState<DocumentRequirement | null>(null);
  const history = useHistory();

  const documentRequirements = loadDocumentRequirementsAction.result;
  const templates = loadDocRequirementTemplates.result;

  function renderForm() {
    return (
      <DocumentRequirementForm
        setDocumentRequirement={setDocumentRequirement}
        documentRequirement={documentRequirement!}
        submitDocumentRequirement={submitDocumentRequirement}
        templates={templates || []}
      />
    );
  }

  function renderTable() {
    return (
      <div className={"is-flex"}>
        <DocumentRequirementTable
          documentRequirements={documentRequirements || []}
          deleteDocumentRequirement={deleteDocumentRequirement}
          editable={editable}
          setDocumentRequirement={setDocumentRequirement}
          tradeFinanceId={id!}
        />
      </div>
    );
  }

  async function submitDocumentRequirement(toSave: DocumentRequirement) {
    if (!tradeFinance.id) {
      const { id } = await TFApi.saveTemplate(form.values);
      if (id) {
        await TFApi.saveDocumentRequirement({ ...toSave, tradeFinance: id });

        history.push("/portal/template/" + id);
      }
    } else {
      await TFApi.saveDocumentRequirement(toSave);
    }
    await loadDocumentRequirementsAction.run();
    setDocumentRequirement(null);
    setUnsavedTerm(false);
  }

  async function deleteDocumentRequirement(
    documentRequirement: DocumentRequirement
  ) {
    await TFApi.deleteDocumentRequirement(documentRequirement.id);
    await loadDocumentRequirementsAction.run();
  }

  switch (tradeFinance.tradeFinanceType) {
    case "tradefin/importLC":
    case "tradefin/outgoingStandbyLC":
    case "tradefin/exportLC":
      return (
        <Step
          stepId={StepId.documentRequirements}
          title={t(
            isCollection(tradeFinance)
              ? "tradeFinance.documentTerms"
              : "tradeFinance.documentRequirements"
          )}
        >
          <div className={"document-requirements-step"}>
            <LetterOfCreditDocumentRequirements />
          </div>
        </Step>
      );
    case "tradefin/importCollection":
    case "tradefin/outgoingStandbyLC":
    case "tradefin/exportCollection":
      return (
        <Step
          stepId={StepId.documentRequirements}
          state={t(
            "tradeFinance.stepState." +
              (documentRequirements && documentRequirements.length > 0
                ? "finished"
                : "inProgress")
          )}
          headerEnd={
            isUnsavedTerm ? <WarningIcon className="warning-icon" /> : null
          }
          title={t(
            isCollection(tradeFinance)
              ? "tradeFinance.documentTerms"
              : "tradeFinance.documentRequirements"
          )}
        >
          <div className={"document-requirements-step"}>
            {renderTable()}
            {documentRequirement && renderForm()}
            {editable && !documentRequirement && (
              <RoundedButton
                label={t("add")}
                buttonClass={"top-margin"}
                onClick={() => {
                  setDocumentRequirement({
                    tradeFinance: tradeFinance.id,
                  } as DocumentRequirement);
                  setUnsavedTerm(true);
                }}
                inverse={true}
                icon={<Plus />}
              />
            )}
          </div>
        </Step>
      );
    default:
      return null;
  }
}

export default DocumentRequirementsStep;
