import { ChipsFilter, TradeFinanceSearchParams } from "../../types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import * as React from "react";
import { ChipsFilterProps } from "./assets/types";

function ChipsFilterComp({
  filterValue,
  setFilterValue,
  listStats,
  listType,
  sortOptionField,
}: ChipsFilterProps) {
  const { t } = useTranslation();

  const filterAmendments = filterValue?.events?.includes("amendmentEvent");
  const showCancelEvents =
    listType === "concept" ||
    (listType === "letterOfCredit" &&
      filterValue?.chipsFilter === ChipsFilter.drafts);
  const showDraftFilter =
    listType === "concept" ||
    (["guarantees", "letterOfCredit", "standby"].includes(listType) &&
      filterValue?.chipsFilter === ChipsFilter.drafts);

  return (
    <div className="chips-filters">
      {listType !== "concept" && (
        <div className={"chips-filter-container is-flex"}>
          {Object.keys(ChipsFilter).map((chipsName) => {
            let selected =
              (filterValue?.chipsFilter || ChipsFilter.activated) === chipsName;
            return (
              <button
                className={classnames("button is-rounded is-small", {
                  "is-success": selected,
                  "is-outlined": !selected,
                })}
                key={chipsName}
                onClick={() =>
                  setFilterValue({
                    ...filterValue,
                    chipsFilter: chipsName,
                    events: undefined,
                  })
                }
              >
                {t("chipsFilter." + chipsName)}
                {listStats && (
                  <span className="count">
                    ({(listStats as any)[chipsName]})
                  </span>
                )}
              </button>
            );
          })}

          <div className={"is-flex is-right"}>{sortOptionField}</div>
        </div>
      )}

      {showDraftFilter && (
        <div className={"chips-filter-container draft-filters is-flex"}>
          <span className="drafts-title">
            {t("chipsFilter.whatDraftsToDisplay")}:
          </span>
          <button
            className={classnames("button is-rounded is-small", {
              "is-success": !filterAmendments,
              "is-outlined": filterAmendments,
            })}
            onClick={() => {
              const newFilterValue = {
                ...filterValue,
              } as TradeFinanceSearchParams;
              if (newFilterValue.events) {
                delete newFilterValue.events;
                setFilterValue(newFilterValue);
              }
            }}
          >
            {t("chipsFilter.applications")}
            <span className="count">({listStats?.applications})</span>
          </button>

          <button
            className={classnames("button is-rounded is-small", {
              "is-success": filterAmendments,
              "is-outlined": !filterAmendments,
            })}
            onClick={() => {
              const eventFilters = showCancelEvents
                ? ["amendmentEvent", "cancelEvent"]
                : ["amendmentEvent"];
              setFilterValue({ ...filterValue, events: eventFilters });
            }}
          >
            {t("chipsFilter.amendments")}
            <span className="count">({listStats?.amendments})</span>
          </button>

          {listType === "concept" && (
            <div className={"is-flex is-right"}>{sortOptionField}</div>
          )}
        </div>
      )}
    </div>
  );
}
export default ChipsFilterComp;
