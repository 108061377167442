import classnames from "classnames";
import moment from "moment";
import React, { useRef } from "react";
import { DocumentsTable } from "../..";
import { KBAvatar } from "../../../../icons/Icons";
import { MessageComponentProps } from "../types";

function MessageComponent({
  company,
  comment,
  tradeFinanceId,
}: MessageComponentProps) {
  const {
    createTime,
    lastTime,
    text,
    subject,
    createdBy,
    isCreateInFrontEnd,
    createUserEmail,
    createUserPhone,
  } = comment;
  const companyName = company.companyName;
  const messagesRef = useRef<HTMLDivElement>(null);

  function getMessageHeaderComponent() {
    return (
      <div
        className={classnames("contact-message-header", {
          dark: isCreateInFrontEnd,
        })}
      >
        <div
          className={classnames("message-avatar", { dark: isCreateInFrontEnd })}
        >
          {!isCreateInFrontEnd && (
            <div className="kb-avatar">
              <KBAvatar />
            </div>
          )}
          {isCreateInFrontEnd && companyName && (
            <div className={"contact-message-header-sender"}>{companyName}</div>
          )}
          <div className={"contact-message-header-sender"}>{createdBy}</div>

          {!isCreateInFrontEnd && (
            <>
              <div className={"contact-message-header-contact"}>
                {createUserEmail}
              </div>
              <div className={"contact-message-header-contact"}>
                {createUserPhone}
              </div>
            </>
          )}

          <div className="contact-message-header-details">
            {moment(lastTime).format("DD.MM.YYYY HH:mm")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classnames("contact-message-container", {
          dark: isCreateInFrontEnd,
        })}
      >
        {!isCreateInFrontEnd ? (
          getMessageHeaderComponent()
        ) : (
          <div className="contact-message-header contact-message-header-mobile" />
        )}

        <div
          className={classnames("contact-message", {
            dark: isCreateInFrontEnd,
          })}
        >
          <div className="contact-message-subject is-bold">{subject}</div>
          <div
            className="contact-message-content"
            style={{ wordBreak: "break-word" }}
          >
            {text}
          </div>

          <div className="contact-message-file">
            <DocumentsTable
              documentHolder={comment}
              documentHolderType={"comment"}
              tradeFinanceId={tradeFinanceId}
            />
          </div>

          <div
            className={classnames("contact-message-corner", {
              dark: isCreateInFrontEnd,
            })}
          >
            <div className="corner-inner" />
          </div>
        </div>

        {isCreateInFrontEnd ? (
          getMessageHeaderComponent()
        ) : (
          <div className="contact-message-header" />
        )}
      </div>
    </>
  );
}

export default MessageComponent;
