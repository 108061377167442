// @ts-nocheck
import React, { useContext, useRef, useState } from "react";
import * as API from "../../api/portalApi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { LoginProps } from "./assets/type";
import { PasswordInput, RoundedButton } from "../../components";

export default function LoginPage(props: LoginProps) {
  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
    error: null,
  });
  const emailField = useRef<HTMLInputElement | null>(null);
  const { email, password, loading, error } = state;
  const history = useHistory();
  const { t } = useTranslation();
  const { portalConfig } = useContext(AppContext);

  function EnterKeyEvent(key: String) {
    if (key.toUpperCase() === "ENTER") {
      doLogin();
    }
  }

  async function doLogin() {
    try {
      setState({ ...state, loading: true, error: null });
      await props.login(email, password);

      if (
        API.locationBeforeAuth &&
        API.locationBeforeAuth.pathname !== "/login"
      ) {
        history.replace(API.locationBeforeAuth);
        API.setLocationBeforeAuth(null);
      } else {
        history.push("/portal/dashboard");
      }
    } catch (error) {
      setState({ ...state, error: error.message, loading: false });
    }
  }

  if (portalConfig.caas) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="page login-page">
        <div className="login-wrapper">
          <div className="form-container login box">
            <div className="login-form">
              <div className="fields">
                <div className="field">
                  <label className="label">{t("login.email")}</label>
                  <div className="control">
                    <input
                      ref={emailField}
                      className="input"
                      type="email"
                      value={email}
                      onKeyPress={(e) => EnterKeyEvent(e.key)}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">{t("login.password")}</label>
                  <PasswordInput
                    className="input"
                    value={password}
                    onKeyPress={(e: KeyboardEvent) => EnterKeyEvent(e.key)}
                    onChange={(e: any) =>
                      setState({
                        ...state,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="login-button-container">
                <RoundedButton
                  loading={loading}
                  buttonClass="login-button"
                  large={true}
                  label={t("login.login")}
                  onClick={doLogin}
                />
              </div>
              {error && <div className="errors">{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
