import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Copy } from "../../../../icons/Icons";
import * as API from "../../../../api/tradeFinanceApi";
import { TradeFinance } from "../../../../types";
import { ConfirmButton, RoundedButton } from "../../..";

function CopyTradeFinanceButton({
  tradeFinance,
}: {
  tradeFinance: TradeFinance;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  if (!tradeFinance || !tradeFinance.id) {
    return null;
  }

  async function createCopy() {
    const { id } = await API.copyTradeFinance(tradeFinance, false);
    //const href = history.createHref({pathname: `/portal/trade-finance-request/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`});
    //window.open(href);
    history.push(`/portal/trade-finance-request/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`);
  }

  return (
    <ConfirmButton
      confirmation={t("tradeFinance.copyConfirm")}
      button={
        <RoundedButton
          label={t("tradeFinance.copy")}
          icon={<Copy />}
          onClick={createCopy}
        />
      }
    />
  );
}

export default CopyTradeFinanceButton;
