import { useEffect, useState } from "react";

export function useModal(
  initialOpened: boolean
): [boolean, () => void, () => void] {
  const [isOpened, setOpened] = useState(initialOpened);

  useEffect(() => {
    setOpened(initialOpened);
  }, [initialOpened]);

  function close() {
    setOpened(false);
  }

  function open() {
    setOpened(true);
  }

  return [isOpened, open, close];
}
