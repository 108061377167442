import { FormikProps } from "formik";
import moment from "moment";
import {
  FieldProps,
  SelectFieldProps,
  SelectOption,
} from "../../../components/Fields/types";
import {
  ContainerDesc,
  ContainerType,
  FieldContainer,
} from "../../../components/Form/types";
import { collectFields } from "../../../components/Form/helper";
import {
  Language,
  Partner,
  TFDocument,
  TFEvent,
  TradeFinance,
  Univ,
} from "../../../types";
import {
  isImportLetterOfCreditInCancelEventWaitingForClient,
  isPreCheckEvent,
  isTradeFinanceEditable,
} from "./tradeFinanceUtils";
import { TradeFinanceRequestContextType } from "./type";

export function getSelectFieldValueLabel(
  conainer: ContainerDesc,
  fieldId: string,
  value: string,
  lang: Language
) {
  const select: SelectFieldProps = collectFields(conainer).filter(
    (f) => f.id === fieldId
  )[0] as SelectFieldProps;
  const selectedOption: SelectOption =
    select &&
    select.options &&
    select.options.filter((opt) => opt.value === value)[0];
  return selectedOption ? (selectedOption.label as Univ)[lang] : null;
}

export function setVisible(fieldProps: FieldProps, visible: boolean) {
  if (fieldProps) {
    fieldProps.hidden = !visible;
  }
}

export function setVisibleAndEditable(
  fieldProps: FieldProps,
  visible: boolean,
  editable: boolean
) {
  if (fieldProps) {
    fieldProps.hidden = !visible;
    fieldProps.disabled = !editable;
  }
}

export function setEditable(fieldProps: FieldProps, editable: boolean) {
  if (fieldProps) {
    fieldProps.disabled = !editable;
  }
}

export function setRequiredAndVisible(
  fieldProps: FieldProps,
  requiredAndVisible: boolean
) {
  if (fieldProps) {
    fieldProps.required = requiredAndVisible;
    fieldProps.hidden = !requiredAndVisible;
  }
}

export function isDetailStepEditable(
  tfinContext: TradeFinanceRequestContextType
) {
  return (
    isTradeFinanceEditable(tfinContext) &&
    !isPreCheckEvent(tfinContext.tradeFinance) &&
    !isImportLetterOfCreditInCancelEventWaitingForClient(
      tfinContext.tradeFinance
    )
  );
}

export const partnerFieldMapping = {
  otherPartnerName: "name",
  otherPartnerRegistrationNumber: "partnerRegistrationNumber",
  otherPartnerSwiftAddress: "partnerSwiftAddress",
  otherPartnerAddressDesc: "partnerFullAddress",
  otherPartnerAccountNumber: "partnerAccountNumber",
  otherPartnerBankName: "bankName",
  otherPartnerBankSWIFTCode: "bankSwiftCode",
  otherPartnerNonDefinedAddress: "bankSwiftAddress",
  otherPartnerFullName: "fullName",
  otherPartnerBankPhoneNr: "bankPhoneNr",
};

export const partnerFields = new Set(Object.keys(partnerFieldMapping));

export function findBeneficiaryByRegNumberAndName(
  partners: Partner[] | undefined,
  otherPartnerName: string,
  otherPartnerAddress: string,
  regNumber: string
) {
  if (partners) {
    const partner: Partner | undefined = partners.find(
      (p) =>
        (regNumber && p.partnerRegistrationNumber === regNumber) ||
        (p.name === otherPartnerName &&
          p.partnerSwiftAddress === otherPartnerAddress)
    );
    return partner;
  }
  return undefined;
}

export function getEventFieldProps(
  form: FormikProps<TradeFinance>,
  fieldId: keyof TFEvent & string,
  label: string | Univ
): FieldProps {
  const eventFieldId: string = fieldId.substr(
    fieldId.indexOf(".") + 1
  ) as string;
  const fieldProps = {
    id: fieldId as string,
    label: label,
    value: ((form.values.event ?? {}) as any)[eventFieldId],
    error: (form.errors?.event ?? ({} as any))[eventFieldId] as string,
    onChange: (e: any) => {
      return form.setFieldValue("event", {
        ...(form.values.event || {}),
        [eventFieldId]: e.target.value,
      });
    },
    setValue: (value: any) => {
      return form.setFieldValue("event." + (eventFieldId as string), value);
    },
    setError: (error: string | null) => {
      const newErrors = { ...form.errors };
      if (!newErrors.event) {
        (newErrors.event as any) = {};
      }
      if (error == null) {
        delete (newErrors.event as any)[eventFieldId];
      } else {
        (newErrors as any).event[eventFieldId] = error;
      }
      return form.setErrors(newErrors);
    },
  };

  return fieldProps;
}

export function containsTheField(
  container: ContainerDesc,
  fieldId: string
): boolean {
  return (
    container.type === ContainerType.FORM &&
    !!container.content.find((field) => field.id === fieldId)
  );
}

export function findContainerByField(
  container: ContainerDesc,
  fieldId: string
): FieldContainer | undefined {
  if (containsTheField(container, fieldId)) {
    return container as FieldContainer;
  }

  for (let i = 0; container.content && i < container.content.length; i++) {
    const childContainer = (container.content as ContainerDesc[])[i];
    if (containsTheField(childContainer, fieldId)) {
      return childContainer as FieldContainer;
    } else {
      let result = findContainerByField(childContainer, fieldId);
      if (result) {
        return result;
      }
    }
  }
}

export function insertFieldsAfter(
  form: FieldContainer,
  fieldId: string,
  fields: FieldProps[]
): void {
  if (form) {
    fields = fields.filter(
      (toBeInserted) => !containsTheField(form, toBeInserted.id)
    );

    if (fields.length === 0) {
      return;
    }

    const fieldAfter = form.content.find((field) => field.id === fieldId)!;
    const fieldAfterIdx = form.content.indexOf(fieldAfter);
    if (fieldAfterIdx >= 0) {
      if (fieldAfterIdx < form.content.length - 1) {
        form.content = [
          ...form.content.slice(0, fieldAfterIdx + 1),
          ...fields,
          ...form.content.slice(fieldAfterIdx + 1),
        ];
      } else {
        form.content.push(...fields);
      }
    }
  }
}

export const today = moment().startOf("day");

export function filterSelectOptions(
  fieldProps: FieldProps,
  pred: (optVal: any) => boolean
) {
  if (fieldProps && (fieldProps as SelectFieldProps).options) {
    (fieldProps as SelectFieldProps).options = (
      fieldProps as SelectFieldProps
    ).options.filter((opt) => pred(opt.value));
  }
}

export function nullSafeEqual(a: any, b: any) {
  if ((a === undefined && b === null) || (b === undefined && a === null)) {
    return true;
  }

  if (a === true || a === false || b === true || b === false) {
    return !!a === !!b;
  }

  return a === b;
}

export function hasDocumentError(docs: TFDocument[]) {
  return !docs.every((doc) => !doc.error);
}
