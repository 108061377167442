import React from "react";
import { useTranslation } from "react-i18next";

function ProfileUpdateMessage(props: {
  showMessage: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  if (!props.showMessage) {
    return null;
  }

  return (
    <div className="notification is-success">
      <button className="delete" onClick={props.onClose}></button>
      {t("dataSaved")}
    </div>
  );
}

export default ProfileUpdateMessage;
