import classnames from "classnames";
import React, { ReactNode, useContext } from "react";
import { TradFinanceRequestContext } from "../../../context";

function Steps({ children }: { children: ReactNode }) {
  const { tradeFinance } = useContext(TradFinanceRequestContext);
  const newTradeFinance = tradeFinance.id === "new" || !tradeFinance.identifier;

  return (
    <div className={classnames("wizards-wrapper", { box: newTradeFinance })}>
      <div className="wizards">
        <ul className="steps is-vertical has-gaps is-large">{children}</ul>
      </div>
    </div>
  );
}

export default Steps;
