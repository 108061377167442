import { useMutation } from "react-query";
import { reportGeneratedCallback } from "./helper";
import { ReportTemplate } from "./types";
import * as TFApi from "../../api/tradeFinanceApi";

export function useGenerateReport() {
  return useMutation(
    async (reportTemplate: ReportTemplate) => {
      return await TFApi.generateReport(reportTemplate);
    },
    {
      onSuccess: reportGeneratedCallback,
    }
  );
}
