export default {
    serverUrl: '',
    backgrounds: {
        loanCalculator: 'img/main_picture_01',
        loanCalculator_mobile: 'img/main_picture_01_tablet_1024',
        wizard: 'img/main_picture_02',
        wizard_mobile: 'img/main_picture_02_tablet_1024',
        finish: 'img/main_picture_03',
        finish_mobile: 'img/main_picture_03_tablet_1024',
        login: 'img/main_picture_04_v2',
        login_mobile: 'img/main_picture_04_tablet_1024_v2'
    },
    threshold: 1024,
    defaultLanguage: 'en'
};