// @ts-nocheck
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";
import { AppContext } from "../../../../../../../../../../App";
import { useModal } from "../../../../../../../../../../components/ModalComponent/assets/hooks";
import { useAction } from "../../../../../../../../../../hooks";
import { ChipsFilter } from "../../../../../../../../../../types";
import {
  getCoverTemplateId,
  isAmendment,
  isCollection,
  isImportLetterOfCredit,
  isLetterOfCredit,
  isOutgoingTradeFinance,
  showEventHistory,
} from "../../../../../../../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TradeFinanceListContext } from "../../../../../../../context";
import {
  getAmendmentAction,
  tradeFinanceLink,
} from "../../../../../../../helper";
import { TradeFinanceCardHeaderProps } from "../../../../../../../types";
import * as TradeFinanceApi from "../../../../../../../../../../api/tradeFinanceApi";
import * as API from "../../../../../../../../../../api/tradeFinanceApi";
import { useCreateNewRequest } from "../../../../../../../hooks";
import {
  Delete,
  DownloadIcon,
  Edit,
  Eye,
  Messages,
  Signatures,
} from "../../../../../../../../../../icons/Icons";

import {
  ConfirmButton,
  RoundedButton,
  Modal,
} from "../../../../../../../../../../components";
import { TabId } from "../../../../../../../../../TradeFinanceRequestDetailsPage/assets/type";

function TradeFinanceCardButtons({
  tradeFinance,
  copyFrom,
  templateMode,
}: //underWritersInfo,
TradeFinanceCardHeaderProps) {
  const { userStatus, refreshStats, lang } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isConcept = location.search.includes(
    "chipsFilter=" + ChipsFilter.drafts
  );
  const isFilterOngoing = location.search.includes(
    "chipsFilter=" + ChipsFilter.ongoing
  );
  const isSignature = location.search.includes(
    "chipsFilter=" + ChipsFilter.signatureRequired
  );
  const isAmendmentAndConcept =
    isAmendment(tradeFinance) &&
    (location.pathname.endsWith("/concept") || isConcept);
  const { id, state } = tradeFinance;
  const { reload, type } = useContext(TradeFinanceListContext);
  const [modalIsOpened, openModal, closeModal] = useModal(false);
  const [showConfirmation, openConfirmation, closeConfirmation] =
    useModal(false);
  //const signatureEventId = tradeFinance.signature?.eventId;
  const isMessage =
    tradeFinance.signature &&
    tradeFinance.signature.transactionEventType &&
    tradeFinance.signature.transactionEventType[lang] ===
      t("tradeFinance.messaging.message");

  const amendmentAction = useAction<void>(async () => {
    await getAmendmentAction(id, openModal, history);
  });

  const preCheckAction = useAction<void>(async () => {
    const eventId = await TradeFinanceApi.preCheck(id);
    if (eventId) {
      history.push(`/portal/trade-finance-request/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24?event=${eventId.id}`);
    }
  });

  const cancelAction = useAction<void>(async () => {
    const eventId = await TradeFinanceApi.cancelOpenedImportLC(id);
    if (eventId) {
      history.push(`/portal/trade-finance-request/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24?event=${eventId.id}`);
    }
  });

  const cancelOpenedImportLCAction = useAction<void>(async () => {
    const hasInProgressCancelEvent =
      await TradeFinanceApi.hasInProgressCancelEvent(id);
    const hasInProgressAmendmentEvent =
      await TradeFinanceApi.hasInProgressAmendmentEvent(id);

    if (hasInProgressCancelEvent || hasInProgressAmendmentEvent) {
      openModal();
    } else {
      openConfirmation();
    }
  });

  const deleteTemplateAction = useAction<void>(async () => {
    await TradeFinanceApi.deleteTemplate(id);
    window.scrollTo({ left: 0, top: 0 });
    reload();
  });

  const deleteTradeFinanceAction = useAction<void>(async () => {
    await TradeFinanceApi.deleteTradeFinance(id);
    window.scrollTo({ left: 0, top: 0 });
    refreshStats();
    reload();
  });

  // const copyRequest, {status, data: copyResult}] = useCreateNewRequest();
  const copyRequest = useCreateNewRequest();

  if (copyRequest.data) {
    refreshStats();
    return (
      <Redirect to={`/portal/trade-finance-request/${copyRequest.data.id}`} />
    );
  }

  let buttons = null;
  if (templateMode) {
    let deleteButton = (
      <div onClick={deleteTemplateAction.run}>
        <Delete />
      </div>
    );

    buttons = (
      <>
        <Link
          className="card-button"
          to={`/portal/template/${tradeFinance.id}`}
        >
          <Edit />
          <div className="button-label">{t("edit")}</div>
        </Link>

        <div
          className={classnames("card-button has-red-hover", {
            disabled: deleteTemplateAction.isLoading,
          })}
        >
          <ConfirmButton
            confirmation={t("areYouSureToDelete")}
            button={deleteButton}
          />
          <div className="button-label">{t("delete")}</div>
        </div>
      </>
    );
  } else if (copyFrom) {
    buttons = (
      <div className="card-button">
        <div
          className={classnames("card-button", {
            disabled: copyRequest.isLoading,
          })}
          onClick={() => copyRequest.mutate({ tradeFinance, copy: true })}
        >
          <Eye />
          <div className="button-label">{t("tradeFinance.copy")}</div>
        </div>
      </div>
    );
  } else {
    let viewButton =
      isSignature && tradeFinance.signature?.canSign ? (
        <Link
          className="card-button has-green-hover"
          to={
            tradeFinance.signature
              ? `/portal/trade-finance-request/${tradeFinance.id}` +
                (tradeFinance.signature.eventId
                  ? "?event=" + tradeFinance.signature.eventId
                  : "") +
                (isMessage ? "#" + TabId.chat : "")
              : `/portal/trade-finance-request/${tradeFinance.id}`
          }
        >
          {<Signatures style={{ height: 30, width: 26 }} />}
          <div className="button-label">{t("sign")}</div>
        </Link>
      ) : (
        <Link
          className="card-button"
          to={
            tradeFinanceLink(tradeFinance, isSignature) +
            (isSignature && isMessage ? "#" + TabId.chat : "")
          }
        >
          {<Eye />}
          <div className="button-label">{t("view")}</div>
        </Link>
      );
    let messagesButton = showEventHistory(tradeFinance) ? (
      <Link
        className={"card-button"}
        to={tradeFinanceLink(tradeFinance, false) + "#" + TabId.chat}
      >
        <Messages />
        <div className="button-label">
          {t("tradeFinance.messaging.messages")}
        </div>
      </Link>
    ) : (
      ""
    );

    const coverTemplateId = getCoverTemplateId(userStatus!, tradeFinance);
    const documentCoverURL = API.getDocumentCoverDownloadLink(
      tradeFinance.id,
      coverTemplateId,
      tradeFinance.event?.id
    );
    const ongoingFilterEnabled = location.search.includes(
      "chipsFilter=" + ChipsFilter.ongoing
    );

    let downloadCoverButton =
      isCollection(tradeFinance) && ongoingFilterEnabled ? (
        <a className={"card-button"} href={documentCoverURL} target={"__blank"}>
          <DownloadIcon style={{ height: 30, width: 26 }} />
          <div className="button-label">{t("documents.coverLetter")}</div>
        </a>
      ) : (
        ""
      );
    if (isSignature) {
      buttons = (
        <>
          {
            <>
              {viewButton} {messagesButton}
            </>
          }
        </>
      );
    } else if (state === "activated" || state === "opened") {
      buttons = (
        <>
          {viewButton}
          {messagesButton}
          {downloadCoverButton}
          {!["ongoing"].includes(type) &&
            !isFilterOngoing &&
            isOutgoingTradeFinance(tradeFinance) &&
            !isCollection(tradeFinance) &&
            !isAmendmentAndConcept && (
              <>
                <div
                  className={classnames("card-button", {
                    disabled: amendmentAction.isLoading,
                  })}
                  onClick={amendmentAction.run}
                >
                  <Edit />
                  <div className="button-label">
                    {t("tradeFinance.amendment")}
                  </div>
                </div>
              </>
            )}

          {state === "opened" && isImportLetterOfCredit(tradeFinance) && (
            <>
              <div
                className={classnames("card-button has-red-hover", {
                  disabled: cancelOpenedImportLCAction.isLoading,
                })}
                onClick={cancelOpenedImportLCAction.run}
              >
                <Delete />
                <div className="button-label">
                  {t("tradeFinance.cancelOpenedImportLC")}
                </div>
              </div>
            </>
          )}
        </>
      );
    } else if (
      state === "advised" &&
      !isOutgoingTradeFinance(tradeFinance) &&
      isLetterOfCredit(tradeFinance)
    ) {
      buttons = (
        <>
          {viewButton}
          {messagesButton}

          {type !== "ongoing" && !isFilterOngoing && (
            <>
              <div
                className={classnames("card-button", {
                  disabled: preCheckAction.isLoading,
                })}
                onClick={preCheckAction.run}
              >
                <Edit />
                <div className="button-label">{t("tradeFinance.preCheck")}</div>
              </div>
            </>
          )}
        </>
      );
    } else {
      let deleteButton = (
        <div onClick={deleteTradeFinanceAction.run}>
          <Delete />
        </div>
      );

      buttons = (
        <>
          {viewButton}
          {messagesButton}
          {downloadCoverButton}

          {state === "concept" && (
            <div
              className={classnames("card-button has-red-hover", {
                disabled: deleteTradeFinanceAction.isLoading,
              })}
            >
              <ConfirmButton
                confirmation={t("tradeFinance.deleteConceptConfirm")}
                button={deleteButton}
              />
              <div className="button-label">{t("delete")}</div>
            </div>
          )}
        </>
      );
    }
  }

  return (
    <div className="card-buttons-container">
      <div className="buttons horizontal-buttons">
        {buttons}
        <Modal
          isOpened={showConfirmation}
          modalCardClass="confirm-dialog"
          hideCloseButton={true}
          onClose={closeConfirmation}
        >
          <div className="box">
            <div className="title is-6">
              {t("tradeFinance.cancelOpenedImportLCConfirmation")}
            </div>
            <div className="portal-buttons">
              <RoundedButton
                label={t("yes")}
                onClick={cancelAction.run}
                loading={cancelAction.isLoading}
              />
              <RoundedButton label={t("no")} onClick={closeConfirmation} />
            </div>
          </div>
        </Modal>
        <Modal isOpened={modalIsOpened} onClose={closeModal}>
          <div className="modal-message">
            {t("tradeFinance.amendmentValidationMessage")}
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default TradeFinanceCardButtons;
