import React from "react";
import {
  isCollection,
  isGuarantee,
  isLetterOfCredit,
} from "../../../../../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TradeFinanceCardProps } from "../../../../../types";
import { CollectionCard, GuaranteeCard, LetterOfCreditCard } from "./assets";

function DesktopCardComponent(props: TradeFinanceCardProps) {
  if (isGuarantee(props.tradeFinance)) {
    return <GuaranteeCard {...props} />;
  } else if (isLetterOfCredit(props.tradeFinance)) {
    return <LetterOfCreditCard {...props} />;
  } else if (isCollection(props.tradeFinance)) {
    return <CollectionCard {...props} />;
  }

  return null;
}

export default DesktopCardComponent;
