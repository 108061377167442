import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import { Eye, Hide } from "../../../../../../icons/Icons";

import "./ColumnHideComponent.scss";
export const ColumnHideComponent = (props: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <div
      className={classNames("columnHideComponent")}
      onMouseLeave={() => setOpen(false)}
    >
      <span
        className={classNames("columnHideComponent-button")}
        onMouseOver={() => setOpen(true)}
      >
        {open ? <Hide /> : <Eye />}
      </span>
      {open && (
        <div className={classNames("columnHideComponent-container")}>
          <div className="columnHideComponent-title">
            {t("hideOrShowColumns")}
          </div>
          {props.allColumns
            .filter((item: Column) => item.id !== "currency")
            .filter((item: Column) => item.id !== "stringVauleDate")
            .filter((item: Column) => item.id !== "stringDueDate")
            .filter((item: Column) => item.id !== "stringAmount")
            .filter((item: Column) => item.id !== "stringDate")
            .filter((item: Column) => item.id !== "stringDrawingAmount")
            .filter((item: Column) => item.id !== "accountNumber")
            .filter((item: Column) => item.id !== "action")
            .map((column: Column) => (
              <div
                key={column.id}
                className={classNames("columnHideComponent-item")}
              >
                <label>
                  <input
                    type="checkbox"
                    {...(column as any).getToggleHiddenProps()}
                  />
                  {column.Header}
                </label>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
