import React, { useContext, useState } from "react";
import { AppContext } from "../../../../../../../../App";
import { useTranslation } from "react-i18next";
import * as TFApi from "../../../../../../../../api/tradeFinanceApi";
import { TFDocument } from "../../../../../../../../types";
import { isTradeFinanceEditable } from "../../../../../tradeFinanceUtils";
import { errorToString } from "../../../../../../../../utils";
import { TradFinanceRequestContext } from "../../../../../context";
import { TradeFinanceDocumentsComponentProps } from "./assets/types";
import { DocumentListItemComponent } from "./assets";
import { MultiDocumentUpload } from "../../../../../../../../components";

function TradeFinanceDocumentsComponent({
  uploadDocuments,
  save,
  isSubmitting,
}: TradeFinanceDocumentsComponentProps) {
  const { t } = useTranslation();
  const { tradeFinance, loadDocumentsAction } = useContext(
    TradFinanceRequestContext
  );
  const tradeFinCtx = useContext(TradFinanceRequestContext);
  const { docsToUpload, setDocsToUpload } = tradeFinCtx;
  const tradeFinanceEditable = isTradeFinanceEditable(tradeFinCtx);
  const documents = loadDocumentsAction.result || [];
  const [loading, setLoading] = useState<boolean>(false);
  const { lang } = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);

  async function deleteDocument(doc: TFDocument) {
    await TFApi.deleteTradeFinanceDocument(tradeFinance.id, doc.id);
    loadDocumentsAction.setResult(documents.filter((d) => d.id !== doc.id));
  }

  async function saveDocumentUpload(docs: TFDocument[]) {
    setLoading(true);
    setError(null);
    try {
      const isValid = await uploadDocuments();

      if (isValid) {
        save();
        await loadDocumentsAction.run();
        setDocsToUpload([]);
      }
      setLoading(false);
    } catch (e) {
      const errorMessage = errorToString(e, t, lang);
      setLoading(false);
      if (errorMessage === "fileUploadFailed") {
        setError(t("fileUploadFailed"));
      } else if (errorMessage) {
        setError(errorMessage);
      }
    }
  }

  return (
    <div className="documents">
      <div className="documents-content">
        {tradeFinanceEditable && (
          <div className="portal-buttons">
            {loading ? (
              <button className="button is-loading is-large loading-indicator" />
            ) : (
              <MultiDocumentUpload
                tradeFinance={tradeFinance}
                editable={true}
                documents={docsToUpload}
                updateDocuments={setDocsToUpload}
                error={error}
                setError={setError}
                saveDocuments={saveDocumentUpload}
                disabledButton={isSubmitting}
              />
            )}
          </div>
        )}

        {documents.length > 0 && (
          <div className="table-container">
            <table className="top-margin uploaded-documents portal-table table is-fullwidth">
              <thead>
                <tr>
                  <th>
                    <div>{t("document.fileName")}</div>
                  </th>
                  <th className="column--description">
                    <div>
                      <div>{t("document.description")}</div>
                      <div>{t("document.type")}</div>
                    </div>
                  </th>
                  <th>
                    <div>{t("document.date")}</div>
                  </th>
                  <th>
                    <div>{t("actions")}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, idx) => (
                  <DocumentListItemComponent
                    key={doc.id}
                    document={doc}
                    onDelete={tradeFinanceEditable ? deleteDocument : null}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default TradeFinanceDocumentsComponent;
