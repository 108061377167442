import React from "react";
import {
  CollectionHighlighter,
  GuaranteeHighlighter,
  LetterOfCreditHighlighter,
} from "..";
import {
  isCollection,
  isGuarantee,
  isLetterOfCredit,
} from "../../../../../../../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TradeFinanceHighlighterProps } from "../../../../../../../types";

function TradeFinanceHighlighter({
  tradeFinance,
  template,
  copyFrom,
}: TradeFinanceHighlighterProps) {
  if (isGuarantee(tradeFinance)) {
    return (
      <GuaranteeHighlighter
        tradeFinance={tradeFinance}
        template={template}
        copyFrom={copyFrom}
      />
    );
  } else if (isLetterOfCredit(tradeFinance)) {
    return (
      <LetterOfCreditHighlighter
        tradeFinance={tradeFinance}
        template={template}
        copyFrom={copyFrom}
      />
    );
  } else if (isCollection(tradeFinance)) {
    return (
      <CollectionHighlighter
        tradeFinance={tradeFinance}
        template={template}
        copyFrom={copyFrom}
      />
    );
  }

  return null;
}

export default TradeFinanceHighlighter;
