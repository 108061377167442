import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { useLoadNotifications } from "../hooks";
import { NotificationListProps } from "../types";
import { Notification } from "../../../../types";
import { formatDate } from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { Eye } from "../../../../icons/Icons";
import { EmptyList, PageHeader, ShowMoreButton } from "../../../../components";

function NotificationList(props: NotificationListProps) {
  const { notificationType } = props;
  const { t } = useTranslation();

  const { notifications, showMore, isLoading, hasMore } =
    useLoadNotifications(notificationType);

  const history = useHistory();

  function navigateToTradeFinance(notification: Notification) {
    const hasEventNavigation = ![
      "tradefin/importCollection",
      "tradefin/exportLC",
      "tradefin/receivedGuarantee",
      "tradefin/incomingStandbyBG",
      "tradefin/incomingStandbyLC",
    ].includes(notification.tradeFinanceDescriptorId);
    const linkParams =
      hasEventNavigation && notification.relatedEventId
        ? "?event=" + notification.relatedEventId
        : "";
    return (
      `/portal/trade-finance-request/${notification.tradeFinanceId}` +
      linkParams
    );
  }

  if (!notifications) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={t(
          notificationType === "todo"
            ? "dashboard.todoNotifications"
            : "dashboard.informationNotifications"
        )}
      />
      <div className="container">
        {!isLoading && notifications.length === 0 && (
          <EmptyList message={t("notifications.emptyNotificationList")} />
        )}

        {notifications && (
          <div className="table-container">
            <table className="table portal-table underwriters-table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>{t("notification.tradeFinanceIdentifier")}</th>
                  <th>{t("notification.otherPartnerName")}</th>
                  <th>{t("notification.referenceNo")}</th>
                  <th>{t("notification.subject")}</th>
                  <th align={"right"}>{t("notification.createDate")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {notifications.map((notification) => (
                  <tr key={notification.id}>
                    <td>
                      <NavLink to={navigateToTradeFinance(notification)}>
                        {notification.tradeFinanceIdentifier}
                      </NavLink>
                    </td>
                    <td>{notification.otherPartnerName}</td>
                    <td>{notification.referenceNo}</td>
                    <td>{notification.subject}</td>
                    <td align={"right"}>
                      {formatDate(notification.createDate)}
                    </td>

                    <td className="row-actions">
                      <div
                        className="action-button"
                        onClick={() =>
                          history.push(navigateToTradeFinance(notification))
                        }
                      >
                        <Eye />
                        {t("view")}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ShowMoreButton
              isLoading={isLoading}
              showMore={showMore}
              hasMore={hasMore}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default NotificationList;
