import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
} from "../../../../../../icons/Icons";
import * as API from "../../../../../../api/tradeFinanceApi";
import { useClickOutsideHandler } from "../../../hooks";

import "./Pagination.scss";

export const Pagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  isFees,
  selectedFlatRows,
}: any) => {
  const [openDownload, setOpenDownload] = useState(false);
  const { t } = useTranslation();
  const params = useParams();
  const wrapperRef = useRef(null);
  const tfId = (params as any).id;
  const sendIds: string = isFees
    ? selectedFlatRows.map((item: any) => item.original.id).toString()
    : selectedFlatRows.map((item: any) => item.original.eventId).toString();

  function downloadDocuments(ids: string) {
    const link = isFees
      ? API.downloadFeesDocuments(tfId, ids)
      : API.downloadDrawingDocuments(tfId, ids);
    window.open(link, "_blank");
  }

  useClickOutsideHandler(wrapperRef, setOpenDownload, !openDownload);

  return (
    <div className="Pagination">
      <div className={classNames("Pagination-container")}>
        <div className={classNames("Pagination-selectContainer")}>
          <label htmlFor="selectPage">{t("pagination.itemsOnPage")}</label>
          <select
            id="selectPage"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className={classNames("Pagination-gotToPageContainer")}>
          <label htmlFor="pageIndeexInput">{t("pagination.goToPage")}</label>
          <span>
            <input
              id="pageIndeexInput"
              type="number"
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
            {t("pagination.of")} {pageOptions.length}
          </span>
        </div>

        <div className={classNames("Pagination-gotToButtons")}>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowLeft />
          </button>
          <span></span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowRight />
          </button>
        </div>
      </div>

      <div className={classNames("Pagination-download")}>
        <button
          className={classNames("Pagination-download--button")}
          onClick={() => setOpenDownload(!openDownload)}
        >
          <span>{t("pagination.download")}</span>
          <ArrowDown />
        </button>
        {openDownload && (
          <div
            ref={wrapperRef}
            className={classNames("Pagination-download--btnContainer")}
          >
            <button
              className={classNames("downloadAll")}
              onClick={() => downloadDocuments("")}
            >
              {t("pagination.downloadAll")}
            </button>
            <button
              disabled={selectedFlatRows.length === 0}
              onClick={() => downloadDocuments(sendIds)}
            >
              {t("pagination.downloadSelected")} ({selectedFlatRows.length})
            </button>
            <span className={classNames("arrow-down")} />
          </div>
        )}
      </div>
    </div>
  );
};
