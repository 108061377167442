import React from "react";
import classnames from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import { changeUserLanguage } from "../../../../api/portalApi";
import { Language } from "../../../../types";

function LanguageItem({ lang, label }: { lang: Language; label: string }) {
  const { t, i18n } = useTranslation();
  const appContext = useContext(AppContext);

  return (
    <a
      className={classnames("dropdown-item", {
        "is-selected": appContext.lang === lang,
      })}
      onClick={async () => {
        await changeUserLanguage(lang);
        await appContext.updateUserState();
      }}
    >
      <label>{label}</label>
    </a>
  );
}
export default LanguageItem;
