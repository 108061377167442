import { useInfiniteQuery, useMutation } from "react-query";
import { TradeFinanceDetail, PAGE_LIMIT } from "../../../api/tradeFinanceApi";
import {
  CreateRequestProps,
  TFCopyMethod,
  TradeFinanceRequestListType,
} from "./types";
import * as API from "../../../api/tradeFinanceApi";
import * as TradeFinanceApi from "../../../api/tradeFinanceApi";
import { TradeFinance, TradeFinanceSearchParams } from "../../../types";
import { useTranslation } from "react-i18next";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../App";
import { useHistory, useLocation } from "react-router-dom";
import {
  tfSearchParamsToUrlSearchParams,
  urlSearchParamsToTradeFinanceSearchParams,
} from "./helper";

export function useCreateNewRequest() {
  return useMutation(async ({ tradeFinance, copy }: CreateRequestProps) => {
    const id = tradeFinance.id;
    const tradeFinanceType = tradeFinance.tradeFinanceType;
    if ((!id || id === "new") && tradeFinanceType) {
      const tf = (await API.findTradeFinance("new", tradeFinanceType))
        .tradeFinance;
      return await API.saveTradeFinanceModifications(
        tf as TradeFinanceDetail,
        false
      );
    } else if (id && copy && tradeFinanceType) {
      return await API.copyTradeFinance(tradeFinance, false);
    }

    return null;
  });
}

export function useTradeStateName(tradeFinance: TradeFinance): string {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);

  if (tradeFinance.event) {
    const eventState = tradeFinance.event.state;
    if (eventState === "canceled") {
      return t("tradeFinance.eventState.canceled");
    } else if (
      eventState === "waitingForClient" ||
      eventState === "clientDecision"
    ) {
      return t("tradeFinance.eventState.client");
    } else if (eventState === "approved") {
      return t("tradeFinance.eventState.approved");
    } else if (eventState === "rejected") {
      return t("tradeFinance.eventState.rejected");
    } else if (eventState === "refused") {
      return t("tradeFinance.eventState.refused");
    } else if (eventState === "waitingForBeneficiary") {
      return t("tradeFinance.eventState.waitingForBeneficiary");
    } else {
      return t("tradeFinance.eventState.waitingForBank");
    }
  } else {
    if (tradeFinance.stateName) {
      return tradeFinance.stateName[lang] || "";
    } else {
      return "";
    }
  }
}

export function useLoadTradeFinances(
  filter: TradeFinanceSearchParams | null,
  filterIsMandatory: boolean,
  type: TradeFinanceRequestListType,
  copy: TFCopyMethod
) {
  const {
    //status,
    //isError,
    //error,
    data,
    refetch,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery(
    ["tfList", type, filter, copy],
    async ({ pageParam = 0 }) => {
      if (filterIsMandatory && !filter) {
        return [];
      }

      return await TradeFinanceApi.findTradeFinances(
        type as TradeFinanceRequestListType,
        filter,
        copy,
        pageParam
      );
    },
    {
      getNextPageParam: (lastGroup, allGroups) => {
        if (lastGroup?.length >= PAGE_LIMIT) {
          const count = allGroups.reduce(
            (count, group) => count + group.length,
            0
          );
          return count;
        }
      },
    }
  );

  function showMore() {
    fetchNextPage();
  }

  function reload() {
    refetch();
  }

  const isLoading = isFetching;
  const hasMore = hasNextPage;
  const tradeFinances: TradeFinance[] = [];
  //eslint-disable no-unused-expressions
  const data_tmp_fn = () =>
    data?.pages.forEach((group) =>
      group.forEach((tf) => tradeFinances.push(tf))
    );
  data_tmp_fn();
  return { tradeFinances, showMore, isLoading, reload, hasMore };
}

export function useTradeFinanceSearchParams(): [
  TradeFinanceSearchParams | null,
  React.Dispatch<React.SetStateAction<TradeFinanceSearchParams | null>>
] {
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const [filter, setFilter] = useState<TradeFinanceSearchParams | null>(() =>
    urlSearchParamsToTradeFinanceSearchParams(urlSearchParams)
  );

  useEffect(() => {
    let filtersFromUrl = urlSearchParamsToTradeFinanceSearchParams(
      new URLSearchParams(location.search)
    );
    if (JSON.stringify(filtersFromUrl) !== JSON.stringify(filter)) {
      setFilter(filtersFromUrl);
    }
  }, [location]);

  function _setFilter(filters: TradeFinanceSearchParams | null) {
    setFilter(filters);
    history.push(
      location.pathname +
        (filters ? "?" + tfSearchParamsToUrlSearchParams(filters) : "")
    );
  }

  return [filter, _setFilter] as [
    TradeFinanceSearchParams | null,
    React.Dispatch<React.SetStateAction<TradeFinanceSearchParams | null>>
  ];
}
