import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../components";
import { getFieldProps } from "../../../../components/Form/helper";
import { SectionProps } from "../../types";

function ReportNameSection({ form, isEditable }: SectionProps) {
  const { t } = useTranslation();
  const name = getFieldProps(form, "name", t("report.name"), {
    disabled: !isEditable,
  });

  return (
    <div className="report-filters section box">
      <div className="section--title">{t("report.reportNameTitle")}</div>
      <div className="section--content">
        <TextField {...name} required={true} />
      </div>
    </div>
  );
}

export default ReportNameSection;
