import React from "react";
import { useLocation, useParams } from "react-router-dom";
import TradeFinanceRequestDetailsPage from "./TradeFinanceRequestDetailsPage";

export function TradeFinanceRequestDetailsPageParent() {
  // @ts-ignore
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.has("event")
    ? parseInt(searchParams.get("event")!)
    : undefined;
  // If the "event" URL param changes, refresh the whole page
  return <TradeFinanceRequestDetailsPage key={id + "_" + eventId} />;
}
