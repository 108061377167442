import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Step } from "..";
import { WarningIcon } from "../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../context";
import { EditableStepProps } from "../../../type";
import { OpenedStateComponent } from "./assets";

function EditableStep(props: EditableStepProps) {
  const { stepId, title, state, layout, className, changed } = props;
  const tfinContext = useContext(TradFinanceRequestContext);
  const { form, tradeFinance } = tfinContext;
  const { t } = useTranslation();
  const eventState = tradeFinance.event?.eventState;

  return (
    <Step
      stepId={stepId}
      title={title}
      state={t(
        (eventState && eventState === "rejected") || eventState === "approved"
          ? "tradeFinance.stepState.finished"
          : "tradeFinance.stepState." + state
      )}
      headerEnd={
        changed &&
        !(
          (eventState && eventState === "rejected") ||
          eventState === "approved"
        ) ? (
          <WarningIcon className="warning-icon" />
        ) : null
      }
    >
      <div className={classnames("step-content", className)}>
        <OpenedStateComponent
          form={form}
          layout={layout}
          formFields={tfinContext.formFields}
          buttons={props.buttons}
        />
      </div>
    </Step>
  );
}

export default EditableStep;
