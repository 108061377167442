import React, { useContext } from "react";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { SelectFieldProps, SelectOption } from "../types";

function MultiSelectField(props: SelectFieldProps) {
  const { lang } = useContext(AppContext);
  const { id, value, setValue } = props;

  function isChecked(option: SelectOption): boolean {
    return (
      (props.value as string).split(",").indexOf(option.value?.toString()) > -1
    );
  }

  function toggleOption(option: SelectOption, checked: boolean) {
    const valueSet = value ? new Set(value.split(",")) : new Set();
    if (checked) {
      valueSet.add(option.value);
    } else {
      valueSet.delete(option.value);
    }

    setValue(Array.from(valueSet).join(","));
  }

  function renderSelect() {
    return (
      <div className="multiselect" id={id}>
        {props.options.map((option) => {
          return (
            <div className="field" key={option.value}>
              <input
                id={id + "_" + option.value}
                type="checkbox"
                className="is-checkradio"
                checked={isChecked(option)}
                onChange={(e) => toggleOption(option, e.target.checked)}
              />
              <label htmlFor={id + "_" + option.value}>{option.label}</label>
            </div>
          );
        })}
      </div>
    );
  }

  return DecorateField(props, renderSelect(), lang);
}

export default MultiSelectField;
