import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ChipsFilter } from "../../../../../../types";
import { DeviceType, useDeviceType } from "../../../../../tradeFinanceHooks";
import { TradeFinanceListContext } from "../../../context";

function TradeFinanceCardsHeader() {
  const { t } = useTranslation();
  const location = useLocation();
  const { type } = useContext(TradeFinanceListContext);
  const device = useDeviceType();

  return (
    <div className={"list-card list-header"}>
      <div className={"details"}>
        <div className={"columns guarantee-columns"}>
          <div className={"column"}>
            <div>
              <div>{t("header.customerReference")}</div>
              <div>{t("header.bankReference")}</div>
              <div>{t("header.status")}</div>
            </div>
          </div>
          <div className={"column"}>
            <div>
              <div>
                {t(
                  type !== "collection"
                    ? "header.beneficiary"
                    : "collection.otherPartnerName"
                )}
              </div>
              <div>{t("header.type")}</div>
              {type === "guarantees" && <div>{t("header.subtype")}</div>}
            </div>
          </div>
          {location.search.includes(
            "chipsFilter=" + ChipsFilter.signatureRequired
          ) && (
            <div className={"column has-right-text"}>
              <div>{t("menu.signatures")}</div>
            </div>
          )}
          <div className={"column has-right-text"}>
            <div>
              <div>{t("header.value")}</div>
              {type !== "collection" && <div>{t("header.dateOfExpiry")}</div>}
            </div>
          </div>
          {device === DeviceType.desktop && (
            <div className={"column is-narrow"}>
              <div>
                <div style={{ width: 150 }}>{t("actions")}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TradeFinanceCardsHeader;
