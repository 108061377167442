// @ts-nocheck
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { AppContext } from "../../../../App";
import { UnderWriterConfigItemComponentProps } from "../types";
import { Event } from "../../../UserConfigurationPage/assets/types";
import { ItemError } from "..";
import NumberFormat from "react-number-format";

function LimitField({
  item,
  items,
  updateItem,
  idx,
  limitErrors,
  setLimitErrors,
  validateItems,
}: UnderWriterConfigItemComponentProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const { user } = useContext(AppContext);
  const [showError, setShowError] = useState<boolean>(false);
  const error = limitErrors[item.id];
  const hasError = !!error;

  const isMessaging = item.eventName === Event.messaging;

  function updateErrorVisibility(visible: boolean) {
    if (showError !== visible) {
      setTimeout(() => {
        setShowError(visible);
      }, 200);
    }
  }

  return (
    <>
      <div
        className={classnames("dropdown is-up", {
          "is-active": hasError && showError,
        })}
      >
        {!isMessaging && (
          <div className="dropdown-trigger">
            <div
              aria-haspopup="true"
              aria-controls="limit-notification-dropdown"
            >
              <NumberFormat
                getInputRef={inputRef}
                onBlur={() => validateItems()}
                onMouseEnter={() => updateErrorVisibility(hasError)}
                onMouseLeave={() => updateErrorVisibility(false)}
                className={classnames({
                  "input is-small": user?.superUser,
                  "has-error": hasError,
                })}
                thousandSeparator={" "}
                decimalSeparator={","}
                value={item.limit === null ? "" : item.limit}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={user?.superUser ? "input" : "text"}
                allowNegative={false}
                placeholder={t("underwriter.noLimit")}
                onValueChange={(values) =>
                  updateItem(item.id, "limit", values.floatValue || null)
                }
              />
            </div>
          </div>
        )}

        <ItemError error={error} />
      </div>
    </>
  );
}

export default LimitField;
