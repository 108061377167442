import * as React from "react";
import { forwardRef, useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Home, KBLogoWithText } from "../../icons/Icons";
import { MobileHeader } from "../MobileHeader/MobileHeader";
import { DeviceType, useDeviceType } from "../../pages/tradeFinanceHooks";
import { ProfileComponent } from "../../pages/DashboardPage/assets";
import { useTradeFinanceSearchParams } from "../../pages/TradeFinanceListPage/assets/hooks";
import { AppHeaderProps } from "./assets/types";
import {
  HeaderMenuItem,
  LanguageSelector,
  LoginButton,
  LogoutButton,
  PrivateMenu,
} from "./assets";
import { TradeFinanceFilterComponent, HeaderDropdown } from "..";

function _AppHeader(props: AppHeaderProps, ref: React.Ref<any>) {
  const { isLoggedIn } = props;
  const [searchParams, setSearchParams] = useTradeFinanceSearchParams();
  const history = useHistory();
  const location = useLocation();
  const device = useDeviceType();
  const { t } = useTranslation();
  const { stats } = useContext(AppContext);

  const [privateMenuVisible, setPrivateMenuVisible] = useState<boolean>(false);
  const [scrollTop, setScrollTop] = useState<number>(80);

  const handleScroll = () => {
    setTimeout(() => {
      const position = window.pageYOffset > 80 ? window.pageYOffset : 80;
      setScrollTop(position);
    }, 250);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (privateMenuVisible) {
      setPrivateMenuVisible(false);
    }
  }, [location]);

  let secondHeader = (
    <div
      className={classnames("second-header", {
        "has-private-menu": privateMenuVisible,
      })}
    >
      <div className="container">
        <div className="menu-items">
          <HeaderMenuItem
            label={""}
            icon={<Home />}
            href={"/"}
            isActive={location.pathname === "/"}
            onClick={() => {
              //when current location is '/' and user clicks the logo, refresh the page
              if (location.pathname === "/") {
                history.go(0);
              }
            }}
          />
          <HeaderMenuItem
            label={t("menu.ongoing")}
            href={"/portal/trade-finance-list/ongoing"}
            count={stats?.ongoingTransactions}
          />
          <HeaderMenuItem
            label={t("menu.concept")}
            href={"/portal/trade-finance-list/concept"}
            count={stats?.draft}
          />
          <HeaderMenuItem
            label={t("menu.guarantees")}
            href={"/portal/trade-finance-list/guarantees"}
            count={stats?.activeGuarantees}
          />
          <HeaderMenuItem
            label={t("menu.letterOfCredit")}
            href={"/portal/trade-finance-list/letterOfCredit"}
            count={stats?.activeLC}
          />
          <HeaderMenuItem
            label={t("menu.collection")}
            href={"/portal/trade-finance-list/collection"}
            count={stats?.activeCollection}
          />
          <HeaderMenuItem
            label={t("menu.standby")}
            href={"/portal/trade-finance-list/standby"}
            count={stats?.standby}
          />
          <HeaderMenuItem
            label={t("menu.all")}
            isMenu={true}
            isActive={privateMenuVisible}
            onClick={() => {
              setPrivateMenuVisible(!privateMenuVisible);
            }}
          />
        </div>
      </div>
    </div>
  );

  if (device !== DeviceType.desktop) {
    return <MobileHeader {...props} />;
  }

  return (
    <>
      <div className={"header"}>
        <div className={"navbar container"}>
          <NavLink
            className="logo"
            to="/"
            onClick={() => {
              //when current location is '/' and user clicks the logo, refresh the page
              if (location.pathname === "/") {
                history.go(0);
              }
            }}
          >
            <KBLogoWithText className={"is-flex"} />
          </NavLink>

          {isLoggedIn && (
            <>
              <div className="dashboard">
                <ProfileComponent />
              </div>

              <TradeFinanceFilterComponent
                searchParams={searchParams}
                placeholder={t("tradeFinance.searchPlaceholder")}
              />
            </>
          )}

          <div className="navbar-end">
            <LanguageSelector />
            <LogoutButton onLogout={props.onLogout} />
            {!isLoggedIn && <LoginButton {...props} />}
          </div>
        </div>
      </div>

      {isLoggedIn && secondHeader}

      {privateMenuVisible && (
        <>
          <PrivateMenu
            secondHeader={secondHeader}
            scrollTop={scrollTop}
            privateMenuVisible={privateMenuVisible}
            setPrivateMenuVisible={setPrivateMenuVisible}
          />
          <div className={"modality-curtain"}></div>
        </>
      )}
    </>
  );
}

const AppHeader = forwardRef(_AppHeader);

export default AppHeader;
