// @ts-nocheck
import * as React from "react";
import { FC } from "react";
import { DeviceType, useDeviceType } from "../../pages/tradeFinanceHooks";
import { AdaptiveComponentProps } from "./assets/types";

export default function AdaptiveComponent<P>(props: AdaptiveComponentProps<P>) {
  const device = useDeviceType();

  const { mobile, tablet, desktop, threshHold, ...componentProps } = props;
  let ComponentToRender: FC<P>;

  if (mobile && device === DeviceType.mobile) {
    ComponentToRender = mobile || tablet;
  } else if (tablet && device === DeviceType.tablet) {
    ComponentToRender = tablet;
  } else {
    ComponentToRender = desktop;
  }

  return <ComponentToRender {...(componentProps as P)} />;
}
