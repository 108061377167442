// @ts-nocheck
import classnames from "classnames";
import React, { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { NumberFieldProps } from "../types";

function NumberField(props: NumberFieldProps) {
  const [focused, setFocused] = useState(false);
  const { lang } = useContext(AppContext);
  const { id, min, max, step, disabled, value, error, decimals, suffix } =
    props;

  function _setValue(val: string) {
    let number = convertValue(val);
    if (!isNaN(number) && isFinite(number)) {
      props.setValue(number);
    } else if (val === "") {
      props.setValue(null);
    }
    return null;
  }

  function convertValue(val: string): number {
    let res = parseFloat(val);
    return res;
  }

  return DecorateField(
    props,
    <NumberFormat
      className={classnames("input is-small", { "is-danger": !!error })}
      displayType={"input"}
      id={id}
      disabled={disabled}
      value={value}
      step={step}
      thousandSeparator={" "}
      decimalSeparator={","}
      allowNegative={false}
      suffix={suffix}
      autoComplete="off"
      decimalScale={decimals || 0}
      fixedDecimalScale={true}
      onFocus={() => setFocused(true)}
      isAllowed={(values) => {
        let isValid = true;
        if (values.floatValue !== undefined) {
          if (min !== null && min !== undefined) {
            isValid = values.floatValue >= min;
          }
          if (isValid && max !== null && max !== undefined) {
            isValid = values.floatValue <= max;
          }
        }
        return isValid;
      }}
      onBlur={() => {
        setFocused(false);
      }}
      onValueChange={({ value }) => _setValue(value)}
    />,
    lang
  );
}

export default NumberField;
