import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Step } from "..";
import { AppContext } from "../../../../../../App";
import {
  NumberField,
  TextAreaField,
  TextField,
} from "../../../../../../components";
import { getFieldProps } from "../../../../../../components/Form/helper";
import { TradFinanceRequestContext } from "../../../context";
import { getEventFieldProps } from "../../../helper";
import {
  getCurrencyDecimals,
  isEventWaitingForClient,
  isLetterOfCredit,
  isPreCheckEvent,
} from "../../../tradeFinanceUtils";
import { StepId } from "../../../type";

function PreCheckStep() {
  const { tradeFinance, underWritersInfo, form } = useContext(
    TradFinanceRequestContext
  );
  const { userStatus } = useContext(AppContext);

  const currencyScale = useMemo(
    () => getCurrencyDecimals(userStatus, form.values.currency),
    [userStatus, form.values.currency]
  );
  const { t } = useTranslation();

  if (!isLetterOfCredit(tradeFinance) || !isPreCheckEvent(tradeFinance)) {
    return null;
  }

  const isFieldDisabled =
    form.isSubmitting ||
    !!underWritersInfo ||
    !isEventWaitingForClient(tradeFinance);

  if (!Object.keys(form.values).length) {
    return null;
  }

  return (
    <Step
      stepId={StepId.preCheck}
      title={t("tradeFinance.preCheckDetails.title")}
    >
      <div className="pre-check">
        <div className="columns">
          <div className="column">
            {tradeFinance.event?.preCheckResult && (
              <TextAreaField
                {...getEventFieldProps(
                  form,
                  "preCheckResult",
                  t("tradeFinance.preCheckDetails.preCheckResult")
                )}
                disabled={true}
                maxLength={4000}
              />
            )}
            <div className="columns">
              <div className="column">
                <NumberField
                  {...getEventFieldProps(
                    form,
                    "claimAmount",
                    t("tradeFinance.preCheckDetails.preCheckAmount")
                  )}
                  disabled={isFieldDisabled}
                  required={true}
                  decimals={currencyScale}
                />
              </div>
              <div className="column">
                <TextField
                  {...getFieldProps(
                    form,
                    "currency",
                    t("tradeFinance.currency")
                  )}
                  disabled={true}
                />
              </div>
            </div>

            <TextField
              {...getEventFieldProps(
                form,
                "invoiceNumber",
                t("tradeFinance.preCheckDetails.invoiceNumber")
              )}
              disabled={isFieldDisabled}
            />

            <div className="columns">
              <div className="column">
                <TextAreaField
                  {...getEventFieldProps(
                    form,
                    "originOfGoodsEvent",
                    t("tradeFinance.preCheckDetails.originOfGoodsEvent")
                  )}
                  maxLength={4000}
                  required={true}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="column">
                <TextAreaField
                  {...getEventFieldProps(
                    form,
                    "otherInstructionEvent",
                    t("tradeFinance.preCheckDetails.instruction")
                  )}
                  maxLength={4000}
                  disabled={isFieldDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Step>
  );
}

export default PreCheckStep;
