import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectField2,
  TextAreaField,
  TextField,
  Form,
  RoundedButton,
} from "../../../../../../../../components";
import { SelectOption } from "../../../../../../../../components/Fields/types";
import {
  getFieldProps,
  validateMandatoryFields,
} from "../../../../../../../../components/Form/helper";
import { TradFinanceRequestContext } from "../../../../../context";
import { DocumentRequirementFormProps } from "../types";

function DocumentRequirementForm({
  documentRequirement,
  setDocumentRequirement,
  submitDocumentRequirement,
  templates,
}: DocumentRequirementFormProps) {
  const tradFinanceRequestContext = useContext(TradFinanceRequestContext);
  const { setUnsavedTerm } = tradFinanceRequestContext;
  const { t } = useTranslation();
  const templateOptions = templates!.map(
    (t) => ({ label: t.documentName, value: t.id } as SelectOption)
  );
  const [template, setTemplate] = useState(
    documentRequirement.template &&
      templates
        .find((t) => t.id === documentRequirement.template)!
        .id.toString()
  );
  const invoiceOption = templateOptions.find((t) => t.label === "Invoice");
  return (
    <Form
      initialValues={documentRequirement}
      validate={(values) =>
        validateMandatoryFields(
          values,
          values.template + "" === invoiceOption?.value + ""
            ? [
                "documentLongName",
                "originals",
                "copies",
                "documentsInvoiceNumber",
              ]
            : ["documentLongName", "originals", "copies"],
          t
        )
      }
      onSubmit={submitDocumentRequirement}
    >
      {(form) => (
        <div className="document-requirement-form top-margin">
          <div className="document-requirement-form--title">
            {t("documentRequirements.newDocument")}
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <div className={"control"}>
                <SelectField2
                  label={t("documentRequirements.template")}
                  className={"field"}
                  value={template}
                  options={templateOptions}
                  id={"documentRequirement.template"}
                  setValue={(value) => {
                    setTemplate(value);
                    form.setFieldValue(
                      "documentLongName",
                      templates!.find((t) => t.id === value)!
                        .documentDescription
                    );
                    form.setFieldValue("template", value);
                  }}
                  setError={() => {}}
                  onChange={(e) => {}}
                />
                {form.values.template + "" === invoiceOption?.value + "" && (
                  <TextField
                    {...getFieldProps(
                      form,
                      "documentsInvoiceNumber",
                      t("documentRequirements.documentsInvoiceNumber")
                    )}
                    required={true}
                  />
                )}
                <TextField
                  {...getFieldProps(
                    form,
                    "originals",
                    t("documentRequirements.originals")
                  )}
                  required={true}
                />
                <TextField
                  {...getFieldProps(
                    form,
                    "copies",
                    t("documentRequirements.copies")
                  )}
                  required={true}
                />
              </div>
            </div>
            <div className="column">
              <TextAreaField
                {...getFieldProps(
                  form,
                  "documentLongName",
                  t("documentRequirements.documentLongName")
                )}
              />
            </div>
          </div>
          <div className="form-buttons buttons">
            <RoundedButton
              buttonClass="is-cancel"
              label={t("cancel")}
              disabled={form.isSubmitting}
              loading={form.isSubmitting}
              inverse={true}
              onClick={() => {
                setDocumentRequirement(null);
                setUnsavedTerm(false);
              }}
            />

            <RoundedButton
              label={documentRequirement.id ? t("modify") : t("saveWithAdd")}
              buttonClass="is-save"
              disabled={form.isSubmitting}
              loading={form.isSubmitting}
              onClick={form.handleSubmit}
            />
          </div>
        </div>
      )}
    </Form>
  );
}
export default DocumentRequirementForm;
