import React from "react";
import { useTranslation } from "react-i18next";
import { MaturityTableRow } from "..";
import { MaturitiesTableTableProps } from "../types";

function MaturitiesTable({
  maturities,
  editable,
  setEditedMaturity,
  deleteMaturity,
  tradeFinanceId,
}: MaturitiesTableTableProps) {
  const { t } = useTranslation();

  return (
    <div className="table-container">
      {(maturities || []).length > 0 && (
        <table className="table portal-table installments-table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>{t("tradeFinance.installment.paymentMethod")}</th>
              <th>{t("tradeFinance.installment.amount")}</th>
              <th>{t("tradeFinance.installment.dueDate")}</th>
              <th>{t("tradeFinance.installment.state")}</th>
              {editable && <th>{t("actions")}</th>}
            </tr>
          </thead>

          <tbody>
            {maturities.map((maturity) => (
              <MaturityTableRow
                key={maturity.id}
                maturity={maturity}
                deleteMaturity={deleteMaturity}
                editable={editable}
                tradeFinanceId={tradeFinanceId}
                setEditedMaturity={setEditedMaturity}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
export default MaturitiesTable;
