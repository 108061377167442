import classnames from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClauseSelectorRow } from "..";
import { RoundedButton } from "../..";
import { CloseIcon, ListIcon } from "../../../icons/Icons";
import { Clause } from "../../../types";
import { ClauseSelectorProps } from "../types";

function ClauseSelector(props: ClauseSelectorProps) {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [selectedClauses, setSelectedClauses] = useState(new Set<string>());

  //useOnClickOutside(dropdownRef, () => setActive(false));

  function toggleClause(clause: Clause) {
    const newSelectedClaueses = new Set(selectedClauses);
    if (newSelectedClaueses.has(clause.clauseId)) {
      newSelectedClaueses.delete(clause.clauseId);
    } else {
      newSelectedClaueses.add(clause.clauseId);
    }
    setSelectedClauses(newSelectedClaueses);
  }

  function onPaste() {
    props.onSelect(
      props.clauses.filter((c) => selectedClauses.has(c.clauseId))
    );
    cancel();
  }

  function cancel() {
    setActive(false);
    setSelectedClauses(new Set());
  }

  return (
    <div
      ref={dropdownRef}
      className={classnames("header-button dropdown is-right", {
        "is-active": active,
      })}
    >
      <div
        className={classnames("dropdown-trigger is-flex")}
        onClick={() => setActive(!active)}
      >
        <RoundedButton
          transparent={true}
          label={t("selectClauses")}
          onClick={() => setActive(true)}
          icon={<ListIcon />}
        />
        {/*<ArrowDown className={"dropdown-indicator"}/>*/}
      </div>

      <div className="dropdown-menu clause-selector-dropdown">
        <div className={"arrow-up is-right"}>
          <div className={"inner-arrow-up"} />
        </div>
        <div className="dropdown-content">
          <div className="clause-selector">
            <header>
              {t("selectClause")}
              <CloseIcon onClick={cancel} />
            </header>
            <section>
              <div className="table-container">
                <table className="table is-fullwidth is-hoverable">
                  <tbody>
                    {props.clauses.map((clause, idx) => (
                      <ClauseSelectorRow
                        key={clause.clauseId}
                        clause={clause}
                        isSelected={selectedClauses.has(clause.clauseId)}
                        toggleClause={toggleClause}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
            <footer>
              <div className="portal-buttons">
                <RoundedButton
                  buttonClass="cancel"
                  label={t("cancel")}
                  onClick={() => setActive(false)}
                />
                <RoundedButton
                  buttonClass="pasteSelected"
                  label={t("pasteSelected")}
                  onClick={onPaste}
                />
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClauseSelector;
