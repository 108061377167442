import { ReactNode, useEffect, useRef, useState } from "react";
import { notify, useSubscription } from "../../../Subscription";
import { PopUpProps } from "./types";

export function usePopUp(content: ReactNode): PopUpProps {
  const elementRef = useRef<any>();
  const [isOpened, setOpened] = useState(false);

  useSubscription("popup", () => setOpened(false));

  useEffect(() => {
    if (content) {
      setOpened(true);
    }
  }, [content]);

  function open() {
    setOpened(true);
  }

  function close() {
    if (isOpened) {
      notify("popup", null);
      setOpened(false);
    }
  }

  return { elementRef, isOpened, open, close, content };
}
