import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextAreaField } from "..";
import { RoundedButton } from "../..";
import { AppContext } from "../../../App";
import { Univ } from "../../../types";
import { EnlargedFieldProps } from "../types";

function EnlargedTextField(props: EnlargedFieldProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const [textareaValue, setTextareaValue] = useState(props.value);

  function save() {
    props.setValue(textareaValue);
    props.onClose();
  }

  function setValue(val: any) {
    setTextareaValue(props.format ? props.format(val) : val);
  }

  return (
    <div className="modal enlarged-textarea is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          {typeof props.label === "object"
            ? (props.label as Univ)[lang]
            : props.label}
        </header>
        <section className="modal-card-body">
          <TextAreaField
            {...props}
            disableEnlarge={true}
            value={textareaValue}
            onChange={(e) => setValue(e.target.value)}
            setValue={setValue}
          />
        </section>
        <footer className="modal-card-foot">
          <div className="portal-buttons">
            <RoundedButton
              buttonClass="discard"
              label={t("discardChanges")}
              onClick={props.onClose}
            />
            <RoundedButton
              buttonClass="save"
              label={t("save")}
              onClick={save}
            />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default EnlargedTextField;
