import { TradeFinance } from "../../../types";
import * as TFApi from "../../../api/tradeFinanceApi";

export async function signFunction(
  tradeFinance: TradeFinance,
  history: any,
  caas: boolean,
  signatureCompleted: boolean
): Promise<void> {
  const res = await TFApi.sign(
    tradeFinance.id,
    tradeFinance.event ? tradeFinance.event.id : null
  );
  const isDraft = tradeFinance?.initiationAs === "draft";

  if (caas && res?.redirectUrl) {
    window.location.href = res?.redirectUrl;
  } else {
    history.push(
      signatureCompleted || isDraft
        ? "/portal/trade-finance-list/ongoing"
        : "/portal/signatures"
    );
  }
}
