import React from "react";
import { ClauseSelectorFactory, TextAreaField } from "..";
import { TextAreaWithClausesProps } from "../types";

function TextAreaFieldWithClause(props: TextAreaWithClausesProps) {
  return (
    <TextAreaField {...props} headerElementsFactory={ClauseSelectorFactory} />
  );
}

export default TextAreaFieldWithClause;
