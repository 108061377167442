import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ReportListProps } from "../../types";
import * as API from "../../../../api/tradeFinanceApi";
import { formatDate } from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import {
  DiscardReportButton,
  FieldsColumn,
  GenerateReportButton,
  ProductsColumn,
} from "..";
import { Link } from "react-router-dom";
import { Edit, Eye } from "../../../../icons/Icons";

function ReportTemplateList(props: ReportListProps) {
  const { t } = useTranslation();
  const loadReportTemplatesQuery = useQuery("reportTemplates", async () => {
    return API.getReportTemplates();
  });

  if (loadReportTemplatesQuery.isFetching) {
    return null;
  }

  return (
    <div className="report-templates container box">
      <div className="table-container">
        <table className="table portal-table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>
                <div>{t("report.templateName")}</div>
                <div>{t("report.lastUsed")}</div>
                <div>{t("report.format")}</div>
              </th>
              <th>{t("report.products")}</th>
              <th>{t("report.fields")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {loadReportTemplatesQuery.data?.result.map((template, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div>
                      <strong>{template.name}</strong>
                    </div>
                    <div>{formatDate(template.lastUsed)}</div>
                    <div>{template.format?.join(",")}</div>
                  </td>
                  <td>
                    {<ProductsColumn products={template.selectedProducts} />}
                  </td>
                  <td>{<FieldsColumn fields={template.columns} />}</td>
                  <td>
                    <div className={"action-buttons-container"}>
                      <div className="horizontal-buttons">
                        <Link
                          className="card-button"
                          to={`/portal/report-template/${template.id}`}
                        >
                          {template.createdOnPortal ? <Edit /> : <Eye />}
                          <div className="button-label">
                            {t(
                              template.createdOnPortal
                                ? "report.edit"
                                : "report.view"
                            )}
                          </div>
                        </Link>
                        <GenerateReportButton
                          reportTemplate={template}
                          onSuccess={props.updateStats}
                        />
                        {template.createdOnPortal && (
                          <DiscardReportButton
                            reportTemplate={template}
                            onSuccess={loadReportTemplatesQuery.refetch}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReportTemplateList;
