import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../App";
import { ArrowDown, ArrowRight } from "../../../../../icons/Icons";
import { CollapsibleState, ContainerWithLabel } from "../../../types";

function CollapsibleContainer({ container, content, idx }: ContainerWithLabel) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);

  const [collapsible, setCollapsible] = useState(container.collapsible);
  if (container.label) {
    if (CollapsibleState.CLOSED === collapsible) {
      return (
        <div key={idx} className="layout-container">
          <div className="container">
            <div
              key="label"
              className="container-title collapsible-container-header collapsible-container-header-closed"
              onClick={() => setCollapsible(CollapsibleState.OPENED)}
            >
              <div className="collapsible-container-header-title">
                {container.label[lang]}
              </div>
              <div className="collapsible-container-header-icon">
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      //OPENED WITH LABEL
      return (
        <div key={idx} className="layout-container">
          <div className="container">
            <div
              key="label"
              className="container-title collapsible-container-header"
              onClick={() => setCollapsible(CollapsibleState.CLOSED)}
            >
              <div className="collapsible-container-header-title">
                {container.label[lang]}
              </div>
              <div className="collapsible-container-header-icon">
                <ArrowDown />
              </div>
            </div>
          </div>
          <div className="opened-collapsible-container-colored">
            <div className="container opened-collapsible-container-border">
              {content}
            </div>
          </div>
        </div>
      );
    }
  } else {
    if (CollapsibleState.CLOSED === collapsible) {
      return (
        <div key={idx} className="layout-container">
          <div className="container">
            <div
              key="label"
              className="container-title collapsible-container-header collapsible-container-header-closed"
              onClick={() => setCollapsible(CollapsibleState.OPENED)}
            >
              <div className="collapsible-container-header-title">
                {t("collapsibleContainer")}
              </div>
              <div className="collapsible-container-header-icon">
                <ArrowDown />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      //OPENED WITH NO LABEL
      return (
        <div key={idx} className="layout-container">
          <div className="container">
            <div
              key="label"
              className="container-title collapsible-container-header"
              onClick={() => setCollapsible(CollapsibleState.CLOSED)}
            >
              <div className="collapsible-container-header-title">
                {t("collapsibleContainer")}
              </div>
              <div className="collapsible-container-header-icon">
                <ArrowDown />
              </div>
            </div>
          </div>
          <div className="opened-collapsible-container-colored">
            <div className="container opened-collapsible-container-border">
              {content}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CollapsibleContainer;
