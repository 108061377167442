import classnames from "classnames";
import React from "react";
import { StepId, StepProps } from "../../../type";

function Step({
  stepId,
  title,
  headerEnd,
  children,
  state,
  stateClass,
  stepClass,
}: StepProps) {
  return (
    <li className={classnames("steps-segment", "is-active", stepClass)}>
      <div className="step">
        <div className="step-header container">
          <span className="steps-marker"></span>
          <p className={classnames("step-title", "is-active")}>{title}</p>
          {state && (
            <div
              className={classnames("step-state", stateClass, {
                finished: state === "finished",
              })}
            >
              {state}
            </div>
          )}
          {headerEnd}
        </div>

        <div className={classnames("step-body", "active")}>
          <div className="container">
            <div className="step-container">
              <div
                className={classnames("step-container-body", {
                  sign: stepId === "sign",
                  "is-block":
                    stepId === StepId.sign || stepId === StepId.reference,
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Step;
