import { useEffect, useState } from "react";
import { useLoadingAction } from "../../../hooks";
import * as TradeFinanceApi from "../../../api/tradeFinanceApi";
import { PAGE_LIMIT } from "../../../api/tradeFinanceApi";
import { NotificationType } from "./types";
import { Notification } from "../../../types";

export function useLoadNotifications(notificationType: NotificationType) {
  const [notifications, setNotifications] = useState<
    Notification[] | undefined
  >(undefined);
  const [offset, setOffset] = useState(0);

  const loadNotificationsAction = useLoadingAction(async () => {
    if (!notificationType) {
      return [];
    }

    let result = await TradeFinanceApi.fetchNotifications(
      notificationType === "todo",
      "done",
      offset,
      PAGE_LIMIT
    );

    return result || [];
  }, [notificationType, offset]);

  useEffect(() => {
    setOffset(0);
    setNotifications([]);
  }, [notificationType]);

  function showMore() {
    if (
      loadNotificationsAction.result &&
      loadNotificationsAction.result.length >= PAGE_LIMIT
    ) {
      setOffset(offset + PAGE_LIMIT);
    }
  }

  useEffect(() => {
    if (loadNotificationsAction.result) {
      let prevNotifications = notifications || [];
      setNotifications([
        ...prevNotifications,
        ...loadNotificationsAction.result,
      ]);
    }
  }, [loadNotificationsAction.result]);

  function reload() {
    setNotifications([]);
    loadNotificationsAction.run();
  }

  const isLoading = loadNotificationsAction.isLoading;
  const hasMore =
    loadNotificationsAction.result &&
    loadNotificationsAction.result.length >= PAGE_LIMIT;
  return { notifications, showMore, isLoading, reload, hasMore };
}
