import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { RoundedButton } from "../../../../components";
import { Delete } from "../../../../icons/Icons";
import { Report, ReportTemplate } from "../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";

function DiscardReportTemplateButton({ form }: { form: FormikProps<Report> }) {
  const { t } = useTranslation();
  const history = useHistory();
  const discardReportTemplate = useMutation(
    async (report: ReportTemplate) => {
      if (report.id) {
        await TFApi.discardReport(report.id);
      }
    },
    {
      onSuccess: (data) => {
        history.push("/portal/reports");
      },
    }
  );

  async function discardTemplate() {
    await discardReportTemplate.mutate(form.values);
  }

  return (
    <RoundedButton
      label={t("report.actions_options.discardReport")}
      disabled={discardReportTemplate.isLoading}
      onClick={discardTemplate}
      buttonClass={"is-danger"}
      icon={<Delete />}
    />
  );
}
export default DiscardReportTemplateButton;
