import React, { useState } from "react";
import { CheckBox } from "..";
import { Clause } from "../../../types";

function ClauseSelectorRow({
  clause,
  toggleClause,
  isSelected,
}: {
  clause: Clause;
  isSelected: boolean;
  toggleClause: (clause: Clause) => void;
}) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <tr
      key={clause.clauseId}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => toggleClause(clause)}
    >
      <td>
        <CheckBox
          id={clause.clauseId}
          hovered={isHovered}
          checked={isSelected}
          onChange={() => {}}
        />
      </td>
      <td>{clause.clauseId}</td>
      <td className="is-bold">{clause.clauseDesc}</td>
    </tr>
  );
}

export default ClauseSelectorRow;
