// @ts-nocheck
import React, { useCallback, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { ActionMeta, ValueType } from "react-select/src/types";
import AsyncSelect from "react-select/async";
import { AppContext } from "../../../App";
import { TradeFinancePartner } from "../../../types";
import { partnerToOption, reactSelectStyles } from "../helper";
import { SelectOption, SwiftCodeFieldProps } from "../types";
import * as TFAPI from "../../../api/tradeFinanceApi";
import { DecorateField } from "..";
import classnames from "classnames";

function SwiftCodeField(props: SwiftCodeFieldProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const { pathname } = useLocation();
  const isBeneficiaryPage = pathname.includes("/portal/beneficiary/");

  const partnersRef = useRef<TradeFinancePartner[]>();

  function onChange(swiftCode: ValueType<SelectOption>, action: ActionMeta) {
    props.setValue(swiftCode ? (swiftCode as SelectOption).value : null);

    if (props.onSelected) {
      const partner = !swiftCode
        ? null
        : partnersRef.current?.find(
            (partner) => partner.swiftCode === (swiftCode as SelectOption).value
          );
      props.onSelected(partner);
    }
  }

  const initialOptions = useQuery(
    ["tfPartnersBySwiftCode", props.value],
    async () =>
      (await TFAPI.getTradeFinancePartners(props.value)).map(partnerToOption)
  );

  if (
    !isBeneficiaryPage &&
    !initialOptions.isLoading &&
    props.value &&
    initialOptions.data &&
    initialOptions.data.length === 0
  ) {
    props.setError(t("tradeFinance.warnings.inactiveBank"));
  }

  const loadOptions = useCallback(
    async (search: string) => {
      if (search?.length < 3) {
        return [];
      }

      let partners: TradeFinancePartner[] = [];
      try {
        partners = await TFAPI.getTradeFinancePartners(search);
      } catch (e) {
        console.error("Couldn't fetch trade finance partners");
      }
      partnersRef.current = partners;

      const options = partners.map(partnerToOption);

      return options;
    },
    [partnersRef]
  );

  return DecorateField(
    props,
    <div
      className={classnames(
        "is-fullwidth",
        { "is-danger": !!props.error },
        props.className
      )}
    >
      <AsyncSelect
        className="react-select"
        styles={reactSelectStyles}
        value={initialOptions.data?.find(
          (option) => (option as SelectOption).value === props.value
        )}
        cacheOptions={false}
        defaultOptions={initialOptions.data || []}
        onChange={onChange}
        isClearable={true}
        isDisabled={props.disabled}
        placeholder={t("startTypingToSearch")}
        loadOptions={loadOptions}
      />
    </div>,
    lang
  );
}

export default SwiftCodeField;
