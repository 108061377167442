import {
  changeUserConfigItemField,
  deepEqual,
  defaultValuesInitState,
} from "../../helper";
import { UserConfigItem, Event, UserConfigItemValues } from "../../types";

export function cancel(
  setItems: React.Dispatch<React.SetStateAction<UserConfigItem[]>>,
  origConfigItems: UserConfigItem[]
) {
  setItems(origConfigItems);
}

export function close(
  setEditedUserConfigId: (configId: number | null) => void
) {
  setEditedUserConfigId(null);
}

export function updateItem(
  idx: number,
  field: "groupA" | "groupB" | "dataEntry" | "draftMaker",
  items: UserConfigItem[],
  setItems: React.Dispatch<React.SetStateAction<UserConfigItem[]>>,
  setItemsChanged: React.Dispatch<React.SetStateAction<boolean>>,
  origConfigItems: UserConfigItem[]
) {
  let newItems = items.map((item, i) => {
    if (i === idx) {
      const newItem = { ...item, [field]: !item[field] };
      if (item.eventName === Event.messaging) {
        newItem.dataEntry = false;
        newItem.draftMaker = false;
      }
      return changeUserConfigItemField(newItem, field);
    } else {
      return item;
    }
  });

  setItemsChanged(!deepEqual(origConfigItems, newItems));
  setItems(newItems);
}

export function updateDefaultValue(
  field: "groupA" | "groupB" | "dataEntry" | "draftMaker",
  defaultValues: UserConfigItemValues,
  setDefaultValues: React.Dispatch<React.SetStateAction<UserConfigItemValues>>
) {
  const newDefaultValue = changeUserConfigItemField(
    { ...defaultValues, [field]: !defaultValues[field] } as UserConfigItem,
    field
  );
  setDefaultValues(newDefaultValue);
}

export function updateDefaultValues(
  items: UserConfigItem[],
  isSuperUser: boolean | undefined,
  defaultValues: UserConfigItemValues,
  setItems: React.Dispatch<React.SetStateAction<UserConfigItem[]>>,
  setDefaultValues: React.Dispatch<React.SetStateAction<UserConfigItemValues>>
): void {
  let newItems = items.map((item, idx) => {
    let isDraftMakerDisabled =
      !isSuperUser ||
      item.eventName === Event.messaging ||
      item.groupA ||
      item.groupB;

    if (!isDraftMakerDisabled) {
      return Object.assign(item, defaultValues);
    } else {
      return {
        ...item,
        groupA: defaultValues.groupA,
        groupB: defaultValues.groupB,
        draftMaker: defaultValues.draftMaker,
        dataEntry: defaultValues.dataEntry,
      };
    }
  });

  setItems(newItems);
  setDefaultValues(defaultValuesInitState);
}
