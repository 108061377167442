import React, { useRef } from "react";
import { CloseIcon } from "../../../icons/Icons";
import useOnClickOutside from "use-onclickoutside";
import { PopUpProps } from "../assets/types";

function PopUp({ elementRef, open, close, content, isOpened }: PopUpProps) {
  const popupRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(popupRef, close);

  if (!isOpened || !elementRef.current) {
    return null;
  }

  const elementDims = elementRef.current.getBoundingClientRect();

  const pos = {
    top: elementRef.current.offsetTop - 10,
    left: elementRef.current.offsetLeft + elementDims.width / 2,
    transform: `translateY(-100%) translateX(-50%)`,
  };
  return (
    <div className="popup" ref={popupRef} style={pos}>
      <CloseIcon onClick={close} className={"close-icon"} />
      {content}
    </div>
  );
}

export default PopUp;
