import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TradeFinance } from "../../../../../../types";
import { TradFinanceRequestContext } from "../../../context";
import { useIsTemplate } from "../../../hooks";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import { RoundedButton } from "../../../../../../components";
import { TemplateIcon } from "../../../../../../icons/Icons";
import { Modal } from "./assets";

function CreateTemplateButton({
  tradeFinance,
  disabled,
}: {
  tradeFinance: TradeFinance;
  disabled?: boolean;
}) {
  const { form } = useContext(TradFinanceRequestContext);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (useIsTemplate(tradeFinance) || id === "new") {
    return null;
  }

  async function creatTemplate() {
    await TFApi.saveTradeFinanceModifications(form.values, false);
    setIsLoading(true);
    //TODO
    const copyResult = await TFApi.copyTradeFinance(
      tradeFinance,
      true,
      templateName
    );

    await TFApi.getTradeFinanceLastTime(tradeFinance.id).then((item: any) => {
      form.setFieldValue("lastTime", item.date);
    });

    setShowModal(false);
    setIsLoading(false);
    setTemplateName("");
  }

  let saveDisabled = templateName === "";

  return (
    <>
      <RoundedButton
        label={t("tradeFinance.template")}
        icon={<TemplateIcon />}
        onClick={() => setShowModal(true)}
      />

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        templateName={templateName}
        setTemplateName={setTemplateName}
        saveDisabled={saveDisabled}
        isLoading={isLoading}
        creatTemplate={creatTemplate}
      />
    </>
  );
}

export default CreateTemplateButton;
