import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import { Count } from "..";
import { AppContext } from "../../../../App";
import { PrivateMenuProps } from "../types";

function PrivateMenu(props: PrivateMenuProps) {
  const { t } = useTranslation();
  const { secondHeader, scrollTop, setPrivateMenuVisible, privateMenuVisible } =
    props;
  const { stats } = useContext(AppContext);
  const privateMenuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(privateMenuRef, () => {
    if (privateMenuVisible) {
      setPrivateMenuVisible(false);
    }
  });

  return (
    <div className={"container private-menu"} style={{ top: scrollTop }}>
      {secondHeader}
      <div className={"menu-content"} ref={privateMenuRef}>
        <div className="columns">
          <div className="column is-two-fifths">
            <div className={"column-title"}>{t("menu.transactions")}</div>

            <div className="columns">
              <div className="column">
                <NavLink to={"/portal/signatures"}>
                  {t("menu.signatures")}{" "}
                  <Count value={stats?.signatureRequired} />
                </NavLink>
                <NavLink to={"/portal/trade-finance-list/ongoing"}>
                  {t("menu.ongoing")}{" "}
                  <Count value={stats?.ongoingTransactions} />
                </NavLink>
                <NavLink to={"/portal/trade-finance-list/concept"}>
                  {t("menu.concept")} <Count value={stats?.draft} />
                </NavLink>
              </div>

              <div className="column">
                <NavLink to={"/portal/trade-finance-list/guarantees"}>
                  {t("menu.guarantees")}
                  <Count value={stats?.activeGuarantees} />
                </NavLink>
                <NavLink to={"/portal/trade-finance-list/letterOfCredit"}>
                  {t("menu.letterOfCredit")} <Count value={stats?.activeLC} />
                </NavLink>
                <NavLink to={"/portal/trade-finance-list/collection"}>
                  {t("menu.collection")}
                  <Count value={stats?.activeCollection} />
                </NavLink>
                <NavLink to={"/portal/trade-finance-list/standby"}>
                  {t("menu.standby")} <Count value={stats?.standby} />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="column is-one-fifth">
            <div className={"column-title"}>{t("menu.report")}</div>
            <div className="columns">
              <div className="column">
                <NavLink to={"/portal/reports"}>{t("menu.report")}</NavLink>
              </div>
            </div>
          </div>

          <div className="column is-two-fifths">
            <div className={"column-title"}>{t("menu.settings")}</div>

            <div className="columns">
              <div className="column">
                <NavLink to={"/portal/beneficiaries"}>
                  {t("menu.beneficiary")}
                </NavLink>
                <NavLink to={"/portal/templates"}>
                  {t("menu.templates")}
                </NavLink>
                <NavLink to={"/portal/clause"}>{t("menu.clause")}</NavLink>
                <NavLink to={"/portal/help"}>{t("menu.help")}</NavLink>
              </div>

              <div className="column">
                <NavLink to={"/portal/profile"}>{t("menu.profile")}</NavLink>
                <NavLink to={"/portal/underwriters"}>
                  {t("menu.underwriters")}
                </NavLink>
                <NavLink to={"/portal/userConfiguration"}>
                  {t("menu.userConfiguration")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivateMenu;
