import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RoundedButton } from "..";
import { PopUp } from "../..";
import { AppContext } from "../../../App";
import { UseActionResult } from "../../../hooks";
import { errorToString } from "../../../utils";
import { usePopUp } from "../../PopUpComponents/assets/hooks";
import { RoundedButtonProps } from "../types";

function ActionButton(props: RoundedButtonProps & { action: UseActionResult }) {
  const { action, ...buttonProps } = props;
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const popUp = usePopUp(
    action.error ? errorToString(action.error, t, lang) : null
  );

  function onClick() {
    popUp.close();
    action.run();
  }

  const isLoading = props.loading || action.isLoading;

  return (
    <>
      <RoundedButton
        {...buttonProps}
        ref={popUp.elementRef}
        onClick={onClick}
        disabled={props.disabled || isLoading}
        loading={isLoading}
      />
      {popUp.isOpened && <PopUp {...popUp} />}
    </>
  );
}

export default ActionButton;
