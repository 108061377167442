import React, { useContext } from "react";
import { LanguageItem } from "..";
import { AppContext } from "../../../../App";
import { HeaderDropdown } from "../../..";

export function LanguageSelector() {
  const appContext = useContext(AppContext);

  const languageOptions = (
    <>
      <LanguageItem lang="en" label="EN" />
      <LanguageItem lang="cz" label="CZ" />
    </>
  );

  return (
    <HeaderDropdown
      cssId={"langSelector"}
      label={appContext.lang}
      showDropdownIcon={true}
      popupContent={languageOptions}
    />
  );
}
