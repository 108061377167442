import * as React from "react";
import EmptyFolderIllustration from "../../icons/png/EmptyFolderIllustration.png";
import { EmptyListProps } from "./assets/types";

function EmptyList({ message }: EmptyListProps) {
  return (
    <div className="empty-list">
      <div className="msg">{message}</div>
      <img src={EmptyFolderIllustration} alt="" />
    </div>
  );
}
export default EmptyList;
