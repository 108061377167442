import * as React from "react";
import { useState } from "react";
import { Id } from "../../../types";
import { NavLink } from "react-router-dom";
import * as API from "../../../api/tradeFinanceApi";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { Plus } from "../../../icons/Icons";
import { DeviceType, useDeviceType } from "../../tradeFinanceHooks";
import { PAGE_LIMIT } from "../../../api/tradeFinanceApi";
import { BeneficiariesPageProps } from "./assets/typest";
import { BeneficiariesFilterComponent, BeneficiaryCard } from "./assets";
import {
  EmptyList,
  PageHeader,
  ShowMoreButton,
  RoundedButton,
} from "../../../components";

export default function BeneficiariesPage(props: BeneficiariesPageProps) {
  const { t } = useTranslation();
  const device = useDeviceType();
  const [search, setSearch] = useState<string | null | undefined>();
  const {
    status,
    data,
    //isFetchingNextPage,
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteQuery(
    ["beneficiaries", { search }],
    async ({ pageParam = 0 }) => {
      return API.fetchBeneficiaries(search, pageParam as number, PAGE_LIMIT);
    },
    {
      getNextPageParam: (lastGroup, allGroups) =>
        lastGroup.length < PAGE_LIMIT ? false : allGroups.length * PAGE_LIMIT,
    }
  );

  async function deleteBeneficiary(id: Id) {
    await API.deleteBeneficiary(id);
    await refetch();
  }

  let pageButton = (
    <NavLink to="/portal/beneficiary/new">
      <RoundedButton
        label={t("tradeFinance.newPartner")}
        icon={<Plus />}
      ></RoundedButton>
    </NavLink>
  );

  return (
    <>
      <div className="beneficiaries-page page card-list-page ongoing-applications-page">
        <PageHeader title={t("menu.beneficiary")} headerContent={pageButton} />

        <BeneficiariesFilterComponent
          search={search || ""}
          onChange={setSearch}
        />

        {(data?.pages || []).length === 0 && status !== "loading" && (
          <EmptyList message={t("beneficiary.emptyBeneficiaryList")} />
        )}
        <div className="list-cards top-margin">
          <div className={"list-card list-header"}>
            <div className={"details"}>
              <div className={"columns guarantee-columns"}>
                <div className={"column"}>
                  <div>{t("header.name")}</div>
                </div>
                <div className={"column"}>
                  <div>{t("header.bankName")}</div>
                </div>
                <div className={"column has-right-text"}>
                  <div>{t("header.bankAddress")}</div>
                </div>
                {device === DeviceType.desktop && (
                  <div className={"column is-narrow"}>
                    <div>{t("actions")}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(data?.pages || []).map((beneficiary) => {
            return beneficiary.map((b) => (
              <BeneficiaryCard
                key={b.id}
                beneficiary={b}
                deleteBeneficiary={deleteBeneficiary}
              />
            ));
          })}
        </div>
        <div style={{ marginTop: "20px" }}>
          <ShowMoreButton
            isLoading={status === "loading"}
            showMore={fetchNextPage}
            hasMore={hasNextPage}
          />
        </div>
      </div>
    </>
  );
}
