import React from "react";

function Count({ value }: { value?: number }) {
  if (!value) {
    return null;
  }
  return <span className="count"> ({value})</span>;
}

export default Count;
