import React from "react";
import { useTranslation } from "react-i18next";
import { ShowMoreButtonProps } from "../../types";

function ShowMoreButton({ showMore, isLoading, hasMore }: ShowMoreButtonProps) {
  const { t } = useTranslation();

  if (!hasMore) {
    return null;
  }

  return (
    <div className="show-more-button">
      <button className="button" onClick={showMore} disabled={isLoading}>
        {t("tradeFinance.showMore")}
      </button>
    </div>
  );
}

export default ShowMoreButton;
