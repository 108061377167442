import { FormikErrors, FormikProps } from "formik";
import i18next from "i18next";
import { Univ } from "../../types";
import { FieldProps } from "../Fields/types";
import { ContainerDesc, ContainerType, FormErrors } from "./types";

export function collectFields(layout: ContainerDesc | null): FieldProps[] {
  let res: FieldProps[] = [];
  if (layout) {
    if (layout.type === ContainerType.FORM) {
      res = [...res, ...layout.content];
    } else {
      const contentFields = layout.content
        .map(collectFields)
        .reduce((acc, fields) => acc.concat(fields), []);
      res = [...res, ...contentFields];
    }
  }
  return res;
}

export function hasError(errors: FormikErrors<any>): boolean {
  return errors && Object.keys(errors).length > 0;
}

export function getFieldProps<T>(
  form: FormikProps<T>,
  fieldId: keyof T & string,
  label: string | Univ,
  additionalProps?: Partial<FieldProps>
): FieldProps {
  //TODO
  return {
    id: fieldId as string,
    label: label,
    value: form.values[fieldId],
    error: form.errors[fieldId] as string,
    onChange: form.handleChange,
    setValue: (value: any) => form.setFieldValue(fieldId, value),
    setError: (error: string | null) => {
      const newErrors = { ...form.errors };
      if (error == null) {
        delete newErrors[fieldId];
      } else {
        (newErrors as any)[fieldId] = error;
      }
      return form.setErrors(newErrors);
    },
    ...additionalProps,
  };
}

export function validateMandatoryFields<T>(
  values: T,
  fields: Array<keyof T>,
  t: i18next.TFunction
): FormikErrors<T> {
  function validate(values: T, fields: Array<keyof T>) {
    if (fields) {
      const errorMessage = t("mandatoryField");
      return fields.reduce<FormErrors<T>>((res, field) => {
        let val = values ? (values[field] as any) : null;
        if (
          val === "" ||
          val === null ||
          val === undefined ||
          (Array.isArray(val) && val.length === 0)
        ) {
          return Object.assign(res, { [field]: errorMessage });
        } else {
          return res;
        }
      }, {});
    }
    return {};
  }

  const errors: FormikErrors<T> & { event?: FormikErrors<T> } = validate(
    values,
    fields.filter((fieldId) => !(fieldId as string).startsWith("event."))
  );
  const eventErrors: FormikErrors<T> = validate(
    (values as any).event,
    fields
      .filter((fieldId) => (fieldId as string).startsWith("event."))
      .map((fieldId) =>
        (fieldId as string).substr((fieldId as string).indexOf(".") + 1)
      ) as any
  );
  if (eventErrors && Object.keys(eventErrors).length > 0) {
    errors.event = eventErrors;
  }

  return errors;
}
