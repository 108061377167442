import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ConfirmButton,
  RoundedButton,
} from "../../../../../../../../components";
import {
  Edit,
  SaveDraft,
  Signatures,
  SignDraft,
} from "../../../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../../../context";
import { isAmendment } from "../../../../../tradeFinanceUtils";
import CreateTemplateButton from "../../../CreateTemplateButton/CreateTemplateButton";
import { beforeSign, getSignatureHint } from "../helper";
import { GetStepButtonsType } from "../types";

function GetStepButtons({
  deleteSignatureAction,
  signAction,
  letterOfCreditClaimEvent,
  setShowFinalDocument,
}: GetStepButtonsType) {
  const { t } = useTranslation();
  const { tradeFinance, underWritersInfo, form, setSignError } = useContext(
    TradFinanceRequestContext
  );
  let canSign =
    underWritersInfo && !underWritersInfo.completed && underWritersInfo.canSign;
  let canReOpen =
    underWritersInfo &&
    (underWritersInfo.hasRightToSign ||
      (underWritersInfo.draftMaker &&
        !underWritersInfo.underWritersInGroupA.length &&
        !underWritersInfo.underWritersInGroupB.length));
  let isSendFinalEnabled = useMemo(() => {
    return (
      !underWritersInfo?.draft &&
      (canSign ||
        (underWritersInfo &&
          underWritersInfo.draftMaker &&
          !underWritersInfo.readyForSign))
    );
  }, [canSign, underWritersInfo]);
  const editButton = canReOpen ? (
    <ConfirmButton
      confirmation={t("tradeFinance.deleteSignatureConfirm")}
      button={
        <RoundedButton
          label={t("tradeFinance.reOpenRequest")}
          onClick={deleteSignatureAction.run}
          icon={<Edit />}
          loading={deleteSignatureAction.isLoading}
          disabled={signAction.isLoading}
          buttonClass={"is-danger"}
        />
      }
    />
  ) : (
    ""
  );

  const signButton = letterOfCreditClaimEvent ? (
    <RoundedButton
      label={t("tradeFinance.signAndSend")}
      onClick={signAction.run}
      icon={<Signatures />}
      loading={signAction.isLoading}
      disabled={deleteSignatureAction.isLoading || !canSign}
    />
  ) : (
    <RoundedButton
      label={t("tradeFinance.sendFinal")}
      onClick={() =>
        beforeSign(setSignError, setShowFinalDocument, form, tradeFinance, t)
      }
      loading={false}
      icon={<Signatures />}
      disabled={!isSendFinalEnabled}
      buttonClass={"is-success"}
    />
  );

  const sendDraftButton = (
    <RoundedButton
      label={t("tradeFinance.sendDraft")}
      onClick={() => setShowFinalDocument(true)}
      icon={<SignDraft />}
      buttonClass={"is-success"}
      disabled={!underWritersInfo?.draft}
    />
  );

  const saveDraft = (
    <>
      <div className={"button-divider"}></div>
      <RoundedButton
        label={t("tradeFinance.saveDraft")}
        icon={<SaveDraft />}
        disabled={true}
      />
    </>
  );

  if (letterOfCreditClaimEvent) {
    return (
      <>
        {editButton}
        <div className={"is-flex is-right"}>
          <div className={"sign-hint is-bold"}>
            {getSignatureHint(t, underWritersInfo)}&nbsp;&nbsp;&nbsp;
          </div>
          {signButton}
        </div>
      </>
    );
  }

  return (
    <>
      {signButton}
      {sendDraftButton}
      {saveDraft}
      {!isAmendment(tradeFinance) && (
        <>
          <CreateTemplateButton tradeFinance={tradeFinance} />
          <div className={"button-divider"}></div>
        </>
      )}
      {editButton}
    </>
  );
}

export default GetStepButtons;
