// @ts-nocheck
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import { useLoadingAction } from "../../../../hooks";
import { BasicDataProps } from "../types";
import * as API from "../../../../api/portalApi";
import { validateMandatoryFields } from "../../../../components/Form/helper";
import { Form } from "../../../../components";
import { User } from "../../../../types";
import { FormikActions } from "formik";
import { BasicDataForm } from "./assets";
import { NotificationContext } from "../../../../components/Notification/assets/context";

function BasicData(props: BasicDataProps) {
  const { user } = props;
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { refreshStats, updateAvatar } = useContext(AppContext);
  const { setNotification } = useContext(NotificationContext);

  const loadCompaniesAction = useLoadingAction(() => API.getUserCompanyDTOs());
  const companies = loadCompaniesAction.result;

  async function submitBasicData(
    values: User,
    { setError, setSubmitting }: FormikActions<User>
  ) {
    try {
      setNotification(null);
      await API.updateUserProfile(values);
      Object.assign(props.user, values);
      props.updateAvatar();

      if (typeof values.initialDefaultCompany === "number") {
        updateAvatar();
        refreshStats();
      }

      setSubmitting(false);
      setShowMessage(true);
    } catch (e) {
      setError(e.message);
      setNotification(t("serverError"));
    }
  }

  if (!loadCompaniesAction.result) {
    return null;
  }

  return (
    <Form
      initialValues={user}
      validateOnChange={false}
      validate={(values) =>
        validateMandatoryFields(
          values,
          [
            values.notificationSignature,
            values.notificationToDo,
            values.notificationInformation,
          ].includes("smsAndEmail") ||
            [
              values.notificationSignature,
              values.notificationToDo,
              values.notificationInformation,
            ].includes("sms")
            ? [
                "name",
                "initialDefaultCompany",
                "phoneNumber",
                "email",
                "countryCode",
                "phoneNumberContact",
              ]
            : ["phoneNumberContact", "email", "initialDefaultCompany", "name"],
          t
        )
      }
      onSubmit={submitBasicData}
    >
      {(form) => (
        <BasicDataForm
          form={form}
          basicDataProperties={props}
          companies={companies}
          showMessage={showMessage}
          setShowMessage={setShowMessage}
        />
      )}
    </Form>
  );
}

export default BasicData;
