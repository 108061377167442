import * as React from "react";
import { useParams } from "react-router-dom";
import { NotificationList } from "./assets";
import { NotificationType } from "./assets/types";

export default function NotificationListPage() {
  const { notificationType } = useParams<{
    notificationType: NotificationType;
  }>();

  return (
    <div className="page notification-list-page">
      <NotificationList
        key={notificationType}
        notificationType={notificationType as NotificationType}
      />
    </div>
  );
}
