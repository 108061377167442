import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import { useCodeBaseAsSelectOptions } from "../../../../hooks";
import { selectOptionsToString } from "../../helper";

function FieldsColumn({ fields }: { fields: string[] }) {
  const { lang } = useContext(AppContext);
  const { t } = useTranslation();
  const columnOptions = useCodeBaseAsSelectOptions("tradefin/reportData");

  if (!columnOptions || !fields?.length) {
    return null;
  }

  const columns = selectOptionsToString(
    fields.slice(0, 10),
    columnOptions!,
    lang
  );
  return (
    <div className="fields">
      {fields.length > 10
        ? columns + " " + t("report.more", { count: fields.length - 10 })
        : columns}
    </div>
  );
}

export default FieldsColumn;
