import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { SelectFieldProps } from "../types";

function SelectField(props: SelectFieldProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const { id, value, disabled, error, className, setValue, fullWidth } = props;

  function onChange(e: any) {
    if (e.target.value === "") {
      setValue(null);
    } else {
      props.onChange(e);
    }
  }

  function renderSelect() {
    return (
      <div
        className={classnames(
          "select is-small",
          { "is-danger": !!error, "is-fullwidth": fullWidth },
          className
        )}
      >
        <select
          id={id}
          disabled={disabled}
          value={value || ""}
          onChange={onChange}
        >
          <option value={""}> {disabled ? "" : "-- " + t("select")}</option>
          {(props.options || []).map((o) => (
            <option key={o.value} value={o.value}>
              {typeof o.label === "string" || !o.label
                ? o.label
                : o.label[lang]}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return DecorateField(props, renderSelect(), lang);
}

export default SelectField;
