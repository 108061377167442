import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DescriptionFieldProps } from "./assets/types";

function DescriptionField({
  document,
  editable,
  required,
  updateDocument,
}: DescriptionFieldProps) {
  const { t } = useTranslation();
  const [touched, setTouched] = useState(false);

  return (
    <div className="field">
      <label className="label">
        {t("tradeFinance.messaging.documentDescription")}
        {required && <span className="asterisk">&nbsp;*</span>}
      </label>
      <div className="control">
        <textarea
          disabled={!editable}
          className="textarea is-small"
          rows={1}
          value={document.description || ""}
          maxLength={255}
          onChange={(e) =>
            updateDocument({ ...document, description: e.target.value })
          }
          onBlur={() => {
            setTouched(true);
          }}
        />
        <p className="help is-danger">
          {touched && required && !document.description && t("mandatoryField")}
        </p>
      </div>
    </div>
  );
}

export default DescriptionField;
