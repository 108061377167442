import classnames from "classnames";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PDFObject } from "react-pdfobject";
import { GetSignatureSentences } from "..";
import { AppContext } from "../../../../../../../../App";
import { PopUp, RoundedButton } from "../../../../../../../../components";
import { usePopUp } from "../../../../../../../../components/PopUpComponents/assets/hooks";
import {
  CloseIcon,
  DownloadIcon,
  LeaveFormIcon,
  Signatures,
} from "../../../../../../../../icons/Icons";
import {
  DeviceType,
  isIEBrowser,
  useDeviceType,
} from "../../../../../../../tradeFinanceHooks";
import { TradFinanceRequestContext } from "../../../../../context";
import { GetFinalDocumentModalType } from "../types";

function GetFinalDocumentModal({
  signAction,
  showFinalDocument,
  setShowFinalDocument,
  letterOfCreditClaimEvent,
}: GetFinalDocumentModalType) {
  const { t } = useTranslation();
  const popUp = usePopUp(null);
  const [pdfError, setPdfError] = useState<boolean>(false);
  const device = useDeviceType();

  const { tradeFinance, underWritersInfo } = useContext(
    TradFinanceRequestContext
  );
  const { lang } = useContext(AppContext);

  if (!underWritersInfo?.signatureId) {
    return null;
  }

  const pdfUrl = `/api/signatures/${underWritersInfo?.signatureId}/finalDocument`;

  function checkExstreamAnswer() {
    fetch(pdfUrl + "?inline=true")
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          signAction.run();
          setPdfError(false);
          return response.statusText;
        }
      })
      .catch((error) => {
        setPdfError(true);
        console.error("error: " + error);
      });
  }

  const signButtonLabel = t(
    underWritersInfo.hasRightToSign
      ? "tradeFinance.signAndSend"
      : "tradeFinance.sendForSigning"
  );

  return (
    <div className={classnames("modal", { "is-active": showFinalDocument })}>
      <div className="modal-background"></div>
      <div className="modal-window modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t(
              tradeFinance.initiationAs === "draft"
                ? "tradeFinance.draftRequest"
                : "tradeFinance.finalRequest"
            )}
          </p>
          <CloseIcon
            className={"close-icon"}
            onClick={() => setShowFinalDocument(false)}
          />
        </header>
        <section className="modal-card-body">
          {underWritersInfo?.signatureId && (
            <PDFObject
              forcePDFJS={true}
              PDFJS_URL={"/js/pdfjs/web/viewer.html"}
              url={pdfUrl + "?inline=true"}
              pdfOpenParams={{ view: "FitH" }}
            />
          )}
        </section>
        <footer className="modal-card-foot">
          <div className={"modal-buttons"}>
            <RoundedButton
              label={t("back")}
              style={{ minWidth: isIEBrowser() ? "80px" : "initial" }}
              icon={<LeaveFormIcon />}
              buttonClass={"is-danger"}
              disabled={signAction.isLoading}
              onClick={() => setShowFinalDocument(false)}
            />

            {device === DeviceType.desktop &&
              underWritersInfo.hasRightToSign && (
                <GetSignatureSentences
                  letterOfCreditClaimEvent={letterOfCreditClaimEvent}
                  showFinalDocument={showFinalDocument}
                />
              )}
            <a className="card-button margin-left-auto" href={pdfUrl}>
              <RoundedButton
                label={t("download") + " PDF"}
                icon={<DownloadIcon />}
              />
            </a>

            <RoundedButton
              label={signButtonLabel}
              ref={popUp.elementRef}
              style={{
                minWidth: isIEBrowser()
                  ? lang === "en"
                    ? "120px"
                    : "155px"
                  : "auto",
              }}
              onClick={checkExstreamAnswer}
              icon={<Signatures />}
              loading={signAction.isLoading}
              buttonClass={classnames("is-success", {
                "on-modal": showFinalDocument,
              })}
            />
            <PopUp
              {...popUp}
              isOpened={pdfError}
              content={t("somethingWentWrong")}
              close={() => setPdfError(false)}
            />
          </div>
          {device !== DeviceType.desktop && underWritersInfo.hasRightToSign && (
            <GetSignatureSentences
              letterOfCreditClaimEvent={letterOfCreditClaimEvent}
              showFinalDocument={showFinalDocument}
            />
          )}
        </footer>
      </div>
    </div>
  );
}

export default GetFinalDocumentModal;
