import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  SwiftCodeField,
  TextAreaField,
  TextField,
  RoundedButton,
} from "../../../../../components";
import { getFieldProps } from "../../../../../components/Form/helper";
import { SaveIcon } from "../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../../TradeFinanceRequestDetailsPage/assets/context";
import { RenderFormType } from "../types";

function RenderForm({ beneficiaryForm, isNew, errorMessage }: RenderFormType) {
  const { t } = useTranslation();
  const tfinContext = useContext(TradFinanceRequestContext);
  const { createNewPartnerOption } = tfinContext;
  const { error, isSubmitting, handleSubmit } = beneficiaryForm;
  return (
    <div className="wizard">
      <div className="columns">
        <div className="column">
          <div className="container-title">
            {t("beneficiary.partnerInformation")}
          </div>

          <TextField
            {...getFieldProps(beneficiaryForm, "name", t("beneficiary.name"))}
            required={true}
          />
          <TextAreaField
            {...getFieldProps(
              beneficiaryForm,
              "partnerSwiftAddress",
              t("beneficiary.partnerSwiftAddress")
            )}
            required={true}
          />
          <TextField
            {...getFieldProps(
              beneficiaryForm,
              "partnerRegistrationNumber",
              t("beneficiary.partnerRegistrationNumber")
            )}
          />
          <TextField
            {...getFieldProps(
              beneficiaryForm,
              "partnerAccountNumber",
              t("beneficiary.partnerAccountNumber")
            )}
          />
        </div>
        {((!createNewPartnerOption && isNew) ||
          (!createNewPartnerOption && !isNew) ||
          (createNewPartnerOption &&
            tfinContext.tradeFinance.businessEntityDescriptorId !==
              "tradefin/IssuedGuarantee")) && (
          <div className="column">
            <div className="container-title">
              {t("beneficiary.bankInformation")}
            </div>

            <SwiftCodeField
              {...getFieldProps(
                beneficiaryForm,
                "bankSwiftCode",
                t("beneficiary.bankSwiftCode")
              )}
              onSelected={(partner) => {
                if (partner) {
                  beneficiaryForm.setFieldValue("bankName", partner.bankName);
                  beneficiaryForm.setFieldValue(
                    "bankSwiftAddress",
                    partner.swiftAddress
                  );
                }
              }}
            />

            <TextField
              {...getFieldProps(
                beneficiaryForm,
                "bankName",
                t("beneficiary.bankName")
              )}
            />

            <TextAreaField
              {...getFieldProps(
                beneficiaryForm,
                "bankSwiftAddress",
                t("beneficiary.bankSwiftAddress")
              )}
              onBlur={() => {
                const value = beneficiaryForm.values.bankStreetNumber;
                if (value) {
                  const lines = value.split("\n");
                  if (lines.length > 3) {
                    beneficiaryForm.setFieldError(
                      "bankStreetNumber",
                      "Too much lines"
                    );
                  } else if (lines.find((line) => line.length > 5)) {
                    beneficiaryForm.setFieldError(
                      "bankStreetNumber",
                      "Too long line"
                    );
                  } else {
                    beneficiaryForm.setFieldError("bankStreetNumber", "");
                  }
                }
              }}
            />

            <TextField
              {...getFieldProps(
                beneficiaryForm,
                "bankPhoneNr",
                t("beneficiary.bankPhoneNr")
              )}
            />
          </div>
        )}
      </div>

      {error && <div className="errors">{error}</div>}
      {errorMessage && (
        <div className="message is-warning">
          <div className="message-body">
            {t("tradeFinance.errors.partnerError")}
          </div>
        </div>
      )}
      <div className="buttons">
        <RoundedButton
          label={!isNew ? t("saveChanges") : t("save")}
          disabled={isSubmitting}
          icon={<SaveIcon />}
          loading={isSubmitting}
          style={{ marginLeft: "auto" }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default RenderForm;
