import classNames from "classnames";
import moment, { Moment } from "moment";
import { CalendarEvents } from "../../../types";

export function filteredCalendarEvents(
  calendarEvents: CalendarEvents | undefined,
  date: Moment
): CalendarEvents | null {
  if (!calendarEvents) {
    return null;
  }

  const eventsForDay = calendarEvents.events.filter((event) =>
    event.date.isSame(date)
  );

  if (eventsForDay.length === 0) {
    return null;
  }

  return {
    events: eventsForDay,
    holidays: [],
  };
}

export function getDayStyles(
  calEvents: CalendarEvents | undefined,
  day: Moment
) {
  const hasEvent = calEvents?.events?.some((event) => event.date.isSame(day));
  const isHoliday =
    day.isoWeekday() === 6 ||
    day.isoWeekday() === 7 ||
    calEvents?.holidays?.some((holiday) => holiday.isSame(day));
  const today = moment().startOf("day");

  return classNames({
    "is-today": day.isSame(today),
    "is-holiday": isHoliday,
    "has-event": hasEvent,
  });
}
