import classnames from "classnames";
import React, { ReactNode } from "react";
import { HelpComponent } from "..";
import { isIEBrowser } from "../../../pages/tradeFinanceHooks";
import { Language } from "../../../types";
import { FieldProps, FieldType } from "../types";

function RenderLabel(
  fieldProps: FieldProps,
  lang: Language,
  contentBefore?: ReactNode
) {
  const { label, required, error } = fieldProps;
  if (!label) {
    return null;
  }
  return (
    <div className={classnames("is-flex")}>
      <label
        style={{ maxWidth: isIEBrowser() ? "230px" : "initial" }}
        className={classnames("label is-small", {
          checkbox: fieldProps.type === FieldType.boolean,
          "is-checked":
            fieldProps.type === FieldType.boolean && fieldProps.value,
          "has-text-danger": error,
        })}
      >
        {contentBefore}
        {fieldProps.type === FieldType.boolean && " "}
        <span className={"label-width"}>
          {typeof label === "string" || !label ? label : label[lang]}
        </span>
        {required && <span className="asterisk">&nbsp;*</span>}
        {fieldProps.type !== FieldType.boolean && (
          <HelpComponent {...fieldProps} />
        )}
      </label>
      {fieldProps.type === FieldType.boolean && fieldProps.help && (
        <span style={{ marginBottom: "6px" }}>
          {<HelpComponent {...fieldProps} />}
        </span>
      )}
    </div>
  );
}

export default RenderLabel;
