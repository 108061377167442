import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { UserConfigProfileComponent } from "..";
import { AppContext } from "../../../../../../App";
import { CheckBox, Form } from "../../../../../../components";
import { validateMandatoryFields } from "../../../../../../components/Form/helper";
import { Edit } from "../../../../../../icons/Icons";
import { User, UserConfig } from "../../../../../../types";
import {
  convertItemsToUserConfig,
  convertUserConfigToItems,
  defaultValuesInitState,
  QUERY_KEY,
} from "../../../helper";
import { UserConfigItemValues, Event, Product } from "../../../types";
import { updateDefaultValue, updateDefaultValues, updateItem } from "../helper";
import * as TFAPi from "../../../../../../api/tradeFinanceApi";

type GetContentType = {
  userConfig: UserConfig;
  setItemsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setUserChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

function GetContent({
  userConfig,
  setItemsChanged,
  setUserChanged,
}: GetContentType) {
  const origConfigItems = convertUserConfigToItems(userConfig);
  const { user, updateUserState } = useContext(AppContext);
  const { t } = useTranslation();
  const [items, setItems] = useState(origConfigItems);
  const [defaultValues, setDefaultValues] = useState<UserConfigItemValues>(
    defaultValuesInitState
  );

  let queryClient = useQueryClient();

  const isSuperUser = user?.superUser;
  const origUser = userConfig.user;

  const saveUserConfig = useMutation(
    async (userConfig: UserConfig) =>
      TFAPi.updateUserConfigurations([userConfig]),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEY);
        await updateUserState();
      },
    }
  );

  const isLoading = saveUserConfig.isLoading;

  async function save(values: User) {
    const newUserConfig = { ...userConfig, user: values };
    const convertedConfig = convertItemsToUserConfig(items, newUserConfig);
    await saveUserConfig.mutate(convertedConfig);
  }

  return (
    <div className="has-grey-background">
      <div className="table-container">
        <table className="table portal-table user-config-table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>{t("userConfig.transactionType")}</th>
              <th className="has-right-text">{t("userConfig.eventLabel")}</th>
              <th>{t("userConfig.groupA")}</th>
              <th>{t("userConfig.groupB")}</th>
              <th>{t("userConfig.draftMaker")}</th>
              <th>{t("userConfig.dataEntry")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isSuperUser && (
              <tr className="new-value-setter">
                <td className="category-name">{t("userConfig.newValues")}</td>
                <td></td>
                <td>
                  <CheckBox
                    checked={defaultValues.groupA}
                    id={"default_groupA"}
                    onChange={() =>
                      updateDefaultValue(
                        "groupA",
                        defaultValues,
                        setDefaultValues
                      )
                    }
                  />
                </td>
                <td>
                  <CheckBox
                    checked={defaultValues.groupB}
                    id={"default_groupB"}
                    onChange={() =>
                      updateDefaultValue(
                        "groupB",
                        defaultValues,
                        setDefaultValues
                      )
                    }
                  />
                </td>
                <td>
                  <CheckBox
                    checked={defaultValues.draftMaker}
                    id={"default_draftMaker"}
                    onChange={() =>
                      updateDefaultValue(
                        "draftMaker",
                        defaultValues,
                        setDefaultValues
                      )
                    }
                    disabled={defaultValues.groupA || defaultValues.groupB}
                  />
                </td>
                <td>
                  <CheckBox
                    checked={defaultValues.dataEntry}
                    id={"default_dataEntry"}
                    onChange={() => {}}
                    disabled={true}
                  />
                </td>
                <td>
                  {isSuperUser && (
                    <div
                      className="is-small action-button"
                      onClick={() =>
                        updateDefaultValues(
                          items,
                          isSuperUser,
                          defaultValues,
                          setItems,
                          setDefaultValues
                        )
                      }
                    >
                      <Edit className={"icon is-small"} />
                      {t("userConfig.updateItems")}
                    </div>
                  )}
                </td>
              </tr>
            )}

            {items.map((item, idx) => {
              let isDataEntryDisabled =
                !isSuperUser ||
                item.eventName === Event.messaging ||
                item.groupA ||
                item.groupB;
              const dataEntryVisible = item.eventName !== Event.messaging;
              const draftMakerVisible =
                item.eventName !== Event.messaging &&
                item.eventName !== Event.preCheck &&
                !(
                  item.product === Product.documentaryCollection &&
                  item.eventName === Event.new
                );
              return (
                <tr key={idx}>
                  <td className={"is-bold"}>
                    {(idx === 0 || items[idx - 1].product !== item.product) &&
                      t("userConfig.product." + item.product)}
                  </td>
                  <td className="has-right-text">
                    <div className="step-state ">
                      {t("userConfig.event." + item.eventName)}
                    </div>
                  </td>
                  <td>
                    <CheckBox
                      disabled={!isSuperUser}
                      checked={item.groupA}
                      id={idx + "_groupA"}
                      onChange={() =>
                        updateItem(
                          idx,
                          "groupA",
                          items,
                          setItems,
                          setItemsChanged,
                          origConfigItems
                        )
                      }
                    />
                  </td>
                  <td>
                    <CheckBox
                      disabled={!isSuperUser}
                      checked={item.groupB}
                      id={idx + "_groupB"}
                      onChange={() =>
                        updateItem(
                          idx,
                          "groupB",
                          items,
                          setItems,
                          setItemsChanged,
                          origConfigItems
                        )
                      }
                    />
                  </td>
                  <td>
                    {draftMakerVisible && (
                      <CheckBox
                        checked={item.draftMaker}
                        id={idx + "_draftMaker"}
                        onChange={() =>
                          updateItem(
                            idx,
                            "draftMaker",
                            items,
                            setItems,
                            setItemsChanged,
                            origConfigItems
                          )
                        }
                        disabled={isDataEntryDisabled}
                      />
                    )}{" "}
                  </td>
                  <td>
                    {dataEntryVisible && (
                      <CheckBox
                        checked={item.dataEntry || !isDataEntryDisabled}
                        id={idx + "_dataEntry"}
                        onChange={() =>
                          updateItem(
                            idx,
                            "dataEntry",
                            items,
                            setItems,
                            setItemsChanged,
                            origConfigItems
                          )
                        }
                        disabled={true}
                      />
                    )}
                  </td>

                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Form
        initialValues={{ ...origUser }}
        validateOnChange={false}
        validate={(values) =>
          validateMandatoryFields(
            values,
            [
              values.notificationSignature,
              values.notificationToDo,
              values.notificationInformation,
            ].includes("smsAndEmail") ||
              [
                values.notificationSignature,
                values.notificationToDo,
                values.notificationInformation,
              ].includes("sms")
              ? [
                  "phoneNumber",
                  "email",
                  "initialDefaultCompany",
                  "countryCode",
                  "phoneNumberContact",
                ]
              : [
                  "phoneNumberContact",
                  "email",
                  "initialDefaultCompany",
                  "name",
                ],
            t
          )
        }
        onSubmit={(values) => save(values)}
      >
        {(form) => (
          <UserConfigProfileComponent
            form={form}
            setUserChanged={setUserChanged}
            origUser={origUser}
            isLoading={isLoading}
            setItems={setItems}
            origConfigItems={origConfigItems}
          />
        )}
      </Form>
    </div>
  );
}

export default GetContent;
