import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EditableStep } from "..";
import { TradFinanceRequestContext } from "../../../context";
import { isDetailStepEditable } from "../../../helper";
import { useStepState } from "../../../hooks";
import { DetailStepProps, StepId, StepState } from "../../../type";

function RequestDetailsStep({ changed, layout }: DetailStepProps) {
  const tfinContext = useContext(TradFinanceRequestContext);
  const { tradeFinance } = tfinContext;
  const { t } = useTranslation();
  const stepId = StepId.requestDetails;
  const isEditable = isDetailStepEditable(tfinContext);

  const state: StepState = useStepState(tradeFinance, layout);

  return (
    <EditableStep
      stepId={stepId}
      title={t("tradeFinance.requestDetails")}
      state={state}
      changed={changed}
      isEditable={isEditable}
      layout={layout}
    />
  );
}

export default RequestDetailsStep;
