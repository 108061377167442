import React from "react";
import { useTranslation } from "react-i18next";
import { HelpDocsTableProps } from "../types";

function HelpDocsTable({ helpDocs, reLoad }: HelpDocsTableProps) {
  const { t } = useTranslation();

  return (
    <div className="table-container">
      <table className="clauses-table table is-fullwidth">
        <thead>
          <tr>
            <th>{t("helpDoc.title")}</th>
            <th>{t("helpDoc.description")}</th>
            <th>{t("helpDoc.comment")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {helpDocs.map((helpDoc) => (
            <tr key={helpDoc.id}>
              <td>{helpDoc.title}</td>
              <td>{helpDoc.description}</td>
              <td>{helpDoc.comment}</td>

              <td className="row-actions" style={{ width: 130 }}>
                <button className="button is-outlined">
                  <a
                    className="document-details"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`api/help-document/${helpDoc.id}`}
                  >
                    {t("download")}
                  </a>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HelpDocsTable;
