import { FormikProps } from "formik";
import { TFunction } from "i18next";
import moment from "moment";
import { today } from "../../../../helper";
import {
  getMissingSignatureCount,
  isPreCheckEvent,
} from "../../../../tradeFinanceUtils";
import * as TFAPi from "../../../../../../../api/tradeFinanceApi";
import { TradeFinance, UnderWritersInfo } from "../../../../../../../types";

export function beforeSign(
  setSignError: (hasSignError: boolean) => void,
  setShowFinalDocument: (showFinalDocument: boolean) => void,
  form: FormikProps<TFAPi.TradeFinanceDetail>,
  tradeFinance: TradeFinance,
  t: TFunction
) {
  if (
    form.values.dateOfExpiry &&
    moment(form.values.dateOfExpiry).isBefore(
      moment(moment(today).local().format("YYYY-MM-DD")).toDate()
    ) &&
    !isPreCheckEvent(tradeFinance)
  ) {
    form.setFieldError("dateOfExpiry", t("tradeFinance.dateError"));
    setSignError(true);
  } else {
    setSignError(false);
    setShowFinalDocument(true);
  }
}

export function getSignatureHint(
  t: TFunction,
  underWritersInfo: UnderWritersInfo | undefined
) {
  if (underWritersInfo && !underWritersInfo.completed) {
    if (!underWritersInfo.hasRightToSign) {
      return t("tradeFinance.claim.noSignPermission");
    }
    const otherSignatures = getMissingSignatureCount(underWritersInfo);
    return t("tradeFinance.claim.otherSignatureInfo", {
      amount: otherSignatures,
    });
  }
}
