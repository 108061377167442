import { Formik } from "formik";
import React from "react";
import { FormProps } from "../types";

export function Form<T>(props: FormProps<T>) {
  return (
    <Formik
      {...props}
      validateOnChange={
        props.validateOnChange !== undefined ? props.validateOnChange : false
      }
    />
  );
}
