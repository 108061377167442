import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useCodeBaseAsSelectOptions } from "../../../hooks";
import { Report } from "../types";
import * as API from "../../../api/tradeFinanceApi";
import { ReportPage } from "..";

function ReportTemplateDetailsPage() {
  const { templateId } = useParams<{ templateId: string }>();
  const isNew = templateId === "new";
  const loadReportTemplateQuery = useQuery(
    ["reportTemplateById", templateId],
    async (_) => {
      return API.getReportTemplate(templateId as string);
    },
    { enabled: !isNew && !!templateId }
  );

  const lengthOfProductOptions = useCodeBaseAsSelectOptions(
    "tradefin/timeCharacter"
  );

  if (loadReportTemplateQuery.isLoading) {
    return null;
  }

  if (!lengthOfProductOptions || lengthOfProductOptions?.length === 0) {
    return null;
  }

  const newReport = {
    createdOnPortal: true,
    states: ["issued"],
    lengthOfProduct: lengthOfProductOptions!.map((opt) => opt.value),
  } as Report;
  const report = isNew ? newReport : (loadReportTemplateQuery.data as Report);
  return <ReportPage report={report} isEditable={report.createdOnPortal} />;
}

export default ReportTemplateDetailsPage;
