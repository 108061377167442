// @ts-nocheck
import * as React from "react";
import { Context, useEffect, useRef, useState } from "react";
import { Router } from "react-router";
import { Route, Switch } from "react-router-dom";
import classnames from "classnames";
import { createHashHistory, UnregisterCallback } from "history";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";

import * as PortalApi from "./api/portalApi";
import * as API from "./api/tradeFinanceApi";
import { UseActionResult, useLoadingAction } from "./hooks";
import { isIEBrowser } from "./pages/tradeFinanceHooks";
import { Language, PortalConfig, Stats, User, UserStatus } from "./types";
import {
  BeneficiariesPage,
  BeneficiaryDetailsPage,
  ClauseDetailsPage,
  ClausesListPage,
  CreateNewTradeFinanceRequestPage,
  DashboardPage,
  HelpPage,
  LoginPage,
  NotificationListPage,
  ProfilePage,
  ReportDetailsPage,
  ReportsPage,
  ReportTemplateDetailsPage,
  SignatureListPage,
  TemplateListPage,
  TradeFinanceListPage,
  TradeFinanceRequestDetailsPage,
  TradeFinanceRequestDetailsPageParent,
  UnderwritersConfigPage,
  UserConfigurationPage,
  WholeCalendarPage,
} from "./pages";
import { AppFooter, AppHeader, Notification } from "./components/";

import { AppContextType, AppProps, AppState } from "./AppTypes";
import { defaultLang, ScrollToTopComp, strToLang } from "./AppHelper";
import { NotificationValue } from "./components/Notification/assets/types";
import { NotificationContext } from "./components/Notification/assets/context";

import "./i18n";
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";
import Teams from "./components/Teams/Teams";

require("unorm");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      suspense: false,
    },
  },
});

const history = createHashHistory();
PortalApi.setHistory(history);

export const AppContext: Context<AppContextType> =
  React.createContext<AppContextType>({} as AppContextType);

function App(props: AppProps) {
  const { t, i18n } = useTranslation();
  const locationListener = useRef<UnregisterCallback | null>(null);
  const appHeaderRef = useRef<any>(null);
  const [notification, setNotification] = useState<NotificationValue>(null);
  const [portalConfig, setPortalConfig] = useState<PortalConfig>();
  const [state, setState] = useState<AppState>({
    lang: strToLang(i18n.language),
    isLoggedIn: false,
    userStatus: null,
    loading: true,
  });
  const isIE = isIEBrowser();

  let loadStatsAction: UseActionResult<null | Stats> = useLoadingAction(() => {
    if (!state.isLoggedIn) {
      return null;
    }
    return API.getStats();
  }, [state.isLoggedIn]);

  useEffect(() => {
    API.portalConfig().then(setPortalConfig);

    updateLoginState();

    return () => {
      if (locationListener.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        locationListener.current();
      }
    };
  }, []);

  useEffect(() => {
    async function doLanguageChange() {
      await i18n.changeLanguage(state.lang);
    }

    if (state.lang !== i18n.language) {
      doLanguageChange();
    }
  }, [state.lang]);

  function renderPublicRoutes(userStatus: UserStatus | null) {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/login"
            component={() => <LoginPage login={handleLogin} />}
          />
        </Switch>
      </React.Fragment>
    );
  }

  function onLanguageChange(language: Language) {
    setState({ ...state, lang: language });
  }

  function renderPrivateRoutes(userStatus: UserStatus | null) {
    if (state.loading) {
      return (
        <div
          className={
            "button is-white is-loading is-large is-flex app-indicator top-margin"
          }
        ></div>
      );
    }
    return (
      <div className="portal">
        <Switch>
          <Route
            path="/portal/profile"
            component={() => <ProfilePage user={userStatus!.user} />}
          />

          <Route path="/portal/beneficiaries" component={BeneficiariesPage} />
          <Route
            path="/portal/underwriters"
            component={UnderwritersConfigPage}
          />
          <Route
            path="/portal/beneficiary/:id"
            component={BeneficiaryDetailsPage}
          />

          {/*Trade finanace*/}

          <Route
            path="/portal/trade-finance-list/:type"
            component={TradeFinanceListPage}
          />
          <Route
            path="/portal/trade-finance-request-create/:requestCreateMethod"
            component={CreateNewTradeFinanceRequestPage}
          />
          <Route
            path="/portal/trade-finance-request/:id"
            component={TradeFinanceRequestDetailsPageParent}
          />
          <Route path="/portal/signatures" component={SignatureListPage} />
          <Route path="/portal/templates" component={TemplateListPage} />
          <Route
            path="/portal/template/:id"
            component={TradeFinanceRequestDetailsPage}
          />
          <Route path="/portal/clause/:id" component={ClauseDetailsPage} />
          <Route path="/portal/clause" component={ClausesListPage} />
          <Route
            path="/portal/userConfiguration"
            component={UserConfigurationPage}
          />

          <Route
            path="/portal/notifications/:notificationType"
            component={NotificationListPage}
          />
          <Route path="/portal/reports" component={ReportsPage} exact={true} />
          <Route
            path="/portal/report-template/:templateId"
            component={ReportTemplateDetailsPage}
          />
          <Route
            path="/portal/report/:reportId"
            component={ReportDetailsPage}
          />
          <Route path="/portal/help" component={HelpPage} />
          <Route path="/portal/calendar" component={WholeCalendarPage} />
          <Route path="/" component={DashboardPage} />
        </Switch>
      </div>
    );
  }

  async function updateLoginState() {
    try {
      setState({ ...state, loading: true });
      const userStatus = await PortalApi.status();
      setState({
        ...state,
        loading: false,
        userStatus,
        isLoggedIn: !!userStatus,
        lang: userStatus?.user?.lang || defaultLang,
      });
    } catch (e) {
      setState({
        ...state,
        loading: false,
        userStatus: null,
        isLoggedIn: false,
        lang: defaultLang,
      });
    }
  }

  async function logout() {
    if (!portalConfig?.caas) {
      try {
        await PortalApi.logout();
        setState({
          ...state,
          isLoggedIn: false,
          userStatus: null,
          lang: defaultLang,
        });
        history.push("/login");
      } catch (e) {
        console.warn("Logout failed:", e);
      }
    } else {
      const redirectResponse = await PortalApi.logout();

      if (redirectResponse?.redirectUrl) {
        window.location.href = redirectResponse?.redirectUrl;
      }
    }
  }

  async function handleLogin(email: string, password: string) {
    try {
      const user: User = await PortalApi.login(email, password);
      const userStatus = await PortalApi.status();
      if (!user) {
        throw new Error(t("login.invalidLogin"));
      }

      setState({
        ...state,
        lang: user.lang || defaultLang,
        userStatus: userStatus,
        isLoggedIn: true,
      });
      history.push("/portal");
    } catch (e) {
      throw new Error(t("login.invalidLogin"));
    }
  }

  function updateAvatar() {
    if (appHeaderRef.current) {
      //appHeaderRef.current.forceUpdate();
    }
  }

  async function changeCompany(companyId: number) {
    await PortalApi.changeCompany(companyId);
    await updateLoginState();
    history.push("/");
    loadStatsAction.run();
  }

  if (!portalConfig || (!state.userStatus && state.loading)) {
    return null;
  }

  const menuProps = {
    isLoggedIn: state.isLoggedIn,
    userStatus: state.userStatus,
    onLogout: logout,
    onLanguageChange: onLanguageChange,
  };

  if (!state.isLoggedIn && portalConfig?.caas) {
    return null;
  }

  if (
    loadStatsAction.isLoading ||
    (state.isLoggedIn && !loadStatsAction.result)
  ) {
    return null;
  }

  const devMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          portalConfig,
          userStatus: state.userStatus,
          user: state.userStatus ? state.userStatus.user : null,
          lang: state.lang,
          stats: loadStatsAction.result,
          refreshStats: loadStatsAction.run,
          changeCompany,
          updateUserState: updateLoginState,
          updateAvatar: updateAvatar,
          setTransparentHeader: (isTransparent) => {
            if (appHeaderRef.current) {
              appHeaderRef.current.setTransparent(isTransparent);
            }
          },
        }}
      >
        <Router history={history}>
          <ScrollToTopComp />
          <React.Fragment>
            <div className={classnames({ "is-ie": isIE })}>
              <div className="root" id="outer-container">
                <div id="page-wrap">
                  <NotificationContext.Provider
                    value={{ notification, setNotification }}
                  >
                    <AppHeader ref={appHeaderRef} {...menuProps} />
                    <Notification />
                    <div className="app-content">
                      {state.isLoggedIn
                        ? renderPrivateRoutes(state.userStatus)
                        : renderPublicRoutes(state.userStatus)}
                    </div>
                  </NotificationContext.Provider>
                </div>
              </div>
              <AppFooter />
            </div>
          </React.Fragment>
        </Router>

        <Teams/>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
