import classNames from "classnames";
import React, {
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import useOnClickOutside from "use-onclickoutside";
import { CloseIcon, Edit, SaveIcon } from "../../../../icons/Icons";
import { EditableTextProps } from "../../types";

function EditableText_(
  {
    value,
    onChange,
    className,
    inputClass,
    placeholder,
    editable,
    disabled,
  }: EditableTextProps,
  editableTextRef: Ref<any>
) {
  const [isEdited, setEdited] = useState(editable);
  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState(value || "");
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(editableTextRef, () => ({
    edit: () => {
      setEdited(true);
    },
    select: () => {
      setTimeout(() => {
        inputRef.current && inputRef.current.select(); //kis hack. amikor meghívódik a fv, akkor még nincs mindig input
      }, 50);
    },
  }));

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  useEffect(() => {
    if (isEdited && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdited]);

  function edit() {
    if (!isEdited) {
      setEdited(true);
    }
  }

  function close() {
    setInputValue(value || "");
    setEdited(false);
  }

  function save() {
    if (isEdited) {
      onChange(inputValue);
      close();
    }
  }

  function handleKeys(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      close();
    } else if (e.keyCode === 13) {
      save();
    }
  }

  useOnClickOutside(ref, save);

  return (
    <div
      ref={ref}
      className={classNames("editable-text", className, {
        "is-edited": isEdited,
      })}
    >
      {isEdited ? (
        <>
          <input
            type="text"
            className={classNames("input ", inputClass, {
              "is-danger": !inputValue,
            })}
            autoFocus={true}
            ref={inputRef}
            placeholder={placeholder}
            value={inputValue}
            onFocus={(e) => e.target.select()}
            onKeyDown={handleKeys}
            onChange={(e) => setInputValue(e.target.value)}
            disabled={disabled}
          />
          <div className="editable-text-buttons">
            <button className="button is-small edit-icon" onClick={save}>
              <SaveIcon />
            </button>
            <button className="button is-small edit-icon" onClick={close}>
              <CloseIcon />
            </button>
          </div>
          <span className="underline">{inputValue}</span>
        </>
      ) : (
        <span>{value}</span>
      )}
      {!isEdited && !disabled && (
        <button className="button is-text edit-button" onClick={edit}>
          <Edit />
        </button>
      )}
    </div>
  );
}

export const EditableText = React.forwardRef(EditableText_);
