import classnames from "classnames";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../../../../../../App";
import {
  FileExtensionIcon,
  ConfirmButton,
} from "../../../../../../../../../../components";
import {
  Delete,
  DownloadIcon,
} from "../../../../../../../../../../icons/Icons";
import {
  DeviceType,
  useDeviceType,
} from "../../../../../../../../../tradeFinanceHooks";
import { TradFinanceRequestContext } from "../../../../../../../context";
import {
  formatDate,
  //isTradeFinanceEditable,
} from "../../../../../../../tradeFinanceUtils";
import { DocumentListItemComponentProps } from "../types";

function DocumentListItemComponent(props: DocumentListItemComponentProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const { tradeFinance } = useContext(TradFinanceRequestContext);
  const [fileName] = useState<string | null>(props.document.fileName || null);

  const { document, onDelete } = props;
  const {
    description,
    id,
    date,
    fileTypeUniv,
    createUser,
    lastModifiedUser,
    lastModifiedUserUniv,
    uploadedByClient,
    createUserEmail,
    createUserPhone,
    //mandatory,
    //generatedFromTemplate,
  } = document;
  const isBankUser = !uploadedByClient && createUser !== "SYSTEM";

  //const tradeFinCtx = useContext(TradFinanceRequestContext);
  //const tradeFinanceEditable = isTradeFinanceEditable(tradeFinCtx);

  const lastUser =
    lastModifiedUser != null
      ? lastModifiedUserUniv[lang] + ": " + lastModifiedUser
      : null;
  const device = useDeviceType();
  const isMobile = device !== DeviceType.desktop;

  return (
    <tr className={classnames({ "document-highlighter": isBankUser })}>
      <td className={""}>
        <div>
          <div className="filename-and-user">
            <FileExtensionIcon fileName={fileName || ""} />
            <div
              className="multi-line"
              style={{
                maxWidth: isMobile ? window.innerWidth - 142 : "initial",
              }}
            >
              <span className="is-bold" style={{ overflowWrap: "break-word" }}>
                &nbsp;
                {fileName && fileName.includes("\\")
                  ? fileName.substr(fileName.lastIndexOf("\\") + 1)
                  : fileName}
              </span>
              {!isBankUser ? (
                <>
                  <div className="create-user">{createUser}</div>
                  <div className="create-user">{lastUser}</div>
                </>
              ) : (
                <>
                  <div className="create-user">{"Komerční banka, a.s. "}</div>
                  <div className={"create-user-name"}>{createUser}</div>
                  <div className={"create-user-details"}>{createUserEmail}</div>
                  <div className={"create-user-details"}>{createUserPhone}</div>
                  <div className={"create-user-name"}>{lastUser}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className={""}>
        <div className="multi-line">
          <div className={"is-bold"}>{description || ""}</div>
          <div>{fileTypeUniv ? fileTypeUniv[lang] : ""}</div>
        </div>
      </td>
      <td className={""}>
        <div>{formatDate(date)}</div>
      </td>
      <td className={"actions-column"}>
        <div className="card-buttons-container">
          <div className="buttons horizontal-buttons">
            <a
              className="card-button"
              href={`api/trade-finance/${tradeFinance.id}/document/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`}
            >
              <DownloadIcon style={{ width: 26, height: 32 }} />
              <div className="button-label">{t("download")}</div>
            </a>

            {onDelete && !document.dmsId && document.portalOnly && (
              <ConfirmButton
                confirmation={t("areYouSureToDelete")}
                button={
                  <div
                    className={"card-button"}
                    onClick={() => onDelete(document)}
                  >
                    <Delete />
                    <div className="button-label">{t("document.remove")}</div>
                  </div>
                }
              />
            )}
            {/*{tradeFinanceEditable && <WebDAVLink tradeFinanceId={tradeFinance.id} document={document} userId={user!.id}/>}*/}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default DocumentListItemComponent;
