import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Language } from "./types";

export const defaultLang = "cz";

export function ScrollToTopComp() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function strToLang(lang: string): Language {
  switch (lang) {
    case "en":
      return "en";
    case "cz":
      return "cz";
  }
  return defaultLang;
}
