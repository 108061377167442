import * as i18next from "i18next";
import { Language, Univ } from "./types";

const equal = require("deep-equal");

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function range(size: number, offset = 0): number[] {
  const res = [];
  for (let i = 0; i < size; i++) {
    res.push(i + offset);
  }
  return res;
}

export function joinStrings(...args: string[]): string {
  return args.filter((e) => e).join(" ");
}

export function formatNumber(num: number): string {
  if (!num && num !== 0) {
    return "";
  }
  return num.toLocaleString(["hu", "en"], {
    style: "decimal",
  } as Intl.NumberFormatOptions);
}

export function errorToString(
  error: any,
  t: i18next.TFunction,
  lang: Language
): string | undefined {
  if (error.univ || error.response?.data?.univ) {
    const univ: Univ = error.univ || error.response?.data?.univ;
    const args = error.args || error.response?.data?.args;
    let message = univ[lang];

    if (args && args.length) {
      return message?.replace(/%s/, args[0]); // only 1 arg works
    }
    return message;
  } else if (error.error) {
    return t(error.error);
  } else if (typeof error === "string") {
    return error;
  } else if (error.message) {
    return error.message;
  }

  return error;
}

export function deepEqual(a: any, b: any): boolean {
  return equal(a, b);
}

export function partition(input: any[], spacing: number) {
  const output = [];

  for (let i = 0; i < input.length; i += spacing) {
    output[output.length] = input.slice(i, i + spacing);
  }

  return output;
}
