import { UseActionResult } from "../../../hooks";
import {
  DocumentRequirement,
  Partner,
  Template,
  TFDocument,
  TradeFinance,
  TradeFinanceResponse,
  Notification,
  Comment,
  UnderWritersInfo,
} from "../../../types";
import { EventHistory } from "../../../components/EventHistoryTab/assets/type";
import { FormikErrors, FormikProps } from "formik";
import { TradeFinanceDetail } from "../../../api/tradeFinanceApi";
import { ContainerDesc, FieldMap } from "../../../components/Form/types";
import { ReactNode } from "react";

export enum StepId {
  reference = "reference",
  clientDetails = "clientDetail",
  requestDetails = "requestDetails",
  documents = "documents",
  documentRequirements = "documentRequirements",
  sign = "sign",
  claim = "claim",
  installments = "installments",
  preCheck = "preCheck",
}

export type NameListContainerType = {
  isAGroup?: boolean;
  hasGroup?: boolean;
  info: UnderWritersInfo;
};

export enum TabId {
  request,
  notifications,
  chat,
  history,
}

export interface TradeFinanceRequestContextType extends TradeFinanceResponse {
  uploadDocuments: () => Promise<boolean>;
  setDocsToUpload: (docs: TFDocument[]) => void;
  docsToUpload: TFDocument[];
  setTradeFinance: (tradeFinance: TradeFinance) => void;
  loadDocumentsAction: UseActionResult<TFDocument[]>;
  loadCommentsAction: UseActionResult<Comment[] | undefined>;
  loadPartnersAction: UseActionResult<Partner[]>;
  loadNotificationsAction: UseActionResult<Notification[]>;
  loadDocRequirementTemplates: UseActionResult<Template[] | undefined>;
  reLoad: () => Promise<void>;
  loadDocumentRequirementsAction: UseActionResult<DocumentRequirement[]>;
  loadEventHistoryAction: UseActionResult<EventHistory[]>;
  loadMessageHistoryAction: UseActionResult<EventHistory[]>;
  form: FormikProps<TradeFinanceDetail>;
  save: (
    stepId:
      | StepId.clientDetails
      | StepId.requestDetails
      | StepId.preCheck
      | StepId.reference
  ) => void;
  validate: (
    tfDetails: TradeFinanceDetail,
    validateMandatoryFields?: boolean
  ) => Promise<FormikErrors<TradeFinanceDetail>>;
  origTradeFinance: TradeFinanceDetail;
  formFields: FieldMap<TradeFinanceDetail>;
  setFormFields: (fields: FieldMap<TradeFinanceDetail>) => void;
  isUnsavedTerm: boolean;
  setUnsavedTerm: (isUnsavedTerm: boolean) => void;
  hasUnsavedModification: boolean;
  setUnsavedModification: (hasUnsavedModification: boolean) => void;
  createNewPartnerOption: boolean;
  setNewPartnerOption: (createNewPartnerOption: boolean) => void;
  hasMaturityError: boolean;
  setMaturityError: (hasMaturityError: boolean) => void;
  hasSignError: boolean;
  setSignError: (hasSignError: boolean) => void;
}

export interface StepProps {
  stepId: StepId;
  title: ReactNode;
  state?: StepState | string | null;
  children: ReactNode;
  stateClass?: string;
  stepClass?: string;
  headerEnd?: ReactNode;
}

export interface EditableStepProps extends Omit<StepProps, "children"> {
  summaryComp?: () => ReactNode;
  changed: boolean;
  layout: ContainerDesc;
  className?: string;
  isEditable: boolean;
  enhanceFieldProps?: (
    fieldProps: FieldMap<TradeFinanceDetail>,
    form: FormikProps<TradeFinanceDetail>
  ) => FieldMap<TradeFinanceDetail>;
  buttons?: any;
}

export type DetailStepProps = {
  changed: boolean;
  layout: ContainerDesc;
};

export type StepState = "inProgress" | "finished";

export type NameListProps = {
  names: string[];
  specialNames: string[];
};

export type SignStepProps = {
  showFinalDocument: boolean;
  setShowFinalDocument: (showFinalDocument: boolean) => void;
};

export type TradeFinanceDetailsChanges = {
  requestDetailsChanged: boolean;
  partnerDetailsChanged: boolean;
  referenceNoChanged: boolean;
  documentRequirementChanged: boolean;
  eventChanged: boolean;
};

export type FloatingSaveType = {
  isLoading: boolean;
  save: (
    stepId:
      | StepId.requestDetails
      | StepId.clientDetails
      | StepId.preCheck
      | StepId.reference
  ) => void;
  underWritersInfo: UnderWritersInfo | undefined;
  tradeFinance: TradeFinance | null;
  hasUnsavedModification: boolean;
};
