import React from "react";
import { NotificationComponent } from "./assets";
import { TabProps } from "./assets/types";

export function NotificationsTab({ tradeFinance, notifications }: TabProps) {
  return (
    <div className={"page notification-page"}>
      <div className="container">
        {notifications.map((notification) => (
          <NotificationComponent
            key={notification.id}
            notification={notification}
            tradeFinanceId={tradeFinance.id}
          />
        ))}
      </div>
    </div>
  );
}
