import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import { FilePDF } from "../../../../icons/Icons";
import * as API from "../../../../api/tradeFinanceApi";
import { formatDate } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TransactionDetails } from "../../../../types";

function ServicesComp({
  serviceEvents,
  tradeFinanceId,
  eventId,
}: TransactionDetails & { tradeFinanceId: string; eventId?: number }) {
  const { t } = useTranslation();
  const { userStatus } = useContext(AppContext);
  const documentCoverURL = API.getDocumentCoverDownloadLink(
    tradeFinanceId,
    userStatus!.serviceTemplate,
    eventId
  );

  if ((serviceEvents || []).length === 0) {
    return null;
  }

  return (
    <div className={"services services-table"}>
      <div className="table--header">
        <div className="table--title">
          {t("transactionDetails.services.title")}
        </div>
      </div>
      <div className="table-container">
        <table className="table portal-table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>{t("transactionDetails.services.date")}</th>
              <th>{t("transactionDetails.issuedDocument")}</th>
            </tr>
          </thead>
          <tbody>
            {serviceEvents.map((event, idx) => {
              return (
                <tr key={idx}>
                  <td>{formatDate(event.orderDate)}</td>
                  <td>
                    <a
                      href={API.getDocumentCoverDownloadLink(
                        tradeFinanceId,
                        userStatus!.serviceTemplate,
                        event.id
                      )}
                      target={"__blank"}
                      className={"action"}
                    >
                      <FilePDF /> <span>{t("download")}</span>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ServicesComp;
