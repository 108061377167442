import moment from "moment";
import { number } from "prop-types";
import { useEffect } from "react";
import { Language } from "../../../types";
import { FormaterHelper, StateCreateHandler } from "./helper";
import { DrawingsProps, FeesProps, FinancialDataType } from "./interfaces";

export enum COLOR {
  BLUE,
  RED,
  YELLOW,
}

export const feesDataHook = (
  props: Array<FeesProps> | undefined,
  lang: Language
) => {
  return (props || []).map((item) => {
    const eventItem = item.eventName && item.eventName[lang];
    return {
      description: item.description,
      event: eventItem,
      eventNumber: item.eventNumber,
      accountNumber: item.accountNo,
      amount: item.amount,
      currency: item.currency,
      dueDate: item.dueDate,
      details: item.details,
      id: item.id,
      stringDueDate: moment(item.dueDate).format("DD.MM.YYYY"),
      accountingTransaction: item.accountingTransaction,
      stringAmount: FormaterHelper(item.amount).split(".").join(","),
    };
  });
};

export const drawingsDataHook = (
  props: Array<DrawingsProps> | undefined,
  tradeFinanceType: string
) => {
  return (props || []).map((item) => {
    const valueDateItem = (item.financialData || [])
      .sort(
        (a: FinancialDataType, b: FinancialDataType) =>
          (a.valueDate as number) - (b.valueDate as number)
      )
      .filter((item) => item.valueDate)
      .map((item) => item.valueDate);
    const accountNoItem = (item.financialData || [])
      .sort(
        (a: FinancialDataType, b: FinancialDataType) =>
          (a.valueDate as number) - (b.valueDate as number)
      )
      .filter((item) => item.accountNo)
      .map((item) => item.accountNo)
      .join("\n");
    const financailDataDetails = (item.financialData || [])
      .sort(
        (a: FinancialDataType, b: FinancialDataType) =>
          (a.valueDate as number) - (b.valueDate as number)
      )
      .filter((item) => item.details)
      .map((item) => item.details);
    return {
      drawingNumber: item.numberOfDrawing,
      drawingAmount: item.claimAmount,
      currency: item.currency,
      state: StateCreateHandler(
        item.state,
        tradeFinanceType,
        item.financialData
      ),
      date: item.orderDate,
      valueDate: valueDateItem,
      stringVauleDate:
        valueDateItem === undefined
          ? ""
          : (item.financialData || [])
              .filter((item) => item.valueDate)
              .map((item) => moment(item.valueDate).format("DD.MM.YYYY")),
      stringDate: moment(item.orderDate).format("DD.MM.YYYY"),
      invoiceNumber: item.invoiceNumber,
      accountNumber: accountNoItem,
      tradefinanceId: item.tradeFinanceId,
      eventId: item.eventId,
      id: item.tradeFinanceId,
      details: financailDataDetails,
      financialData: item.financialData,
      stringDrawingAmount: FormaterHelper(item.claimAmount)
        .split(".")
        .join(","),
      action: item.action,
    };
  });
};

export const useClickOutsideHandler = (
  ref: any,
  fn: (args: boolean) => void,
  close: boolean = false
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn(close);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [close, fn, ref]);
};
