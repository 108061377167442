import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../../../../../components";
import { ModalType } from "../types";

const Modal = ({
  showModal,
  setShowModal,
  templateName,
  setTemplateName,
  saveDisabled,
  isLoading,
  creatTemplate,
}: ModalType) => {
  const { t } = useTranslation();

  return (
    <div className={classnames("modal", { "is-active": showModal })}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{t("tradeFinance.template")}</p>
        </header>
        <section className="modal-card-body">
          <div className={"columns"}>
            <div className={"column is-6"}>
              <TextField
                label={t("template.name")}
                required={true}
                id={"template-name"}
                value={templateName}
                setValue={() => {}}
                setError={() => {}}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <div className={"modal-buttons"}>
            <button
              className={"button is-danger is-outlined is-small is-bold"}
              onClick={() => setShowModal(false)}
            >
              {t("cancel")}
            </button>
            <button
              className={classnames("button is-outlined is-small is-bold", {
                "is-static": saveDisabled,
                "is-success": !saveDisabled,
                "is-loading": isLoading,
              })}
              onClick={creatTemplate}
              disabled={saveDisabled}
            >
              {t("save")}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
