import React, { useContext } from "react";
import { BooleanField, DecorateField } from "..";
import { AppContext } from "../../../App";
import { CheckBoxSelectionFieldProps } from "../types";

function CheckBoxSelectionField(props: CheckBoxSelectionFieldProps) {
  const { lang } = useContext(AppContext);
  const { id, options, value, disabled } = props;

  function checkBoxValueChanged(
    optionValue: string | number,
    checkBoxValue: boolean
  ) {
    const newValue = (value || []).filter((val) => val !== optionValue);
    if (checkBoxValue) {
      newValue.push(optionValue);
    }
    props.setValue(newValue);
  }

  return DecorateField(
    props,
    <div className="checkbox-selection-field">
      {options.map((option) => (
        <BooleanField
          key={id + "_" + option.value}
          id={id + "_" + option.value}
          disabled={disabled}
          label={option.label}
          value={value?.includes(option.value)}
          setValue={() => {}}
          setError={() => {}}
          onChange={(e) => checkBoxValueChanged(option.value, e.target.checked)}
        />
      ))}
    </div>,
    lang
  );
}
export default CheckBoxSelectionField;
