import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CompaniesPopup } from "..";
import { AppContext } from "../../../../App";
import { useLoadingAction } from "../../../../hooks";
import { Company, Phone, UserIcon } from "../../../../icons/Icons";
import * as PortalAPI from "../../../../api/portalApi";
import { ContactsPopup, HeaderDropdown } from "../../../../components";
import { CompanyOption } from "../types";

function ProfileComponent() {
  const { t } = useTranslation();
  const appCtx = useContext(AppContext);
  const history = useHistory();
  const user = appCtx.userStatus!.user;
  const loadCompaniesAction = useLoadingAction(() =>
    PortalAPI.getUserCompanies()
  );

  const companyOptions: CompanyOption[] = useMemo(() => {
    if (loadCompaniesAction.result) {
      return loadCompaniesAction.result.map((option) => {
        return {
          label: option.label as string,
          value: option.value as number,
        };
      });
    }
    return [];
  }, [loadCompaniesAction.result]);

  return (
    <div className="profile">
      <div className="profile-data">
        <div
          className="profile-avatar header-button"
          title={t("dashboard.editMyProfile")}
          onClick={() => history.push("/portal/profile")}
        >
          {user.profileImage ? (
            <img className={"icon"} src={user.profileImage} alt="icon" />
          ) : (
            <UserIcon className={"icon"} />
          )}{" "}
          {user.name}
        </div>
        {user.defaultCompany && companyOptions.length > 0 && (
          <>
            <HeaderDropdown
              label={user.defaultCompany.companyName}
              icon={<Company className={"icon"} />}
              popupContent={<CompaniesPopup companyOptions={companyOptions} />}
              cssId={"company"}
              showDropdownIcon={companyOptions.length > 1}
              buttonClass={"company-selector"}
            />
          </>
        )}

        <HeaderDropdown
          label={t("header.contacts")}
          icon={<Phone className={"phone-icon icon"} />}
          popupContent={<ContactsPopup />}
          showDropdownIcon={true}
          buttonClass={"contacts-dropdown"}
        />
      </div>
    </div>
  );
}

export default ProfileComponent;
