import * as React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";

import * as API from "../../../api/tradeFinanceApi";
import { useTranslation } from "react-i18next";
import { Plus } from "../../../icons/Icons";
import { UnderwriterListPageProps } from "../types";
import { ClausesFilterComponent, ClausesTable } from "./assets";
import { EmptyList, PageHeader, RoundedButton } from "../../../components";

export default function ClausesListPage(props: UnderwriterListPageProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | null | undefined>();
  let loadingAction = useQuery(["clauses", { search }], async (_) =>
    API.findClauses(search, true)
  );

  const clauses = loadingAction.data || [];

  let pageButton = (
    <NavLink to="/portal/clause/new">
      <RoundedButton label={t("tradeFinance.newClause")} icon={<Plus />} />
    </NavLink>
  );

  return (
    <div className="page clauses-page card-list-page ongoing-applications-page">
      <PageHeader title={t("clause.clauses")} headerContent={pageButton} />

      <ClausesFilterComponent search={search || ""} onChange={setSearch} />

      <div className={"container box top-margin"}>
        {!loadingAction.isFetching && clauses.length === 0 && (
          <EmptyList message={t("clause.emptyList")} />
        )}
        {clauses.length > 0 && (
          <ClausesTable
            clauses={clauses}
            reLoad={() => loadingAction.refetch()}
          />
        )}
      </div>
    </div>
  );
}
