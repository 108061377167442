// @ts-nocheck
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { AppContext } from "../../../../App";
import { Edit } from "../../../../icons/Icons";
import { UpdateNewValueFn } from "../types";

function NewValueSetter({
  updateNewValue,
}: {
  updateNewValue: UpdateNewValueFn;
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const [newGroupA, setNewGroupA] = useState<number | null>();
  const [newGroupB, setNewGroupB] = useState<number | null>();

  function update() {
    updateNewValue({ groupA: newGroupA || 0, groupB: newGroupB || 0 });
    setNewGroupA(null);
    setNewGroupB(null);
  }

  return (
    <tr className={"new-value-setter"}>
      <td className={"category-name"}>{t("underwriter.newValues")}</td>
      <td></td>
      <td></td>

      <td align={"right"}>
        <div className={"number-spinner is-flex"}>
          <NumberFormat
            displayType={user?.superUser ? "input" : "text"}
            className={classNames({ "input is-small": user?.superUser })}
            decimalSeparator={","}
            decimalScale={0}
            allowNegative={false}
            value={newGroupA || ""}
            onValueChange={(values) => setNewGroupA(values.floatValue)}
          />

          <div className={"spinner-button-container"}>
            <div
              className={"spinner-button is-plus"}
              onClick={() => setNewGroupA((newGroupA || 0) + 1)}
            >
              {"+"}
            </div>
            <div
              className={"spinner-button is-minus"}
              onClick={() => setNewGroupA((newGroupA || 1) - 1)}
            >
              {"-"}
            </div>
          </div>
        </div>
      </td>

      <td align={"right"}>
        <div className={"number-spinner is-flex"}>
          <NumberFormat
            displayType={user?.superUser ? "input" : "text"}
            className={classNames({ "input is-small": user?.superUser })}
            decimalSeparator={","}
            decimalScale={0}
            allowNegative={false}
            value={newGroupB || ""}
            onValueChange={(values) => setNewGroupB(values.floatValue)}
          />

          <div className={"spinner-button-container"}>
            <div
              className={"spinner-button is-plus"}
              onClick={() => setNewGroupB((newGroupB || 0) + 1)}
            >
              {"+"}
            </div>
            <div
              className={"spinner-button is-minus"}
              onClick={() => setNewGroupB((newGroupB || 1) - 1)}
            >
              {"-"}
            </div>
          </div>
        </div>
      </td>

      <td>
        {user?.superUser && (
          <div className="is-small action-button" onClick={update}>
            <Edit className={"icon is-small"} />
            {t("underwriter.updateItems")}
          </div>
        )}
      </td>
    </tr>
  );
}

export default NewValueSetter;
