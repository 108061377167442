import React, { useCallback, useContext } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MAX_FILE_SIZE, MultiFileUploadProps } from "../types";
import * as TFApi from "../../../../api/tradeFinanceApi";
import { DescriptionField, FileExtensionIcon } from "../..";
import { TradFinanceRequestContext } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/context";
import { TFDocument } from "../../../../types";
import { RoundedButton } from "../../..";
import { getSortedFileExtensions } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { Delete } from "../../../../icons/Icons";

function MultiFileUpload({
  comment,
  editable,
  descriptionRequired,
  updateDocuments,
  tradeFinanceId,
  error,
  setError,
}: MultiFileUploadProps) {
  const { t } = useTranslation();
  const { tradeFinance } = useContext(TradFinanceRequestContext);

  const onDrop: (acceptedFiles: File[]) => void = useCallback(
    (acceptedFiles) => {
      setError(null);
      if (updateDocuments) {
        updateDocuments([
          ...(comment.documents || []),
          ...acceptedFiles.map(
            (file: File) => ({ file, fileName: file.name } as any as TFDocument)
          ),
        ]);
      }
    },
    [comment.documents]
  );

  const onDropRejected = (
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    setError(
      t("attachmentSizeLimit") +
        ": " +
        fileRejections.map((f) => f.file.name).join(",")
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: MAX_FILE_SIZE,
  });

  function updateDocument(idx: number, doc: TFDocument) {
    if (updateDocuments) {
      updateDocuments(
        (comment.documents || []).map((origDoc, i) =>
          i === idx ? doc : origDoc
        )
      );
    }
  }

  async function deleteDocument(doc: TFDocument, idx: number) {
    if (doc.id) {
      await TFApi.deleteCommentDocument(tradeFinanceId, comment, doc);
    }

    if (updateDocuments) {
      updateDocuments(
        (comment.documents || []).filter((origDoc, i) => i !== idx)
      );
    }
  }

  return (
    <div className="comment-documents-component">
      {updateDocuments && editable && (
        <div className="upload-component" {...getRootProps()}>
          <RoundedButton
            label={t("tradeFinance.messaging.selectFile")}
            inverse={true}
          />
          <input {...getInputProps()} />
          <div className="dnd-message is-bold">
            {t("tradeFinance.messaging.dndMessage")}
          </div>
          <div className="limits">
            {t("tradeFinance.messaging.limits", {
              formats: getSortedFileExtensions(),
            })}
          </div>
          {error && <div className="top-margin error-message">{error}</div>}
        </div>
      )}
      <div className="comment-documents">
        {comment.documents?.map((doc, idx) => (
          <div key={idx} className="comment-document">
            <div className="comment-document--header">
              <div className="document-file-name is-bold">
                <FileExtensionIcon fileName={doc.fileName} />
                <span>{doc.fileName}</span>
              </div>
              <div>
                {editable && (
                  <RoundedButton
                    label={t("tradeFinance.messaging.removeDocument")}
                    onClick={() => deleteDocument(doc, idx)}
                    icon={<Delete />}
                    transparent={true}
                  />
                )}
              </div>
            </div>

            <div className="comment-document--description">
              <DescriptionField
                tradeFinance={tradeFinance}
                editable={editable && !doc.id}
                document={doc}
                required={descriptionRequired}
                updateDocument={(doc) => updateDocument(idx, doc)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MultiFileUpload;
