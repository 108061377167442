import * as TFAPi from "../../api/tradeFinanceApi";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { QUERY_KEY } from "./assets/helper";
import { UserConfigurationContext } from "./assets/hooks";
import { UserConfigurationRow } from "./assets";
import { PageHeader } from "../../components";

export default function UserConfigurationPage() {
  const { t } = useTranslation();

  let loadUserConfigurations = useQuery(QUERY_KEY, () =>
    TFAPi.getUserConfigurations()
  );
  const [editedUserConfigId, setEditedUserConfigId] = useState<number | null>(
    null
  );

  const userConfigurations = loadUserConfigurations.data;

  if (!userConfigurations) {
    return null;
  }

  async function reload() {
    await loadUserConfigurations.refetch();
  }

  return (
    <UserConfigurationContext.Provider
      value={{ editedUserConfigId, setEditedUserConfigId, reload }}
    >
      <div className="page user-config-page">
        <PageHeader title={t("userConfig.guide")} />

        <div className="container box">
          {userConfigurations.map((userConfig) => (
            <UserConfigurationRow
              key={userConfig.id}
              open={() => setEditedUserConfigId(userConfig.id)}
              userConfig={userConfig}
              isExpanded={editedUserConfigId === userConfig.id}
            />
          ))}
        </div>
      </div>
    </UserConfigurationContext.Provider>
  );
}
