import { TradeFinanceRequestListType } from "../../TradeFinanceListPage/assets/types";

export function getTradeFinanceFilterType(
  tradeFinanceType: string
): TradeFinanceRequestListType {
  let listType: TradeFinanceRequestListType;

  switch (tradeFinanceType) {
    case "tradefin/receivedGuarantee":
    case "tradefin/IssuedGuarantee":
      listType = "guarantees";
      break;
    case "tradefin/importLC":
      listType = "letterOfCredit";
      break;
    case "tradefin/exportCollection":
      listType = "collection";
      break;
    case "tradefin/outgoingStandbyBG":
    case "tradefin/outgoingStandbyLC":
    case "tradefin/incomingStandbyBG":
    case "tradefin/incomingStandbyLC":
      listType = "standby";
      break;
    default:
      listType = "dashboard";
  }

  return listType;
}
