import React, { useState } from "react";
import { RoundedButton } from "../../../components";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import * as API from "../../../api/tradeFinanceApi";
import { Plus } from "../../../icons/Icons";
import { GeneratedReportList, ReportTemplateList } from "../assets";

export default function ReportsPage() {
  const [listType, setListType] = useState<"generated" | "templates">(
    "templates"
  );
  const { t } = useTranslation();

  const reportStatsQuery = useQuery(["reportStats", {}], async () => {
    return API.getReportStats();
  });

  if (reportStatsQuery.isLoading) {
    return null;
  }

  const updateStats = reportStatsQuery.refetch;

  return (
    <div className="page reports-page">
      <div className="container">
        <div className="page-header">
          <div className="page-title">{t("report.reports")}</div>
          <div className={"portal-buttons"}>
            <NavLink to="/portal/report-template/new">
              <RoundedButton label={t("report.newReport")} icon={<Plus />} />
            </NavLink>

            <RoundedButton
              label={
                t("report.templates") +
                " (" +
                reportStatsQuery.data?.templateCount +
                ")"
              }
              onClick={() => setListType("templates")}
              buttonClass={classNames({ selected: listType === "templates" })}
            />
            <RoundedButton
              label={
                t("report.generatedReports") +
                " (" +
                reportStatsQuery.data?.reportCount +
                ")"
              }
              onClick={() => setListType("generated")}
              buttonClass={classNames({ selected: listType === "generated" })}
            />
          </div>
        </div>

        {listType === "generated" && (
          <GeneratedReportList updateStats={updateStats} />
        )}
        {listType === "templates" && (
          <ReportTemplateList updateStats={updateStats} />
        )}
      </div>
    </div>
  );
}
