import moment from "moment";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../../../../App";
import {
  CodeBaseField,
  DateField,
  NumberField,
  TextAreaField,
  RoundedButton,
} from "../../../../../../../../components";
import { Form } from "../../../../../../../../components";
import {
  getFieldProps,
  validateMandatoryFields,
} from "../../../../../../../../components/Form/helper";
import { Maturity } from "../../../../../../../../types";
import { TradFinanceRequestContext } from "../../../../../context";
import { getCurrencyDecimals } from "../../../../../tradeFinanceUtils";
import { MaturityFormProps } from "../types";

function MaturityForm({
  maturity,
  setEditedMaturity,
  submitMaturity,
  maturities,
}: MaturityFormProps) {
  const { t } = useTranslation();
  const { tradeFinance } = useContext(TradFinanceRequestContext);
  const { userStatus } = useContext(AppContext);

  const currencyScale = useMemo(
    () => getCurrencyDecimals(userStatus, tradeFinance.currency),
    [userStatus, tradeFinance.currency]
  );

  return (
    <Form
      initialValues={maturity}
      validate={(values) => {
        const mandatoryFields = ["paymentMethod", "amount"];

        const errors = validateMandatoryFields<Maturity>(
          values,
          mandatoryFields as any,
          t
        );

        if (values.paymentMethod !== "atSight") {
          if (!values.dueDateText && !values.dueDate) {
            errors.dueDate = t(
              "tradeFinance.installment.dueDateOrDueDateTextMandatory"
            );
          }

          if (values.dueDateText && values.dueDate) {
            errors.dueDate = t("tradeFinance.installment.dueDateOrText");
          }
        }

        if (
          !errors.dueDate &&
          values.dueDate &&
          moment(values.dueDate).isBefore(moment())
        ) {
          errors.dueDate = t("tradeFinance.installment.dueDate_future");
        }

        return errors;
      }}
      onSubmit={submitMaturity}
    >
      {(form) => (
        <>
          <div className="columns">
            <div className="column">
              <div className={"control"}>
                <NumberField
                  {...getFieldProps(
                    form,
                    "amount",
                    t("tradeFinance.installment.amount")
                  )}
                  decimals={currencyScale}
                />
                <CodeBaseField
                  codeBaseId="tradefin/TF_MaturityPaymentMethod"
                  {...getFieldProps(
                    form,
                    "paymentMethod",
                    t("tradeFinance.installment.paymentMethod")
                  )}
                />
                {form.values.paymentMethod !== "atSight" && (
                  <DateField
                    {...getFieldProps(
                      form,
                      "dueDate",
                      t("tradeFinance.installment.dueDate")
                    )}
                  />
                )}
                {form.values.paymentMethod !== "atSight" && (
                  <TextAreaField
                    {...getFieldProps(
                      form,
                      "dueDateText",
                      t("tradeFinance.installment.dueDateText")
                    )}
                    maxLength={4000}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="form-buttons buttons">
            <RoundedButton
              label={t("cancel")}
              disabled={form.isSubmitting}
              loading={form.isSubmitting}
              inverse={true}
              onClick={() => setEditedMaturity(null)}
              buttonClass={"is-cancel"}
            />
            <RoundedButton
              label={maturity.id ? t("modify") : t("save")}
              disabled={form.isSubmitting}
              loading={form.isSubmitting}
              onClick={form.handleSubmit}
              buttonClass={"is-save"}
            />
          </div>
        </>
      )}
    </Form>
  );
}

export default MaturityForm;
