import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as API from "../../api/tradeFinanceApi";
import { useQuery } from "react-query";
import { HelpDocFilterComponent, HelpDocsTable } from "./assets";
import { PageHeader } from "../../components";

export function HelpPage() {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | null>(null);
  let helpDocumentsQuery = useQuery(["helpDocuments", { search }], async (_) =>
    API.getHelpDocuments(search)
  );

  return (
    <>
      <PageHeader
        title={t("helpDoc.pageTitle")}
        headerContent={
          <HelpDocFilterComponent onChange={setSearch} search={search} />
        }
      />

      <div className="container box top-margin">
        {!helpDocumentsQuery.isFetching && (
          <HelpDocsTable
            helpDocs={helpDocumentsQuery.data || []}
            reLoad={() => helpDocumentsQuery.refetch({})}
          />
        )}
      </div>
    </>
  );
}
