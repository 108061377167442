// @ts-nocheck
import React, { useContext, useMemo } from "react";
import NumberFormat from "react-number-format";
import { AppContext } from "../../App";
import { getCurrencyDecimals } from "../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { CurrencyNumberFormatProps } from "./assets/types";

function CurrencyNumberFormat(props: CurrencyNumberFormatProps) {
  const { userStatus } = useContext(AppContext);
  const { value, currency, displayType } = props;
  const currencyScale = useMemo(
    () => getCurrencyDecimals(userStatus, currency),
    [userStatus, currency]
  );

  return (
    <NumberFormat
      thousandSeparator={" "}
      decimalSeparator={","}
      decimalScale={currencyScale}
      fixedDecimalScale={true}
      value={value}
      displayType={displayType}
    />
  );
}

export default CurrencyNumberFormat;
