import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../App";
import { NotificationsCardProps } from "../types";
import { Notification } from "../../../../types";
import { isIEBrowser } from "../../../tradeFinanceHooks";
import { NotificationComp } from "..";
import { PortalCard } from "../../../../components";

function NotificationsCard({
  title,
  subtitle,
  notifications,
  actionNeeded,
  icon,
}: NotificationsCardProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { lang } = useContext(AppContext);

  let cardSize = lang === "en" ? 100 : 125;
  let notificationsHeight =
    notifications.length > 0 ? notifications.length * cardSize + "px" : "340px";

  return (
    <PortalCard
      icon={icon}
      title={title}
      subtitle={subtitle}
      style={{ height: isIEBrowser() ? notificationsHeight : "auto" }}
    >
      <div className="notification-list">
        {notifications.map((notif: Notification) => (
          <NotificationComp
            key={notif.id}
            actionNeeded={actionNeeded}
            notification={notif}
          />
        ))}
      </div>

      {notifications.length > 0 && (
        <div className="portal-card-buttons">
          <button
            className={"button show-all-button"}
            onClick={() =>
              history.push(
                `/portal/notifications/${actionNeeded ? "todo" : "information"}`
              )
            }
          >
            {t("tradeFinance.showAll")}
          </button>
        </div>
      )}
    </PortalCard>
  );
}

export default NotificationsCard;
