// @ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
import { Univ } from "./types";
import { useTranslation } from "react-i18next";
import * as API from "./api/portalApi";
import { errorToString } from "./utils";
import { AppContext } from "./App";
import { NotificationContext } from "./components/Notification/assets/context";
import { CodeBaseOption } from "./components/Fields/types";

type ActionFn<T> = () => Promise<T> | T;

type ActionState<T> = {
  result: T | undefined;
  isLoading: boolean;
  error: string | Univ | Error | null | undefined | { error: string };
};

export function useMounted() {
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}

export interface UseActionResult<T = any> extends ActionState<T> {
  run: () => Promise<void>;
  setResult: (result: T) => void;
}
export function useAction<T>(actionFn: ActionFn<T>): UseActionResult<T> {
  const initialState = {
    error: undefined,
    result: undefined,
    isLoading: false,
  };
  const { setNotification } = useContext(NotificationContext);
  const { lang } = useContext(AppContext);
  const [state, setState] = useState<ActionState<T>>(initialState);
  const { t } = useTranslation();
  const mounted = useMounted();

  function setResult(result: T) {
    setState({ ...initialState, result });
  }

  const runAction = async () => {
    if (mounted.current) {
      try {
        setState((state) => ({ ...state, isLoading: true, error: null }));
        const result = await actionFn();
        if (mounted.current) {
          setState({ error: undefined, isLoading: false, result });
        }
      } catch (e) {
        if (mounted.current) {
          if (e.response?.status >= 400 && e.response?.status < 500) {
            let message = "serverError";
            if (e.response.data && e.response.data.error) {
              message = e.response.data.error;
            }

            setState({
              error: t(message),
              isLoading: false,
              result: undefined,
            });
          } else {
            let errorString = errorToString(e, t, lang);
            setState({
              error: errorString || t("serverError"),
              isLoading: false,
              result: undefined,
            });
            setNotification(errorString);
          }
        }
      }
    }
  };

  return {
    ...state,
    run: runAction,
    setResult: setResult,
  };
}

export function useLoadingAction<T>(
  loadFn: ActionFn<T>,
  deps?: any[]
): UseActionResult<T> {
  let loadAction = useAction(loadFn);

  useEffect(() => {
    loadAction.run();
  }, deps || []);

  return loadAction;
}

function serialize(o: any) {
  if (o == null || o === undefined) {
    return o;
  }

  return `[${JSON.stringify(o)}]`;
}

function deSerialize<T>(
  value: string | null | undefined
): T | null | undefined {
  if (value == null || value === undefined) {
    return value;
  }
  return JSON.parse(value)[0];
}

export function useLocalStorage<T>(key: string) {
  const [value, setValue] = useState(() =>
    deSerialize(window.localStorage.getItem(key))
  );
  const saveValue = (newVal: any) => {
    if (newVal == null || newVal === undefined) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, serialize(newVal));
    }
    setValue(newVal);
  };
  return [value, saveValue];
}

export function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef<HTMLElement>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

export function usePrevious<T = any>(value: any): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useCodeBaseAsSelectOptions(
  codeBaseId: string
): CodeBaseOption[] | null {
  const mountedRef = useMounted();
  const [options, setOptions] = useState<CodeBaseOption[] | null>(null);

  useEffect(() => {
    if (codeBaseId) {
      API.getCodeElements(codeBaseId).then((codeElements) => {
        if (mountedRef.current) {
          setOptions(
            (codeElements || [])
              .filter((item) => item.active)
              .map(
                (ce) =>
                  ({
                    label: ce.univ,
                    value: ce.id,
                    tags: ce.tags,
                  } as CodeBaseOption)
              )
          );
        }
      });
    }
  }, [codeBaseId]);

  return options;
}
