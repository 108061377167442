import classnames from "classnames";
import React from "react";
import { CheckedIcon, UncheckedIcon } from "../../../../../../icons/Icons";
import { NameListProps } from "../../../type";

function NameList({ names, specialNames }: NameListProps) {
  return (
    <div className="columns is-multiline">
      {(names || []).map((name) => {
        let special = (specialNames || []).includes(name);

        return (
          <div
            className={classnames("name column is-3 is-flex", {
              "is-special": special,
            })}
            key={name}
          >
            {special ? <CheckedIcon /> : <UncheckedIcon />}
            &nbsp;{name}
          </div>
        );
      })}
    </div>
  );
}

export default NameList;
