import React, { useMemo } from "react";
import {
  BooleanField,
  CodeBaseField,
  DateField,
  DocumentField,
  LinkField,
  MultiSelectField,
  NumberField,
  PercentageField,
  SelectField2,
  SwiftField,
  TextAreaField,
  TextAreaFieldWithClause,
  TextField,
  TextFieldWithClause,
} from "..";
import SwiftCodeField from "../SwiftCodeField/SwiftCodeField";
import {
  CodeBaseFieldProps,
  DocumentFieldProps,
  FieldProps,
  FieldType,
  LinkFieldProps,
  NumberFieldProps,
  SelectFieldProps,
  SwiftCodeFieldProps,
  SwiftFieldProps,
  TextAreaProps,
  TextAreaWithClausesProps,
  TextFieldProps,
  TextWithClausesProps,
} from "../types";

function Field(props: FieldProps) {
  function renderField() {
    const fieldProps = props;

    if (fieldProps.hidden) {
      return null;
    }
    switch (props.type) {
      case FieldType.text:
        return <TextField {...(fieldProps as TextFieldProps)} />;
      case FieldType.textArea:
        return <TextAreaField {...(fieldProps as TextAreaProps)} />;
      case FieldType.decimal:
      case FieldType.integer:
        return <NumberField {...(fieldProps as NumberFieldProps)} />;
      case FieldType.select:
        if ((fieldProps as SelectFieldProps).multiSelect) {
          return <MultiSelectField {...(fieldProps as SelectFieldProps)} />;
        } else {
          return <SelectField2 {...(fieldProps as SelectFieldProps)} />;
        }
      case FieldType.document:
        return <DocumentField {...(fieldProps as DocumentFieldProps)} />;
      case FieldType.date:
        return <DateField {...(fieldProps as FieldProps)} />;
      case FieldType.percentage:
        return <PercentageField {...(fieldProps as FieldProps)} />;
      case FieldType.link:
        return <LinkField {...(fieldProps as LinkFieldProps)} />;
      case FieldType.boolean:
        return <BooleanField {...(fieldProps as LinkFieldProps)} />;
      case FieldType.textAreWithClauses:
        return (
          <TextAreaFieldWithClause
            {...(fieldProps as TextAreaWithClausesProps)}
          />
        );
      case FieldType.codebase:
        return <CodeBaseField {...(fieldProps as CodeBaseFieldProps)} />;
      case FieldType.swift:
        return <SwiftField {...(fieldProps as SwiftFieldProps)} />;
      case FieldType.swiftCode:
        return <SwiftCodeField {...(fieldProps as SwiftCodeFieldProps)} />;
      case FieldType.textWithClauses:
        return (
          <TextFieldWithClause {...(fieldProps as TextWithClausesProps)} />
        );
      default:
        return <div>Field: {fieldProps.id}</div>;
    }
  }

  return useMemo(renderField, [JSON.stringify(props)]);
  //return renderField();
}

export default Field;
