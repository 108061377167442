import React, { ReactElement } from "react";
import { TradeFinanceHighlighter } from "..";
import { TradeFinanceCardProps } from "../../../../../../../types";

function LetterOfCreditCard({
  tradeFinance,
  templateMode,
  copyFrom,
}: TradeFinanceCardProps): ReactElement {
  return (
    <div className="list-card">
      <div className="details">
        <TradeFinanceHighlighter
          tradeFinance={tradeFinance}
          template={templateMode}
          copyFrom={copyFrom}
        />
      </div>
    </div>
  );
}

export default LetterOfCreditCard;
