import React from "react";
import { AdaptiveComponent } from "../../../../../../components";
import { TradeFinanceCardProps } from "../../../types";
import { DesktopCardComponent } from "./assets";

const AdaptiveCardComponent = React.memo((props: TradeFinanceCardProps) => {
  const { tradeFinance } = props;
  return (
    <AdaptiveComponent
      key={tradeFinance.identifier || tradeFinance.id}
      {...props}
      desktop={DesktopCardComponent}
    />
  );
});

export default AdaptiveCardComponent;
