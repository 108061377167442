import React from "react";
import { useTranslation } from "react-i18next";
import { NameList } from "../../..";
import { NameListContainerType } from "../../../../../type";
import { getSubtitleClasses } from "../helper";

const NameListContainer = ({
  isAGroup,
  hasGroup,
  info,
}: NameListContainerType) => {
  const { t } = useTranslation();
  const {
    underWritersInGroupA,
    underWritersInGroupB,
    numberOfGroupA,
    numberOfGroupB,
    membersOfGroupA,
    membersOfGroupB,

    underWritersInDraftMakers,
    membersOfDraftMakers,
  } = info;
  return hasGroup ? (
    <>
      <div className={getSubtitleClasses(!!isAGroup, info)}>
        {isAGroup ? t("underwriter.groupA") : t("underwriter.groupB")}:{" "}
        {isAGroup ? underWritersInGroupA.length : underWritersInGroupB.length}/
        {isAGroup ? numberOfGroupA : numberOfGroupB}
      </div>
      <NameList
        names={isAGroup ? membersOfGroupA : membersOfGroupB}
        specialNames={isAGroup ? underWritersInGroupA : underWritersInGroupB}
      />
    </>
  ) : (
    <>
      <div className={"subtitle"}>
        {t("underwriter.draftMakers")}: {underWritersInDraftMakers.length}
        /1
      </div>
      <NameList
        names={membersOfDraftMakers}
        specialNames={underWritersInDraftMakers}
      />
    </>
  );
};

export default NameListContainer;
