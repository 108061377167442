import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { TradeFinanceSearchParams, TradeFinanceType } from "../../types";
import { useTranslation } from "react-i18next";
import { useField } from "../Form/hooks";
import { useHistory, useParams } from "react-router-dom";
import { CloseIcon, SearchIcon } from "../../icons/Icons";
import { TradeFinanceFilterComponentProps } from "./assets/types";
import { AdvancedFilterComponent } from "./assets";
import { TradeFinanceRequestListType } from "../../pages/TradeFinanceListPage/assets/types";
import { DeviceType, useDeviceType } from "../../pages/tradeFinanceHooks";
import { getTradeFinanceFilterType } from "../../pages/CreateNewTradeFinanceRequestPage/assets/helper";
import { tfSearchParamsToUrlSearchParams } from "../../pages/TradeFinanceListPage/assets/helper";
import { HeaderDropdown } from "..";
import { MobileHeaderContext } from "../MobileHeader/assets/context";

function TradeFinanceFilterComponent(props: TradeFinanceFilterComponentProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { placeholder, searchParams } = props;
  const { searchOpened, setSearchOpened } = useContext(MobileHeaderContext);

  const { type } = useParams<{ type: TradeFinanceRequestListType }>();
  const typeSearchParam = new URLSearchParams(history.location.search).get(
    "type"
  );
  const advancedFilter = useField({
    id: "advancedFilter",
    label: t("tradeFinance.filters.advanced"),
    value: false,
  });
  const device = useDeviceType();

  const listType = getTradeFinanceFilterType(type ?? typeSearchParam);
  const [searchValue, setSearchValue] = useState(searchParams?.search ?? "");

  const filterPopup = (
    <div className={"advanced-filter"}>
      <AdvancedFilterComponent
        onSearch={advancedSearch}
        listType={listType}
        searchParams={searchParams || {}}
      />
    </div>
  );

  useEffect(() => {
    setSearchValue(searchParams?.search ?? "");
    advancedFilter.setValue(
      searchParams &&
        Object.entries(searchParams).filter(
          ([k, v]) =>
            !["search", "chipsFilter", "sortOption", "events"].includes(k) &&
            !!v
        ).length > 0
    );
  }, [searchParams]);

  function onChange(params: TradeFinanceSearchParams | null) {
    let currentPath = history.location.pathname;
    let urlSearchParams = new URLSearchParams(history.location.search);

    let isDashboard = currentPath === "/" || currentPath.endsWith("/dashboard");
    let isOnListPage =
      currentPath.includes("/concept") || currentPath.endsWith("/ongoing");
    let isOnProductPage =
      currentPath.includes("/guarantees") ||
      currentPath.includes("/letterOfCredit") ||
      currentPath.includes("/collection") ||
      currentPath.includes("/standby");
    let chipsFilterParam = urlSearchParams.get("chipsFilter");
    let sortOptionParam = urlSearchParams.get("sortOption");

    if (params) {
      params.sortOption = sortOptionParam || undefined;
      params.chipsFilter = chipsFilterParam || undefined;
      params.type = typeSearchParam
        ? (typeSearchParam as TradeFinanceType)
        : null;
    } else {
      params = {
        sortOption: sortOptionParam || undefined,
        chipsFilter: chipsFilterParam || undefined,
        type: typeSearchParam ? (typeSearchParam as TradeFinanceType) : null,
      };
    }

    let newPath =
      isDashboard || (!isOnListPage && !isOnProductPage)
        ? params != null
          ? "/portal/trade-finance-list/dashboard"
          : "/"
        : currentPath;
    history.push(
      newPath +
        `?${params ? tfSearchParamsToUrlSearchParams(params).toString() : ""}`
    );
  }

  function simpleSearch() {
    onChange({ search: searchValue });
  }

  function advancedSearch(searchParams: TradeFinanceSearchParams | null) {
    onChange(searchParams);
  }

  function clear() {
    setSearchValue("");
    onChange(null);
  }

  return (
    <div className="trade-finance-filter-component">
      {(device === DeviceType.desktop || searchOpened) && (
        <div className="simple-filter">
          <div
            className={classnames(
              "search-box control has-icons-right",
              props.className
            )}
          >
            <input
              type="text"
              className={classnames("input", { "has-value": searchValue })}
              value={searchValue}
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  simpleSearch();
                }
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <span className="icon search-icon" onClick={simpleSearch}>
              <SearchIcon />
            </span>
            {searchValue && (
              <span className="icon clear-icon" onClick={clear}>
                <CloseIcon />
              </span>
            )}
            {device === DeviceType.desktop && (
              <HeaderDropdown
                label={t("tradeFinance.filters.advanced")}
                popupContent={filterPopup}
                stayOnContentClick={true}
              />
            )}
          </div>
        </div>
      )}

      {device !== DeviceType.desktop && (
        <>
          {!searchOpened ? (
            <SearchIcon onClick={(e) => setSearchOpened(true)} />
          ) : (
            <CloseIcon
              onClick={(e) => setSearchOpened(false)}
              className={"close-mobile-search"}
            />
          )}
        </>
      )}
    </div>
  );
}
export default TradeFinanceFilterComponent;
