import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import * as TFApi from "../../../../api/tradeFinanceApi";
import { PAGE_SIZE } from "../../helper";
import { ReportListProps, ReportTemplate } from "../../types";
import * as API from "../../../../api/tradeFinanceApi";
import { formatDate } from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { FieldsColumn, PagingComponent, ProductsColumn } from "..";
import { NavLink } from "react-router-dom";
import { RoundedButton } from "../../../../components";

function GeneratedReportList(props: ReportListProps) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const loadGeneratedReports = useQuery(
    ["generatedReports", page],
    () => API.getGeneratedReports(PAGE_SIZE * page, PAGE_SIZE),
    { keepPreviousData: true }
  );

  const deleteReport = useMutation(
    async (report: ReportTemplate) => {
      await TFApi.deleteReport(report.id);
    },
    {
      onSuccess: (data) => {
        loadGeneratedReports.refetch();
        props.updateStats();
      },
    }
  );
  if (loadGeneratedReports.isFetching) {
    return null;
  }

  const totalPages = Math.ceil(
    (loadGeneratedReports.data?.total ?? 0) / PAGE_SIZE
  );

  return (
    <div className="report-templates container box">
      <div className="table-container">
        <table className="table portal-table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>
                <div>{t("report.templateName")}</div>
                <div>{t("report.dateOfGeneration")}</div>
                <div>{t("report.format")}</div>
              </th>
              <th>{t("report.products")}</th>
              <th>{t("report.fields")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {loadGeneratedReports.data?.result.map((report, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div>
                      <strong>{report.name}</strong>
                    </div>
                    <div>{formatDate(report.dateOfGeneration)}</div>
                    <div>
                      {report.format ? report.format.join(",") : report.format}
                    </div>
                  </td>
                  <td>
                    {<ProductsColumn products={report.selectedProducts} />}
                  </td>
                  <td>{<FieldsColumn fields={report.columns} />}</td>
                  <td>
                    <div className="actions is-flex">
                      <NavLink to={`/portal/report/${report.id}`}>
                        <RoundedButton label={t("report.view")} />
                      </NavLink>

                      <a
                        href={`/api/report/download/${report.id}`}
                        download={
                          report.tfDocuments.length === 1 &&
                          (report.tfDocuments[0].fileName.includes(".html")
                            ? report.tfDocuments[0].fileName
                            : "")
                        }
                      >
                        <RoundedButton label={t("report.download")} />
                      </a>

                      <RoundedButton
                        label={t("report.delete")}
                        onClick={() => deleteReport.mutate(report)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <PagingComponent page={page} setPage={setPage} totalPages={totalPages} />
    </div>
  );
}

export default GeneratedReportList;
