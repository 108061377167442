import classNames from "classnames";
import moment from "moment";
import React, { useContext } from "react";
import { CalendarEventsPopup } from "..";
import { AdaptivePopup } from "../../..";
import { AppContext } from "../../../../App";
import { range } from "../../../../utils";
import { DeviceType, useDeviceType } from "../../../../pages/tradeFinanceHooks";
import { filteredCalendarEvents, getDayStyles } from "../helper";
import { MonthViewProps } from "../types";

function MonthView({
  year,
  month,
  calendarEvents,
  selectedDay,
  onDaySelected,
}: MonthViewProps) {
  const { lang } = useContext(AppContext);
  const firstDayInMonth = moment([year, month, 1]);
  const lastDayInMonth = moment([year, month, firstDayInMonth.daysInMonth()]);
  const prevMonthDays = moment
    .duration(firstDayInMonth.diff(firstDayInMonth.clone().isoWeekday(1)))
    .asDays();
  const nextMonthDays = moment
    .duration(lastDayInMonth.clone().isoWeekday(7).diff(lastDayInMonth.clone()))
    .asDays();
  const device = useDeviceType();

  const dayNames = [1, 2, 3, 4, 5, 6, 0].map((i) =>
    moment().isoWeekday(i).locale(lang).format("dd")
  );

  return (
    <div className="calendar-month-view">
      <div className="calendar-month-view--header">
        {firstDayInMonth.locale(lang).format("MMMM")}
      </div>
      <div className="day-names">
        {dayNames.map((dayName) => (
          <div key={dayName}>{dayName}</div>
        ))}
      </div>
      <div className="calendar-month-view--days">
        {range(prevMonthDays, 1).map((prevMonthDay) => {
          const prevDay = firstDayInMonth.clone().isoWeekday(prevMonthDay);
          return (
            <div
              key={"prev-" + prevMonthDay}
              className={classNames("day", {
                "is-holiday":
                  prevDay.isoWeekday() === 6 || prevDay.isoWeekday() === 7,
              })}
            ></div>
          );
        })}
        {range(firstDayInMonth.daysInMonth(), 1).map((idx) => {
          const day = moment([year, month, idx]);
          const calendarEventsForDay = filteredCalendarEvents(
            calendarEvents,
            day
          );
          return (
            <div
              key={"day-" + day}
              onClick={() => onDaySelected(day)}
              className={classNames("day", getDayStyles(calendarEvents, day))}
            >
              {idx}
              {selectedDay && calendarEventsForDay && day.isSame(selectedDay) && (
                <AdaptivePopup
                  active={true}
                  deactivate={() => onDaySelected(null)}
                >
                  <CalendarEventsPopup
                    calendarEvents={calendarEventsForDay}
                    autoPos={device === DeviceType.desktop}
                    close={() => onDaySelected(null)}
                  />
                </AdaptivePopup>
              )}
            </div>
          );
        })}
        {range(nextMonthDays)
          .reverse()
          .map((nextMonthDay) => {
            const nextDay = lastDayInMonth.clone().isoWeekday(7 - nextMonthDay);
            return (
              <div
                key={"next-" + nextMonthDay}
                className={classNames("day", {
                  "is-holiday":
                    nextDay.isoWeekday() === 6 || nextDay.isoWeekday() === 7,
                })}
              ></div>
            );
          })}
      </div>
    </div>
  );
}

export default MonthView;
