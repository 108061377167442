// @ts-nocheck
import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { NavLink, useHistory } from "react-router-dom";
import { AppContext } from "../../../../App";
import { Eye, Signatures } from "../../../../icons/Icons";
import { DeviceType, useDeviceType } from "../../../tradeFinanceHooks";
import { formatDate } from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TabId } from "../../../TradeFinanceRequestDetailsPage/assets/type";
import { NewType, SignaturesTableProps } from "../types";

function SignaturesTable({ pages, reLoad }: SignaturesTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { lang } = useContext(AppContext);
  let columnSize = window.innerWidth / 3 - 8;
  const device = useDeviceType();
  const isMobile = device !== DeviceType.desktop;

  function navigateToTradeFinance(sig: NewType) {
    let isMessage =
      sig.transactionEventType &&
      sig.transactionEventType[lang] === t("tradeFinance.messaging.message");
    const tradeFinanceLink =
      `/portal/trade-finance-request/${sig.tradeFinanceId}` +
      (sig.eventId ? "?event=" + sig.eventId : "") +
      (isMessage ? "#" + TabId.chat : "");
    history.push(tradeFinanceLink);
  }

  return (
    <div className="table-container">
      <table className="table portal-table signatures-table is-striped is-fullwidth top-margin">
        <thead>
          <tr>
            <th>{t("signature.transactionMainType")}</th>
            <th>{t("signature.transactionEventType")}</th>
            <th>{t("signature.referenceNumber")}</th>
            <th>{t("signature.beneficiaryName")}</th>
            <th>{t("signature.amount")}</th>
            <th align={"right"}>{t("signature.date")}</th>
            <th>{t("signature.numberOfGroupA")}</th>
            <th>{t("signature.numberOfGroupB")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(pages || []).map((signatures, idx) => {
            return signatures.map((signature) => (
              <tr key={signature.id}>
                <td style={{ minWidth: isMobile ? columnSize : "initial" }}>
                  {signature.transactionMainType &&
                    signature.transactionMainType[lang]}
                </td>
                <td style={{ minWidth: isMobile ? columnSize : "initial" }}>
                  {signature.transactionEventType &&
                    signature.transactionEventType[lang]}
                </td>
                <td style={{ minWidth: isMobile ? columnSize : "initial" }}>
                  <NavLink
                    to={
                      `/portal/trade-finance-request/${signature.tradeFinanceId}` +
                      (signature.eventId ? `?event=${signature.eventId}` : "")
                    }
                  >
                    {signature.referenceNumber}
                  </NavLink>
                </td>
                <td>{signature.beneficiaryName}</td>
                <td>
                  <NumberFormat
                    value={signature.amount}
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    displayType="text"
                    suffix={" " + signature.currency}
                  />
                </td>
                <td align={"right"}>{formatDate(signature.date)}</td>
                <td>{signature.numberOfGroupA || 0}</td>
                <td>{signature.numberOfGroupB || 0}</td>
                <td className="row-actions">
                  <div
                    className={classnames("action-button", {
                      "has-green-hover": signature.canSign,
                    })}
                    onClick={() => navigateToTradeFinance(signature)}
                  >
                    {signature.canSign ? <Signatures /> : <Eye />}
                    {t(signature.canSign ? "sign" : "view")}
                  </div>
                </td>
              </tr>
            ));
          })}
        </tbody>
      </table>
    </div>
  );
}

export default SignaturesTable;
