import React from "react";
import { useTranslation } from "react-i18next";
import { ContactsPopup, HeaderDropdown } from "../../..";
import { Phone } from "../../../../icons/Icons";

function ContactsComponent() {
  const { t } = useTranslation();
  return (
    <div className="is-flex footer-contacts">
      {t("contactUs")}&nbsp;
      <HeaderDropdown
        label={t("header.contacts")}
        icon={<Phone className={"phone-icon icon is-small"} />}
        popupContent={<ContactsPopup />}
        buttonClass={"contacts-dropdown"}
        isInFooter={true}
      />
    </div>
  );
}

export default ContactsComponent;
