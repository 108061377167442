import React from "react";
import { FilePDF, FileXLS, SaveDraft } from "../../icons/Icons";

function FileExtensionIcon({ fileName }: { fileName: string }) {
  if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
    return <FileXLS className={"icon"} />;
  } else if (fileName.endsWith(".pdf")) {
    return <FilePDF className={"icon"} />;
  } else {
    return <SaveDraft className={"icon"} />;
  }
}

export default FileExtensionIcon;
