import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NotificationCompProps } from "../types";
import {
  businessEntityDescriptorRefName,
  formatDate,
} from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { CurrencyNumberFormat } from "../../../../components";

function NotificationComp({
  notification,
  actionNeeded,
}: NotificationCompProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { amount, currency, tradeFinanceDescriptorId, relatedEventId } =
    notification;
  const tradeFinanceName = t(
    "report.transactionTypes." +
      businessEntityDescriptorRefName(tradeFinanceDescriptorId)
  );

  return (
    <div
      className="notification-list--item"
      onClick={() => {
        const hasEventNavigation = ![
          "tradefin/importCollection",
          "tradefin/exportLC",
          "tradefin/receivedGuarantee",
          "tradefin/incomingStandbyBG",
          "tradefin/incomingStandbyLC",
        ].includes(tradeFinanceDescriptorId);
        const linkParams =
          hasEventNavigation && relatedEventId
            ? "?event=" + relatedEventId
            : "";
        const tradeFinanceLink =
          `/portal/trade-finance-request/${notification.tradeFinanceId}` +
          linkParams;
        history.push(tradeFinanceLink);
      }}
    >
      <div className={"signature-list--item-row"}>
        <div>
          <div>{t("signature.referenceNumberOrName")}</div>
          <div className="identifier">
            {notification.tradeFinanceIdentifier}
          </div>
          <div className="beneficiary">{notification.otherPartnerName}</div>
        </div>

        <div style={{ textAlign: "right" }}>
          <div>
            <span className={"amount"}>
              {amount ? (
                <CurrencyNumberFormat
                  displayType={"text"}
                  value={amount}
                  currency={currency}
                />
              ) : (
                "-"
              )}
            </span>
            <span className={"currency"}> {currency}</span>
          </div>
          <div className="date">{formatDate(notification.createDate)}</div>
          <div className={"type"}>{tradeFinanceName}</div>
        </div>
      </div>

      <div className={"divider"} />
      <div className={"signature-list--item-row"}>
        <div className={"subject"}> {notification.subject}</div>
      </div>
    </div>
  );
}

export default NotificationComp;
