import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TradeFinanceCardButtons, TradeFinanceTags } from "..";
import { AppContext } from "../../../../../../../../../../App";
import { CurrencyNumberFormat } from "../../../../../../../../../../components";
import { ChipsFilter, Guarantee } from "../../../../../../../../../../types";
import { useCodeElement } from "../../../../../../../../../tradeFinanceHooks";
import {
  businessEntityDescriptorRefName,
  formatDate,
} from "../../../../../../../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TradeFinanceHighlighterProps } from "../../../../../../../types";

function GuaranteeHighlighter({
  tradeFinance,
  template,
  copyFrom,
}: TradeFinanceHighlighterProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const location = useLocation();
  const {
    //id,
    amount,
    otherPartnerName,
    subtype,
    dateOfExpiry,
    currency,
    //type,
    subTypeName,
    identifier,
  } = tradeFinance as Guarantee;

  const subtypeUniv = useCodeElement("tradefin/TF_GuaranteeSubtype", subtype);
  const tradeFinanceTypeName = t(
    "report.transactionTypes." +
      businessEntityDescriptorRefName(tradeFinance.businessEntityDescriptorId)
  );

  let numberOfGroupA = tradeFinance.signature
    ? tradeFinance.signature.numberOfGroupA
    : "";
  let numberOfGroupB = tradeFinance.signature
    ? tradeFinance.signature.numberOfGroupB
    : "";

  return (
    <div className="data-columns-container">
      <div className={"data-columns columns guarantee-columns"}>
        <div className={"column"}>
          <div>
            <div>{tradeFinance.referenceNo || "-"}</div>
            <div>{identifier}</div>
            <TradeFinanceTags tradeFinance={tradeFinance} />
          </div>
        </div>

        <div className={"column"}>
          <div>
            <div className={"beneficiary"}>{otherPartnerName}</div>
            <div>{tradeFinanceTypeName}</div>
            <div>
              {(subTypeName && subTypeName[lang]) || subtypeUniv || "-"}
            </div>
          </div>
        </div>
        {location.search.includes(
          "chipsFilter=" + ChipsFilter.signatureRequired
        ) && (
          <div className={"column has-right-text"}>
            <div>
              <div
                className={classnames({
                  "has-text-danger": numberOfGroupA.startsWith("0"),
                })}
              >
                {t("underwriter.groupA") + ": " + numberOfGroupA}
              </div>
              <div
                className={classnames({
                  "has-text-danger": numberOfGroupB.startsWith("0"),
                })}
              >
                {t("underwriter.groupB") + ": " + numberOfGroupB}
              </div>
            </div>
          </div>
        )}

        <div className={"column has-right-text"}>
          <div>
            <div>
              <span className={"amount"}>
                {amount ? (
                  <CurrencyNumberFormat
                    displayType={"text"}
                    value={amount}
                    currency={currency}
                  />
                ) : (
                  "-"
                )}
              </span>
              <span className={"currency"}> {currency}</span>
            </div>
            <div className="date">
              {dateOfExpiry && formatDate(dateOfExpiry)}
            </div>
          </div>
        </div>
        <div className={"column is-narrow"}>
          <TradeFinanceCardButtons
            tradeFinance={tradeFinance}
            templateMode={template}
            copyFrom={copyFrom}
          />
        </div>
      </div>
    </div>
  );
}

export default GuaranteeHighlighter;
