import classnames from "classnames";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { TradFinanceRequestContext } from "../../../../../context";
import { CompanyWarningModalType } from "../types";

const CompanyWarningModal = ({
  showCompanyWarning,
  setShowCompanyWarning,
  setDraftMode,
  draftMode,
  send,
}: CompanyWarningModalType) => {
  const [signInProgress, setSignInProgress] = useState<boolean>(false);
  const { t } = useTranslation();
  const { tradeFinance } = useContext(TradFinanceRequestContext);
  return (
    <div className={classnames("modal", { "is-active": showCompanyWarning })}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("tradeFinance.warnings.companyChanged")}
          </p>
        </header>
        <section className="modal-card-body">
          {t("tradeFinance.warnings.companyChanged_desc") +
            tradeFinance.clientDetails?.companyName +
            " !"}
        </section>
        <footer className="modal-card-foot">
          <div className={"modal-buttons"}>
            <button
              className={"button is-danger is-outlined is-small is-bold"}
              onClick={() => {
                setShowCompanyWarning(false);
                setDraftMode(null);
              }}
              disabled={signInProgress}
            >
              {t("cancel")}
            </button>
            <button
              className={classnames(
                "button is-success is-outlined is-small is-bold",
                { "is-loading": signInProgress }
              )}
              onClick={async () => {
                setSignInProgress(true);
                await send(!!draftMode);

                setSignInProgress(false);
                setShowCompanyWarning(false);
                setDraftMode(null);
              }}
              disabled={signInProgress}
            >
              {t("continue")}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default CompanyWarningModal;
