import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../App";
import { TabId } from "../../../TradeFinanceRequestDetailsPage/assets/type";
import { Signature } from "../../../../types";
import {
  businessEntityDescriptorRefName,
  formatDate,
} from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { CurrencyNumberFormat } from "../../../../components";

function SignatureCardItem({ signature }: { signature: Signature }) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  let isMessage =
    signature.transactionEventType &&
    signature.transactionEventType[lang] ===
      t("tradeFinance.messaging.message");
  const tradeFinanceLink =
    `/portal/trade-finance-request/${signature.tradeFinanceId}` +
    (signature.eventId ? "?event=" + signature.eventId : "") +
    (isMessage ? "#" + TabId.chat : "");

  const history = useHistory();
  const { amount, currency, tradeFinanceDescriptorId } = signature;
  let tradeFinanceName = t(
    "report.transactionTypes." +
      businessEntityDescriptorRefName(tradeFinanceDescriptorId)
  );

  return (
    <div
      className="notification-list--item"
      onClick={() => history.push(tradeFinanceLink)}
    >
      <div className={"signature-list--item-row"}>
        <div>
          <div>{t("signature.referenceNumberOrName")}</div>
          <div className="identifier">{signature.referenceNumber}</div>
          <div className="beneficiary">{signature.beneficiaryName}</div>
        </div>

        <div style={{ textAlign: "right" }}>
          <div>
            <span className={"amount"}>
              {amount ? (
                <CurrencyNumberFormat
                  displayType={"text"}
                  value={amount}
                  currency={currency}
                />
              ) : (
                "-"
              )}
            </span>
            <span className={"currency"}> {currency}</span>
          </div>
          <div className="date">{formatDate(signature.date)}</div>
          <div className={"type"}>{tradeFinanceName}</div>
        </div>
      </div>
      <div className={"divider"} />
      <div className={"signature-list--item-row"}>
        <div>{t("menu.signatures")}</div>
        <div>
          <span className={"group-a"}>
            {t("signature.groupA")}:{signature.numberOfGroupA}
          </span>
          &nbsp;
          <span>
            {t("signature.groupB")}:{signature.numberOfGroupB}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SignatureCardItem;
