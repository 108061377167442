import {
  Beneficiary,
  CalendarEvents,
  ClaimData,
  Clause,
  Comment,
  Document,
  DocumentRequirement,
  HasId,
  Id,
  ListStats,
  Maturity,
  Notification,
  PageResult,
  Partner,
  PortalConfig,
  Signature,
  Stats,
  Template,
  TFDocument,
  TradeFinance,
  TradeFinancePartner,
  TradeFinanceResponse,
  TradeFinanceSearchParams,
  TradeFinanceType,
  TransactionDetails,
  UnderWriter,
  UnderWritersInfo,
  User,
  UserConfig,
} from "../types";
import { deleteRequest, getRequest, postRequest } from "./portalApi";
import Config from "../Config";
import { FormValues } from "../components/Form/types";
import {
  Report,
  ReportStats,
  ReportTemplate,
} from "../pages/ReportPageComponents/types";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import {
  TFCopyMethod,
  TradeFinanceRequestListType,
} from "../pages/TradeFinanceListPage/assets/types";
import {
  EventHistory,
  FieldChange,
} from "../components/EventHistoryTab/assets/type";

//IE-11 cache bug
axios.defaults.headers.get["Pragma"] = "no-cache";
axios.defaults.headers.get["Cache-Control"] = "no-cache, no-store";

export async function findTradeFinance(
  id: string,
  type?: TradeFinanceType,
  eventId?: number | undefined,
  copy?: boolean,
  template?: boolean
): Promise<TradeFinanceResponse> {
  const url = new URLSearchParams();

  if (type) {
    url.append("type", type);
  }

  if (eventId) {
    url.append("event", eventId + "");
  }

  if (copy) {
    url.append("copy", "true");
  }

  if (template) {
    url.append("template", !!template + "");
  }

  return getRequest(
    Config.serverUrl + `api/trade-finance/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24?` + url.toString()
  );
}

export type TradeFinanceDetail = TradeFinance & {
  favouriteBeneficiary: any;
  modifyExistingBeneficiary: boolean;
};

export async function saveTradeFinanceModifications(
  tradeFinance: TradeFinanceDetail,
  template: boolean
): Promise<{ id: string }> {
  let id = tradeFinance.id;
  if (id === "new") {
    /* @ts-expect-error */
    delete tradeFinance.id;
  }

  if (!id) {
    id = "new";
  }

  const { modifyExistingBeneficiary, ...tfToSave } = tradeFinance;
  return await postRequest(
    Config.serverUrl +
      `api/${template ? "template" : "trade-finance"}/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24/save`,
    tfToSave
  );
}

export async function copyTradeFinance(
  tradeFinance: TradeFinance,
  template: boolean,
  templateName?: string
): Promise<HasId<string>> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinance.id}/copy?template=${template}` +
      (templateName ? "&templateName=" + templateName : "") +
      (tradeFinance.event ? "&event=" + tradeFinance.event.id : "")
  );
}

export async function sendForSign(
  tradeFinance: TradeFinance,
  draft: boolean
): Promise<{ id: string }> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${
        tradeFinance.id || "new"
      }/send-for-sign?draft=${draft}` +
      (tradeFinance.event ? "&event=" + tradeFinance.event.id : ""),
    tradeFinance
  );
}

export async function deleteSignature(
  tradeFinance: TradeFinance
): Promise<void> {
  await deleteRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinance.id}/delete-signature?` +
      (tradeFinance.event ? "event=" + tradeFinance.event.id : "")
  );
}

export async function readyForSign(tradeFinance: TradeFinance): Promise<void> {
  await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinance.id}/ready-for-sign?` +
      (tradeFinance.event ? "event=" + tradeFinance.event.id : "")
  );
}

export async function cancelTradeFinance(
  tradeFinance: TradeFinance
): Promise<void> {
  const eventParam = tradeFinance.event
    ? "?event=" + tradeFinance.event.id
    : "";
  await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinance.id}/cancel` +
      eventParam
  );
}

interface ColumnMapping {
  [key: string]: string;
}

const reportColumnMapping: ColumnMapping = {
  productType: "businessEntityDescriptorId",
  issuedDate: "dateOfIssue",
  transactionStatus: "state",
  expiryDate: "dateOfExpiry",
};

function selectedProductMapping(selectedProducts: string[]) {
  if (selectedProducts && selectedProducts.includes("outgoingStandby")) {
    selectedProducts.push("outgoingStandbyBG", "outgoingStandbyLC");
  }
  if (selectedProducts && selectedProducts.includes("incomingStandby")) {
    selectedProducts.push("incomingStandbyBG", "incomingStandbyLC");
  }

  return selectedProducts;
}

export function getDocumentCoverDownloadLink(
  tradeFinanceId: string,
  templateId: string,
  eventId?: number
): string {
  const eventQueryParam = eventId ? `?eventId=${eventId}` : "";
  return (
    Config.serverUrl +
    "api/trade-finance/" +
    tradeFinanceId +
    "/documentCoverDownload/" +
    templateId +
    eventQueryParam
  );
}

export async function generateReport(
  reportParams: ReportTemplate
): Promise<Report> {
  const tfFields = reportParams.columns.map(
    (col) => reportColumnMapping[col] || col
  );
  const selectedProducts = selectedProductMapping(
    reportParams.selectedProducts
  );
  return await postRequest(Config.serverUrl + "api/report/generate", {
    ...reportParams,
    columns: tfFields,
  });
}

export async function generateReportFromTemplate(
  templateId: number
): Promise<Report> {
  return await postRequest(
    Config.serverUrl + "api/report/generate/" + templateId
  );
}

export function downloadPortalDocs(tfId: number, eventId: number): string {
  return (
    Config.serverUrl + "api/document/download/" + tfId + "/event/" + eventId
  );
}

export function downloadDrawingDocuments(tfId: string, ids: string): string {
  return (
    Config.serverUrl +
    `/api/trade-finance/${tfId}/exportdrawings${ids && `?events=${ids}`}`
  );
}

//trade-finance/63604385/exportdrawings

export function downloadFeesDocuments(tfId: string, ids: string): string {
  return (
    Config.serverUrl +
    `/api/trade-finance/${tfId}/exportFees${ids && `?fees=${ids}`}`
  );
}

export async function getReportTemplate(id: string): Promise<ReportTemplate> {
  if (!id) {
    id = "";
  }
  return await getRequest(Config.serverUrl + "api/report/templates/" + id);
}

export async function deleteReport(reportId: number): Promise<void> {
  await deleteRequest(Config.serverUrl + "api/report/reports/" + reportId);
}

export async function getReport(id: string): Promise<Report> {
  return await getRequest(Config.serverUrl + "api/report/reports/" + id);
}

export async function getReportTemplates(): Promise<
  PageResult<ReportTemplate>
> {
  return await getRequest(Config.serverUrl + "api/report/templates");
}

export async function getGeneratedReports(
  offset: number = 0,
  limit: number = 100
): Promise<PageResult<Report>> {
  return await getRequest(
    Config.serverUrl + `api/report/reports?offset=${offset}&limit=${limit}`
  );
}

export async function getReportStats(): Promise<ReportStats> {
  return await getRequest(Config.serverUrl + "api/report/stats");
}

export async function getStats(): Promise<Stats> {
  return await getRequest(Config.serverUrl + "api/stats");
}

export async function saveReportTemplate(
  reportParams: ReportTemplate
): Promise<{ id: string }> {
  const tfFields = reportParams.columns.map(
    (col) => reportColumnMapping[col] || col
  );
  return await postRequest(Config.serverUrl + "api/report/save", {
    ...reportParams,
    columns: tfFields,
  });
}

function handleFileUploadError(
  res: AxiosResponse<any>
): AxiosResponse<any> | never {
  if (
    res.status === 200 &&
    res.headers["content-type"]?.startsWith("text/html")
  ) {
    throw new Error("fileUploadFailed");
  }
  return res;
}

export async function uploadTradeFinanceDocuments(
  tradeFinanceId: string,
  eventId: number | undefined,
  docs: TFDocument[]
): Promise<HasId<number>> {
  let formData = new FormData();

  docs.forEach((doc, index) => {
    formData.append("description_" + index, doc.description || " ");

    if (doc.id) {
      formData.append("id_" + index, doc.id + "");
    }

    if (eventId) {
      formData.append("eventId_" + index, eventId + "");
    }

    if (doc.fileType) {
      formData.append("fileType_" + index, doc.fileType);
    }

    if (doc.file) {
      formData.append("file_" + index, doc.file);
    }
  });

  const res: AxiosResponse<any> = await axios.post(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/documents`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return handleFileUploadError(res).data;
}

export async function deleteTemplate(templateId: string): Promise<void> {
  await deleteRequest(Config.serverUrl + `api/template/${templateId}`);
}

export async function saveTemplate(
  tradeFinance: TradeFinance
): Promise<HasId<string>> {
  return await postRequest<HasId<string>>(
    Config.serverUrl + `api/template/${tradeFinance.id || "new"}/save`,
    tradeFinance
  );
}

export async function deleteTradeFinanceDocument(
  tradeFinanceId: string,
  docId: number
): Promise<void> {
  await deleteRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/document/${docId}`
  );
}

export async function getTradeFinanceDocuments(
  tradeFinanceId: string,
  eventId: number | null | undefined
): Promise<TFDocument[]> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/document${
        eventId ? "?event=" + eventId : ""
      }`
  );
}

export async function getMessageEventHistory(
  tradeFinanceId: string
): Promise<EventHistory[]> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/history?commentRelated=true`
  );
}

export async function getTradeFinanceHistory(
  tradeFinanceId: string
): Promise<EventHistory[]> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/history?commentRelated=false`
  );
}

export async function getTradeFinanceHistoryChanges(
  tradeFinanceId: string,
  detailId: number,
  messageEvents: boolean
): Promise<FieldChange[]> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/history-changes?commentRelated=${messageEvents}${
        detailId ? "&detailId=" + detailId : ""
      }`
  );
}

export async function getTradeFinanceComments(
  tradeFinanceId: string,
  event?: number
): Promise<Comment[]> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comments${
        event ? "?event=" + event : ""
      }`
  );
}

export async function getUnderWriterInfoForNewComment(
  tradeFinanceId: string,
  eventId?: number
): Promise<UnderWritersInfo> {
  const eventQueryParam = eventId ? `?eventId=${eventId}` : "";
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comment/new/underwriterInfo` +
      eventQueryParam
  );
}

export async function amendment(
  tradeFinanceId: string
): Promise<{ id: number }> {
  return await postRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/amendment`
  );
}

export async function preCheck(
  tradeFinanceId: string
): Promise<{ id: number }> {
  return await postRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/precheck`
  );
}

export async function cancelOpenedImportLC(
  tradeFinanceId: string
): Promise<{ id: number }> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/cancel-opened-import-lc`
  );
}

export async function claim(
  claimData: ClaimData,
  tradeFinanceId: string,
  event: number
): Promise<UnderWritersInfo> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/claim?event=${event}`,
    claimData
  );
}

export async function getExportedDocuments(
  tradeFinanceId: string,
  events?: String[]
): Promise<TFDocument> {
  return await getRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/exportdrawings`
  );
}

export async function getExportedDocumentsFees(
  tradeFinanceId: string,
  events?: String[]
): Promise<TFDocument> {
  return await getRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/exportFees`
  );
}

export const PAGE_LIMIT = 20;

export async function findTradeFinances(
  type: TradeFinanceRequestListType,
  searchParams: TradeFinanceSearchParams | null,
  copy: TFCopyMethod | undefined,
  offset: number
): Promise<TradeFinance[]> {
  const urlSearchParams = searParamsToUrlSearchParams(searchParams);

  if (copy) {
    urlSearchParams.append("copy", copy);
  }

  urlSearchParams.append("type", type);
  urlSearchParams.append("offset", (offset || 0) + "");
  urlSearchParams.append("limit", PAGE_LIMIT + "");

  return getRequest<TradeFinance[]>(
    Config.serverUrl + `api/trade-finance?${urlSearchParams.toString()}`
  );
}

function searParamsToUrlSearchParams(
  searchParams: TradeFinanceSearchParams | null
): URLSearchParams {
  const urlSearchParams = new URLSearchParams();

  if (searchParams) {
    if (searchParams.search) {
      urlSearchParams.append("search", searchParams.search);
    }

    if (searchParams.referenceNo) {
      urlSearchParams.append("referenceNo", searchParams.referenceNo);
    }
    if (searchParams.currency) {
      urlSearchParams.append("currency", searchParams.currency.join(","));
    }
    if (
      searchParams.minAmount !== undefined &&
      searchParams.minAmount !== null
    ) {
      urlSearchParams.append("minAmount", searchParams.minAmount + "");
    }
    if (
      searchParams.maxAmount !== undefined &&
      searchParams.maxAmount !== null
    ) {
      urlSearchParams.append("maxAmount", searchParams.maxAmount + "");
    }
    if (searchParams.otherPartnerName) {
      urlSearchParams.append("otherPartnerName", searchParams.otherPartnerName);
    }
    if (
      searchParams.businessEntityDescriptorId &&
      searchParams.businessEntityDescriptorId.length > 0
    ) {
      urlSearchParams.append(
        "businessEntityDescriptorId",
        searchParams.businessEntityDescriptorId.join(",")
      );
    }
    if (
      searchParams.eventDescriptorId &&
      searchParams.eventDescriptorId.length > 0
    ) {
      urlSearchParams.append(
        "eventDescriptorId",
        searchParams.eventDescriptorId.join(",")
      );
    }
    if (searchParams.subType && searchParams.subType.length > 0) {
      urlSearchParams.append("subType", searchParams.subType.join(","));
    }
    if (searchParams.identifier) {
      urlSearchParams.append("identifier", searchParams.identifier);
    }
    if (searchParams.minDateOfExpiry) {
      urlSearchParams.append(
        "minDateOfExpiry",
        searchParams.minDateOfExpiry + ""
      );
    }
    if (searchParams.maxDateOfExpiry) {
      urlSearchParams.append(
        "maxDateOfExpiry",
        searchParams.maxDateOfExpiry + ""
      );
    }
    if (searchParams.chipsFilter) {
      urlSearchParams.append("chipsFilter", searchParams.chipsFilter);
    }
    if (searchParams.sortOption) {
      urlSearchParams.append("sortOption", searchParams.sortOption);
    }
    if (searchParams.events && searchParams.events.length > 0) {
      urlSearchParams.append("events", searchParams.events.join(","));
    }
  }
  return urlSearchParams;
}

export function exportListLink(
  type: TradeFinanceRequestListType,
  searchParams: TradeFinanceSearchParams | null
): string {
  const urlSearchParams = searParamsToUrlSearchParams(searchParams);
  urlSearchParams.append("type", type);

  return (
    Config.serverUrl + `api/trade-finance/export?${urlSearchParams.toString()}`
  );
}

export async function getListStats(
  type: TradeFinanceRequestListType,
  searchParams: TradeFinanceSearchParams | null
): Promise<ListStats> {
  const urlSearchParams = searParamsToUrlSearchParams(searchParams);
  urlSearchParams.append("type", type);

  return getRequest(
    Config.serverUrl + `api/trade-finance-stats?${urlSearchParams.toString()}`
  );
}

export async function deleteUnderwriter(id: Id) {
  await deleteRequest(`api/underwriter/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`);
}

export async function deleteBeneficiary(id: Id) {
  await deleteRequest(Config.serverUrl + `api/beneficiary/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`);
}

export async function findUnderwriters(): Promise<UnderWriter[]> {
  return getRequest(`api/underwriter`);
}

export async function findClauses(
  search: string | null | undefined,
  fromPortal?: boolean
): Promise<Clause[]> {
  return getRequest(
    `api/clause?fromPortal=${fromPortal}` + (search ? "&search=" + search : "")
  );
}

const clausesCache: Map<string, Promise<Clause[]>> = new Map();

export async function findActiveClauses(user: User): Promise<Clause[]> {
  let result: Promise<Clause[]>;

  if (clausesCache.has(user.email)) {
    result = clausesCache.get(user.email)!;
  } else {
    const promise: Promise<Clause[]> = new Promise(async (resolve) => {
      const response: Clause[] = await getRequest(`api/clause?active=true`);
      resolve(response);
    });
    clausesCache.set(user.email, promise);
    result = promise;
  }

  return await result;
}

export async function findClause(id: Id): Promise<Clause> {
  return getRequest(Config.serverUrl + `api/clause/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`);
}

export async function saveClause(clause: Clause): Promise<{ id: number }> {
  clausesCache.clear();
  return await postRequest(Config.serverUrl + `api/clause`, clause);
}

export async function activateClause(clause: Clause): Promise<{ id: number }> {
  clausesCache.clear();
  return await postRequest(
    Config.serverUrl + `api/clause/${clause.id}/activate`
  );
}

export async function archiveClause(clause: Clause): Promise<{ id: number }> {
  clausesCache.clear();
  return await postRequest(
    Config.serverUrl + `api/clause/${clause.id}/archive`
  );
}

export async function deleteClause(clause: Clause): Promise<{ id: number }> {
  clausesCache.clear();
  return await postRequest(Config.serverUrl + `api/clause/${clause.id}/delete`);
}

export async function discardReport(id: number): Promise<{ id: number }> {
  return await postRequest(Config.serverUrl + `api/report/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24/discard`);
}

export async function findUnderwriterById(
  underwriterConfigId: Id
): Promise<UnderWriter> {
  return await getRequest(
    Config.serverUrl + `api/underwriter/${underwriterConfigId}`
  );
}

export async function findSignatures(
  isDashboard: boolean,
  offset: number,
  limit?: number
): Promise<Signature[]> {
  return getRequest(
    `api/signatures?isDashboard=${isDashboard}` +
      (limit ? `&offset=${offset}&limit=${limit}` : "")
  );
}

export type GroupMember = {
  name: string;
  memberInGroupA: boolean;
  memberInGroupB: boolean;
};

export async function getGroupMembers(): Promise<GroupMember[]> {
  return getRequest(Config.serverUrl + `api/group-members`);
}

export async function saveUnderwriters(
  underWriters: UnderWriter[]
): Promise<void> {
  return postRequest(`api/underwriter`, underWriters);
}

export async function findBeneficiaryById(
  beneficiaryId: Id
): Promise<Beneficiary | undefined> {
  return getRequest(Config.serverUrl + `api/beneficiary/${beneficiaryId}`);
}

export async function getPartners(): Promise<Partner[]> {
  return getRequest(Config.serverUrl + `api/partners`);
}

export async function getAllPartner(): Promise<Partner[]> {
  return getRequest(Config.serverUrl + `api/otherPartners`);
}

export async function findBeneficiaries(
  search: string | null | undefined
): Promise<Beneficiary[]> {
  return getRequest(
    Config.serverUrl + `api/beneficiaries` + (search ? "?search=" + search : "")
  );
}

export async function fetchBeneficiaries(
  search: string | null | undefined,
  offset: number,
  limit?: number
): Promise<Beneficiary[]> {
  return getRequest(
    Config.serverUrl +
      `api/beneficiary` +
      (limit ? `?offset=${offset}&limit=${limit}` : "") +
      (search ? "&search=" + search : "")
  );
}

export async function saveBeneficiary(
  beneficiary: FormValues<Beneficiary>
): Promise<FormValues<Beneficiary>> {
  return postRequest(Config.serverUrl + `api/beneficiary`, beneficiary);
}

export async function fetchNotifications(
  actionNeeded: boolean,
  stateFilter?: string,
  offset?: number,
  limit?: number
): Promise<Notification[]> {
  let stateFilterParam = stateFilter ? "&stateFilter=" + stateFilter : "";
  return getRequest(
    Config.serverUrl +
      `api/notifications?actionNeeded=${actionNeeded}&offset=${
        offset || 0
      }&limit=${limit || 10}` +
      stateFilterParam
  );
}

export async function fetchNotificationsForTradeFinance(
  tradeFinanceId: string
): Promise<Notification[]> {
  return getRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/notifications`
  );
}

export async function updateLastMessageInfo(
  tradeFinanceId: string,
  companyId: number,
  messageId: number
) {
  return postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/updateLastMessageInfo?companyId=${companyId}&messageId=${messageId}`
  );
}

export async function updateTradeFinanceReferenceNo(
  tradeFinanceId: string,
  title: string,
  lastTime: string
): Promise<void> {
  await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/update-referenceNo?referenceNo=${title}&lastTime=${lastTime}`
  );
}

export async function getTradeFinanceLastTime(
  tradeFinanceId: string
): Promise<void> {
  return await getRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/lastTime`
  );
}

export async function sign(
  tradeFinanceId: string,
  eventId: number | null
): Promise<{ redirectUrl?: string }> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/sign` +
      (eventId ? "?event=" + eventId : "")
  );
}

export async function signComment(
  tradeFinanceId: string,
  commentId: number,
  signatureId: number
): Promise<{ redirectUrl?: string }> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comment/${commentId}/sign/${signatureId}`
  );
}

export async function reOpenComment(
  tradeFinanceId: string,
  commentId: number
): Promise<void> {
  return await postRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comment/${commentId}/reopen`
  );
}

export async function deleteComment(
  tradeFinanceId: string,
  commentId: number
): Promise<void> {
  await deleteRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comment/${commentId}`
  );
}

export async function saveComment(
  tradeFinanceId: string,
  eventId: number | undefined,
  companyId: number,
  comment: Comment
): Promise<{ redirectUrl?: string }> {
  const formData = new FormData();

  if (comment.id) {
    formData.append("id", comment.id.toString());
  }

  formData.append("subject", comment.subject);
  formData.append("text", comment.text);
  formData.append("createdBy", comment.createdBy);

  (comment.documents || [])
    .filter((doc) => !doc.id)
    .forEach((doc, index) => {
      if (doc.id) {
        formData.append("id_" + index, doc.id + "");
      }

      if (doc.file) {
        formData.append("file_" + index, doc.file);
      }

      formData.append("description_" + index, doc.description);
    });

  const res = await axios.post(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comment?companyId=${companyId}${
        eventId ? "&event=" + eventId : ""
      }`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return handleFileUploadError(res).data;
}

export async function deleteCommentDocument(
  tradeFinanceId: string,
  comment: Comment,
  doc: TFDocument
): Promise<void> {
  await deleteRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/comment/${comment.id}/document/${doc.id}`
  );
}

export async function getUserConfigurations(): Promise<UserConfig[]> {
  return getRequest(Config.serverUrl + `api/user-configuration`);
}

export async function updateUserConfigurations(
  userConfigs: UserConfig[]
): Promise<void> {
  return postRequest(Config.serverUrl + `api/user-configuration`, userConfigs);
}

export async function getTradeFinanceDocumentRequirements(
  tradeFinanceId: string
): Promise<DocumentRequirement[]> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/document-requirements`
  );
}

export async function deleteDocumentRequirement(id: Id) {
  await deleteRequest(`api/document-requirement/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`);
}

export async function deleteTradeFinance(id: Id) {
  await deleteRequest(`api/trade-finance/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`);
}

export async function saveDocumentRequirement(
  documentRequirement: FormValues<DocumentRequirement>
): Promise<FormValues<DocumentRequirement>> {
  return postRequest(
    Config.serverUrl + `api/documentRequirement`,
    documentRequirement
  );
}

export async function getDocumentRequirementTemplates(): Promise<Template[]> {
  return getRequest(Config.serverUrl + `api/docreq-template`);
}

export async function getMaturities(
  tradeFinanceId: string
): Promise<Maturity[]> {
  if (!tradeFinanceId) {
    return [];
  }
  return await getRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/maturities`
  );
}

export async function saveMaturity(
  maturity: FormValues<Maturity>,
  tradeFinanceId: string
): Promise<FormValues<Maturity>> {
  return postRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/maturity`,
    maturity
  );
}

export async function deleteMaturity(id: Id, tradeFinanceId: string) {
  await deleteRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/maturity/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`
  );
}

export async function hasInProgressAmendmentEvent(
  tradeFinanceId: string
): Promise<boolean> {
  const res = await getRequest<{ hasInProgressAmendment: boolean }>(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/hasInProgressAmendmentEvent`
  );
  return res.hasInProgressAmendment;
}

export async function hasInProgressCancelEvent(
  tradeFinanceId: string
): Promise<boolean> {
  const res = await getRequest<{ hasInProgressCancel: boolean }>(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/hasInProgressCancelEvent`
  );
  return res.hasInProgressCancel;
}

export async function getHelpDocuments(
  search: string | null | undefined
): Promise<Document[]> {
  return await getRequest(
    Config.serverUrl +
      `api/help-documents` +
      (search ? "?search=" + search : "")
  );
}

export async function getCalendarEvents(
  from: Date,
  to: Date
): Promise<CalendarEvents> {
  const fromDate: number = from.getTime();
  const toDate: number = to.getTime();
  const calendarEvents: CalendarEvents = await getRequest(
    Config.serverUrl + `api/calendar-events?from=${fromDate}&to=${toDate}`
  );
  const events = calendarEvents.events.map((event) => {
    event.date = moment(event.date);
    return event;
  });
  const holidays = calendarEvents.holidays.map((holiday) => moment(holiday));
  return { events, holidays };
}

export async function getTransactionDetails(
  tradeFinanceId: string
): Promise<TransactionDetails> {
  return await getRequest(
    Config.serverUrl + `api/trade-finance/${tradeFinanceId}/transaction-details`
  );
}

export async function getTradeFinancePartners(
  search: string
): Promise<TradeFinancePartner[]> {
  if (!search) {
    return [];
  }

  if (search.includes(" ")) {
    search = search.substr(0, search.indexOf(" "));
  }

  return getRequest(Config.serverUrl + `api/tfpartners?search=${search}`);
}

export async function portalConfig(): Promise<PortalConfig> {
  return await getRequest(Config.serverUrl + `api/portal-config`);
}

export async function generateWebDavToken(
  tradeFinanceId: string,
  documentId: number
): Promise<{ token: String }> {
  return await getRequest(
    Config.serverUrl +
      `api/trade-finance/${tradeFinanceId}/document/${documentId}/generate-token`
  );
}

export async function generateTeamsMeetingUrl(): Promise<{ teamsOnlineUrl: string }> {
  return await getRequest(
      Config.serverUrl + `api/generate-teams-meeting`
  );
}

