import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Clause } from "../../../../../types";
import { ClausesTableProps } from "../../../types";
import * as TFApi from "../../../../../api/tradeFinanceApi";
import { CodeElementComponent } from "../../..";
import { Activate, Archive, Delete, Edit } from "../../../../../icons/Icons";

function ClausesTable({ clauses, reLoad }: ClausesTableProps) {
  const { t } = useTranslation();
  const history = useHistory();

  function navigateToEdit(clause: Clause) {
    history.push(`/portal/clause/${clause.id}`);
  }

  async function activate(clause: Clause) {
    await TFApi.activateClause(clause);
    await reLoad();
  }

  async function archive(clause: Clause) {
    await TFApi.archiveClause(clause);
    await reLoad();
  }

  async function deleteClause(clause: Clause) {
    await TFApi.deleteClause(clause);
    await reLoad();
  }

  return (
    <div className="table-container">
      <table className="table portal-table clauses-table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>{t("clause.clauseDesc")}</th>
            <th>{t("clause.clauseId")}</th>
            <th>{t("clause.clauseText")}</th>
            <th>{t("clause.dealType")}</th>
            <th>{t("clause.fieldId")}</th>
            <th>{t("clause.state")}</th>
            <th>{t("clause.actions")}</th>
          </tr>
        </thead>
        <tbody>
          {clauses.map((clause) => (
            <tr key={clause.id}>
              <td>{clause.clauseDesc}</td>
              <td>{clause.clauseId}</td>
              <td>{clause.clauseText}</td>
              <td>
                <CodeElementComponent
                  codeBaseId={"tradefin/TF_productTypePortal"}
                  codeElement={
                    clause.dealType.includes("outgoingStandby")
                      ? clause.dealType.filter(
                          (x) =>
                            !x.includes("outgoingStandbyBG") &&
                            !x.includes("outgoingStandbyLC")
                        )
                      : clause.dealType
                  }
                />
              </td>
              <td>
                <CodeElementComponent
                  codeBaseId={"tfportal/clauseFields"}
                  codeElement={clause.fieldId}
                />
              </td>
              <td>
                <div className={"step-state"}>
                  {t("clause.states." + clause.state)}
                </div>
              </td>

              <td className="row-actions">
                {(clause.state === "CREATED" || clause.state === "active") && (
                  <div
                    className="action-button"
                    onClick={() => navigateToEdit(clause)}
                  >
                    <Edit />
                    {t("edit")}
                  </div>
                )}

                {(clause.state === "CREATED" ||
                  clause.state === "archived") && (
                  <div
                    className="action-button"
                    onClick={() => activate(clause)}
                  >
                    <Activate />
                    {t("clause.activate")}
                  </div>
                )}

                {clause.state === "active" && (
                  <div
                    className="action-button"
                    onClick={() => archive(clause)}
                  >
                    <Archive />
                    {t("clause.archive")}
                  </div>
                )}

                {
                  <div
                    className="action-button"
                    onClick={() => deleteClause(clause)}
                  >
                    <Delete />
                    {t("clause.delete")}
                  </div>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ClausesTable;
