import React from "react";
import { UnderWritersInfo } from "../../../../../../types";
import { NameListContainer } from "./assets";

function UnderWritersBlock({ info }: { info: UnderWritersInfo }) {
  return (
    <>
      <div className="group-members">
        {!info.draft && info.numberOfGroupA > 0 && (
          <NameListContainer isAGroup hasGroup info={info} />
        )}

        {!info.draft && info.numberOfGroupB > 0 && (
          <NameListContainer hasGroup info={info} />
        )}

        {info.draft && <NameListContainer info={info} />}
      </div>
    </>
  );
}

export default UnderWritersBlock;
