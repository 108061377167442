import classnames from "classnames";
import React, { useContext } from "react";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { TextFieldProps } from "../types";

function TextField(props: TextFieldProps) {
  const { lang } = useContext(AppContext);
  const {
    id,
    disabled,
    value,
    className,
    placeholder,
    onChange,
    onBlur,
    error,
    maxLength,
    headerElementsFactory,
  } = props;

  const headerElements = headerElementsFactory
    ? headerElementsFactory(props)
    : null;

  return DecorateField(
    props,
    <input
      id={id}
      disabled={disabled}
      className={classnames(
        "input is-small",
        { "is-danger": !!error },
        className
      )}
      value={value || ""}
      type="text"
      maxLength={maxLength}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
    />,
    lang,
    headerElements
  );
}

export default TextField;
