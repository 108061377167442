import React from "react";
import { useTranslation } from "react-i18next";
import { TextAreaField, TextAreaFieldWithClause, TextField } from "..";
import { validateSwiftFieldValue, wrapText } from "../helper";
import { SwiftFieldProps } from "../types";

function SwiftField(props: SwiftFieldProps) {
  const { t } = useTranslation();
  const { lines, validationType, clauses, ...textFieldProps } = props;
  const maxCharsInLine = parseInt(validationType.substr(1));

  function setValue(val: string) {
    const formattedText = lines > 1 ? wrapText(val, maxCharsInLine) : val;
    props.setValue(formattedText);
  }

  function onChange(e: React.ChangeEvent<any>) {
    setValue(e.target.value);
  }

  function validate(e: React.FocusEvent<any>) {
    props.setError(
      validateSwiftFieldValue(
        e.target.value as string,
        validationType,
        lines,
        t
      )
    );
  }

  function format(val: string) {
    return wrapText(val, maxCharsInLine);
  }

  if (lines > 1) {
    if (clauses) {
      return (
        <TextAreaFieldWithClause
          {...textFieldProps}
          onChange={onChange}
          setValue={setValue}
          clauses={clauses}
          onBlur={validate}
          format={format}
        />
      );
    } else {
      return (
        <TextAreaField
          {...textFieldProps}
          onChange={onChange}
          setValue={setValue}
          onBlur={validate}
          format={format}
        />
      );
    }
  } else {
    return (
      <TextField
        {...textFieldProps}
        onChange={onChange}
        setValue={setValue}
        onBlur={validate}
        format={format}
      />
    );
  }
}

export default SwiftField;
