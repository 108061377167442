import React from "react";
import { useTranslation } from "react-i18next";
import { LogoutButton } from "..";
import { AppHeaderProps } from "../types";

function LoginButton(props: AppHeaderProps) {
  const { t } = useTranslation();

  return props.isLoggedIn ? (
    <LogoutButton onLogout={props.onLogout} />
  ) : (
    <a className="login-button header-button navbar-item" href={"#/login"}>
      {t("menu.login")}
    </a>
  );
}

export default LoginButton;
