import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../../../../../../../../App";
import { ChipsFilter, TradeFinance } from "../../../../../../../../../../types";
import {
  formatDate,
  isAmendment,
  isCancelEvent,
} from "../../../../../../../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";

function TradeFinanceTags({ tradeFinance }: { tradeFinance: TradeFinance }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { lang } = useContext(AppContext);
  const { initiationAs, createTime, stateName, event, signature } =
    tradeFinance;

  const isConcept = location.search.includes(
    "chipsFilter=" + ChipsFilter.drafts
  );
  const isOngoing =
    location.search.includes("chipsFilter=" + ChipsFilter.ongoing) ||
    location.pathname.endsWith("/ongoing");
  const signatureRequired = location.search.includes(
    "chipsFilter=" + ChipsFilter.signatureRequired
  );
  const waitingForClient = event?.eventState === "waitingForClient";
  const isImportant = (waitingForClient && signature) || signatureRequired;
  const isAmendmentInConcept =
    isAmendment(tradeFinance) &&
    (location.pathname.endsWith("/concept") || isConcept);
  const isCancelEventInConcept =
    isCancelEvent(tradeFinance) &&
    (location.pathname.endsWith("/concept") || isConcept);
  const isInWaitingForClientAndSigned = checkSignatures();
  const signatureEvent = getSignatureEvent();
  const initiationAsEvent = getInitiationAsForSignList();

  function checkSignatures() {
    const groupA = signature?.numberOfGroupA.split("/");
    const groupB = signature?.numberOfGroupB.split("/");

    return (
      groupA && groupA[0] === groupA[1] && groupB && groupB[0] === groupB[1]
    );
  }

  function getEventStateByFilter() {
    if (isAmendmentInConcept || isCancelEventInConcept) {
      return t("tradeFinance.eventState.concept");
    }
    if (waitingForClient && (!signature || isInWaitingForClientAndSigned)) {
      return t("tradeFinance.eventState.waitingForClient");
    }
    if ((waitingForClient && signature) || signatureRequired) {
      return t("tradeFinance.waitingForSignatures");
    }
    return t("tradeFinance.eventState.waitingForBank");
  }

  function getSignatureEvent() {
    if (event?.eventDescRefName) {
      return event?.eventDescRefName[lang];
    } else if (
      tradeFinance.signature &&
      tradeFinance.signature.transactionEventType
    ) {
      return tradeFinance.signature.transactionEventType[lang];
    } else {
      return "";
    }
  }

  function getInitiationAsForSignList() {
    if (
      tradeFinance?.signature?.transactionEventType &&
      event?.eventDescRef.endsWith("message")
    ) {
      return "";
    }
    if (
      tradeFinance?.signature?.transactionEventType &&
      event?.eventDescRef.endsWith("preCheckEvent")
    ) {
      return "finalized";
    }
    return initiationAs;
  }

  return (
    <>
      <div className={"tags"}>
        {!(isOngoing || signatureRequired) &&
          !isAmendmentInConcept &&
          stateName && <div className={"state"}>{stateName[lang]}</div>}
        {(isAmendmentInConcept || isCancelEventInConcept) && (
          <>
            <div className="step-state">{event?.eventDescRefName[lang]}</div>
            <div className="state">{getEventStateByFilter()}</div>
          </>
        )}
        {(isOngoing || signatureRequired) && (
          <div className={"tags"}>
            <div
              className={classnames("step-state", {
                "is-important": isImportant && !isInWaitingForClientAndSigned,
              })}
            >
              {getEventStateByFilter()}
            </div>
            {initiationAsEvent && (
              <div className="step-state">
                {t("tradeFinance.initiationAs." + initiationAsEvent)}
              </div>
            )}
            {signatureRequired && signatureEvent && (
              <div className="step-state">{signatureEvent}</div>
            )}
            {isOngoing && (
              <div className={"step-state"}>
                {event?.eventDescRefName[lang]}
              </div>
            )}
          </div>
        )}
        {isConcept && createTime && !isAmendment(tradeFinance) && (
          <div className="step-state">{formatDate(createTime)}</div>
        )}
      </div>
    </>
  );
}

export default TradeFinanceTags;
