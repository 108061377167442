import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import {
  formatDate,
  getTradeFinanceShortName,
  isClaimEvent,
  isLetterOfCredit,
} from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { TradeFinanceListContext } from "../context";
import { useTradeStateName } from "../hooks";
import { TradeFinanceCardButtons } from "../TradeFinanceCards/assets/AdaptiveCardComponent/assets/DesktopCardComponent/assets";
import { TradeFinanceCardHeaderProps } from "../types";

function TradeFinanceCardHeader(props: TradeFinanceCardHeaderProps) {
  const { lang } = useContext(AppContext);
  const { identifier, state, event, createTime, initiationAs } =
    props.tradeFinance;
  const { type } = useContext(TradeFinanceListContext);
  const { t } = useTranslation();
  const letterOfCreditClaimEvent =
    isLetterOfCredit(props.tradeFinance) && isClaimEvent(props.tradeFinance);

  const tradeFinanceEventStateName = useTradeStateName(props.tradeFinance);

  function getPageTitle() {
    let tradeFinanceShortName = getTradeFinanceShortName(props.tradeFinance, t);
    let pageTitle = tradeFinanceShortName;

    if (letterOfCreditClaimEvent) {
      pageTitle = t(
        props.underWritersInfo?.completed
          ? "tradeFinance.claim.title"
          : "tradeFinance.claim.newClaim"
      );
    } else if (identifier) {
      pageTitle = tradeFinanceShortName + ": " + identifier;
    } else if (["concept", "evaluateRequest"].includes(state)) {
      pageTitle = t("tradeFinance.newApplication", {
        tf: tradeFinanceShortName,
      });
    }
    return <div className={classnames("page-title")}>{pageTitle}</div>;
  }

  return (
    <div
      className={classnames("trade-finance-icon-and-title", {
        "bottom-margin": !identifier,
      })}
    >
      {getPageTitle()}
      <div className="step-states">
        {!props.underWritersInfo && tradeFinanceEventStateName && (
          <div className="step-state">{tradeFinanceEventStateName}</div>
        )}
        {event && event.eventDescRefName && (
          <div className="step-state">{event.eventDescRefName[lang]}</div>
        )}
        {type === "concept" && createTime && (
          <div className="step-state">{formatDate(createTime)}</div>
        )}
        {type === "ongoing" && initiationAs && (
          <div className="step-state">
            {t("tradeFinance.initiationAs." + initiationAs)}
          </div>
        )}
        {props.underWritersInfo && !props.underWritersInfo.completed && (
          <div className="step-state is-important">
            {t("tradeFinance.waitingForSignatures")}
          </div>
        )}
      </div>
      {props.editable && !props.hideButtons && (
        <TradeFinanceCardButtons
          tradeFinance={props.tradeFinance}
          templateMode={props.templateMode}
          copyFrom={props.copyFrom}
        />
      )}
    </div>
  );
}

export default TradeFinanceCardHeader;
