import * as React from "react";
import { useRef, useState } from "react";
import classnames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { ArrowDown } from "../../icons/Icons";
import { DeviceType, useDeviceType } from "../../pages/tradeFinanceHooks";
import { AdaptivePopup } from "..";
import { HeaderDropdownProps } from "./assets/types";

function HeaderDropdown(props: HeaderDropdownProps) {
  const {
    cssId,
    icon,
    label,
    showDropdownIcon,
    buttonClass,
    isInFooter,
    popupContent,
    stayOnContentClick,
  } = props;
  const [active, setActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLInputElement>(null);
  const device = useDeviceType();
  const companySelector = cssId === "company";

  useOnClickOutside(dropdownRef, () => {
    if (device === DeviceType.desktop || companySelector) {
      setActive(false);
    }
  });

  return (
    <div
      ref={dropdownRef}
      id={cssId}
      className={classnames("header-button dropdown " + (buttonClass || ""), {
        "is-active": active,
        "is-up is-right": isInFooter,
      })}
    >
      <div
        className={classnames("dropdown-trigger is-flex")}
        onClick={() => setActive(!active)}
      >
        {icon}
        <span className={classnames({ "company-name": "company" === cssId })}>
          {label}
        </span>
        {showDropdownIcon && <ArrowDown className={"dropdown-indicator"} />}
      </div>

      <div className="dropdown-menu">
        {(device === DeviceType.desktop || companySelector) && !isInFooter && (
          <div className={"arrow-up"}>
            <div className={"inner-arrow-up"} />
          </div>
        )}
        <div
          className="dropdown-content"
          onClick={(e) => {
            const targetClasses = (e.target as any).className || "";
            const buttonClicked =
              targetClasses.includes("portal-button-label") ||
              targetClasses.includes("portal-button");

            if (!stayOnContentClick || buttonClicked) {
              setActive(false);
            }
          }}
        >
          {companySelector ? (
            popupContent
          ) : (
            <AdaptivePopup active={active} deactivate={() => setActive(false)}>
              {popupContent}
            </AdaptivePopup>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderDropdown;
