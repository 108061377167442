import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AppContext } from "../../../../App";
import { useLoadTradeFinances, useTradeFinanceSearchParams } from "../hooks";
import {
  TFCopyMethod,
  TradeFinanceListProps,
  TradeFinanceRequestListType,
} from "../types";
import * as TradeFinanceApi from "../../../../api/tradeFinanceApi";
import * as API from "../../../../api/tradeFinanceApi";
import { usePrevious } from "../../../../hooks";
import { ListStats, SortOption } from "../../../../types";
import { updateSortOptions } from "../../../TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { deepEqual } from "../../../../utils";
import { TradeFinanceListContext } from "../context";
import { TradeFinanceCards } from "..";
import { NewRequestComponent } from "./assets";
import { chipsEnabledListTypes } from "../../../../components/ChipsFilterComp/assets/helper";
import {
  ChipsFilterComp,
  EmptyList,
  ShowMoreButton,
} from "../../../../components";
import { SelectOption } from "../../../../components/Fields/types";
import { SelectField2 } from "../../../../components";

function TradeFinanceList(props: TradeFinanceListProps) {
  const { lang } = useContext(AppContext);
  const { type, copy, filterIsMandatory = false, templateMode } = props;

  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useTradeFinanceSearchParams();
  const { tradeFinances, reload, showMore, isLoading, hasMore } =
    useLoadTradeFinances(filterValue, false, type, copy as TFCopyMethod);
  const downloadLink = useMemo(
    () => TradeFinanceApi.exportListLink(type, filterValue),
    [type, filterValue]
  );
  const { chipsFilter, events, ...filterWithoutChipsFilter } =
    filterValue || {};
  const prevFilterWithoutChipsFilter = usePrevious(filterWithoutChipsFilter);
  const showChipsFilter = chipsEnabledListTypes.includes(type);
  const isCopyOrTemplate = copy === "COPY" || copy === "TEMPLATE";

  const sortOptions: SelectOption[] = useMemo(() => {
    let result = Object.values(SortOption).map((key) => {
      return { label: t("sortOption." + key, { lng: lang }), value: key };
    });
    return updateSortOptions(result, type, filterValue?.chipsFilter);
  }, [type, filterValue, lang]);

  let loadListStatsAction = useQuery(
    ["listStats", type, filterWithoutChipsFilter],
    async () => {
      if (
        showChipsFilter &&
        !deepEqual(prevFilterWithoutChipsFilter, filterValue)
      ) {
        return await API.getListStats(
          type as TradeFinanceRequestListType,
          filterValue
        );
      }
      return null;
    }
  );

  const sortOptionField = (
    <SelectField2
      value={filterValue?.sortOption}
      options={sortOptions}
      id={"sort-option-field"}
      setValue={(value) =>
        setFilterValue({ ...filterValue, sortOption: value })
      }
      isClearable={true}
      onClearValue={() =>
        setFilterValue({ ...filterValue, sortOption: undefined })
      }
      onChange={(e) => {}}
      setError={(e) => {}}
      label={t("report.orderBy")}
    />
  );

  if (
    ![
      "guarantees",
      "letterOfCredit",
      "collection",
      "closed",
      "ongoing",
      "concept",
      "template",
      "dashboard",
      "standby",
    ].includes(type)
  ) {
    return <div>{t("tradeFinance.invalidRequestType")}</div>;
  }

  if (!tradeFinances || loadListStatsAction.isFetching) {
    return null;
  }

  const listStats: ListStats | null | undefined = loadListStatsAction.data;
  return (
    <div className="page trade-finance-list-page card-list-page">
      <NewRequestComponent sortOptionField={sortOptionField} />

      {!isCopyOrTemplate && showChipsFilter && (
        <ChipsFilterComp
          listType={type}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          listStats={listStats}
          sortOptionField={sortOptionField}
        />
      )}

      {isCopyOrTemplate && (
        <div className={"page-header is-flex"}>
          <div className={"page-title"}>{t("menu." + type)}</div>
        </div>
      )}

      {isLoading && (
        <div
          className={
            "button is-white is-loading is-large is-flex is-transparent"
          }
        ></div>
      )}
      {!isLoading && tradeFinances.length === 0 && (
        <EmptyList message={t("tradeFinance.emptyTradeFinanceRequestList")} />
      )}

      {tradeFinances && (
        <TradeFinanceListContext.Provider value={{ ...props, reload }}>
          <TradeFinanceCards
            tradeFinances={tradeFinances}
            copyFrom={!!copy}
            templateMode={templateMode}
            downloadLink={downloadLink}
          />
          <ShowMoreButton
            isLoading={isLoading}
            showMore={showMore}
            hasMore={hasMore}
          />
        </TradeFinanceListContext.Provider>
      )}
    </div>
  );
}

export default TradeFinanceList;
