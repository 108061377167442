import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import {
  DiscardReportTemplateButton,
  GenerateReportButtonUseForm,
  SaveReportTemplateButton,
} from "..";
import { getFieldProps } from "../../../../components/Form/helper";
import { ReportTemplate, SectionProps } from "../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";
import { SelectOption } from "../../../../components/Fields/types";
import { CheckBoxSelectionField, RoundedButton } from "../../../../components";

function FinishReportSection({ form, isEditable }: SectionProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const formatOptions: SelectOption[] = [
    { label: "Web", value: "html" },
    { label: "Excel", value: "xlsx" },
    { label: "CSV", value: "csv" },
  ];

  const deliveryModeOptions: SelectOption[] = [
    { label: t("report.deliveryModeOptions.email"), value: "email" },
    {
      label: t("report.deliveryModeOptions.inApplication"),
      value: "inApplication",
    },
  ];

  const referenceDate = getFieldProps(
    form,
    "referenceDate",
    t("report.referenceDate"),
    { disabled: !isEditable }
  );
  const format = getFieldProps(form, "format", t("report.format"), {
    disabled: !isEditable,
  });
  const deliveryMode = getFieldProps(
    form,
    "deliveryMode",
    t("report.deliveryMode"),
    { disabled: !isEditable }
  );
  const receivers = getFieldProps(form, "receivers", t("report.receivers"), {
    disabled: !isEditable,
  });

  const deleteReport = useMutation(
    async (report: ReportTemplate) => {
      await TFApi.deleteReport(report.id);
    },
    {
      onSuccess: (data) => {
        history.push("/portal/reports");
      },
    }
  );

  return (
    <div className="report-filters section box">
      <div className="section--title">{t("report.finishReport")}</div>
      <div className="section--content">
        <CheckBoxSelectionField
          {...format}
          options={formatOptions}
          required={true}
        />

        {!form.values.dateOfGeneration && (
          <div className="buttons">
            <GenerateReportButtonUseForm form={form} />

            {isEditable && <SaveReportTemplateButton form={form} />}
            {isEditable && <DiscardReportTemplateButton form={form} />}
          </div>
        )}

        {form.values.dateOfGeneration && (
          <div className="buttons">
            <a href={`/api/report/download/${form.values.id}`}>
              <RoundedButton label={t("report.download")} />
            </a>
            <RoundedButton
              label={t("report.delete")}
              onClick={() => deleteReport.mutate(form.values)}
              disabled={deleteReport.isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default FinishReportSection;
