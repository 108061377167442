// @ts-nocheck
import React, { useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { AppContext } from "../../../../../../App";
import { cs, enGB } from "date-fns/locale";

export const DatePickerComponent = (props: any) => {
  const { lang } = useContext(AppContext);
  registerLocale("lang", lang === "cz" ? cs : enGB);
  return (
    <div className="datepicker">
      <DatePicker
        selected={props.date}
        locale={"lang"}
        onChange={props.handleDateChange}
        dateFormat="dd.MM.yyyy"
      />
    </div>
  );
};
