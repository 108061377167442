import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxSelectionField, SelectField2 } from "../../../../components";
import { getFieldProps } from "../../../../components/Form/helper";
import { useCodeBaseAsSelectOptions } from "../../../../hooks";
import { activeProducts } from "../../helper";
import { SectionProps } from "../../types";

function ReportColumnsSection({ form, isEditable }: SectionProps) {
  const { t } = useTranslation();
  //const columnOptions = useMemo(() => columnNames.map(col => ({label: t("report.columns." + col), value: col})), [columnNames]);
  const columnOptions = useCodeBaseAsSelectOptions("tradefin/reportData");
  const basicFields = useMemo(
    () =>
      (columnOptions || [])
        .filter((ce) => ce.tags?.includes("basic"))
        .map((colOption) => colOption.value),
    [columnOptions]
  );
  const columns = getFieldProps(
    form,
    "columns",
    t("report.dataIncludeInReport"),
    { disabled: isEditable }
  );
  const orderByOptions = useCodeBaseAsSelectOptions("tradefin/reportOrderBy");
  const orderBy = getFieldProps(form, "orderBy", t("report.orderBy"), {
    disabled: !isEditable,
  });
  const reportType = form.values.reportType;

  const customReport = reportType === "customReport";
  useEffect(() => {
    if (reportType !== "customReport") {
      form.setFieldValue("columns", basicFields);
    }
  }, [reportType, basicFields]);

  const isGuarantee =
    form.values.selectedProducts?.filter((product) =>
      product.toLowerCase().includes("guarantee")
    ).length > 0;
  const isStandBy =
    form.values.selectedProducts?.filter((product) =>
      product.toLowerCase().includes("standby")
    ).length > 0;
  const isOutgoingStandByBG =
    form.values.selectedProducts?.filter((product) =>
      product.includes("outgoingStandbyBG")
    ).length > 0;
  const isIssuedGuarantee =
    form.values.selectedProducts?.filter((product) =>
      product.includes("IssuedGuarantee")
    ).length > 0;
  const isLC =
    form.values.selectedProducts?.filter((product) => product.includes("LC"))
      .length > 0;
  const isCollection =
    form.values.selectedProducts?.filter((product) =>
      product.includes("Collection")
    ).length > 0;

  const guaranteeFields = new Set([
    "subtype",
    "guaranteeMainType",
    "contractIdentification",
    "counterGuaranteeDateOfExpiry",
  ]);
  const standbyFields = new Set(["contractIdentification"]);
  const lcFields = new Set([
    "balance",
    "maxPercCreditAmountTolerance",
    "minPercCreditAmountTolerance",
    "paymentMethod",
  ]);

  const nonCollectionFields = new Set(["dateOfIssue", "dateOfExpiry"]);
  let containsPassiveProduct = form.values.selectedProducts
    ? form.values.selectedProducts.some((x) => !activeProducts.includes(x))
    : false;
  let containsActiveProduct = form.values.selectedProducts
    ? form.values.selectedProducts.some((x) => activeProducts.includes(x))
    : false;

  const columnsToDisplay = columnOptions?.filter((columnOption) => {
    if (
      !form.values.selectedProducts ||
      form.values.selectedProducts.length === 0
    ) {
      return true;
    }

    const col = columnOption.value as string;

    if (isCollection && nonCollectionFields.has(col)) {
      return false;
    }

    if (
      col === "contractIdentification" &&
      (isOutgoingStandByBG || isIssuedGuarantee)
    ) {
      return true;
    }

    if (col === "timeCharacter" || col === "underFrameAgreement") {
      if (containsActiveProduct && !containsPassiveProduct) {
        return true;
      }
      return false;
    }

    if (!isGuarantee && guaranteeFields.has(col)) {
      return false;
    }

    if (!isLC && lcFields.has(col)) {
      return false;
    }
    if (!isStandBy && standbyFields.has(col)) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    if (columns.value && columnsToDisplay) {
      form.values.columns = (columns.value as string[]).filter((x) =>
        columnsToDisplay?.map((x) => x.value)?.includes(x)
      );
    }
  }, [columnsToDisplay]);

  const columnCheckboxes = useMemo(
    () => (
      <CheckBoxSelectionField
        {...columns}
        options={columnsToDisplay || []}
        className="dataIncludeInReport"
        disabled={!customReport || !isEditable}
      />
    ),
    [customReport, columnsToDisplay, columns.value, basicFields]
  );

  return (
    <div className="report-filters section box">
      <div className="section--title">{t("report.columnsTitle")}</div>
      <div className="section--content">
        <div className="field report-type-field">
          <div>
            <input
              className="is-checkradio"
              id="basicReport"
              type="radio"
              name="basicReport"
              checked={!customReport}
              onChange={() => form.setFieldValue("reportType", "basicReport")}
              disabled={!isEditable}
            />
            <label htmlFor="basicReport">{t("report.basicReport")}</label>
          </div>
          <div className={"is-flex"}>
            <input
              className="is-checkradio"
              id="customReport"
              type="radio"
              name="customReport"
              checked={customReport}
              onChange={() => form.setFieldValue("reportType", "customReport")}
              disabled={!isEditable}
            />
            <label htmlFor="customReport">{t("report.customReport")}</label>
          </div>
        </div>
        {columnCheckboxes}
        <SelectField2 {...orderBy} options={orderByOptions || []} />
      </div>
    </div>
  );
}
export default ReportColumnsSection;
