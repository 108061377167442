import { FormikProps } from "formik";
import { MutableRefObject, useEffect } from "react";

export function useFormRef<T>(
  ref: MutableRefObject<FormikProps<T> | undefined>,
  form: FormikProps<T>
) {
  useEffect(() => {
    if (ref) {
      ref.current = form;
    }

    return () => {
      if (ref) {
        ref.current = undefined;
      }
    };
  });
}
