import { useContext, useEffect, useState } from "react";
import { Univ } from "../types";
import * as API from "../api/portalApi";
import { AppContext } from "../App";
import { useMounted, usePrevious } from "../hooks";

export function useCodeElement(
  codeBase: string,
  codeElement: string
): string | undefined {
  const [codeElementUniv, setCodeElementUniv] = useState<Univ | undefined>();
  const { lang } = useContext(AppContext);
  const mounted = useMounted();
  const prevCodeElement = usePrevious(codeElement);

  useEffect(() => {
    if (!codeElementUniv || prevCodeElement !== codeElement) {
      API.getCodeElementUniv(codeBase, codeElement).then((ce) => {
        if (mounted.current) {
          return setCodeElementUniv(ce);
        }
      });
    }
  }, [codeElement, codeBase]);

  return codeElementUniv ? codeElementUniv[lang] : undefined;
}

export enum DeviceType {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
}

export function useDeviceType(): DeviceType {
  const [device, setDevice] = useState<DeviceType>(getDeviceType());

  function getDeviceType(): DeviceType {
    // bulma threshold values for touch devices
    const threshold = { mobile: 768, tablet: 1023 };
    const screenWidth = window.screen.width;

    if (screenWidth <= threshold.mobile) {
      return DeviceType.mobile;
    } else if (screenWidth <= threshold.tablet) {
      return DeviceType.tablet;
    } else {
      return DeviceType.desktop;
    }
  }

  useEffect(() => {
    const handleWindowSizeChange = () => {
      const nextDevice = getDeviceType();
      if (device !== nextDevice) {
        setDevice(nextDevice);
      }
    };

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [device]);

  return device;
}

export function isIEBrowser() {
  const isEdge = window.navigator.userAgent.indexOf("Edge") !== -1;
  const isIe = window.navigator.userAgent.indexOf("Trident") !== -1 && !isEdge;

  return isIe;
}
