import classNames from "classnames";
import React, { ReactNode, useContext } from "react";
import { Field } from "../..";
import { AppContext } from "../../../App";
import { FieldProps } from "../../Fields/types";
import {
  CollapsibleState,
  ContainerDesc,
  ContainerType,
  FormLayoutProps,
} from "../types";
import { CollapsibleContainer } from "./assets";

function FormLayout<T>({
  form,
  fields,
  enhanceContainerProps,
  layout,
  buttons,
}: FormLayoutProps<T>) {
  const { lang } = useContext(AppContext);

  function BasicContainer(
    container: ContainerDesc,
    content: ReactNode[],
    idx?: number
  ) {
    if (container.label) {
      return (
        <div key={idx} className="layout-container">
          {container.label && (
            <div key="label" className="container-title">
              {container.label[lang]}
            </div>
          )}
          <div key="content" className="content">
            {content}
          </div>
        </div>
      );
    } else {
      return (
        <div key={idx} className="content">
          {content}
        </div>
      );
    }
  }

  function renderForm(container: ContainerDesc, idx?: number): ReactNode {
    if (container.type === ContainerType.VERTICAL) {
      return BasicContainer(
        container,
        container.content.map((c, i) => renderTopLevelContainer(c, i)),
        idx
      );
    }
  }

  function renderTopLevelContainer(
    container: ContainerDesc,
    idx?: number
  ): ReactNode {
    if (
      CollapsibleState.CLOSED === container.collapsible ||
      CollapsibleState.OPENED === container.collapsible
    ) {
      if (container.type === ContainerType.FORM) {
        return (
          <CollapsibleContainer
            key={idx}
            container={container}
            content={
              <div key="content" className="content">
                {container.content.map((f) => renderField(f))}
              </div>
            }
          />
        );
      } else if (container.type === ContainerType.HORIZONTAL) {
        return (
          <CollapsibleContainer
            key={idx}
            container={container}
            content={
              <div key={idx} className="columns">
                {container.content.map((c, idx) => (
                  <div key={idx} className="column">
                    {renderContainer(c, idx)}
                  </div>
                ))}
              </div>
            }
          />
        );
      } else {
        // VERTICAL
        return (
          <CollapsibleContainer
            key={idx}
            container={container}
            content={
              <div key="content" className="content">
                {container.content.map((c, i) => renderContainer(c, i))}
              </div>
            }
          />
        );
      }
    } else {
      // collapsible==null
      return (
        <div className={classNames("container", container.className)} key={idx}>
          {" "}
          {renderContainer(container, idx)}
        </div>
      );
    }
  }

  function renderContainer(container: ContainerDesc, idx?: number): ReactNode {
    if (enhanceContainerProps) {
      container = enhanceContainerProps(container, form);
    }
    if (container.hidden) {
      return;
    }

    if (container.type === ContainerType.FORM) {
      return BasicContainer(
        container,
        container.content.map((f) => renderField(f)),
        idx
      );
    } else if (container.type === ContainerType.HORIZONTAL) {
      return (
        <div key={idx} className="layout-container">
          {container.label && (
            <div key="label" className="container-title">
              {container.label[lang]}
            </div>
          )}
          <div key={idx} className="columns">
            {container.content.map((c, idx) => {
              return (
                <div key={idx} className="column">
                  {renderContainer(c, idx)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return BasicContainer(
        container,
        container.content.map((c, i) => renderContainer(c, i)),
        idx
      );
    }
  }

  function renderField(field: FieldProps): ReactNode {
    const fieldProps = (fields as any)[field.id];
    if (!fieldProps) {
      console.error("Invalid field:", field.id);
      return null;
    }

    const value = field.id.includes(".")
      ? field.id
          .split(".")
          .reduce(
            (res, fieldId) => (res ? (res as any)[fieldId] : null),
            form.values
          )
      : (form.values as any)[fieldProps.id];

    return (
      <Field
        key={field.id}
        className={fieldProps.className}
        {...(fieldProps as FieldProps)}
        value={value}
      />
    );
  }

  if (Object.keys(fields).length === 0) {
    return null;
  }

  return (
    <div>
      <div key="root" className="form">
        {layout && renderForm(layout, 0)}
      </div>
      <div className="container">{buttons}</div>
    </div>
  );
}

export default FormLayout;
