import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../../../../icons/Icons";

type EnlargeHistoryTextFieldType = {
  value: string;
  setEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTabOpened: boolean;
  setFirstTabOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

function EnlargeHistoryTextField({
  value,
  setEnlarged,
  isFirstTabOpened,
  setFirstTabOpened,
}: EnlargeHistoryTextFieldType) {
  const { t } = useTranslation();
  return (
    <td>
      <div className="modal enlarged-textarea is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {t("tradeFinance.eventHistory.details")}
            </p>
            <CloseIcon
              className={"close-icon"}
              onClick={() => setEnlarged(false)}
            />
          </header>
          <section className="modal-card-body">
            <div className="chips-filters">
              <div className={"chips-filter-container is-flex"}>
                <button
                  className={classnames("button is-rounded is-small", {
                    "is-success": isFirstTabOpened,
                    "is-outlined": !isFirstTabOpened,
                  })}
                  onClick={() => {
                    setFirstTabOpened(true);
                  }}
                >
                  {t("tradeFinance.eventHistory.origValue")}
                </button>
                <button
                  className={classnames("button is-rounded is-small", {
                    "is-success": !isFirstTabOpened,
                    "is-outlined": isFirstTabOpened,
                  })}
                  onClick={() => {
                    setFirstTabOpened(false);
                  }}
                >
                  {t("tradeFinance.eventHistory.newValue")}
                </button>
              </div>
            </div>
            <textarea
              disabled={true}
              className="textarea is-small"
              value={value}
            />
          </section>
        </div>
      </div>
    </td>
  );
}

export default EnlargeHistoryTextField;
