import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SignatureCardItem } from "..";
import { Signatures } from "../../../../icons/Icons";
import PortalCard from "../../../../components/PortalCard/PortalCard";
import { Signature } from "../../../../types";
import { SignaturesCardProps } from "../types";

function SignaturesCard({ title, subtitle, signatures }: SignaturesCardProps) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PortalCard icon={<Signatures />} title={title} subtitle={subtitle}>
      <div className="signature-list">
        {signatures.map((signature: Signature) => (
          <SignatureCardItem key={signature.id} signature={signature} />
        ))}

        <div className="portal-card-buttons">
          <button
            className={"button show-all-button"}
            onClick={() => history.push("/portal/signatures")}
          >
            {t("tradeFinance.showAll")}
          </button>
        </div>
      </div>
    </PortalCard>
  );
}

export default SignaturesCard;
