import classNames from "classnames";
import React from "react";

function StateComponent({
  state,
  stateName,
}: {
  state: string;
  stateName: string;
}) {
  return <div className={classNames("state", state)}>{stateName}</div>;
}

export default StateComponent;
