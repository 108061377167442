import { CodeBaseOption } from "../../../../../../components/Fields/types";
import { Clause } from "../../../../../../types";

export function filterTransactionTypes(
  options: CodeBaseOption[],
  values: Clause
) {
  const included: string[] = [];
  if (values.dealType?.includes("IssuedGuarantee")) {
    included.push("completeRequestEvent", "amendmentEvent");
  }

  if (values.dealType?.includes("importLC")) {
    included.push(
      "completeRequestEvent",
      "amendmentEvent",
      "claimEvent",
      "cancelEvent"
    );
  }

  if (values.dealType?.includes("exportLC")) {
    included.push("preCheckEvent");
  }

  if (values.dealType?.includes("exportCollection")) {
    included.push("completeRequestEvent");
  }

  if (values.dealType?.includes("outgoingStandby")) {
    included.push("completeRequestEvent", "amendmentEvent");
  }
  return options.filter((option) => included.includes(option.value));
}

export function filerFields(options: CodeBaseOption[], dealTypes: string[]) {
  if (!dealTypes || !dealTypes.length) {
    return [];
  }
  return options.filter((option) =>
    (dealTypes || []).find((dealType) => option.tags.includes(dealType))
  );
}
