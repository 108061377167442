import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon, SearchIcon } from "../../../../../icons/Icons";
import { BeneficiariesFilterComponentProps } from "../typest";

function BeneficiariesFilterComponent(
  props: BeneficiariesFilterComponentProps
) {
  const { search, onChange } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(search || "");

  function clear() {
    setSearchValue("");
    onChange(null);
  }

  function doSearch() {
    onChange(searchValue || null);
  }

  return (
    <div className="trade-finance-filter-component">
      <div className="simple-filter">
        <div className="search-box control has-icons-right">
          <input
            type="text"
            className={classnames("input", { "has-value": searchValue })}
            value={searchValue}
            placeholder={t("beneficiary.searchPlaceholder")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSearch();
              }
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span className="icon search-icon" onClick={doSearch}>
            <SearchIcon />
          </span>
          {searchValue && (
            <span className="icon close-icon" onClick={clear}>
              <CloseIcon />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default BeneficiariesFilterComponent;
