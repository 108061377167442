import React from "react";
import { useTranslation } from "react-i18next";
import {
  FinishReportSection,
  ReportColumnsSection,
  ReportFiltersSection,
  ReportNameSection,
} from "../assets";
import { validateMandatoryFields } from "../../../components/Form/helper";
import { Form } from "../../../components";
import { Report } from "../types";

function ReportPage({
  report,
  isEditable,
}: {
  report: Report;
  isEditable: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="page report-details-page">
      <Form
        initialValues={report as Report}
        enableReinitialize={true}
        validateOnChange={false}
        validate={(values) =>
          validateMandatoryFields(
            values,
            ["name", "states", "lengthOfProduct", "format"],
            t
          )
        }
        onSubmit={() => {}}
      >
        {(form) => (
          <div className="container">
            <div className="page-header">
              <div className="page-title">{t("report.reports")}</div>
            </div>

            <div className="box">
              <ReportNameSection form={form} isEditable={isEditable} />
              <ReportFiltersSection form={form} isEditable={isEditable} />
              <ReportColumnsSection form={form} isEditable={isEditable} />
              <FinishReportSection form={form} isEditable={isEditable} />
            </div>
          </div>
        )}
      </Form>
    </div>
  );
}

export default ReportPage;
