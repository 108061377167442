// @ts-nocheck
import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { TFDocument } from "../../types";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import classnames from "classnames";
import { MAX_FILE_SIZE } from "../MessagesComponents/assets/types";
import { DescriptionField, FileExtensionIcon } from "../MessagesComponents";
import { MultiDocumentUploadProps } from "./assets/types";
import { DocumentFileTypeField } from "./assets";
import { RoundedButton } from "..";
import { getSortedFileExtensions } from "../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { Delete } from "../../icons/Icons";

function MultiDocumentUpload(props: MultiDocumentUploadProps) {
  const { t } = useTranslation();
  const {
    editable,
    documents,
    updateDocuments,
    saveDocuments,
    error,
    setError,
    disabledButton,
  } = props;

  const onDrop: (acceptedFiles: File[]) => void = useCallback(
    (acceptedFiles) => {
      let total = 0;
      if (acceptedFiles) {
        acceptedFiles.forEach((file) => (total += file.size));
      }

      if (total > MAX_FILE_SIZE) {
        setError(t("attachmentSizeLimit"));
      } else {
        setError(null);
        if (updateDocuments) {
          updateDocuments([
            ...(documents || []),
            ...acceptedFiles.map(
              (file: File) =>
                ({ file, fileName: file.name } as any as TFDocument)
            ),
          ]);
        }
      }
    },
    [documents]
  );

  const onDropRejected = (
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    setError(t("attachmentSizeLimit"));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: MAX_FILE_SIZE,
  });

  function updateDocument(idx: number, doc: TFDocument) {
    if (updateDocuments) {
      updateDocuments(
        (documents || []).map((origDoc, i) => (i === idx ? doc : origDoc))
      );
    }
  }

  async function deleteDocument(doc: TFDocument, idx: number) {
    if (updateDocuments) {
      updateDocuments((documents || []).filter((origDoc, i) => i !== idx));
    }
  }

  function save() {
    saveDocuments(documents);
  }
  return (
    <div className="comment-documents-component common-upload-component">
      {updateDocuments && editable && (
        <div className="upload-component" {...getRootProps()}>
          <RoundedButton
            label={t("tradeFinance.messaging.selectFile")}
            inverse={true}
          />
          <input {...getInputProps()} />
          <div className="dnd-message is-bold">
            {t("tradeFinance.messaging.dndMessage")}
          </div>
          <div className="limits">
            {t("tradeFinance.messaging.limits", {
              formats: getSortedFileExtensions(),
            })}
          </div>

          {error && <div className="top-margin error-message">{error}</div>}
        </div>
      )}
      <div className="comment-documents">
        {documents.map((doc, idx) => (
          <div key={idx} className="uploaded-document">
            <div className="comment-document--header">
              <div className="document-file-name is-bold">
                <FileExtensionIcon fileName={doc.fileName} />
                <span>{doc.fileName}</span>
              </div>
              <div>
                {editable && (
                  <RoundedButton
                    label={t("tradeFinance.messaging.removeDocument")}
                    onClick={() => deleteDocument(doc, idx)}
                    icon={<Delete />}
                    transparent={true}
                  />
                )}
              </div>
            </div>

            <div className="comment-document--file-type">
              <DocumentFileTypeField
                tradeFinance={props.tradeFinance}
                editable={editable && !doc.id}
                error={doc.error}
                document={doc}
                required={true}
                updateDocument={(doc) => updateDocument(idx, doc)}
              />
            </div>

            <div className="comment-document--description">
              <DescriptionField
                tradeFinance={props.tradeFinance}
                editable={editable && !doc.id}
                document={doc}
                required={false}
                updateDocument={(doc) => updateDocument(idx, doc)}
              />
            </div>
          </div>
        ))}

        {documents.length > 0 && (
          <div
            className={classnames("portal-buttons", {
              disabled: disabledButton,
            })}
            style={{ padding: "0 0 10px 10px" }}
          >
            <RoundedButton
              label={t("save")}
              onClick={save}
              disabled={disabledButton}
            />
            <RoundedButton
              label={t("cancel")}
              inverse={true}
              onClick={() => updateDocuments([])}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default MultiDocumentUpload;
