import React, { useContext, useMemo, useState } from "react";
import {
  isCollection,
  isMixedPayment,
  isTradeFinanceEditable,
} from "../../../tradeFinanceUtils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { RoundedButton } from "../../../../../../components";
import { Collection, Maturity, TradeFinance } from "../../../../../../types";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import { Plus, WarningIcon } from "../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../context";
import { Step } from "..";
import { StepId } from "../../../type";
import { MaturitiesTable, MaturityForm } from "./assets";

function InstallmentsStep() {
  const tradFinanceRequestContext = useContext(TradFinanceRequestContext);
  const { tradeFinance, form, setMaturityError } = tradFinanceRequestContext;
  const { t } = useTranslation();
  const { id }: any = useParams();
  const isNew = id === "new";
  const editable = isTradeFinanceEditable(tradFinanceRequestContext) && !isNew;
  const [editedMaturity, setEditedMaturity] = useState<Maturity | null>(null);

  const loadMaturitiesQuery = useQuery(
    [
      "maturities",
      tradeFinance.id,
      (tradeFinance as Collection).conditionsOfDocumentDelivery,
    ],
    async (_) => TFApi.getMaturities(tradeFinance.id),
    {
      enabled:
        !isNew &&
        !!tradeFinance &&
        isCollection(tradeFinance) &&
        isMixedPayment(form.values as TradeFinance),
    }
  );

  const maturities = loadMaturitiesQuery.data || [];

  const sumError = useMemo(() => {
    if (
      maturities.length > 0 &&
      maturities.reduce((sum, mat) => sum + (mat.amount || 0), 0) !==
        (form.values.amount || 0)
    ) {
      setMaturityError(true);
      return t("tradeFinance.installment.amountError");
    } else if (
      maturities.length === 0 &&
      isMixedPayment(form.values as TradeFinance)
    ) {
      setMaturityError(true);
    } else {
      setMaturityError(false);
    }
  }, [maturities, tradeFinance.amount, form.values.amount]);

  if (!isMixedPayment(form.values as TradeFinance)) {
    return null;
  }

  function renderForm() {
    return (
      <MaturityForm
        maturity={editedMaturity!}
        setEditedMaturity={setEditedMaturity}
        submitMaturity={submitMaturity}
        maturities={maturities}
      />
    );
  }

  function renderTable() {
    return (
      <div className={"is-flex"}>
        <MaturitiesTable
          maturities={maturities}
          setEditedMaturity={setEditedMaturity}
          editable={editable}
          deleteMaturity={deleteMaturity}
          tradeFinanceId={id!}
        />
      </div>
    );
  }

  async function submitMaturity(toSave: Maturity) {
    if (toSave.paymentMethod === "atSight") {
      toSave.dueDate = null;
      toSave.dueDateText = null;
    }
    await TFApi.saveMaturity(toSave, tradeFinance.id);
    await loadMaturitiesQuery.refetch();
    setEditedMaturity(null);
  }

  async function deleteMaturity(maturity: Maturity) {
    await TFApi.deleteMaturity(maturity.id, tradeFinance.id);
    await loadMaturitiesQuery.refetch();
  }

  return (
    <Step
      stepId={StepId.installments}
      title={t("tradeFinance.installments")}
      headerEnd={sumError && <WarningIcon className="warning-icon" />}
    >
      <div className={"installments-step"}>
        {sumError && (
          <div className="message is-warning">
            <div className="message-body">{sumError}</div>
          </div>
        )}

        {renderTable()}

        {editedMaturity ? (
          renderForm()
        ) : (
          <RoundedButton
            label={t("add")}
            onClick={() => setEditedMaturity({} as Maturity)}
            inverse={true}
            buttonClass={"top-margin"}
            icon={<Plus />}
          />
        )}
      </div>
    </Step>
  );
}
export default InstallmentsStep;
