import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../../App";
import { RoundedButton } from "../../../../../../components";
import { FormParams } from "../../../../../../components/Form/types";
import { DiscardIcon, SaveIcon } from "../../../../../../icons/Icons";
import { ProfileComponent } from "../../../../../ProfilePage/assets";
import { validateEmails } from "../../../../../ProfilePage/assets/helper";
import { NotificationAlertType, User } from "../../../../../../types";
import { deepEqual } from "../../../helper";
import { UserConfigurationContext } from "../../../hooks";
import { UserConfigItem } from "../../../types";
import { cancel, close } from "../helper";

type UserConfigProfileComponentType = {
  form: FormParams<User>;
  setUserChanged: React.Dispatch<React.SetStateAction<boolean>>;
  origUser: User;
  isLoading: boolean;
  setItems: React.Dispatch<React.SetStateAction<UserConfigItem[]>>;
  origConfigItems: UserConfigItem[];
};

function UserConfigProfileComponent({
  form,
  setUserChanged,
  origUser,
  isLoading,
  setItems,
  origConfigItems,
}: UserConfigProfileComponentType) {
  const { t } = useTranslation();
  const { setEditedUserConfigId } = useContext(UserConfigurationContext);
  const { user } = useContext(AppContext);

  const isSuperUser = user?.superUser;

  function setUserNotifications(
    info: NotificationAlertType,
    todo: NotificationAlertType,
    signature: NotificationAlertType
  ) {
    form.setFieldValue("notificationInformation", info);
    form.setFieldValue("notificationToDo", todo);
    form.setFieldValue("notificationSignature", signature);
  }

  function validateLength() {
    let phoneNumberLength = form.values.phoneNumber
      ? form.values.phoneNumber.length
      : 0;
    return (
      (phoneNumberLength < 9 && phoneNumberLength !== 0) ||
      ([
        form.values.notificationSignature,
        form.values.notificationToDo,
        form.values.notificationInformation,
      ].includes("sms") &&
        phoneNumberLength === 0)
    );
  }

  function isValidForm(): boolean {
    return !validateLength() && validateEmails(form.values.email);
  }

  function checkChanges() {
    setUserChanged(!deepEqual(form.values, origUser));
  }

  return (
    <div onClick={() => checkChanges()}>
      <ProfileComponent
        form={form}
        setUserNotifications={setUserNotifications}
      />
      <div className={"is-flex"}>
        <div className={"actions-column is-flex"}>
          {isSuperUser ? (
            <>
              <RoundedButton
                label={t("discardChanges")}
                icon={<DiscardIcon />}
                disabled={isLoading}
                buttonClass={"is-danger"}
                onClick={() => {
                  form.resetForm();
                  cancel(setItems, origConfigItems);
                }}
              />
              <RoundedButton
                label={t("saveChanges")}
                icon={<SaveIcon />}
                loading={isLoading || form.isSubmitting}
                onClick={form.handleSubmit}
                disabled={!isValidForm()}
              />
            </>
          ) : (
            <RoundedButton
              label={t("close")}
              onClick={() => close(setEditedUserConfigId)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default UserConfigProfileComponent;
