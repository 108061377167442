import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import * as PortalApi from "../../../api/portalApi";

export function CodeElementComponent({
  codeBaseId,
  codeElement,
}: {
  codeBaseId: string;
  codeElement: string | string[];
}) {
  const [result, setResult] = useState<string | undefined>();
  const { lang } = useContext(AppContext);

  useEffect(() => {
    if (codeBaseId && codeElement) {
      Promise.all(
        (typeof codeElement == "string" ? [codeElement] : codeElement).map(
          (ce) => PortalApi.getCodeElementUniv(codeBaseId, ce)
        )
      ).then((res) => {
        setResult(res.map((univ) => (univ ? univ[lang] : null)).join(","));
      });
    }
  }, [codeBaseId, codeElement]);

  return <div>{result}</div>;
}
