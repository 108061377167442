import React, { useContext, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";

import * as API from "../../api/tradeFinanceApi";
import {
  drawingsDataHook,
  feesDataHook,
  useClickOutsideHandler,
} from "./assets/hooks";
import { Action, Table } from "./assets";
import { AppContext } from "../../App";
import moment from "moment";
import { NotificationComponent } from "./assets/Notification/Notification";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "./ReactTable.scss";
import { AmountBetweenFilter, DateBetweenFilter } from "./assets/Table/assets";
import { Info } from "../../icons/Icons";
import { StateComponent } from "./assets/StateComponent/StateComponent";
import { Column } from "react-table";
import ReactDOM from "react-dom";
import {
  DrawingsProps,
  FeesProps,
  TransactionDetailsComponentProps,
} from "./assets/interfaces";
import { CurrencyNumberFormat } from "..";

const ReactTable = ({
  tradeFinance,
  isFees,
  tradeFinanceType,
}: TransactionDetailsComponentProps) => {
  const tradeFinanceId = tradeFinance.id;
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);

  const transactionDetailsQuery = useQuery(
    tradeFinanceId && ["transaction-details", tradeFinanceId],
    (_) => {
      return API.getTransactionDetails(tradeFinanceId);
    }
  );

  const feesData = useMemo(
    () => transactionDetailsQuery.data?.fees,
    [transactionDetailsQuery.data]
  );
  const drawingEventsData = useMemo(
    () => transactionDetailsQuery.data?.drawingEvents,
    [transactionDetailsQuery.data]
  );

  let customSort = (rowA: any, rowB: any, columnId: any) => {
    let a = rowA.values[columnId][0];
    let b = rowB.values[columnId][0];

    if (a === "" || a === null) {
      if (b === "" || b === null) {
        return 0; // Both empty/null
      }
      return -1; // Sort a to an index lower than b
    }

    if (b === "" || b === null) {
      if (a === "" || a === null) {
        return 0; // Both empty/null
      }
      return 1; // Sort b to an index lower than a
    }

    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  };

  const feesColumn: any = useMemo(
    () => [
      {
        Header: t("transactionDetails.fees.description"),
        accessor: "description",
        Cell: (props: any) => {
          if (props.value === "otherBankFee")
            return t("transactionDetails.fees.otherBankFee");
          return props.value;
        },
      },
      {
        Header: t("transactionDetails.fees.event"),
        accessor: "event",
        className: "event",
        Cell: (props: any) => {
          if (props.value === "" || props.value === undefined) return null;
          return (
            <div className={classNames("cell-container--event")}>
              <div className={classNames("cell-border", "blue")}>
                {props.value}
              </div>
            </div>
          );
        },
      },
      {
        Header: t("transactionDetails.fees.event-no"),
        accessor: "eventNumber",
      },
      {
        Header: t("transactionDetails.fees.accountno"),
        accessor: "accountNumber",
      },
      {
        Header: t("transactionDetails.fees.amount"),
        accessor: "amount",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return (
            <>
              <CurrencyNumberFormat
                value={props.value}
                currency={props.row.original.currency}
                displayType={"text"}
              />
              {" " + props.row.original.currency}
            </>
          );
        },
        Filter: AmountBetweenFilter,
        filter: "between",
        sortType: "basic",
      },
      {
        Header: t("transactionDetails.fees.dueDate"),
        accessor: "dueDate",
        Cell: (props: any) => {
          const wrapperRef = useRef(null);
          const [info, setInfo] = useState(false);
          const infoIconChecker = props.row.original.accountingTransaction;
          useClickOutsideHandler(wrapperRef, setInfo, false);
          const date = moment(props.value).format("DD.MM.YYYY");
          if (props.value === undefined) return "";
          const current = ReactDOM.findDOMNode(wrapperRef.current);
          return (
            <div className={classNames("dueDate")}>
              {date}
              {infoIconChecker && (
                <>
                  <span
                    ref={wrapperRef}
                    className={classNames("pointer")}
                    onClick={() => setInfo(!info)}
                  >
                    <Info />
                  </span>
                  {info && (
                    <div
                      className="dueDate-tooltip"
                      style={{
                        position: "fixed",
                        top: (current as Element).getBoundingClientRect().top,
                        left: (current as Element).getBoundingClientRect().left,
                      }}
                    >
                      {t("transactionDetails.fees.informationMessage")}
                    </div>
                  )}
                </>
              )}
            </div>
          );
        },
        Filter: (column: Column) => DateBetweenFilter(column, true),
        filter: "between",
        disableGlobalFilter: true,
      },
      {
        Header: t("transactionDetails.fees.details"),
        accessor: "details",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return (
            <div className={classNames("pointer")}>
              <NotificationComponent
                notif={props.value}
                title={t("transactionDetails.fees.details")}
              />
            </div>
          );
        },
      },
      {
        accessor: "currency",
      },
      {
        accessor: "stringDueDate",
      },
      {
        accessor: "stringAmount",
      },
    ],
    [t]
  );

  const drawingsColumn: any = useMemo(
    () => [
      {
        Header: t("transactionDetails.drawings.number"),
        accessor: "drawingNumber",
      },
      {
        Header: t("transactionDetails.drawings.amount"),
        accessor: "drawingAmount",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return (
            <span className="drawingAmount">
              <CurrencyNumberFormat
                value={props.value}
                currency={props.row.original.currency}
                displayType={"text"}
              />
              {" " + props.row.original.currency}
            </span>
          );
        },
        Filter: AmountBetweenFilter,
        filter: "between",
      },
      {
        Header: t("transactionDetails.drawings.state"),
        accessor: "state",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return <StateComponent value={t(props.value)} />;
        },
      },
      {
        Header: t("transactionDetails.drawings.date"),
        accessor: "date",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return moment(props.value).format("DD.MM.YYYY");
        },
        disableGlobalFilter: true,
      },
      {
        Header: t("transactionDetails.drawings.valueDate"),
        accessor: "valueDate",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return props.value.map((item: number, idx: number) => (
            <div key={idx}>{moment(item).format("DD.MM.YYYY")}</div>
          ));
        },
        Filter: (column: any) => DateBetweenFilter(column, false),
        filter: "between",
        disableGlobalFilter: true,
      },
      {
        Header: t("transactionDetails.drawings.invoiceNumber"),
        accessor: "invoiceNumber",
        Cell: (props: any) => {
          if (props.value === undefined) return "";
          return <div className={classNames("breakLine")}>{props.value}</div>;
        },
      },
      {
        accessor: "accountNumber",
      },
      {
        Header: t("transactionDetails.drawings.details"),
        accessor: "details",
        Cell: (props: any) => {
          if (props.value === undefined || props.value.length === 0) return "";
          return (
            <div>
              {props.value.map((item: string, idx: number) => (
                <NotificationComponent
                  key={idx}
                  notif={item}
                  title={t("transactionDetails.drawings.details")}
                />
              ))}
            </div>
          );
        },
        sortType: customSort,
      },
      {
        Header: t("transactionDetails.actions"),
        accessor: "action",
        disableSortBy: true,
        Cell: (props: any) => {
          return (
            <Action
              action={props.row.original.action}
              tradeFinanceId={props.row.original.id}
              eventId={props.row.original.eventId}
            />
          );
        },
      },
      {
        accessor: "currency",
      },
      {
        accessor: "stringVauleDate",
      },
      {
        accessor: "stringDate",
      },
      {
        accessor: "stringDrawingAmount",
      },
    ],
    [t]
  );

  const data: any = useMemo(() => {
    return isFees
      ? feesDataHook(feesData as FeesProps[] | undefined, lang)
      : drawingsDataHook(
          drawingEventsData as DrawingsProps[] | undefined,
          tradeFinanceType !== undefined ? tradeFinanceType : ""
        );
  }, [drawingEventsData, feesData, isFees, lang, tradeFinanceType]);

  if (!transactionDetailsQuery.data) {
    if (transactionDetailsQuery.isLoading) {
      return <div className={"button is-white is-loading is-large is-flex"} />;
    }
    return null;
  }

  const columns: any = isFees ? feesColumn : drawingsColumn;
  const title: string = isFees
    ? t("transactionDetails.fees.title")
    : t("transactionDetails.drawings.title");

  const tfType = tradeFinance.tradeFinanceType;

  if (data.length === 0) return null;

  return (
    <div>
      <div className="table--title">{title}</div>
      <Table
        isFees={isFees ? true : false}
        columns={columns || {}}
        tradeFinanceType={tfType}
        data={data || []}
      />
    </div>
  );
};

export default ReactTable;
