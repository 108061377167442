import React from "react";
import { useHistory } from "react-router-dom";
import { RoundedButton } from "../../../components";
import { Plus } from "../../../icons/Icons";
import { TradeFinanceType } from "../../../types";

function NewTradeFinanceButton({
  tradeFinanceType,
  label,
}: {
  tradeFinanceType: TradeFinanceType;
  label: string;
}) {
  const history = useHistory();
  return (
    <RoundedButton
      icon={<Plus />}
      label={label}
      onClick={() =>
        history.push("/portal/template/new?type=" + tradeFinanceType)
      }
    />
  );
}

export default NewTradeFinanceButton;
