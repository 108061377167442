import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {RoundedButton} from "../Buttons";
import {useQuery} from "react-query";
import * as API from "../../api/tradeFinanceApi";
import {CloseIcon} from "../../icons/Icons";

export default function Teams() {

    const [isOpened, setIsOpened] = useState(false);

    const {t} = useTranslation();
    return <div id="teams">
        {isOpened && <TeamsPopup onClose={() => setIsOpened(false)}/>}
        <span onClick={() => setIsOpened(true)}>{t('onlineHelp')}</span>
    </div>
}

type TeamsPopupProps = {
    onClose: () => void;
}

function TeamsPopup(props: TeamsPopupProps) {
    const [showJoinLink, setShowJoinLink] = useState(false);
    const {t} = useTranslation();

    const teemsMeetingQuery = useQuery(["generateTeemsMeeting"], async (_) => {
            let joinLink = await API.generateTeamsMeetingUrl();
            if (joinLink.teamsOnlineUrl) {
                window.open(
                    joinLink.teamsOnlineUrl,
                    "_blank"
                );
            }
            return joinLink;
        },
        {enabled: showJoinLink}
    );

    const joinLink = teemsMeetingQuery.data?.teamsOnlineUrl;

    function linkInfo(linkInfoText: string, link: string) {
        const [part1, part2, part3] = linkInfoText.split(/<<(.*?)>>/);
        return (
            <span>
                {part1} <a className="link" target="_blank" href={link}>{part2}</a> {part3}
            </span>
        )
    }

    let content;
    if (teemsMeetingQuery.error) {
        content = (
            <div className="prompt">
                {t("teams.error")}
            </div>)
    } else if (!joinLink) {
        content = (
            <>
                <div className="prompt">
                    {t("teams.prompt")}
                </div>
                <div className="buttons-container">
                    <RoundedButton label={t("teams.initiateMeeting")} onClick={() => setShowJoinLink(true)}/>
                </div>
            </>
        );
    } else {
        content = (
            <div className="prompt">
                {linkInfo(t("teams.linkInfo"), joinLink)}
            </div>);
    }

    return (
        <div className="teams-popup">
            <CloseIcon className="close-icon" onClick={props.onClose}/>
            <div className="header"></div>
            <div className="content">
                {content}
            </div>
        </div>
    )
}