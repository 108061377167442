import { FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Report, ReportTemplate } from "../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";
import { TemplateIcon } from "../../../../icons/Icons";
import { usePopUp } from "../../../../components/PopUpComponents/assets/hooks";
import { PopUp, RoundedButton } from "../../../../components";

function SaveReportTemplateButton({ form }: { form: FormikProps<Report> }) {
  const { t } = useTranslation();
  const popUp = usePopUp(null);
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();
  const saveReportTemplate = useMutation(
    async (report: ReportTemplate) => {
      await TFApi.saveReportTemplate(report);
    },
    {
      onSuccess: (data) => {
        history.push("/portal/reports");
      },
    }
  );

  async function saveAsTemplate() {
    popUp.close();
    setError(false);
    const validationResult = await form.validateForm(form.values);
    if (!validationResult || Object.keys(validationResult).length === 0) {
      await saveReportTemplate.mutate(form.values);
    } else {
      setError(true);
    }
  }

  return (
    <>
      <RoundedButton
        label={t("report.actions_options.saveAsTemplate")}
        onClick={saveAsTemplate}
        ref={popUp.elementRef}
        disabled={saveReportTemplate.isLoading}
        icon={<TemplateIcon />}
      />

      <PopUp
        {...popUp}
        isOpened={error}
        content={t("mandatoryFieldsNotFilled")}
        close={() => setError(false)}
      />
    </>
  );
}

export default SaveReportTemplateButton;
