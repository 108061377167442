import * as React from "react";
import { useParams } from "react-router-dom";
import {
  TradeFinanceListProps,
  TradeFinanceRequestListType,
} from "./assets/types";
import { TradeFinanceList } from "./assets";

export default function TradeFinanceListPage(props: TradeFinanceListProps) {
  const { type } = useParams<{ type: TradeFinanceRequestListType }>();

  return (
    <div className="page trade-finance-list-page card-list-page">
      <TradeFinanceList key={type} type={type as TradeFinanceRequestListType} />
    </div>
  );
}
