import { FormikActions } from "formik";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../../../App";
import { ClaimData } from "../../../../../../types";
import { TradFinanceRequestContext } from "../../../context";
import {
  getCurrencyDecimals,
  getMissingSignatureCount,
  isEventWaitingForClient,
} from "../../../tradeFinanceUtils";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import * as TFAPi from "../../../../../../api/tradeFinanceApi";
import { signFunction } from "../../../apiFunctions";
import { DocumentsStep, Step } from "..";
import { StepId } from "../../../type";
import {
  getFieldProps,
  validateMandatoryFields,
} from "../../../../../../components/Form/helper";
import {
  CodeBaseField,
  DateField,
  NumberField,
  TextAreaField,
  TextField,
  RoundedButton,
  Form,
} from "../../../../../../components";
import { Signatures } from "../../../../../../icons/Icons";
import { FormParams } from "../../../../../../components/Form/types";

function ClaimStep() {
  const { reLoad, tradeFinance, underWritersInfo } = useContext(
    TradFinanceRequestContext
  );
  const history = useHistory();
  const claimData: ClaimData = tradeFinance.claimData;
  const { t } = useTranslation();
  const { userStatus, portalConfig } = useContext(AppContext);

  const currencyScale = useMemo(
    () => getCurrencyDecimals(userStatus, tradeFinance.currency),
    [userStatus, tradeFinance.currency]
  );

  async function finishClaim(
    claimData: ClaimData,
    { setSubmitting }: FormikActions<ClaimData>
  ) {
    if (tradeFinance.event) {
      const underWritersInfoAfterClaim = await TFApi.claim(
        claimData,
        tradeFinance.id,
        tradeFinance.event.id
      );
      await TFAPi.readyForSign(tradeFinance);
      if (
        underWritersInfoAfterClaim &&
        !underWritersInfoAfterClaim.completed &&
        underWritersInfoAfterClaim.canSign &&
        underWritersInfoAfterClaim.hasRightToSign
      ) {
        let missingSignatureCount = getMissingSignatureCount(
          underWritersInfoAfterClaim
        );
        await signFunction(
          tradeFinance,
          history,
          portalConfig.caas,
          missingSignatureCount === 1
        );
      }
      await reLoad();
      setSubmitting(false);
    }
  }

  if (!claimData || !tradeFinance.event) {
    return null;
  }

  const finishClaimDisabled =
    !!underWritersInfo || !isEventWaitingForClient(tradeFinance);

  return (
    <>
      <Step stepId={StepId.claim} title={t("tradeFinance.claim.title")}>
        <Form
          initialValues={
            (claimData
              ? { ...claimData, currency: tradeFinance.currency }
              : {}) as ClaimData
          }
          enableReinitialize={true}
          validateOnChange={false}
          validate={(values) => {
            return validateMandatoryFields(values, ["claimResult"], t);
          }}
          onSubmit={finishClaim}
        >
          {(form: FormParams<ClaimData & { currency?: string }>) => {
            const { isSubmitting, handleSubmit } = form;

            return (
              <div className="claim">
                <div className="columns">
                  <div className="column">
                    <NumberField
                      {...getFieldProps(
                        form,
                        "claimAmount",
                        t("tradeFinance.claim.claimAmount")
                      )}
                      disabled={true}
                      decimals={currencyScale}
                    />
                    <DateField
                      {...getFieldProps(
                        form,
                        "date",
                        t("tradeFinance.claim.date")
                      )}
                      disabled={true}
                    />
                  </div>
                  <div className="column">
                    <TextField
                      {...getFieldProps(
                        form,
                        "currency",
                        t("tradeFinance.currency")
                      )}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <div className="discrepancy">
                      <TextAreaField
                        {...getFieldProps(
                          form,
                          "claimDiscrepancies",
                          t("tradeFinance.claim.discrepancy")
                        )}
                        disabled={true}
                      />
                    </div>

                    {form.values.informationToClient && (
                      <TextAreaField
                        {...getFieldProps(
                          form,
                          "informationToClient",
                          t("tradeFinance.claim.informationToClient")
                        )}
                        disabled={true}
                      />
                    )}

                    {form.values.instructionForClient && (
                      <TextAreaField
                        {...getFieldProps(
                          form,
                          "instructionForClient",
                          t("tradeFinance.claim.instructionForClient")
                        )}
                        disabled={true}
                      />
                    )}
                    <CodeBaseField
                      codeBaseId={"tradefin/claimResult"}
                      {...getFieldProps(
                        form,
                        "claimResult",
                        t("tradeFinance.claim.claimResult")
                      )}
                      disabled={finishClaimDisabled || isSubmitting}
                      required={true}
                    />

                    <TextAreaField
                      {...getFieldProps(
                        form,
                        "otherInstructionEvent",
                        t("tradeFinance.claim.otherInstructionEvent")
                      )}
                      disabled={finishClaimDisabled || isSubmitting}
                    />
                  </div>
                </div>

                {/*{Document step for claim event - KBTF-4062 CR}*/}
                <DocumentsStep />

                {!finishClaimDisabled && (
                  <div className="portal-buttons has-right-align is-flex">
                    <RoundedButton
                      label={t("tradeFinance.signAndSend")}
                      loading={isSubmitting}
                      icon={<Signatures />}
                      onClick={handleSubmit}
                      disabled={finishClaimDisabled}
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Form>
      </Step>
    </>
  );
}

export default ClaimStep;
