import React, { useContext, useEffect, useState } from "react";
import { TradFinanceRequestContext } from "../context";
import {
  useFieldAndLayoutModifications,
  useTradeFinanceDetailsChanges,
} from "../hooks";
import {
  isClaimEvent,
  isLetterOfCredit,
  isPreCheckEvent,
} from "../tradeFinanceUtils";
import {
  ClaimStep,
  DocumentRequirementsStep,
  DocumentsStep,
  FinishRequestStep,
  PartnerDetailsStep,
  PreCheckStep,
  ReferenceStep,
  RequestDetailsStep,
  SaveTemplateStep,
  SignStep,
  Steps,
  InstallmentsStep,
} from "./assets";

function TradeFinanceSteps() {
  const tfinContext = useContext(TradFinanceRequestContext);
  const { tradeFinance, setUnsavedModification, form, loadDocumentsAction } =
    tfinContext;
  const tfChanges = useTradeFinanceDetailsChanges();

  const [updatedPartnerDetailsLayout, requestDetailsLayout] =
    useFieldAndLayoutModifications();
  const [showFinalDocument, setShowFinalDocument] = useState<boolean>(false);

  useEffect(() => {
    if (
      tradeFinance.event?.state === "waitingForClient" ||
      tradeFinance.event?.state === "clientDecision" ||
      tradeFinance.state === "concept" ||
      tradeFinance.state === "template"
    ) {
      if (
        tradeFinance.event?.state === "clientDecision" &&
        loadDocumentsAction.result?.length === 0
      ) {
        setUnsavedModification(false);
      } else {
        setUnsavedModification(
          tfChanges.partnerDetailsChanged ||
            tfChanges.requestDetailsChanged ||
            (!tradeFinance.event?.eventDescRef.includes("cancelEvent") &&
              tradeFinance.event?.state !== "clientDecision" &&
              tradeFinance.event?.state !== "waitingForClient" &&
              tfChanges.referenceNoChanged) ||
            tfChanges.documentRequirementChanged ||
            tfChanges.eventChanged
        );
      }
    } else {
      setUnsavedModification(false);
    }
  }, [tfChanges, loadDocumentsAction.result]);

  return (
    <Steps key={tradeFinance.id}>
      {!(isLetterOfCredit(tradeFinance) && isClaimEvent(tradeFinance)) && (
        <>
          <ReferenceStep changed={tfChanges.referenceNoChanged} />
          {!isPreCheckEvent(tradeFinance) && (
            <PartnerDetailsStep
              changed={tfChanges.partnerDetailsChanged}
              layout={updatedPartnerDetailsLayout}
            />
          )}
          {!isPreCheckEvent(tradeFinance) && (
            <RequestDetailsStep
              changed={
                tfChanges.requestDetailsChanged || tfChanges.eventChanged
              }
              layout={requestDetailsLayout}
            />
          )}
          <InstallmentsStep />
          {!isPreCheckEvent(tradeFinance) && <DocumentRequirementsStep />}
          <PreCheckStep />
          <DocumentsStep />
          <SaveTemplateStep
            hasUnsavedChanges={
              tfChanges.partnerDetailsChanged || tfChanges.requestDetailsChanged
            }
          />
          <FinishRequestStep
            hasUnsavedChanges={
              tfChanges.partnerDetailsChanged || tfChanges.requestDetailsChanged
            }
            showFinalDocument={showFinalDocument}
            setShowFinalDocument={setShowFinalDocument}
          />
        </>
      )}
      <ClaimStep />
      <SignStep
        showFinalDocument={showFinalDocument}
        setShowFinalDocument={setShowFinalDocument}
      />
    </Steps>
  );
}

export default TradeFinanceSteps;
