import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as React from "react";
import { Suspense } from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

export async function initApp() {
  ReactDOM.render(
    <Suspense fallback={"Loading..."}>
      <App />
    </Suspense>,
    document.getElementById("app") as HTMLElement
  );
}

initApp();
