import classnames from "classnames";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NewRequestButtons } from "..";
import { DeviceType, useDeviceType } from "../../../../../tradeFinanceHooks";
import { TradeFinanceRequestListType } from "../../../types";

function NewRequestComponent({
  sortOptionField,
}: {
  sortOptionField: ReactNode;
}) {
  const { type } = useParams<{ type: TradeFinanceRequestListType }>();
  const { t } = useTranslation();
  const device = useDeviceType();

  let buttons: ReactNode = null;
  switch (type) {
    case "letterOfCredit":
      buttons = (
        <NewRequestButtons
          tradeFinanceType="tradefin/importLC"
          label={t("tradeFinance.newRequest")}
        />
      );
      break;
    case "guarantees":
      buttons = (
        <NewRequestButtons
          tradeFinanceType="tradefin/IssuedGuarantee"
          label={t("tradeFinance.newRequest")}
        />
      );
      break;
    case "collection":
      buttons = (
        <NewRequestButtons
          tradeFinanceType="tradefin/exportCollection"
          label={t("tradeFinance.newRequest")}
        />
      );
      break;
    case "standby":
      buttons = (
        <NewRequestButtons
          tradeFinanceType="tradefin/outgoingStandbyBG"
          label={t("tradeFinance.newRequest")}
        />
      );
      break;
  }

  if (!type) {
    return null;
  }

  return (
    <div className={"page-header is-flex"}>
      <div className={"page-title"}>{t("menu." + type)}</div>
      {buttons && <div className="portal-buttons">{buttons}</div>}

      {type === "ongoing" && (
        <div
          className={classnames("is-flex is-right", {
            "is-ongoing": device !== DeviceType.desktop,
          })}
        >
          {sortOptionField}
        </div>
      )}
    </div>
  );
}

export default NewRequestComponent;
