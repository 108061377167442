import classnames from "classnames";
import React from "react";

type ActionButtonType = {
  originalValue: string;
  newValue: string;
  isOriginalValue: boolean;
  setFirstTabOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
  setOrigValue: React.Dispatch<React.SetStateAction<string>>;
  setNewValue: React.Dispatch<React.SetStateAction<string>>;
};

function ActionButton({
  originalValue,
  newValue,
  isOriginalValue,
  setFirstTabOpened,
  setEnlarged,
  setOrigValue,
  setNewValue,
}: ActionButtonType) {
  return (
    <td
      className={classnames("enlarged-field", {
        "is-bold": !isOriginalValue,
      })}
    >
      <div
        className={"action-button"}
        onClick={() => {
          setFirstTabOpened(isOriginalValue);
          setEnlarged(true);
          setOrigValue(originalValue);
          setNewValue(newValue);
        }}
      >
        <span>{isOriginalValue ? originalValue : newValue}</span>
      </div>
    </td>
  );
}

export default ActionButton;
