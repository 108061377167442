// @ts-nocheck
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Redirect } from "react-router-dom";
import { AppContext } from "../../../../../../App";
import { RoundedButton } from "../../../../../../components";
import { Copy, Plus, TemplateIcon } from "../../../../../../icons/Icons";
import { TradeFinance } from "../../../../../../types";
import { useCreateNewRequest } from "../../../hooks";
import { NewRequestButtonProps } from "../../../types";

function NewRequestButtons({ tradeFinanceType, label }: NewRequestButtonProps) {
  const { refreshStats } = useContext(AppContext);
  const { t } = useTranslation();

  // const [createRequest, {status, data: createRequestResult}] = useCreateNewRequest()
  const createRequest = useCreateNewRequest();

  if (createRequest.data) {
    refreshStats();
    return (
      <Redirect
        to={`/portal/trade-finance-request/${createRequest.data.id}`}
        push={true}
      />
    );
  }

  return (
    <div className="portal-buttons create-buttons">
      <RoundedButton
        label={label}
        icon={<Plus />}
        loading={createRequest.isLoading}
        onClick={async () =>
          createRequest.mutate({
            tradeFinance: { tradeFinanceType } as TradeFinance,
            copy: false,
          })
        }
      />

      <NavLink
        to={`/portal/trade-finance-request-create/copyFrom?type=${tradeFinanceType}`}
      >
        <RoundedButton label={t("tradeFinance.copyFrom")} icon={<Copy />} />
      </NavLink>

      <NavLink
        to={`/portal/trade-finance-request-create/template?type=${tradeFinanceType}`}
      >
        <RoundedButton
          label={t("tradeFinance.useTemplate")}
          icon={<TemplateIcon />}
        />
      </NavLink>
    </div>
  );
}

export default NewRequestButtons;
