import * as React from "react";
import { useTranslation } from "react-i18next";
import { Emails, Phone } from "../../icons/Icons";
import { useContext } from "react";
import { AppContext } from "../../App";

function ContactsPopup() {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();

  const company = user?.defaultCompany;
  let formatPhoneNumber = (str: string) => {
    if (str.length === 9) {
      return str.replace(/(\d{3})(\d{3})(\d{3})$/, "$1 $2 $3");
    }
    return str;
  };

  return (
    <div className={"contacts-popup"}>
      <div className={"contacts-header"}>
        <div className={"columns"}>
          <div className={"column is-6"}>
            <div className={"popup-title"}>{t("contacts.techSupport")}</div>
            <div>24 / 7</div>
          </div>
          <div className={"column is-6 is-flex"}>
            <div className={"is-flex phone-info"}>
              <Phone /> 800 521 521
            </div>
          </div>
        </div>
      </div>

      {company && (
        <>
          <div className={"popup-title"}>{t("contacts.productSupport")}</div>

          <div className={"columns"}>
            <div className={"column is-6"}>
              <div className={"name-info"}>
                {company.foreignTradeFinSpecName || "(no name)"}
              </div>
              <div>{t("contacts.openingHours")}</div>
            </div>
            <div className={"column is-6"}>
              <div className={"is-flex phone-info"}>
                {company.foreignTradeFinSpecPhone && (
                  <>
                    <Phone />{" "}
                    {formatPhoneNumber(company.foreignTradeFinSpecPhone)}
                  </>
                )}
              </div>
              <div className={"is-flex email-info"}>
                <Emails />
                {company.foreignTradeFinSpecEmail}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ContactsPopup;
