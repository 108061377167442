// @ts-nocheck
import * as React from "react";
import { Beneficiary, Partner } from "../../../types";
import { Form } from "../../../components";
import { validateMandatoryFields } from "../../../components/Form/helper";
import * as API from "../../../api/tradeFinanceApi";
import { useLoadingAction } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { FormikActions } from "formik";
import classnames from "classnames";
import { useState } from "react";
import { useQuery } from "react-query";

import { findBeneficiaryByRegNumberAndName } from "../../TradeFinanceRequestDetailsPage/assets/helper";
import { RenderForm } from "./assets";
import { PageHeader } from "../../../components";

export default function BeneficiaryDetailsPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id: beneficiaryId } = useParams();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const isNew = beneficiaryId === "new";
  const { result: beneficiary } = useLoadingAction<
    Beneficiary | undefined
  >(async () => {
    if (isNew) {
      return {} as Beneficiary;
    } else if (beneficiaryId) {
      return API.findBeneficiaryById(beneficiaryId);
    }
  }, [beneficiaryId]);

  const [search, setSearch] = useState<string | null | undefined>();
  let loadingAction = useQuery(["beneficiaries", { search }], async (_) =>
    API.findBeneficiaries(search)
  );

  if (!beneficiary) {
    return null;
  }

  async function submitBeneficiary(
    values: Beneficiary,
    actions: FormikActions<Beneficiary>
  ) {
    try {
      Object.assign(beneficiary as Beneficiary, values);
      if (!isNew) {
        const partner = findBeneficiaryByRegNumberAndName(
          loadingAction.data as Partner[],
          values.name,
          values.partnerSwiftAddress,
          values.partnerRegistrationNumber
        );

        if (partner && partner.id !== beneficiary?.id) {
          setErrorMessage(true);
        } else {
          const { id } = await API.saveBeneficiary({
            ...beneficiary,
            id: isNew ? undefined : beneficiaryId,
          });
          history.push(`/portal/beneficiaries`);
        }
      } else {
        if (
          !findBeneficiaryByRegNumberAndName(
            loadingAction.data as Partner[],
            values.name,
            values.partnerSwiftAddress,
            values.partnerRegistrationNumber
          )
        ) {
          const { id } = await API.saveBeneficiary({
            ...beneficiary,
            id: isNew ? undefined : beneficiaryId,
          });
          setErrorMessage(false);
          history.replace(`/portal/beneficiaries`);
        } else {
          setErrorMessage(true);
        }
      }
      actions.setSubmitting(false);
    } catch (e) {
      actions.setError(e.message); // FIXME
    }
  }

  return (
    <div className="container">
      <PageHeader
        title={isNew ? t("tradeFinance.newPartner") : beneficiary?.name || ""}
      />
      <div className={classnames("contact-settings", "box")}>
        <Form
          initialValues={beneficiary}
          validateOnBlur={true}
          validate={(values) =>
            validateMandatoryFields(values, ["partnerSwiftAddress", "name"], t)
          }
          onSubmit={submitBeneficiary}
        >
          {(form) => (
            <RenderForm
              beneficiaryForm={form}
              isNew={isNew}
              errorMessage={errorMessage}
            />
          )}
        </Form>
      </div>
    </div>
  );
}
