import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  Row,
  Cell,
  HeaderGroup,
} from "react-table";
import { IndeterminateCheckbox } from "../IndeterminateCheckbox/IndeterminateCheckbox";
import { TableProps } from "../interfaces";
import {
  ColumnHideComponent,
  GlobalFilter,
  Pagination,
  SortArrowComponent,
} from "./assets";

import "./Table.scss";

export const Table = <T extends { id: string }>({
  columns,
  data,
  isFees,
  tradeFinanceType,
}: TableProps<T>): React.ReactElement => {
  const { t } = useTranslation();
  const filterTypes = useMemo(
    () => ({
      text: (rows: Row<T>[], id: any, filterValue: string | undefined) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const specailAlphabeticShort = (id: string) => {
    const sortFunc = (a: any, b: any) => {
      const a_d = a.values[id] === undefined ? "" : a.values[id];
      const b_d = b.values[id] === undefined ? "" : b.values[id];
      return a_d.localeCompare(b_d);
    };
    return sortFunc;
  };

  const specailAlphabeticShortInArray = (id: string) => {
    const sortFunc = (a: any, b: any) => {
      const a_d = a.values[id][0] === undefined ? "" : a.values[id][0];
      const b_d = b.values[id][0] === undefined ? "" : b.values[id][0];
      return a_d.localeCompare(b_d);
    };
    return sortFunc;
  };

  const sortingClickHandler = (e: HeaderGroup<T>) => {
    if (e.id === "details") {
      e.sortType = Array.isArray(e.preFilteredRows[0].values.details)
        ? specailAlphabeticShortInArray(e.id)
        : specailAlphabeticShort(e.id);
    }
    if (e.id === "state") e.sortType = specailAlphabeticShort(e.id);
    if (e.id === "description") e.sortType = specailAlphabeticShort(e.id);
    if (e.id === "event") e.sortType = specailAlphabeticShort(e.id);
    if (e.id === "action") return;
    e.toggleSortBy();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    dispatch,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: [
          "currency",
          "stringVauleDate",
          "stringDueDate",
          "stringAmount",
          "stringDate",
          "stringDrawingAmount",
          "accountNumber",
        ],
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: Cell) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
      ]);
    }
  );

  return (
    <div className={classNames("react-table")}>
      <div className={classNames("table-filterSection")}>
        <div className={classNames("table-top")}>
          <GlobalFilter
            className={classNames("table-globalFilter")}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <ColumnHideComponent allColumns={allColumns} />
        </div>

        <div className={classNames("table-bottom")}>
          <div className={classNames("table-bottom--filters")}>
            {headerGroups[0].headers
              .filter(
                (item) => item.id === "valueDate" || item.id === "dueDate"
              )
              .map((item) => (
                <div key={item.id}>{item.render("Filter")}</div>
              ))}
            {headerGroups[0].headers
              .filter(
                (item) => item.id === "amount" || item.id === "drawingAmount"
              )
              .map((item) => (
                <div key={item.id}>{item.render("Filter")}</div>
              ))}
          </div>
          <div className={classNames("table-bottom--buttons")}>
            {headerGroups[0].headers.some(
              (item) => item.filterValue !== undefined
            ) && (
              <button
                onClick={() => {
                  dispatch({ type: "resetFilters" });
                }}
              >
                {t("transactionDetails.fees.cancel")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={classNames("table-container")}>
        <table
          className={classNames("table", "table-table")}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (
                    !tradeFinanceType.endsWith("importLC") &&
                    column.id === "action"
                  )
                    return null;
                  return (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                      onClick={() => sortingClickHandler(column)}
                      className={classNames(
                        "table-checkbox",
                        `table-th-${column.id}`
                      )}
                    >
                      <div className={classNames("table-headerContent")}>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortArrowComponent isDown />
                          ) : (
                            <SortArrowComponent isUp />
                          )
                        ) : (
                          <SortArrowComponent />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (
                      !tradeFinanceType.endsWith("importLC") &&
                      cell.column.id === "action"
                    )
                      return null;
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={classNames(`table-td-${cell.column.id}`)}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        isFees={isFees ? true : false}
        selectedFlatRows={selectedFlatRows}
        setPageSize={setPageSize}
      />
    </div>
  );
};
