import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import {
  getFieldProps,
  validateMandatoryFields,
} from "../../../../components/Form/helper";
import { Company } from "../../../../types";
import { CompanyProfileProps } from "../types";
import { useFormRef } from "../hooks";
import { ProfileUpdateMessage } from "..";
import { TextAreaField, TextField, Form } from "../../../../components";
import { FormParams } from "../../../../components/Form/types";

function CompanyProfile({ formRef }: CompanyProfileProps) {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { userStatus } = useContext(AppContext);
  const user = userStatus!.user;

  function CompanyForm(form: FormParams<Company>) {
    const { error } = form;
    useFormRef(formRef, form);

    return (
      <>
        <ProfileUpdateMessage
          showMessage={showMessage}
          onClose={() => setShowMessage(false)}
        />
        {error && <div className="errors">{error}</div>}

        <div className={"data-box"}>
          <div className="data-box--title is-flex">
            {t("profile.basicInfo")}
          </div>
          <div className={"data-box--body"}>
            <div className="columns">
              <div className="column">
                <TextField
                  {...getFieldProps(
                    form,
                    "companyName",
                    t("profile.companyProfile.companyName")
                  )}
                  disabled={true}
                />
                <TextField
                  {...getFieldProps(
                    form,
                    "companyStatId",
                    t("profile.companyProfile.registrationNumber")
                  )}
                  disabled={true}
                />
                <TextAreaField
                  {...getFieldProps(
                    form,
                    "registeredOffice",
                    t("profile.companyProfile.registeredOffice")
                  )}
                  disabled={true}
                  disableEnlarge={true}
                />
                <TextField
                  {...getFieldProps(
                    form,
                    "fileNumber",
                    t("profile.companyProfile.fileNumber")
                  )}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="contact-settings">
      <Form
        initialValues={user.defaultCompany!}
        validateOnChange={false}
        validate={(values) => validateMandatoryFields(values, [], t)}
        onSubmit={() => {}}
      >
        {(form) => <CompanyForm {...form} />}
      </Form>
    </div>
  );
}

export default CompanyProfile;
