import * as React from "react";
import classnames from "classnames";
import { PortalCardProps } from "./assets/types";

function PortalCard(props: PortalCardProps) {
  const { icon, title, subtitle, className, style } = props;
  return (
    <div className={classnames("portal-card", className)} style={style}>
      <div className="portal-card-header">
        {icon}
        <div>
          {title && <div className="portal-card-title">{title}</div>}
          {subtitle && <div className="portal-card-subtitle">{subtitle}</div>}
        </div>
      </div>
      <div className="portal-card-content">{props.children}</div>
    </div>
  );
}
export default PortalCard;
