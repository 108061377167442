import React from "react";
import { useParams } from "react-router-dom";
import * as API from "../../../api/tradeFinanceApi";
import { useQuery } from "react-query";
import { ReportPage } from "..";
import { Report } from "../types";

export function ReportDetailsPage() {
  const { reportId } = useParams<{ reportId: string }>();
  const loadReportQuery = useQuery(
    ["reportById", { reportId }],
    async (_) => {
      return API.getReport(reportId);
    },
    { enabled: reportId !== "new" }
  );

  if (loadReportQuery.isLoading) {
    return null;
  }

  return (
    <ReportPage report={loadReportQuery.data as Report} isEditable={false} />
  );
}
