import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Form, HeaderComponent} from "../../components";
import {FieldMap} from "../../components/Form/types";
import {collectFields, hasError, validateMandatoryFields,} from "../../components/Form/helper";
import {UseActionResult, useLoadingAction, useMounted} from "../../hooks";
import * as TFApi from "../../api/tradeFinanceApi";
import * as TFAPi from "../../api/tradeFinanceApi";
import {TradeFinanceDetail} from "../../api/tradeFinanceApi";
import {
    Collection,
    Comment,
    DocumentRequirement,
    LetterOfCredit,
    Notification,
    Template,
    TFDocument,
    TradeFinance,
    TradeFinanceType,
} from "../../types";
import {StepId} from "./assets/type";
import {
    isAmendment,
    isCancelEvent,
    isCollection,
    isConcept,
    isGuarantee,
    isImportLetterOfCredit,
    isLetterOfCredit,
    isPreCheckEvent,
    isTradeFinanceEditable,
    showEventHistory,
} from "./assets/tradeFinanceUtils";
import {useIsTemplate} from "./assets/hooks";
import {EventHistory} from "../../components/EventHistoryTab/assets/type";
import {FormikActions, FormikErrors} from "formik";
import moment from "moment";
import {hasDocumentError, today} from "./assets/helper";
import {TradFinanceRequestContext} from "./assets/context";
import {FloatingSave, TradeFinanceContentLayout} from "./assets";
import {SwiftFieldProps} from "../../components/Fields/types";
import {validateSwiftFieldValue} from "../../components/Fields/helper";
import {AppContext} from "../../App";

export default function TradeFinanceRequestDetailsPage() {
    // @ts-ignore
    const {id} = useParams();
    const location = useLocation();
    const {t} = useTranslation();
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const mounted = useMounted();
    const searchParams = new URLSearchParams(location.search);
    const tradeFinanceType: TradeFinanceType | null = searchParams.get(
        "type"
    ) as TradeFinanceType;
    const copyId: string | null = searchParams.get("copy");
    const eventId = searchParams.has("event")
        ? parseInt(searchParams.get("event")!)
        : undefined;
    const [openedSteps, setOpenedSteps] = useState<StepId[]>([]);
    const savedStep = useRef<
        | StepId.requestDetails
        | StepId.clientDetails
        | StepId.preCheck
        | StepId.reference
    >();
    const [formFields, setFormFields] = useState<FieldMap<TradeFinanceDetail>>(
        {} as FieldMap<TradeFinanceDetail>
    );
    const {portalConfig} = useContext(AppContext);

    const isNew = id === "new";

    const loadTradeFinanceAction = useQuery(
        ["tradeFinanceById", {id, eventId}],
        async (_) => {
            const isTemplate = location.pathname.includes("/template/");
            return await TFApi.findTradeFinance(
                copyId ? copyId : id!,
                tradeFinanceType,
                eventId,
                copyId != null,
                isTemplate
            );
        }
    );

    const showTransactionDetails =
        !eventId && !isConcept(loadTradeFinanceAction.data?.tradeFinance!);

    const loadDocumentRequirementsAction: UseActionResult<DocumentRequirement[]> =
        useLoadingAction<DocumentRequirement[]>(async () => {
            if (!showTransactionDetails || (isTemplate && !isNew)) {
                return TFApi.getTradeFinanceDocumentRequirements(id!);
            } else {
                return [];
            }
        }, [id, showTransactionDetails]);

    const [createNewPartnerOption, setNewPartnerOption] =
        useState<boolean>(false);
    const loadDocRequirementTemplates: UseActionResult<Template[] | undefined> =
        useLoadingAction<Template[] | undefined>(() =>
            TFApi.getDocumentRequirementTemplates()
        );
    const loadPartnersAction = useLoadingAction(
        () => (!showTransactionDetails || isTemplate ? TFApi.getPartners() : []),
        [showTransactionDetails]
    );

    const [tradeFinance, setTradeFinance] = useState<TradeFinance | null>();
    const isTemplate = useIsTemplate(tradeFinance);
    const [origTradeFinance, setOrigValue] = useState({
        ...tradeFinance,
    } as TradeFinanceDetail);

    const template = useIsTemplate(tradeFinance);
    const [isUnsavedTerm, setUnsavedTerm] = useState<boolean>(false);
    const [hasMaturityError, setMaturityError] = useState<boolean>(false);
    const [hasSignError, setSignError] = useState<boolean>(false);
    const [hasUnsavedModification, setUnsavedModification] =
        useState<boolean>(false);

    const [docsToUpload, setDocsToUpload] = useState<TFDocument[]>([]);

    const loadNotificationsAction = useLoadingAction<Notification[]>(() => {
        if (id && !isNew && !isTemplate) {
            return TFApi.fetchNotificationsForTradeFinance(id);
        }
        return [];
    }, [id, isTemplate]);

    useEffect(() => {
        setOrigValue({...tradeFinance} as TradeFinanceDetail);
    }, [tradeFinance]);

    const loadEventHistoryAction: UseActionResult<EventHistory[]> =
        useLoadingAction<EventHistory[]>(() => {
            if (!isNew && tradeFinance && showEventHistory(tradeFinance)) {
                return TFApi.getTradeFinanceHistory(id as string);
            }
            return [];
        }, [tradeFinance]);

    const loadMessageHistoryAction: UseActionResult<EventHistory[]> =
        useLoadingAction<EventHistory[]>(() => {
            if (!isNew && tradeFinance && showEventHistory(tradeFinance)) {
                return TFApi.getMessageEventHistory(id as string);
            }
            return [];
        }, [tradeFinance]);

    const loadDocumentsAction = useLoadingAction(() => {
        if (!isTemplate && !showTransactionDetails) {
            return tradeFinance && tradeFinance.id
                ? TFApi.getTradeFinanceDocuments(
                    tradeFinance.id,
                    tradeFinance.event?.id
                )
                : Promise.resolve([]);
        }
        return [];
    }, [tradeFinance, showTransactionDetails]);

    const loadCommentsAction = useLoadingAction<
        Comment[] | undefined
    >(async () => {
        if (tradeFinance && tradeFinance.id) {
            const res = await TFAPi.getTradeFinanceComments(tradeFinance.id, eventId);
            if (!portalConfig?.caas) {
                await reLoad();
            }
            return res;
        }
        return undefined;
    }, [tradeFinance]);

    useEffect(() => {
        let res = loadTradeFinanceAction.data;
        setTradeFinance(res ? res.tradeFinance : null);
    }, [loadTradeFinanceAction.data]);

    function openStep(step: StepId) {
        if (!openedSteps.includes(step)) {
            setOpenedSteps([...openedSteps, step]);
        }
    }

    function closeStep(step: StepId) {
        if (openedSteps.includes(step)) {
            setOpenedSteps(openedSteps.filter((s) => s !== step));
        }
    }

    async function reLoad() {
        await loadTradeFinanceAction.refetch();
    }

    if (
        !loadTradeFinanceAction.data ||
        !tradeFinance ||
        loadNotificationsAction.isLoading
    ) {
        return null;
    }

    const {clientDetailsLayout, requestDetailsLayout} =
        loadTradeFinanceAction.data;

    async function validate(
        tfDetails: TradeFinanceDetail,
        mandatoryFieldValidation?: boolean
    ): Promise<FormikErrors<TradeFinanceDetail>> {
        let errors: FormikErrors<LetterOfCredit> = {};
        if (mandatoryFieldValidation) {
            const fields = Object.values(formFields!)
                .filter((fieldProps) => fieldProps!.required && !fieldProps!.hidden)
                .map((fieldProps) => fieldProps!.id);

            if (!tfDetails.otherPartnerName) {
                fields.push("favouriteBeneficiary");
            }

            errors = validateMandatoryFields(tfDetails, fields as any, t);

            if (hasError(errors)) {
                return errors;
            }
        }

        if (isPreCheckEvent(tfDetails)) {
            const errors = validateMandatoryFields(
                tfDetails.event!,
                ["originOfGoodsEvent", "claimAmount"],
                t
            );
            if (hasError(errors)) {
                return {event: errors} as any;
            }
            return {};
        }

        if (
            tfDetails.dateOfExpiry &&
            moment(tfDetails.dateOfExpiry).isBefore(
                moment(moment(today).local().format("YYYY-MM-DD")).toDate()
            )
        ) {
            errors.dateOfExpiry = t("tradeFinance.dateError");
            return errors;
        }

        if (savedStep.current === StepId.reference && !tfDetails.referenceNo) {
            errors.referenceNo = t("tradeFinance.referenceNo_validation");
            return errors;
        }

        // const otherPartnerSwiftAddressError = validateTextArea(tfDetails.otherPartnerSwiftAddress, 3, 35, t);
        // if (otherPartnerSwiftAddressError) {
        //     errors.otherPartnerSwiftAddress = otherPartnerSwiftAddressError;
        // }

        if (
            mandatoryFieldValidation &&
            !(
                tradeFinance &&
                (tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyBG" ||
                    isGuarantee(tradeFinance) ||
                    isCollection(tradeFinance))
            )
        ) {
            const otherPartnerNameProps = collectFields(clientDetailsLayout).find(
                (field) => field.id === "otherPartnerName"
            ) as SwiftFieldProps;
            if (otherPartnerNameProps) {
                const otherPartnerNameError = validateSwiftFieldValue(
                    tfDetails.otherPartnerName,
                    otherPartnerNameProps.validationType,
                    otherPartnerNameProps.lines,
                    t
                );
                if (otherPartnerNameError) {
                    errors.otherPartnerName = otherPartnerNameError;
                }
            }
        }

        if (
            tradeFinance &&
            tradeFinance.otherPartnerSwiftAddress &&
            (isCollection(tradeFinance) ||
                tradeFinance.tradeFinanceType === "tradefin/incomingStandbyLC" ||
                tradeFinance.tradeFinanceType === "tradefin/importLC" ||
                tradeFinance.tradeFinanceType === "tradefin/exportLC")
        ) {
            const otherPartnerAddressProps = collectFields(clientDetailsLayout).find(
                (field) => field.id === "otherPartnerSwiftAddress"
            ) as SwiftFieldProps;
            if (otherPartnerAddressProps) {
                const otherPartnerAddressError = validateSwiftFieldValue(
                    tfDetails.otherPartnerSwiftAddress,
                    otherPartnerAddressProps.validationType,
                    otherPartnerAddressProps.lines,
                    t
                );
                if (otherPartnerAddressError) {
                    errors.otherPartnerSwiftAddress = otherPartnerAddressError;
                }
            }
        }

        if (isLetterOfCredit(tradeFinance!)) {
            const values: LetterOfCredit = tfDetails as unknown as LetterOfCredit;

            if (
                isImportLetterOfCredit(tradeFinance!) &&
                !(isAmendment(tradeFinance!) || isCancelEvent(tradeFinance!))
            ) {
                if (
                    values.latestDateOfShipment &&
                    moment(values.latestDateOfShipment).isAfter(
                        moment(values.dateOfExpiry)
                    )
                ) {
                    errors.latestDateOfShipment = t(
                        "tradeFinance.errors.latestDateOfShipment_expr"
                    );
                }
            }

            if (isAmendment(tradeFinance!)) {
                const increaseAmount = values.event?.increaseAmount;
                const decreaseAmount = values.event?.decreaseAmount;

                if (
                    increaseAmount != null &&
                    increaseAmount !== undefined &&
                    decreaseAmount !== null &&
                    decreaseAmount !== undefined
                ) {
                    if (!errors.event) {
                        (errors.event as any) = {};
                    }
                    (errors.event! as any).increaseAmount = t(
                        "tradeFinance.increase/decreaseValidation"
                    );
                }
            }
            return errors;
        } else if (isCollection(tradeFinance!)) {
            const values = tfDetails as unknown as Collection;

            if (moment(values.acceptanceDueDate).isBefore(today)) {
                errors.acceptanceDueDate = t(
                    "tradeFinance.errors.acceptanceDueDate_future"
                );
            }

            if (isUnsavedTerm) {
                errors.documentRequirements = t("tradeFinance.errors.documentTerms");
            }

            return errors;
        }

        return errors;
    }

    async function save(
        values: TradeFinance,
        actions: FormikActions<TradeFinance>
    ) {
        const {setSubmitting, setError, setErrors} = actions;
        try {
            const isValid = await uploadDocuments();

            if (!isValid) {
                setSubmitting(false);
                return;
            }

            if (isUnsavedTerm) {
                setError(isUnsavedTerm ? t("tradeFinance.errors.documentTerms") : null);
            }

            const errors = await validate(values as TradeFinanceDetail); //action.validateForm doesn't work. why???

            if (errors && Object.keys(errors).length) {
                setErrors(errors);
                if (!tradeFinance!.event?.eventDescRef.includes("cancelEvent")) {
                    setSubmitting(false);
                    return;
                }
            }

            const newTradeFinance = Object.assign(
                {
                    id: tradeFinance!.id || "new",
                    type: tradeFinance!.tradeFinanceType,
                    referenceNo: tradeFinance!.referenceNo, //A fejlécben ezt át lehet írni azért kell
                },
                tradeFinance,
                values
            ) as TradeFinanceDetail;
            const {id} = await TFApi.saveTradeFinanceModifications(
                newTradeFinance,
                template
            );

            if (params.id === "new") {
                history.replace(
                    `/portal/${template ? "template" : "trade-finance-request"}/hu.appello.kbtradefin:kbtf-portal:jar:3.1.24`
                );
                savedStep.current && closeStep(savedStep.current);
            } else if (mounted.current) {
                setTradeFinance(newTradeFinance);
                setSubmitting(false);
                setError(null);
                savedStep.current && closeStep(savedStep.current);
                await reLoad();
            }
        } catch (e) {
            actions.setSubmitting(false);
            // @ts-ignore
            setError(t(e.error ?? "tradeFinance.saveError"));
        }
    }

    async function uploadDocuments() {
        if (tradeFinance) {
            const docsToSave = docsToUpload.map((doc) => ({
                ...doc,
                error: !doc.fileType ? t("mandatoryField") : null,
            }));
            if (!hasDocumentError(docsToSave)) {
                if (docsToSave.length > 0) {
                    await TFApi.uploadTradeFinanceDocuments(
                        tradeFinance.id,
                        tradeFinance.event?.id,
                        docsToSave
                    );
                }
                setDocsToUpload([]);
            } else {
                setDocsToUpload(docsToSave);
                return false;
            }
        }
        return true;
    }

    return (
        <div className="flow-page page trade-finance container">
            <Form
                initialValues={origTradeFinance}
                validate={validate}
                enableReinitialize={true}
                validateOnChange={false}
                onSubmit={save}
            >
                {(form) => {
                    function save(
                        stepId:
                            | StepId.requestDetails
                            | StepId.clientDetails
                            | StepId.preCheck
                            | StepId.reference
                    ) {
                        savedStep.current = stepId;
                        let otherPartnerNameError = null;
                        const otherPartnerNameProps = collectFields(
                            clientDetailsLayout
                        ).find(
                            (field) => field.id === "otherPartnerName"
                        ) as SwiftFieldProps;
                        if (
                            otherPartnerNameProps &&
                            form.values.otherPartnerName &&
                            !(
                                tradeFinance &&
                                (tradeFinance.tradeFinanceType ===
                                    "tradefin/outgoingStandbyBG" ||
                                    isGuarantee(tradeFinance) ||
                                    isCollection(tradeFinance))
                            )
                        ) {
                            otherPartnerNameError = validateSwiftFieldValue(
                                form.values.otherPartnerName,
                                otherPartnerNameProps.validationType,
                                otherPartnerNameProps.lines,
                                t
                            );

                            if (otherPartnerNameError) {
                                form.setFieldError("otherPartnerName", otherPartnerNameError);
                            }
                        }

                        if (!otherPartnerNameError) {
                            form.submitForm();
                            setUnsavedModification(false);
                        }
                    }

                    async function updateReferenceNo(
                        title: string | null | undefined
                    ): Promise<void> {
                        if (!isNew && id && title) {
                            await TFApi.updateTradeFinanceReferenceNo(
                                id,
                                title || "",
                                String(tradeFinance?.lastTime)
                            );

                            await TFApi.getTradeFinanceLastTime(id).then((item: any) => {
                                const newTf: any = {
                                    ...tradeFinance,
                                    lastTime: item.date,
                                    referenceNo: title,
                                };
                                setTradeFinance(newTf);
                            });
                        }

                        form.setFieldValue("referenceNo", title);
                    }

                    const underWritersInfo =
                        loadTradeFinanceAction.data?.underWritersInfo;
                    return (
                        <TradFinanceRequestContext.Provider
                            value={{
                                tradeFinance,
                                setDocsToUpload,
                                uploadDocuments,
                                docsToUpload,
                                requestDetailsLayout,
                                clientDetailsLayout,
                                setTradeFinance,
                                loadDocumentsAction,
                                loadCommentsAction,
                                loadPartnersAction,
                                loadNotificationsAction,
                                loadDocRequirementTemplates,
                                loadEventHistoryAction,
                                loadMessageHistoryAction,
                                underWritersInfo,
                                loadDocumentRequirementsAction,
                                form,
                                save,
                                reLoad,
                                validate,
                                origTradeFinance,
                                formFields,
                                setFormFields,
                                isUnsavedTerm,
                                setUnsavedTerm,
                                hasUnsavedModification,
                                setUnsavedModification,
                                createNewPartnerOption,
                                setNewPartnerOption,
                                setMaturityError,
                                hasMaturityError,
                                setSignError,
                                hasSignError,
                            }}
                        >
                            <HeaderComponent
                                key={form.values.referenceNo}
                                updateTradeFinanceTitle={updateReferenceNo}
                                tradeFinance={tradeFinance}
                                isTradeFinanceEditable={isTradeFinanceEditable({
                                    tradeFinance,
                                    underWritersInfo,
                                })}
                                isApproved={
                                    tradeFinance.event?.state === "approved" ||
                                    tradeFinance.event?.state === "refused" ||
                                    tradeFinance.event?.state === "canceled"
                                }
                            />
                            {tradeFinance.state !== "converted" && (
                                <FloatingSave
                                    isLoading={form.isSubmitting}
                                    save={save}
                                    underWritersInfo={underWritersInfo}
                                    tradeFinance={tradeFinance}
                                    hasUnsavedModification={hasUnsavedModification}
                                />
                            )}
                            <div className={""}>
                                <TradeFinanceContentLayout/>
                            </div>
                        </TradFinanceRequestContext.Provider>
                    );
                }}
            </Form>
        </div>
    );
}
