import React from 'react';
import { OrderingActiveBottom, OrderingActiveTop, OrderingInactive } from '../../../../../../icons/Icons';
import { SortArrowComponentProps } from '../../../interfaces';

import './SortArrowComponent.scss';

export const SortArrowComponent = (props: SortArrowComponentProps) => {
    return (
        <span className='SortArrowComponent'>
            {
                props.isUp ? < OrderingActiveTop /> : props.isDown ? <OrderingActiveBottom /> : <OrderingInactive />
            }
        </span>
    )
}