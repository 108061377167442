import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../App";
import { useLoadingAction } from "../../hooks";
import * as PortalAPI from "../../api/portalApi";
import { Company, KBLogoWithText, Phone, UserIcon } from "../../icons/Icons";
import * as React from "react";
import { LanguageSelector, LogoutButton } from "../AppHeader/assets/index";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { CompanyOption } from "../../pages/DashboardPage/assets/types";
import { CompaniesPopup } from "../../pages/DashboardPage/assets";
import { useTradeFinanceSearchParams } from "../../pages/TradeFinanceListPage/assets/hooks";
import { AppHeaderProps } from "../AppHeader/assets/types";
import { ContactsPopup, TradeFinanceFilterComponent, HeaderDropdown } from "..";
import { MobileHeaderContext } from "./assets/context";
import { NavLinksComponent } from "./assets";

export function MobileHeader({ isLoggedIn, onLogout }: AppHeaderProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { userStatus } = useContext(AppContext);
  const user = userStatus?.user;
  const loadCompaniesAction = useLoadingAction(() =>
    PortalAPI.getUserCompanies()
  );

  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useTradeFinanceSearchParams();
  const [searchOpened, setSearchOpened] = useState<boolean>(false);

  const companyOptions: CompanyOption[] = useMemo(() => {
    if (loadCompaniesAction.result) {
      return loadCompaniesAction.result.map((option) => {
        return {
          label: option.label as string,
          value: option.value as number,
        };
      });
    }
    return [];
  }, [loadCompaniesAction.result]);

  useEffect(() => {
    if (menuOpened) {
      setMenuOpened(false);
    }
  }, [location]);

  return (
    <MobileHeaderContext.Provider
      value={{ menuOpened, searchOpened, setSearchOpened }}
    >
      <nav className="navbar is-mobile">
        <div className={"container"}>
          <div className="navbar-brand">
            {!menuOpened && !searchOpened && (
              <NavLink
                className="navbar-item logo"
                to="/"
                onClick={() => {
                  //when current location is '/' and user clicks the logo, refresh the page
                  if (location.pathname === "/") {
                    history.go(0);
                  }
                }}
              >
                <KBLogoWithText className={"is-flex"} />
              </NavLink>
            )}

            {isLoggedIn &&
              !searchOpened &&
              user?.defaultCompany &&
              companyOptions.length > 0 && (
                <>
                  {!menuOpened ? (
                    <HeaderDropdown
                      label={user.defaultCompany.companyName}
                      icon={<Company className={"icon"} />}
                      popupContent={
                        <CompaniesPopup companyOptions={companyOptions} />
                      }
                      cssId={"company"}
                      showDropdownIcon={companyOptions.length > 1}
                      buttonClass={"navbar-item company-selector"}
                    />
                  ) : (
                    <HeaderDropdown
                      label={t("header.contacts")}
                      icon={<Phone className={"phone-icon icon"} />}
                      popupContent={<ContactsPopup />}
                      showDropdownIcon={true}
                      buttonClass={"navbar-item contacts-dropdown"}
                    />
                  )}
                </>
              )}

            {isLoggedIn && (
              <>
                {!menuOpened && (
                  <div className={"navbar-item search-item"}>
                    <TradeFinanceFilterComponent
                      searchParams={searchParams}
                      placeholder={t("tradeFinance.searchPlaceholder")}
                    />
                  </div>
                )}
                {!searchOpened && (
                  <>
                    {menuOpened && (
                      <div
                        className={"navbar-item has-text-danger close-element"}
                        onClick={() => setMenuOpened(false)}
                      >
                        {t("menu.close")}
                      </div>
                    )}
                    <div
                      className={classNames("navbar-burger", "burger", {
                        "is-active": menuOpened,
                      })}
                      data-target="navMenuDocumentation"
                      onClick={() => setMenuOpened(!menuOpened)}
                    >
                      <span aria-hidden="true"></span>
                      <span aria-hidden="true"></span>
                      <span aria-hidden="true"></span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div
            id={"navMenuDocumentation"}
            className={classNames("navbar-menu", { "is-active": menuOpened })}
          >
            <div className={"navbar-start"}>
              {user && (
                <div className={"navbar-item is-flex"}>
                  <div
                    className="profile-avatar header-button"
                    title={t("dashboard.editMyProfile")}
                    onClick={() => history.push("/portal/profile")}
                  >
                    {user.profileImage ? (
                      <img className={"icon"} src={user.profileImage} />
                    ) : (
                      <UserIcon className={"icon"} />
                    )}{" "}
                    {user.name}
                  </div>

                  <LanguageSelector />
                </div>
              )}

              <hr />
              <div className={"navbar-item column-title"}>
                {t("menu.transactions")}
              </div>
              <NavLinksComponent />
            </div>
            <div className={"navbar-end"}>
              <div className={"navbar-item"}>
                <LogoutButton onLogout={onLogout} hasCaption={true} />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </MobileHeaderContext.Provider>
  );
}
