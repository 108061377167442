import * as React from "react";
import { useContext } from "react";
import * as TradeFinanceAPI from "../../api/tradeFinanceApi";

import { Notification, Signature } from "../../types";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { useLoadingAction } from "../../hooks";
import { Eye, Todo } from "../../icons/Icons";

import { NotificationsCard, SignaturesCard } from "./assets";
import { CalendarCompact } from "../../components";

export default function DashboardPage() {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const { result, isLoading } = useLoadingAction(
    () =>
      Promise.all([
        TradeFinanceAPI.fetchNotifications(true, "done"),
        TradeFinanceAPI.fetchNotifications(false),
        TradeFinanceAPI.findSignatures(true, 0, 10),
      ]),
    [user!.defaultCompany]
  );

  if (!result) {
    return null;
  }

  const actionResult = result as [Notification[], Notification[], Signature[]];
  const todoNotifications = actionResult[0] || [];
  const informationNotifications = actionResult[1] || [];
  const signatures = actionResult[2] || [];

  return (
    <div className="page dashboard container">
      <div className="calendar">
        <CalendarCompact />
      </div>

      <div className="dashboard-cards">
        <div className="columns">
          {signatures.length > 0 && (
            <div className="column is-4">
              <SignaturesCard
                title={t("dashboard.signatureRequired")}
                subtitle={t("dashboard.signatureSubtitle")}
                signatures={signatures}
              />
            </div>
          )}

          <div className="column is-4">
            <NotificationsCard
              icon={<Todo />}
              title={t("dashboard.todoNotifications")}
              subtitle={t("dashboard.todoSubtitle")}
              notifications={todoNotifications}
              actionNeeded={true}
            />
          </div>

          <div className="column is-4">
            <NotificationsCard
              icon={<Eye />}
              title={t("dashboard.informationNotifications")}
              subtitle={t("dashboard.informationSubtitle")}
              notifications={informationNotifications}
              actionNeeded={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
