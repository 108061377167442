import classNames from "classnames";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import { CurrencyNumberFormat, RoundedButton } from "../../..";
import { Eye } from "../../../../icons/Icons";
import { DeviceType, useDeviceType } from "../../../../pages/tradeFinanceHooks";
import { CalendarEvents } from "../../../../types";
import { PopUpPosition } from "../types";

function CalendarEventsPopup({
  calendarEvents,
  close,
  className,
  autoPos,
}: {
  calendarEvents: CalendarEvents;
  close: () => void;
  className?: string;
  autoPos?: boolean;
}) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [popUpPos, setPopUpPos] = useState<PopUpPosition | undefined>();
  const device = useDeviceType();

  useOnClickOutside(ref, (_) => {
    if (device === DeviceType.desktop || device === DeviceType.mobile) {
      close();
    }
  });

  useLayoutEffect(() => {
    if (ref.current && autoPos) {
      const containerWidth = 960;
      const popUpDims = ref.current.getBoundingClientRect();
      const pos =
        popUpDims.left + 400 >= containerWidth ? "is-right" : "is-left";
      setPopUpPos(pos);
    }
  }, [ref.current]);

  return (
    <div
      ref={ref}
      className={classNames("calendar-events", className, popUpPos)}
    >
      <div className={"calendar-events--container"}>
        {calendarEvents.events.map((event, idx) => {
          return (
            <div key={idx} className="calendar-events--event">
              <div>
                <div className={"event-title"}>{event.title}</div>
                <div className="event-amount">
                  <CurrencyNumberFormat
                    displayType={"text"}
                    value={event.amount}
                    currency={event.currency}
                  />
                  <span className="currency">{event.currency}</span>
                </div>
              </div>

              <div>
                <div>
                  <div className="event-identifier">
                    {event.identifier} - {event.referenceNo}
                  </div>

                  <div className="event-otherPartnerName">
                    {event.otherPartnerName}
                  </div>
                </div>

                <Link
                  className="card-button"
                  to={`/portal/trade-finance-request/${event.tradeFinanceId}`}
                >
                  <RoundedButton
                    label={t("calendar.viewDetails")}
                    icon={<Eye />}
                    transparent={true}
                  />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CalendarEventsPopup;
