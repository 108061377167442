import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  DeviceType,
  isIEBrowser,
  useDeviceType,
} from "../../../tradeFinanceHooks";
import { FloatingSaveType, StepId } from "../type";

function FloatingSave({
  isLoading,
  save,
  underWritersInfo,
  tradeFinance,
  hasUnsavedModification,
}: FloatingSaveType) {
  const { t } = useTranslation();
  const device = useDeviceType();
  const isIe = isIEBrowser();

  const isMobile = device !== DeviceType.desktop;
  const isDraft = tradeFinance?.initiationAs === "draft";
  const isFinal = tradeFinance?.initiationAs === "finalized";

  // if(!hasUnsavedModification || (tradeFinance && !isTradeFinanceEditable({tradeFinance, underWritersInfo}))){
  //     return null;
  // }
  //it should test
  if (!hasUnsavedModification) return null;

  return (
    <div className={classnames("floating-save", { "is-ie": isIe })}>
      {!isMobile && (
        <div className={classnames("arrow-left", { "is-ie": isIe })}></div>
      )}
      <div className={classnames({ card: !isMobile, container: isMobile })}>
        <div className={"content"}>
          <div className="floating-elements">
            <div
              className={classnames("floating-text", {
                green: underWritersInfo && tradeFinance?.state !== "concept",
              })}
            >
              {underWritersInfo && (isFinal || isDraft)
                ? t(
                    isFinal
                      ? tradeFinance?.state === "concept"
                        ? "saveAsFinal"
                        : "savedAsFinal"
                      : tradeFinance?.state === "concept"
                      ? "saveAsDraft"
                      : "savedAsDraft"
                  )
                : t(isLoading ? "savingDocument" : "unsavedChanges")}
            </div>
            <button
              onClick={() => save(StepId.requestDetails)}
              className={classnames("portal-button button is-outlined", {
                "loading is-loading": isLoading,
              })}
              disabled={
                tradeFinance?.state !== "concept" &&
                underWritersInfo &&
                (isFinal || isDraft)
              }
            >
              {t("tradeFinance.saveDraft")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloatingSave;
