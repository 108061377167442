import { ReactNode } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { Clause, TradeFinancePartner, Univ } from "../../types";
import { FormError } from "../Form/types";

export enum FieldType {
  text = "TEXT",
  textArea = "TEXTAREA",
  date = "DATE",
  boolean = "BOOLEAN",
  integer = "INTEGER",
  decimal = "DECIMAL",
  select = "SELECT",
  document = "DOCUMENT",
  percentage = "PERCENTAGE",
  link = "LINK",
  textAreWithClauses = "TEXTAREWITHCLAUSES",
  textWithClauses = "TEXTWITHCLAUSES",
  codebase = "CODEBASE",
  swift = "SWIFT",
  swiftCode = "SWIFTCODE",
}

export interface FieldProps {
  id: string;
  type?: FieldType;
  label?: Univ | null | string;
  help?: Univ;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  error?: string;
  value: any;
  setValue: (value: any) => void;
  setError: (error: string | null) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
}

export type FieldPropsWithoutType = Omit<FieldProps, "type">;

export interface TextFieldProps extends FieldPropsWithoutType {
  value: string;
  maxLength?: number;
  format?: (val: string) => string;
  headerElementsFactory?: (props: TextAreaProps) => ReactNode;
}

export interface SwiftFieldProps extends TextFieldProps {
  validationType: string;
  lines: number;
  clauses?: Clause[];
}

export interface TextAreaProps extends FieldPropsWithoutType {
  value: string;
  maxLength?: number;
  rows?: number;
  disableEnlarge?: boolean;
  format?: (val: string) => string;
  headerElementsFactory?: (props: TextAreaProps) => ReactNode;
}

export interface NumberFieldProps extends FieldPropsWithoutType {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  decimals?: number;
  suffix?: string;
}

export interface LinkFieldProps extends FieldPropsWithoutType {
  value: string;
  linkCaption: Univ;
  newTab: boolean;
}

export type FileProps = { name: string; id?: number };

export interface DocumentFieldProps extends FieldPropsWithoutType {
  type: FieldType.document;
  value: FileProps;
  onDelete?: (documentId: number) => void | FormError;
  multi?: boolean;
}

export type SelectOption = {
  label: Univ | string;
  value: string | number;
  tags?: string[];
};

export interface SelectFieldProps extends FieldPropsWithoutType {
  value: any;
  options: SelectOption[];
  multiSelect?: boolean;
  fullWidth?: boolean;
  onClearValue?: () => void;
  isClearable?: boolean;
}

export type EnlargedFieldProps = TextAreaProps & { onClose: () => void };

export type TextAreaWithClausesProps = TextAreaProps & { clauses: Clause[] };
export type TextWithClausesProps = TextFieldProps & { clauses: Clause[] };

export type ClauseSelectorProps = {
  clauses: Clause[];
  onSelect: (selectedClauses: Clause[]) => void;
};

export interface CheckBoxSelectionFieldProps extends FieldPropsWithoutType {
  value: (string | number)[];
  options: SelectOption[];
}

export type DateFieldProps = FieldPropsWithoutType &
  Omit<ReactDatePickerProps, "onChange">;

export type CheckBoxProps = {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  hovered?: boolean;
};

export interface SwiftCodeFieldProps extends FieldPropsWithoutType {
  value: string;
  onSelected?: (partner: TradeFinancePartner | null | undefined) => void;
}

export type CodeBaseOption = { label: Univ; value: string; tags: string[] };

export type CodeBaseFieldProps = {
  codeBaseId: string;
  portalOnly?: boolean;
  optionsFilter?: (options: CodeBaseOption[]) => CodeBaseOption[];
} & Omit<SelectFieldProps, "options">;

export interface BooleanFieldProps extends FieldPropsWithoutType {}
