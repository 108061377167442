import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../../App";
import { Count } from "../../../AppHeader/assets";

const NavLinksComponent = () => {
  const { stats } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <>
      <NavLink className={"navbar-item menu-item"} to={"/portal/signatures"}>
        {t("menu.signatures")} <Count value={stats?.signatureRequired} />
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/trade-finance-list/ongoing"}
      >
        {t("menu.ongoing")} <Count value={stats?.ongoingTransactions} />
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/trade-finance-list/concept"}
      >
        {t("menu.concept")} <Count value={stats?.draft} />
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/trade-finance-list/guarantees"}
      >
        {t("menu.guarantees")}
        <Count value={stats?.activeGuarantees} />
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/trade-finance-list/letterOfCredit"}
      >
        {t("menu.letterOfCredit")} <Count value={stats?.activeLC} />
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/trade-finance-list/collection"}
      >
        {t("menu.collection")}
        <Count value={stats?.activeCollection} />
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/trade-finance-list/standby"}
      >
        {t("menu.standby")} <Count value={stats?.standby} />
      </NavLink>
      <NavLink className={"navbar-item menu-item"} to={"/portal/help"}>
        {t("menu.help")}
      </NavLink>
      <hr />

      <div className={"navbar-item column-title"}>{t("menu.report")}</div>
      <NavLink className={"navbar-item menu-item"} to={"/portal/reports"}>
        {t("menu.report")}
      </NavLink>
      <hr />

      <div className={"navbar-item column-title"}>{t("menu.settings")}</div>
      <NavLink className={"navbar-item menu-item"} to={"/portal/beneficiaries"}>
        {t("menu.beneficiary")}
      </NavLink>
      <NavLink className={"navbar-item menu-item"} to={"/portal/templates"}>
        {t("menu.templates")}
      </NavLink>
      <NavLink className={"navbar-item menu-item"} to={"/portal/clause"}>
        {t("menu.clause")}
      </NavLink>
      <NavLink className={"navbar-item menu-item"} to={"/portal/profile"}>
        {t("menu.profile")}
      </NavLink>
      <NavLink className={"navbar-item menu-item"} to={"/portal/underwriters"}>
        {t("menu.underwriters")}
      </NavLink>
      <NavLink
        className={"navbar-item menu-item"}
        to={"/portal/userConfiguration"}
      >
        {t("menu.userConfiguration")}
      </NavLink>
      <hr />
    </>
  );
};

export default NavLinksComponent;
