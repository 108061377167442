import React, { useContext, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../../../App";
import { TradeFinance } from "../../../../../../types";
import { TradFinanceRequestContext } from "../../../context";
import {
  getCoverTemplateId,
  isCollection,
  isCompleteRequestEvent,
  isExportCollection,
  isExportLetterOfCredit,
  isFinishRequestEnabled,
  isImportLetterOfCredit,
  isImportLetterOfCreditInCancelEventWaitingForClient,
  isMixedPayment,
  isPreCheckEvent,
} from "../../../tradeFinanceUtils";
import { SignStepProps, StepId } from "../../../type";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import { hasError } from "../../../../../../components/Form/helper";
import { useAction } from "../../../../../../hooks";
import { useIsTemplate } from "../../../hooks";
import { RoundedButton } from "../../../../../../components";
import { FilePDF, Help } from "../../../../../../icons/Icons";
import { Step } from "..";
import { Buttons, CompanyWarningModal } from "./assets";
import { NotificationContext } from "../../../../../../components/Notification/assets/context";

function FinishRequestStep({
  setShowFinalDocument,
}: { hasUnsavedChanges: boolean } & SignStepProps) {
  const { t } = useTranslation();
  const {
    tradeFinance,
    underWritersInfo,
    reLoad,
    loadDocumentsAction,
    form,
    uploadDocuments,
    validate,
    isUnsavedTerm,
    hasMaturityError,
  } = useContext(TradFinanceRequestContext);
  const { user, userStatus, refreshStats, lang } = useContext(AppContext);
  const history = useHistory();
  const docError =
    isCollection(tradeFinance) &&
    isMixedPayment(form.values as TradeFinance) &&
    hasMaturityError;

  const [showCompanyWarning, setShowCompanyWarning] = useState<boolean>(false);

  const [draftMode, setDraftMode] = useState<boolean | null>(null);
  const [loadindSend, setLoadingSend] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationContext);

  async function send(draft: boolean) {
    try {
      setLoadingSend(true);
      const { id } = await TFApi.sendForSign(
        { ...form.values, referenceNo: form.values.referenceNo },
        draft
      );

      if (!tradeFinance.id && id) {
        history.replace("/portal/trade-finance-request/" + id);
        setLoadingSend(false);
      } else if (tradeFinance.id) {
        await reLoad();
        setLoadingSend(false);
      }
    } catch (error) {
      setLoadingSend(false);
      throw error;
    }
  }

  async function checkCompanyAndSend(draft: boolean) {
    const errors = await validate({ ...form.values });

    if (hasError(errors)) {
      form.setErrors(errors);
      return;
    }

    if (
      tradeFinance.clientDetails &&
      tradeFinance.clientDetails.id !== user!.defaultCompany?.id
    ) {
      setDraftMode(draft);
      setShowCompanyWarning(true);
    } else {
      await send(draft);
    }
  }

  function throwMandatoryFieldError(message?: string) {
    if (!message) {
      // eslint-disable-next-line no-throw-literal
      throw (
        <div>
          <div className="popup--title">{t("mandatoryFieldsNotFilled")}</div>
          {<div className="popup--content">{t("reCheck")}</div>}
        </div>
      );
    } else {
      // eslint-disable-next-line no-throw-literal
      throw (
        <div>
          <div className="popup--content">{message}</div>
        </div>
      );
    }
  }

  const validateAndRun = async (fn: () => Promise<void>) => {
    form.setErrors({});
    const errors = await validate(form.values, true);
    if ((!errors || Object.keys(errors).length === 0) && !hasMaturityError) {
      await fn();
    } else {
      form.setErrors(errors);
      // eslint-disable-next-line no-throw-literal
      throw (
        <div>
          <div className="popup--title">
            {docError
              ? t("tradeFinance.installment.amountError")
              : t(
                  !isUnsavedTerm
                    ? "mandatoryFieldsNotFilled"
                    : "tradeFinance.errors.documentTerms"
                )}
          </div>
          {!isUnsavedTerm && !docError && (
            <div className="popup--content">{t("reCheck")}</div>
          )}
        </div>
      );
    }
  };

  const sendDraftAction = useAction(async () => {
    await validateAndRun(async () => {
      if (!form.values.referenceNo || form.values.referenceNo === "") {
        validationExecution(
          "referenceNo",
          t("tradeFinance.referenceNo_validation")
        );
      } else {
        await actionExecution(true);
      }
    });
  });

  const sendFinalAction = useAction(async () => {
    await validateAndRun(async () => {
      if (
        form.values.event?.otherInstructionEvent !== undefined &&
        !form.values.event?.otherInstructionEvent
      ) {
        validationExecution(
          "otherInstructionEvent",
          t("tradeFinance.referenceNo_validation")
        );
      }
      if (!form.values.referenceNo || form.values.referenceNo === "") {
        validationExecution(
          "referenceNo",
          t("tradeFinance.referenceNo_validation")
        );
      } else {
        await actionExecution();
      }
    });
  });

  const validationExecution = (field: string, message: string) => {
    form.setFieldError(field, message);
    throwMandatoryFieldError();
  };

  const actionExecution = async (isDraft = false) => {
    const isValid = await uploadDocuments();
    if (!isValid) {
      throwMandatoryFieldError();
    } else {
      setNotification(null);
      await loadDocumentsAction.run();
      try {
        await checkCompanyAndSend(isDraft);
      } catch (err: any) {
        setLoadingSend(false);
        throwMandatoryFieldError(err.univ[lang]);
      }

      setShowFinalDocument(true);
    }
  };

  const cancelAction = useAction(async () => {
    if (tradeFinance.id) {
      await TFApi.cancelTradeFinance(tradeFinance);
    }
    refreshStats();
    history.push("/portal/dashboard");
  });

  const cancelPreCheckAction = useAction(async () => {
    if (tradeFinance.id) {
      await TFApi.cancelTradeFinance(tradeFinance);
    }
    history.push("/portal/dashboard");
  });

  const coverTemplateId = getCoverTemplateId(userStatus!, tradeFinance);
  const documentCoverURL = TFApi.getDocumentCoverDownloadLink(
    tradeFinance.id,
    coverTemplateId,
    tradeFinance.event?.id
  );

  const loading = useMemo(
    () =>
      loadindSend ||
      sendDraftAction.isLoading ||
      sendFinalAction.isLoading ||
      cancelAction.isLoading ||
      form.isSubmitting,
    [
      cancelAction.isLoading,
      form.isSubmitting,
      loadindSend,
      sendDraftAction.isLoading,
      sendFinalAction.isLoading,
    ]
  );

  const eventRef = (
    tradeFinance.event
      ? tradeFinance.event.eventDescRef
      : tradeFinance.businessEntityDescriptorId + "/sendApplication"
  ).replace(/\//g, "__");
  const isDraftMaker = useMemo(
    () =>
      user!.draftMaker.includes(eventRef) || isExportCollection(tradeFinance),
    [eventRef, tradeFinance, user]
  );
  const actionsEnabled = useMemo(
    () =>
      !loading &&
      (isDraftMaker ||
        isPreCheckEvent(tradeFinance) ||
        isImportLetterOfCreditInCancelEventWaitingForClient(tradeFinance)),
    [isDraftMaker, loading, tradeFinance]
  );

  const isNew = useMemo(() => tradeFinance.id === "new", [tradeFinance.id]);

  if (
    !isFinishRequestEnabled(
      tradeFinance,
      useIsTemplate(tradeFinance),
      underWritersInfo
    )
  ) {
    return null;
  }

  function showDocumentCoverDownload(): boolean {
    if (isExportCollection(tradeFinance)) {
      return (
        isCompleteRequestEvent(tradeFinance) || tradeFinance.state === "concept"
      );
    } else if (isImportLetterOfCredit(tradeFinance)) {
      return (
        isCompleteRequestEvent(tradeFinance) || tradeFinance.state === "concept"
      );
    } else if (isExportLetterOfCredit(tradeFinance)) {
      return isPreCheckEvent(tradeFinance);
    }
    return false;
  }

  return (
    <Step stepId={StepId.documents} title={t("tradeFinance.finishRequest")}>
      <div className={"button-container"}>
        {showDocumentCoverDownload() && coverTemplateId && (
          <div className={"download-button-divider"}>
            <a href={documentCoverURL} target={"__blank"}>
              <RoundedButton
                label={t(
                  isImportLetterOfCredit(tradeFinance)
                    ? "documents.downloadDraft"
                    : "documents.downloadCoverLetter"
                )}
                icon={<FilePDF />}
              />
            </a>
            <div className={"button-info"}>
              {t(
                isImportLetterOfCredit(tradeFinance)
                  ? "documents.draftInfo"
                  : "documents.coverLetterInfo"
              )}
            </div>
          </div>
        )}
        <div className="portal-buttons">
          <Buttons
            cancelAction={cancelAction}
            sendFinalAction={sendFinalAction}
            actionsEnabled={actionsEnabled}
            sendDraftAction={sendDraftAction}
            cancelPreCheckAction={cancelPreCheckAction}
            loading={loading}
            isNew={isNew}
          />
        </div>

        {!isExportCollection(tradeFinance) &&
          !isExportLetterOfCredit(tradeFinance) && (
            <div className={"step-hint is-flex has-top-border"}>
              <Help />
              <div>
                <Trans i18nKey={"tradeFinance.sendFinalHint"} />
                <Trans i18nKey={"tradeFinance.sendDraftHint"} />
              </div>
            </div>
          )}

        {form.error && (
          <div className="notification is-danger">{form.error}</div>
        )}
        <CompanyWarningModal
          showCompanyWarning={showCompanyWarning}
          setShowCompanyWarning={setShowCompanyWarning}
          setDraftMode={setDraftMode}
          draftMode={draftMode}
          send={send}
        />
      </div>
    </Step>
  );
}

export default FinishRequestStep;
