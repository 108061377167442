import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { DownloadIcon } from "../../../../icons/Icons";
import { reportGeneratedCallback } from "../../helper";
import { ReportTemplate } from "../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";

function GenerateReportButton({
  reportTemplate,
  onSuccess,
}: {
  reportTemplate: ReportTemplate;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();
  const generateReportFromTemplate = useMutation(
    async (reportTemplate: ReportTemplate) => {
      return await TFApi.generateReportFromTemplate(reportTemplate.id);
    },
    {
      onSuccess: (data) => {
        reportGeneratedCallback(data);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );

  return (
    <div
      className={"card-button"}
      onClick={() => generateReportFromTemplate.mutate(reportTemplate)}
    >
      <DownloadIcon style={{ height: 30, width: 26 }} />
      <div className="button-label">{t("report.generate")}</div>
    </div>
  );
}

export default GenerateReportButton;
