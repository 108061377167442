import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";
import { Edit, Eye, Signatures } from "../../../../icons/Icons";

export const Action = ({
  action,
  tradeFinanceId,
  eventId,
}: {
  action: string;
  tradeFinanceId: number;
  eventId: number;
}) => {
  const { t } = useTranslation();

  let icon = null;
  switch (action) {
    case "edit":
      icon = Edit;
      break;
    case "sign":
      icon = Signatures;
      break;
    case "view":
      icon = Eye;
      break;
  }

  return (
    <NavLink
      to={`/portal/trade-finance-request/${tradeFinanceId}?event=${eventId}`}
    >
      <div className={classNames("action", { "is-green": action === "sign" })}>
        {icon && React.createElement(icon)}
        <span>{t("transactionDetails.action." + action)}</span>
      </div>
    </NavLink>
  );
};
