import { UnderWriter } from "../../../types";
import { UnderwriterConfigItem } from "./types";
import { eventDefs } from "../../UserConfigurationPage/assets/helper";
import { deepEqual } from "../../../utils";

export function convertItemsToUnderWriters(
  items: UnderwriterConfigItem[]
): UnderWriter[] {
  const result: UnderWriter[] = [];

  eventDefs.forEach((eventDef) => {
    const itemsForProductAndEvent = items.filter(
      (itm) =>
        itm.product === eventDef.product && itm.eventName === eventDef.eventName
    );
    eventDef.eventIds.forEach((evenId) => {
      itemsForProductAndEvent.forEach((item) => {
        result.push({
          numberOfGroupA: item.groupA,
          numberOfGroupB: item.groupB,
          limit: item.limit,
          event: evenId.substr(evenId.lastIndexOf("__") + 2),
          type: evenId.split("__")[1],
        } as UnderWriter);
      });
    });
  });

  return result;
}

export function underwriterConfigItEquals(
  a: UnderwriterConfigItem,
  b: UnderwriterConfigItem
) {
  return (
    a.product === b.product &&
    a.product === b.product &&
    a.eventName === b.eventName &&
    a.groupA === b.groupA &&
    a.groupB === b.groupB &&
    deepEqual(a.eventIds, b.eventIds) &&
    a.limit === b.limit
  );
}

export function convertToUnderwriterConfigItems(
  underWriters: UnderWriter[]
): UnderwriterConfigItem[] {
  const result: UnderwriterConfigItem[] = [];

  function addToResult(item: UnderwriterConfigItem) {
    if (!result.find((itm) => underwriterConfigItEquals(item, itm))) {
      result.push(item);
      return item;
    }
    return null;
  }

  eventDefs.forEach((eventDef) => {
    const underWritersForProductAndEvent = underWriters.filter((uw) => {
      const productIds = eventDef.eventIds.map(
        (eventId) => eventId.split("__")[1]
      );
      let eventId = eventDef.eventIds[0];
      if (!eventId) {
        return false;
      }
      eventId = eventId.substr(eventId.lastIndexOf("__") + 2);
      return productIds.includes(uw.type) && eventId === uw.event;
    });

    if (underWritersForProductAndEvent.length > 0) {
      underWritersForProductAndEvent.forEach((underWriter) => {
        addToResult({
          ...eventDef,
          limit: underWriter && underWriter.limit ? underWriter.limit : null,
          groupA: underWriter?.numberOfGroupA ?? 0,
          groupB: underWriter?.numberOfGroupB ?? 0,
          id: Math.random() + "",
        });
      });
    } else {
      addToResult({
        ...eventDef,
        limit: null,
        groupA: 0,
        groupB: 0,
        id: Math.random() + "",
      });
    }
  });

  return result;
}

export function underwriterComparator(a: UnderWriter, b: UnderWriter): number {
  return (a.limit || Number.MAX_VALUE) - (b.limit || Number.MAX_VALUE);
}

export function underwriterItemComparator(
  a: UnderwriterConfigItem,
  b: UnderwriterConfigItem
): number {
  const aIndex = eventDefs.findIndex(
    (eventDef) =>
      eventDef.product === a.product && eventDef.eventName === a.eventName
  );
  const bIndex = eventDefs.findIndex(
    (eventDef) =>
      eventDef.product === b.product && eventDef.eventName === b.eventName
  );

  if (aIndex !== bIndex) {
    return aIndex - bIndex;
  }

  return (a.limit || Number.MAX_VALUE) - (b.limit || Number.MAX_VALUE);
}
