import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TradeFinanceType } from "../../types";
import { TradeFinanceList } from "../TradeFinanceListPage/assets";
import { RequestCreateMethod } from "./assets/types";
import { getTradeFinanceFilterType } from "./assets/helper";

export default function CreateNewTradeFinanceRequestPage() {
  const history = useHistory();
  const location = useLocation();
  const { requestCreateMethod } = useParams<{
    requestCreateMethod: RequestCreateMethod;
  }>();
  const searchParams = new URLSearchParams(location.search);
  const tradeFinanceType: TradeFinanceType | null = searchParams.get(
    "type"
  ) as TradeFinanceType;

  useEffect(() => {
    if ("new" === requestCreateMethod) {
      history.replace(
        `/portal/trade-finance-request/new?type=${tradeFinanceType}`
      );
    }
  }, [requestCreateMethod]);

  let listType = getTradeFinanceFilterType(tradeFinanceType);

  if (!listType) {
    return null;
  }

  return (
    <div className="page">
      <div className="container">
        {requestCreateMethod === "copyFrom" && (
          <TradeFinanceList
            type={listType}
            copy="COPY"
            filterIsMandatory={false}
          />
        )}
        {requestCreateMethod === "template" && (
          <TradeFinanceList
            type={listType}
            copy="TEMPLATE"
            filterIsMandatory={false}
          />
        )}
      </div>
    </div>
  );
}
