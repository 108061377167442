import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { EditedMessageProps, MessageEditorType } from "../types";
import { Comment } from "../../../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";
import classnames from "classnames";
import { FormikActions } from "formik";
import { MultiFileUpload } from "..";
import { DeviceType, useDeviceType } from "../../../../pages/tradeFinanceHooks";
import { AppContext } from "../../../../App";
import { TradFinanceRequestContext } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/context";
import { useAction } from "../../../../hooks";
import { getFieldProps, validateMandatoryFields } from "../../../Form/helper";
import { errorToString } from "../../../../utils";
import { TextAreaField, TextField, RoundedButton, Form } from "../../..";
import { Delete, Edit, SignFinal } from "../../../../icons/Icons";
import { UnderWritersBlock } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/TradeFinanceSteps/assets";
import { FormParams } from "../../../Form/types";

function EditedMessage({
  comment,
  company,
  tradeFinance,
  close,
}: EditedMessageProps) {
  const { t } = useTranslation();
  const device = useDeviceType();
  const { userStatus, lang, portalConfig } = useContext(AppContext);
  const { loadCommentsAction } = useContext(TradFinanceRequestContext);
  const [showUnderWriters, setShowUnderWriters] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const underWritersInfoQuery = useQuery(
    [tradeFinance.id, tradeFinance.event?.id],
    async () => {
      return await TFApi.getUnderWriterInfoForNewComment(
        tradeFinance.id,
        tradeFinance.event?.id
      );
    },
    { cacheTime: 5 * 60 * 1000, enabled: !comment.underWritersInfo }
  );

  const signAction = useAction(async () => {
    if (comment.underWritersInfo) {
      const result = await TFApi.signComment(
        tradeFinance.id,
        comment.id,
        comment.underWritersInfo.signatureId
      );
      if (portalConfig.caas && result?.redirectUrl) {
        window.location.href = result?.redirectUrl;
      } else {
        await loadCommentsAction.run();
      }
    }
  });

  const reOpenAction = useAction(async () => {
    if (comment.underWritersInfo) {
      await TFApi.reOpenComment(tradeFinance.id, comment.id);
      await loadCommentsAction.run();
    }
  });

  const deleteAction = useAction(async () => {
    if (comment.id && comment.underWritersInfo) {
      await TFApi.deleteComment(tradeFinance.id, comment.id);
      await loadCommentsAction.run();
    } else {
      close();
    }
  });

  if (
    !loadCommentsAction.result ||
    !company ||
    underWritersInfoQuery.isLoading
  ) {
    return <div className={"button is-white is-loading is-large is-flex"} />;
  }

  const canSign =
    comment.underWritersInfo &&
    !comment.underWritersInfo.completed &&
    comment.underWritersInfo.canSign;
  const isEditable =
    !comment.id ||
    (comment.underWritersInfo &&
      comment.underWritersInfo.canSign &&
      !comment.underWritersInfo.completed &&
      !comment.underWritersInfo.underWritersInGroupA.length &&
      !comment.underWritersInfo.underWritersInGroupB.length);
  const isReOpenable = comment.underWritersInfo?.hasRightToSign && !isEditable;
  const isDeletable = isEditable;

  const isWaitingForSign =
    comment.underWritersInfo && !comment.underWritersInfo.completed;

  async function sendComment(
    commentToSave: Comment,
    id: string
  ): Promise<void> {
    const result = await TFApi.saveComment(
      id,
      tradeFinance.event?.id,
      company!.id,
      { ...commentToSave }
    );

    if (portalConfig.caas && result?.redirectUrl) {
      window.location.href = result?.redirectUrl;
    } else {
      await loadCommentsAction.run();
      close();
    }
  }

  const underWritersInfo = comment.underWritersInfo;
  return (
    <>
      <div
        className={classnames("contact-messages contact-message-editor", {
          "waiting-for-sign": isWaitingForSign,
          "table-container": device !== DeviceType.desktop,
        })}
      >
        <Form
          initialValues={
            {
              subject: comment.subject,
              content: comment.text,
              documents: comment.documents || [],
            } as MessageEditorType
          }
          enableReinitialize={true}
          validate={(values) => validateMandatoryFields(values, ["subject"], t)}
          onSubmit={async (
            values: MessageEditorType,
            { setFieldValue, setSubmitting }: FormikActions<MessageEditorType>
          ) => {
            const user = userStatus!.user;
            setError(null);
            console.log("comment", comment);
            console.log("values", values);
            console.log("user", user);
            console.log("user", tradeFinance);
            try {
              await sendComment(
                {
                  id: comment.id,
                  createdBy: user.name,
                  subject: values.subject,
                  text: values.content || "",
                  isCreateInFrontEnd: true,
                  documents: values.documents,
                } as Comment,
                tradeFinance.id
              );

              if (!comment.id) {
                setFieldValue("subject", "");
                setFieldValue("content", "");
                setFieldValue("documents", []);
              }
              setSubmitting(false);
              close();
            } catch (e) {
              setSubmitting(false);
              if ((e as Error).message === "fileUploadFailed") {
                setError(t("fileUploadFailed"));
              } else {
                const err = errorToString(e, t, lang);
                if (err) {
                  setError(err);
                }
              }
            }
          }}
        >
          {(form: FormParams<MessageEditorType>) => {
            const { values, handleSubmit, isSubmitting } = form;
            const hasIncompleteDocument = !!(values.documents || []).find(
              (doc) => !doc.description
            );
            const sendButtonCaption =
              underWritersInfo?.canSign || underWritersInfoQuery.data?.canSign
                ? "tradeFinance.signAndSend"
                : "tradeFinance.sendForSigning";

            function deleteMessage() {
              if (comment.id) {
                deleteAction.run();
              } else {
                form.setFieldValue("subject", "");
                form.setFieldValue("content", "");
                form.setFieldValue("documents", []);
              }
            }

            return (
              <React.Fragment>
                <div className={"contact-message-container"}>
                  {device === DeviceType.desktop && (
                    <div className={"contact-message-header"} />
                  )}
                  <div className="inputs contact-message">
                    <TextField
                      {...getFieldProps(form, "subject", "subject")}
                      label={t("tradeFinance.messaging.subject")}
                      required={true}
                      className="subject"
                      disabled={!isEditable || isSubmitting}
                    />

                    <TextAreaField
                      {...getFieldProps(form, "content", "content")}
                      label={t("tradeFinance.messaging.message")}
                      disabled={!isEditable || isSubmitting}
                    />

                    <div className="message-file-and-send">
                      <MultiFileUpload
                        comment={{
                          ...comment,
                          documents: form.values.documents,
                        }}
                        tradeFinanceId={tradeFinance.id}
                        error={error}
                        setError={setError}
                        editable={isEditable && !isSubmitting}
                        descriptionRequired={true}
                        updateDocuments={(docs) =>
                          form.setFieldValue("documents", docs)
                        }
                      />
                    </div>

                    <div className="message-buttons">
                      <div className="sign">
                        {underWritersInfo && !underWritersInfo.completed && (
                          <div className="signature-hint">
                            {!underWritersInfo.signedByMe && (
                              <div>
                                {t(
                                  underWritersInfo.hasRightToSign
                                    ? "tradeFinance.messaging.anotherSignatureRequired"
                                    : "tradeFinance.messaging.dontHavePermission"
                                )}
                              </div>
                            )}
                            {!showUnderWriters ? (
                              <div
                                className="is-underlined"
                                onClick={() => setShowUnderWriters(true)}
                              >
                                {t(
                                  "tradeFinance.messaging.showRequiredSignatures"
                                )}
                              </div>
                            ) : (
                              <div
                                className="is-underlined"
                                onClick={() => setShowUnderWriters(false)}
                              >
                                {t(
                                  "tradeFinance.messaging.hideRequiredSignatures"
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {!underWritersInfo && underWritersInfoQuery.data && (
                          <div className="signature-hint">
                            {!showUnderWriters ? (
                              <div
                                className="is-underlined"
                                onClick={() => setShowUnderWriters(true)}
                              >
                                {t(
                                  "tradeFinance.messaging.showRequiredSignatures"
                                )}
                              </div>
                            ) : (
                              <div
                                className="is-underlined"
                                onClick={() => setShowUnderWriters(false)}
                              >
                                {t(
                                  "tradeFinance.messaging.hideRequiredSignatures"
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {isEditable && (
                          <RoundedButton
                            label={t(sendButtonCaption)}
                            disabled={
                              !values.subject ||
                              hasIncompleteDocument ||
                              isSubmitting
                            }
                            icon={<SignFinal />}
                            loading={isSubmitting}
                            onClick={handleSubmit}
                          />
                        )}

                        {!isEditable &&
                          !underWritersInfo?.signedByMe &&
                          underWritersInfo &&
                          !underWritersInfo.completed && (
                            <RoundedButton
                              buttonClass={"sign-message"}
                              label={t(sendButtonCaption)}
                              disabled={signAction.isLoading || !canSign}
                              onClick={signAction.run}
                              icon={<SignFinal />}
                            />
                          )}
                      </div>
                    </div>
                  </div>

                  <div className={"contact-message-header"}>
                    <div className={classnames("message-avatar", "dark")}>
                      <div
                        className={classnames(
                          "button is-success is-outlined is-rounded is-small is-static bottom-margin",
                          { "is-yellow": underWritersInfo }
                        )}
                      >
                        {underWritersInfo
                          ? t("tradeFinance.messaging.waitingForSigning")
                          : t("tradeFinance.messaging.newMessage")}
                      </div>
                      {company && (
                        <div
                          className={"contact-message-header-sender is-bold"}
                        >
                          {company.companyName}
                        </div>
                      )}
                      <div className={"contact-message-header-sender is-bold"}>
                        {userStatus!.user.name}
                      </div>
                    </div>
                    <div className="message-buttons">
                      {isReOpenable && (
                        <RoundedButton
                          buttonClass="re-open"
                          label={t("tradeFinance.reOpen")}
                          onClick={reOpenAction.run}
                          loading={reOpenAction.isLoading}
                          icon={<Edit />}
                        />
                      )}
                      {isDeletable && tradeFinance.state !== "converted" && (
                        <RoundedButton
                          buttonClass="delete-message"
                          label={t("tradeFinance.messaging.delete")}
                          disabled={isSubmitting}
                          onClick={deleteMessage}
                          loading={reOpenAction.isLoading}
                          icon={<Delete />}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Form>

        {showUnderWriters &&
          (underWritersInfo || underWritersInfoQuery.data) && (
            <div className="underwriter-info">
              <div className="underwriter-info--title">
                {t("tradeFinance.messaging.underwritersTitle")}
              </div>
              <UnderWritersBlock
                info={underWritersInfo ?? underWritersInfoQuery.data}
              />
            </div>
          )}
      </div>
    </>
  );
}

export default EditedMessage;
