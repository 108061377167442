import { UserNotificationData } from "../../types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import * as React from "react";
import { Info } from "../../icons/Icons";
import { useLocation } from "react-router";
import classnames from "classnames";
import { CheckBox } from "../../components";
import { UserNotificationComponentProps } from "./assets/types";
import { setNotificationData } from "./assets/helper";

function UserNotificationComponent(props: UserNotificationComponentProps) {
  const { pathname } = useLocation();
  const isConfigPage = pathname === "/portal/userConfiguration";
  const { t } = useTranslation();
  const { user, setUserNotifications } = props;
  const [notificationData, _setNotificationData] =
    useState<UserNotificationData>({
      signatureSMS:
        user.notificationSignature === "sms" ||
        user.notificationSignature === "smsAndEmail",
      signatureEmail:
        user.notificationSignature === "email" ||
        user.notificationSignature === "smsAndEmail",
      infoSMS:
        user.notificationInformation === "sms" ||
        user.notificationInformation === "smsAndEmail",
      infoEmail:
        user.notificationInformation === "email" ||
        user.notificationInformation === "smsAndEmail",
      toDoSMS:
        user.notificationToDo === "sms" ||
        user.notificationToDo === "smsAndEmail",
      toDoEmail:
        user.notificationToDo === "email" ||
        user.notificationToDo === "smsAndEmail",
    });

  const {
    signatureSMS,
    signatureEmail,
    toDoSMS,
    toDoEmail,
    infoSMS,
    infoEmail,
  } = notificationData;

  return (
    <div className="basic-data columns">
      <div className={classnames("column", { "is-6": !isConfigPage })}>
        <div className="table-container">
          <table className="table portal-table notification-table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>{t("profile.contactSettings.notificationType")}</th>
                <th>SMS</th>
                <th>E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("profile.contactSettings.notificationSignature")}</td>
                <td className={"is-checkbox"}>
                  <CheckBox
                    id={"signatureSMS"}
                    checked={signatureSMS}
                    onChange={(value) =>
                      setNotificationData(
                        {
                          ...notificationData,
                          signatureSMS: value,
                        },
                        _setNotificationData,
                        setUserNotifications
                      )
                    }
                  />
                </td>
                <td className={"is-checkbox"}>
                  <CheckBox
                    id={"signatureEmail"}
                    checked={signatureEmail}
                    onChange={(value) =>
                      setNotificationData(
                        {
                          ...notificationData,
                          signatureEmail: value,
                        },
                        _setNotificationData,
                        setUserNotifications
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>{t("profile.contactSettings.notificationToDo")}</td>
                <td className={"is-checkbox"}>
                  <CheckBox
                    id={"toDoSMS"}
                    checked={toDoSMS}
                    onChange={(value) =>
                      setNotificationData(
                        {
                          ...notificationData,
                          toDoSMS: value,
                        },
                        _setNotificationData,
                        setUserNotifications
                      )
                    }
                  />
                </td>
                <td className={"is-checkbox"}>
                  <CheckBox
                    id={"toDoEmail"}
                    checked={toDoEmail}
                    onChange={(value) =>
                      setNotificationData(
                        {
                          ...notificationData,
                          toDoEmail: value,
                        },
                        _setNotificationData,
                        setUserNotifications
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>{t("profile.contactSettings.notificationInformation")}</td>
                <td className={"is-checkbox"}>
                  <CheckBox
                    id={"infoSMS"}
                    checked={infoSMS}
                    onChange={(value) =>
                      setNotificationData(
                        {
                          ...notificationData,
                          infoSMS: value,
                        },
                        _setNotificationData,
                        setUserNotifications
                      )
                    }
                  />
                </td>
                <td className={"is-checkbox"}>
                  <CheckBox
                    id={"infoEmail"}
                    checked={infoEmail}
                    onChange={(value) =>
                      setNotificationData(
                        {
                          ...notificationData,
                          infoEmail: value,
                        },
                        _setNotificationData,
                        setUserNotifications
                      )
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={"is-flex notification-consent"}>
          <Info />
          {t("profile.basicData.notificationConsent")}
        </div>
      </div>
    </div>
  );
}
export default UserNotificationComponent;
