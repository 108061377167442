import classnames from "classnames";
import React from "react";
import { CalendarIcon } from "../../../icons/Icons";

const DayPickerInputWithIcon = React.forwardRef((props: any, ref) => {
  const {
    value,
    disabled,
    error,
    onChange,
    type,
    onClick,
    className,
    isTouched,
    minDate,
    maxDate,
    setError,
    setValue,
    ...restProps
  } = props;
  return (
    <div className="control has-icons-right">
      <input
        type="text"
        className={classnames("input is-small", { "is-danger": !!error })}
        ref={ref}
        value={value || ""}
        disabled={disabled}
        onClick={(e) => !disabled && onClick(e)}
        onChange={onChange}
        {...restProps}
      />
      <span className="icon is-small is-right day-picker-icon">
        <CalendarIcon />
      </span>
    </div>
  );
});

export default DayPickerInputWithIcon;
