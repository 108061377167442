import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EventHistoryRow } from "..";
import { TradeFinanceEventHistoryComponentProps } from "../type";

function TradeFinanceEventHistoryComponent({
  events,
  messageEvents,
  tradeFinanceId,
}: TradeFinanceEventHistoryComponentProps) {
  const [openedHistoryId, setOpenedHistoryId] = useState<number | null>(null);
  const { t } = useTranslation();

  return (
    <div className="event-history-component">
      <p className={"title is-5"}>{t("tradeFinance.eventHistory.title")}</p>
      {events.map((history, idx) => {
        return (
          <EventHistoryRow
            key={idx}
            history={history}
            tradeFinanceId={tradeFinanceId}
            showMessageEvents={false}
            openedHistoryId={openedHistoryId}
            setOpenedHistoryId={setOpenedHistoryId}
          />
        );
      })}
      <br />
      <p className={"title is-5 top-margin"}>
        {t("tradeFinance.messageHistory.title")}
      </p>
      {messageEvents.map((messageHistory, idx) => {
        return (
          <EventHistoryRow
            key={idx}
            history={messageHistory}
            tradeFinanceId={tradeFinanceId}
            showMessageEvents={true}
            openedHistoryId={openedHistoryId}
            setOpenedHistoryId={setOpenedHistoryId}
          />
        );
      })}
    </div>
  );
}

export default TradeFinanceEventHistoryComponent;
