import {
  Comment,
  Notification,
  Company,
  TFDocument,
  TradeFinance,
} from "../../../types";

export const MAX_FILE_SIZE = 50 * 1024 * 1024;

export interface MessageComponentProps {
  comment: Comment;
  tradeFinanceId: string;
  company: Company;
}

export interface TabProps {
  tradeFinance: TradeFinance;
}

export type MessageEditorType = {
  subject: string;
  content: string;
  documents: TFDocument[];
};

export interface EditedMessageProps {
  tradeFinance: TradeFinance;
  comment: Comment;
  company: Company;
  close: () => void;
}

export type MultiFileUploadProps = {
  comment: Comment;
  editable: boolean;
  descriptionRequired: boolean;
  updateDocuments?: (docs: TFDocument[]) => void;
  tradeFinanceId: string;
  error?: string | null | undefined;
  setError: (error: string | null) => void;
};

export type DescriptionFieldProps = {
  document: TFDocument;
  editable: boolean;
  required: boolean;
  tradeFinance: TradeFinance;
  updateDocument: (doc: TFDocument) => void;
  error?: string | null | undefined;
};

export type DocumentsTableProps = {
  documentHolder: Comment | Notification;
  documentHolderType: "comment" | "notification";
  tradeFinanceId: string;
};
