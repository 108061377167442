import * as React from "react";
import classnames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { usePrevious } from "../../hooks";
import {
  isClaimEvent,
  isLetterOfCredit,
} from "../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { isIEBrowser } from "../../pages/tradeFinanceHooks";
import { TradFinanceRequestContext } from "../../pages/TradeFinanceRequestDetailsPage/assets/context";
import { TabId, TabProps, TabsProps } from "./assets/types";

function Tabs(props: TabsProps) {
  const { tradeFinance } = useContext(TradFinanceRequestContext);
  const prevActiveTabFromPros = usePrevious(props.activeTab);
  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const tabElementRef = useRef<HTMLElement>();
  const [activeTab, setActiveTab] = useState<TabId | undefined>(
    props.activeTab
  );
  const isIe = isIEBrowser();
  const { tabs, className } = props;

  if (
    prevActiveTabFromPros &&
    props.activeTab &&
    prevActiveTabFromPros !== props.activeTab &&
    props.activeTab !== activeTab
  ) {
    setActiveTab(props.activeTab);
  }

  useEffect(() => {
    if (tabContainerRef.current && tabElementRef.current && !isIe) {
      tabContainerRef.current.scroll({
        left: tabElementRef.current.offsetLeft - 16,
        behavior: "smooth",
      });
    }
  }, [activeTab]);

  function handleTabChange(tab: TabProps, e: any) {
    if (tab.id !== activeTab) {
      tabElementRef.current = e.target;
      if (!tab.disabled) {
        let allowTabChange = true;
        if (props.onTabChange) {
          allowTabChange = props.onTabChange(tab.id);
        }

        if (allowTabChange) {
          setActiveTab(tab.id);
        }
      }
    }
  }

  function getTab(tabId: TabId): TabProps {
    const tab = tabs.find(
      (tab) => (isNaN(tabId) ? tabId : Number(tabId)) === tab.id
    );
    if (!tab) {
      throw new Error(`Tab '${tabId}' doesn't exists`);
    }
    return tab;
  }

  return (
    <React.Fragment>
      {tabs.length > 1 &&
        !(isLetterOfCredit(tradeFinance) && isClaimEvent(tradeFinance)) && (
          <div
            ref={tabContainerRef}
            className={classnames("tabs is-boxed", className)}
          >
            <ul>
              {tabs.map((tab) => {
                const { id, label, tag } = tab;
                if (id === 1 || id === 3) {
                  return (
                    <li
                      key={id}
                      onClick={(e) => handleTabChange(tab, e)}
                      className={classnames(tab.className, {
                        "is-active": activeTab === tab.id,
                      })}
                    >
                      <a>{label}</a>
                      {tag}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
            <ul>
              {tabs.map((tab) => {
                const { id, label, tag } = tab;
                if (id === 0 || id === 2) {
                  return (
                    <li
                      key={id}
                      onClick={(e) => handleTabChange(tab, e)}
                      className={classnames(tab.className, {
                        "is-active": activeTab === tab.id,
                      })}
                    >
                      <a>{label}</a>
                      {tag}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        )}
      <div className="tab-content">{getTab(activeTab).render()}</div>
    </React.Fragment>
  );
}

export default Tabs;
