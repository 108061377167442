import { FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportIcon } from "../../../../icons/Icons";
import { PopUp, RoundedButton } from "../../../../components";
import { usePopUp } from "../../../../components/PopUpComponents/assets/hooks";
import { useGenerateReport } from "../../hooks";
import { Report } from "../../types";

function GenerateReportButtonUseForm({ form }: { form: FormikProps<Report> }) {
  const { t } = useTranslation();
  const generateReportAction = useGenerateReport();
  const popUp = usePopUp(null);
  const [error, setError] = useState<boolean>(false);

  async function generateReport() {
    popUp.close();

    setError(false);
    const validationResult = await form.validateForm(form.values);
    if (!validationResult || Object.keys(validationResult).length === 0) {
      await generateReportAction.mutate(form.values);
    } else {
      setError(true);
    }
  }

  return (
    <>
      <RoundedButton
        label={t("report.actions_options.generateReport")}
        onClick={generateReport}
        ref={popUp.elementRef}
        disabled={generateReportAction.isLoading}
        buttonClass={"is-success"}
        icon={<ReportIcon />}
      />

      <PopUp
        {...popUp}
        isOpened={error}
        content={t("mandatoryFieldsNotFilled")}
        close={() => setError(false)}
      />
    </>
  );
}

export default GenerateReportButtonUseForm;
