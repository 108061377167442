import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Step } from "..";
import { TradFinanceRequestContext } from "../../../context";
import { useIsTemplate } from "../../../hooks";
import { StepId } from "../../../type";
import { TradeFinanceDocumentsComponent } from "./assets";

function DocumentsStep() {
  const { t } = useTranslation();
  const { tradeFinance, uploadDocuments, save, form } = useContext(
    TradFinanceRequestContext
  );

  if (useIsTemplate(tradeFinance)) {
    return null;
  }

  return (
    <div className="table-container">
      <Step stepId={StepId.documents} title={t("tradeFinance.documents")}>
        <TradeFinanceDocumentsComponent
          uploadDocuments={uploadDocuments}
          save={() => save(StepId.requestDetails)}
          isSubmitting={form.isSubmitting}
        />
      </Step>
    </div>
  );
}

export default DocumentsStep;
