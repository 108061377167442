import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { TabProps } from "./assets/types";
import * as TFApi from "../../api/tradeFinanceApi";
import { Comment } from "../../types";
import { EditedMessage, MessageComponent, NewMessageDivider } from "./assets";
import { AppContext } from "../../App";
import {
  DeviceType,
  isIEBrowser,
  useDeviceType,
} from "../../pages/tradeFinanceHooks";
import { TradFinanceRequestContext } from "../../pages/TradeFinanceRequestDetailsPage/assets/context";
import { RoundedButton } from "..";
import { Plus } from "../../icons/Icons";

function MessagesTab({ tradeFinance }: TabProps) {
  const { t } = useTranslation();
  const messagesRef = useRef<HTMLDivElement>(null);
  const { user } = useContext(AppContext);
  // last unread message id (from db)
  const [lastSeenMarker, setLastSeenMarker] = useState<number>(0);
  const device = useDeviceType();
  const { loadCommentsAction } = useContext(TradFinanceRequestContext);
  const company = user?.defaultCompany;
  const [showMessageForm, setShowMessageForm] = useState<boolean>(false);
  const isIE = isIEBrowser();

  useEffect(() => {
    if (messagesRef.current && !isIE) {
      const headerOffset = messagesRef.current.scrollHeight;

      window.scrollTo({
        top: headerOffset,
        behavior: "auto",
      });
    }
  }, []);

  async function updateLastSeenMessage(messageId: number) {
    await TFApi.updateLastMessageInfo(tradeFinance.id, company!.id, messageId);
  }

  useEffect(() => {
    if (
      loadCommentsAction.result &&
      loadCommentsAction.result.length > 0 &&
      company
    ) {
      if (!lastSeenMarker) {
        if (tradeFinance.lastMessageInfo) {
          let targetArray = tradeFinance.lastMessageInfo.split(",");
          let targetElement = targetArray.find((value) =>
            value.startsWith(company.id + ":")
          );

          if (targetElement) {
            let messageId = Number(targetElement.split(":")[1]);

            if (messageId !== lastSeenMarker) {
              updateLastSeenMessage(
                loadCommentsAction.result[loadCommentsAction.result.length - 1]
                  .id
              );
            }
            setLastSeenMarker(messageId);
          }
          return;
        }
        // There was no last message record
        let commentLength = loadCommentsAction.result.length;
        let messageId = loadCommentsAction.result[commentLength - 1].id;
        updateLastSeenMessage(messageId);
        setLastSeenMarker(messageId);
      }

      return () => {
        if (
          loadCommentsAction.result &&
          loadCommentsAction.result.length > 0 &&
          company
        ) {
          let commentLength = loadCommentsAction.result.length;
          let messageId = loadCommentsAction.result[commentLength - 1].id;
          // if(lastSeenMarker!=0 && lastSeenMarker!==messageId){
          //     updateLastSeenMessage(messageId);
          // }
          setLastSeenMarker(messageId);
        }
      };
    }
  }, [loadCommentsAction.result, company, lastSeenMarker]);

  const hasInProgressSigning = loadCommentsAction.result?.some(
    (comment) => comment.underWritersInfo && !comment.underWritersInfo.completed
  );

  useEffect(() => {
    if (loadCommentsAction.result) {
      setShowMessageForm(
        loadCommentsAction.result.length === 0 || !hasInProgressSigning
      );
    }
  }, [loadCommentsAction.result, hasInProgressSigning]);

  if (!loadCommentsAction.result || !company) {
    return <div className={"button is-white is-loading is-large is-flex"} />;
  }

  const comments: Comment[] = loadCommentsAction.result;

  function NewMessageButton() {
    if (showMessageForm) {
      return null;
    }
    return (
      <RoundedButton
        icon={<Plus />}
        buttonClass={"new-message-button"}
        label={t("tradeFinance.messaging.writeMessage")}
        onClick={() => setShowMessageForm(true)}
      />
    );
  }

  function NoMessages() {
    return (
      <div className="no-messages">
        <span>{t("tradeFinance.messaging.noMessages")}</span>
      </div>
    );
  }

  return (
    <>
      <div className="contact-messages-layout">
        {comments.length === 0 && <NoMessages />}
        <div
          className={classnames("contact-messages", {
            "table-container": device !== DeviceType.desktop,
          })}
          ref={messagesRef}
        >
          {comments.map((comment, idx) => {
            if (comment.underWritersInfo) {
              return (
                <EditedMessage
                  key={comment.id}
                  tradeFinance={tradeFinance}
                  comment={comment}
                  company={company!}
                  close={() => {}}
                />
              );
            } else {
              return (
                <div
                  key={comment.id}
                  className={classnames({
                    "contact-container": device !== DeviceType.desktop,
                  })}
                >
                  {!tradeFinance.lastMessageInfo &&
                    idx === 0 &&
                    !comment.isCreateInFrontEnd && <NewMessageDivider />}
                  <MessageComponent
                    comment={comment}
                    company={company!}
                    tradeFinanceId={tradeFinance.id}
                  />
                  {lastSeenMarker === comment.id &&
                    idx !== comments.length - 1 &&
                    !comments[comments.length - 1].isCreateInFrontEnd && (
                      <NewMessageDivider />
                    )}
                </div>
              );
            }
          })}
        </div>

        {tradeFinance.state !== "converted" && showMessageForm && (
          <EditedMessage
            tradeFinance={tradeFinance}
            comment={{} as Comment}
            company={company!}
            close={() => {}}
          />
        )}
      </div>
      {tradeFinance.state !== "converted" && comments.length > 0 && (
        <NewMessageButton />
      )}
    </>
  );
}

export default MessagesTab;
