import { SelectOption } from "../../components/Fields/types";
import { Language, TFDocument, Univ } from "../../types";
import { Report } from "./types";

export function reportGeneratedCallback(report: Report) {
  const isHtml = (doc: TFDocument) => doc.fileName.endsWith(".html");
  const htmlReport = report.tfDocuments?.find(isHtml);
  console.log(htmlReport);
  if (htmlReport) {
    window.open(
      window.location.origin +
        window.location.pathname +
        "api/report/download/" +
        report.id +
        "/report-document/" +
        htmlReport.id,
      "__blank"
    );
  }

  if (report.tfDocuments.filter((doc) => !isHtml(doc)).length > 0) {
    window.open(
      window.location.origin +
        window.location.pathname +
        "api/report/download/" +
        report.id
    );
  }
}

export function selectOptionsToString(
  values: string[],
  options: SelectOption[],
  lang: Language
): string {
  return values
    .filter(
      (x) =>
        ![
          "outgoingStandbyBG",
          "outgoingStandbyLC",
          "incomingStandbyBG",
          "incomingStandbyLC",
        ].includes(x)
    )
    .map((val) => {
      const option = options.find((v) => v.value === val);
      return option ? (option.label as Univ)[lang] : val;
    })
    ?.join(", ");
}

export const PAGE_SIZE = 20;

export const activeProducts = [
  "IssuedGuarantee",
  "importLC",
  "outgoingStandbyBG",
  "outgoingStandbyLC",
  "outgoingStandby",
];
