// @ts-nocheck
import i18next from "i18next";
import { StylesConfig } from "react-select/src/styles";
import {
  CheckedIcon,
  DisabledCheckedIcon,
  DisabledUncheckedIcon,
  UncheckedHoverIcon,
  UncheckedIcon,
} from "../../icons/Icons";
import { TradeFinancePartner } from "../../types";
import { CheckBoxProps, SelectOption } from "./types";

export const reactSelectStyles: StylesConfig = {
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      fontSize: "0.75rem",
      minHeight: 30,
      backgroundColor: isDisabled ? "#EBEBEB" : "#ffffff",
      boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.16)",
      border: "1px solid #D6D6D6",
      borderRadius: "4px",
    };
  },
  container: (base) => ({
    ...base,
  }),

  valueContainer: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? "#EBEBEB" : "#ffffff",
      borderRadius: "4px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#EFF3F6" : "#ffffff",
      color: isSelected ? "#23558C" : "#212121",
      cursor: "pointer",
      padding: "2px 6px",
      fontSize: "0.75rem",
      minHeight: "30px",
      lineHeight: "30px",
      fontFamily: "Open Sans Semibold",
    };
  },
  placeholder: (base) => ({
    ...base,
    fontSize: "0.75rem",
    color: "#C2C2C2",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "2px",
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "2px",
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
  }),
  singleValue: (base) => ({
    ...base,
    color: "#212121",
  }),
  multiValue: (base) => ({
    ...base,
    color: "#212121",
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: "0 2px",
    fontSize: "0.75rem",
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

export const X_SET_PATTERN = /^[a-zA-Z0-9-/?:().,+' ]*$/;
export const Z_SET_PATTERN = /^[a-zA-Z0-9.,-/()/='+:?!"%&*<>;{@#_ ]*$/;

export const wrap = (s: string, w: number) =>
  s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"), "$1\n");

export function addNewLineToWord(word: string, maxLength: number) {
  return word.slice(0, maxLength) + "\n" + word.slice(maxLength, word.length);
}

export function breakByCharsInLIne(
  lines: string[],
  line: string,
  maxCharsInLine: number,
  i: number
) {
  if (line.length > maxCharsInLine) {
    let words = line.split(" ");
    for (let j = 0; j < words.length; j++) {
      let word = words[j];
      if (word.length > maxCharsInLine) {
        let longWord = line;
        lines[i] = addNewLineToWord(longWord, maxCharsInLine);
      }
    }
  }
}

export function wrapText(value: string, maxCharsInLine: number) {
  //TODO
  let lines = value.split(/\r?\n/);

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (i === 0) {
      breakByCharsInLIne(lines, line, 31, i);
    } else {
      breakByCharsInLIne(lines, line, maxCharsInLine, i);
    }
  }

  let linesWithEnter = lines.join("\n");
  let formattedText = wrap(linesWithEnter, maxCharsInLine);
  return formattedText;
}

export function normalizeValue(text: string): string {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function validateSwiftFieldValue(
  originalValue: string,
  validationType: string,
  lines: number,
  t: i18next.TFunction
): string | null {
  const value = normalizeValue(originalValue);
  const valueLines = value?.split(/\r?\n/) ?? [];
  const maxCharsInLine = parseInt(validationType.substr(1));
  let pattern: RegExp | null = null;
  let invalidCharPattern: RegExp | null = null;

  switch (validationType.toLowerCase()[0]) {
    case "x":
      pattern = X_SET_PATTERN;
      invalidCharPattern = /[^a-zA-Z0-9-/?:().,+' ]/;
      break;
    case "z":
      pattern = Z_SET_PATTERN;
      invalidCharPattern = /[^a-zA-Z0-9.,-/()/='+:?!"%&*<>;{@#_ ]/;
      break;
  }

  let errorMessages = [];
  if (value && pattern) {
    if (valueLines.length > lines) {
      errorMessages.push(t("swiftError.tooManyLines", { maxLines: lines }));
    }

    for (let i = 0; i < valueLines.length; i++) {
      let l = valueLines[i];
      if (l.length > maxCharsInLine) {
        errorMessages.push(
          t("swiftError.tooManyChars", {
            lineNumber: i + 1,
            maxChars: maxCharsInLine,
          })
        );
      }

      if (!l.match(pattern!)) {
        let badCharacters: string[] = [];
        for (let j = 0; j < l.length; j++) {
          let currentChar = l[j];

          if (currentChar.match(invalidCharPattern!)) {
            if (!badCharacters.includes(currentChar)) {
              badCharacters.push(currentChar);
            }
          }
        }
        errorMessages.push(
          t("swiftError.invalidChars", {
            lineNumber: i + 1,
            invalidChars: badCharacters.toString(),
          })
        );
      }
    }
  }

  if (errorMessages.length > 0) {
    let result = t("swiftError.invalidSwiftValue");
    errorMessages.every((message) => (result += "\n" + message));
    return result;
  } else {
    return null;
  }
}

export function getCheckboxIcon(
  { checked, disabled }: CheckBoxProps,
  hovered: boolean
): any {
  if (disabled) {
    if (checked) {
      return DisabledCheckedIcon;
    } else {
      return DisabledUncheckedIcon;
    }
  }

  if (checked) {
    return CheckedIcon;
  }

  if (!checked) {
    if (hovered) {
      return UncheckedHoverIcon;
    } else {
      return UncheckedIcon;
    }
  }
}

export function partnerToOption(partner: TradeFinancePartner): SelectOption {
  return {
    value: partner.swiftCode,
    label: partner.swiftCode + " - " + partner.bankName,
  };
}
