import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeader } from "../../components";
import { TradeFinanceList } from "../TradeFinanceListPage/assets";
import NewTradeFinanceButton from "./assets/NewTradeFinanceButton";

function TemplateListPage() {
  const { t } = useTranslation();

  let pageButtons = (
    <>
      <NewTradeFinanceButton
        label={t("template.newLetterOfCredit")}
        tradeFinanceType={"tradefin/importLC"}
      />
      <NewTradeFinanceButton
        label={t("template.newGuarantee")}
        tradeFinanceType={"tradefin/IssuedGuarantee"}
      />
      <NewTradeFinanceButton
        label={t("template.newCollection")}
        tradeFinanceType={"tradefin/exportCollection"}
      />
      <NewTradeFinanceButton
        label={t("template.newStandby")}
        tradeFinanceType={"tradefin/outgoingStandbyBG"}
      />
    </>
  );

  return (
    <>
      <PageHeader title={t("menu.templates")} headerContent={pageButtons} />

      <TradeFinanceList type="template" templateMode={true} />
    </>
  );
}

export default TemplateListPage;
