import * as React from "react";
import { KBLogo } from "../../icons/Icons";
import { DeviceType, useDeviceType } from "../../pages/tradeFinanceHooks";
import classnames from "classnames";
import { ContactsComponent } from "./assets";

function AppFooter() {
  const device = useDeviceType();

  return (
    <>
      <div className={classnames("footer")}>
        <div className="container is-flex">
          <div className="footer-logo-and-text is-flex">
            <KBLogo />
            <div className="footer-text">
              © {new Date().getFullYear()} Komerční banka a.s.
            </div>
          </div>
          {device === DeviceType.desktop && <ContactsComponent />}
        </div>
      </div>
      <div className={classnames("second-footer")}>
        {device !== DeviceType.desktop && <ContactsComponent />}
      </div>
    </>
  );
}

export default AppFooter;
