import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Step } from "..";
import { TextField } from "../../../../../../components";
import { getFieldProps } from "../../../../../../components/Form/helper";
import { WarningIcon } from "../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../context";
import { StepId } from "../../../type";

function ReferenceStep({ changed }: { changed: boolean }) {
  const { t } = useTranslation();
  const { tradeFinance, form } = useContext(TradFinanceRequestContext);
  const newTradeFinance = tradeFinance.id === "new" || !tradeFinance.identifier;

  if (!newTradeFinance) {
    return null;
  }

  return (
    <Step
      stepId={StepId.reference}
      title={t("newReference.title")}
      headerEnd={changed ? <WarningIcon className="warning-icon" /> : null}
    >
      <div className="details-comp">
        <div className="details-header">
          <div className="details-header-buttons portal-buttons"></div>
        </div>
      </div>
      <div className={"reference-container"}>
        <TextField
          {...getFieldProps(form, "referenceNo", t("newReference.label"))}
          required={true}
        />
        <div className={"info"}>{t("newReference.info")}</div>
      </div>
    </Step>
  );
}

export default ReferenceStep;
