import Config from "../Config";
import {
  Company,
  DashboardData,
  Language,
  Univ,
  User,
  UserStatus,
} from "../types";

import { FormValues } from "../components/Form/types";
import axios from "axios";
import * as H from "history";
import { SelectOption } from "../components/Fields/types";

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let history: H.History | undefined;
export let locationBeforeAuth: H.Location | null;

export function setLocationBeforeAuth(location: H.Location | null) {
  locationBeforeAuth = location;
}

export function setHistory(h: H.History) {
  history = h;
}

// 401 hiba figyeléséhez interceptor hozzáadása
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401 && history) {
      if (history.location.pathname !== "/login") {
        locationBeforeAuth = history.location;
      }

      if (error.response?.data?.redirectUrl) {
        window.location.href = error.response?.data?.redirectUrl;
      } else if (history.location && history.location.pathname === "/login") {
        return error;
      } else {
        history.push("/login");
      }
    } else if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.data
    ) {
      throw error.response.data;
    } else if (error && error.status >= 500 && error.status < 600) {
      //throw new Error("serverError");
      throw error;
    }

    throw error;
  }
);

// Új axios instance loginhoz (nincs interceptor)
const loginAxios = axios.create();

export async function postRequest<T>(url: string, body?: any): Promise<T> {
  return (await axios.post(url, body)).data;
}

export async function getRequest<T>(url: string): Promise<T> {
  return (await axios.get(url)).data;
}

export async function deleteRequest<T>(url: string): Promise<boolean> {
  let resp = await axios.delete(url);
  return resp.status >= 200 && resp.status < 300;
}

export async function login(email: string, password: string): Promise<User> {
  let user: User = (
    await loginAxios.post("api/login", {
      email,
      password,
    })
  ).data;

  return user;
}

export async function updateUserProfile(
  user: FormValues<User>
): Promise<FormValues<User>> {
  return postRequest("api/profile", user);
}

export async function getCompany(): Promise<Company> {
  return getRequest("api/profile/company");
}

export async function changeCompany(companyId: number): Promise<void> {
  await postRequest("api/profile/change-company/" + companyId);
}

export async function changeUserLanguage(language: Language): Promise<void> {
  await postRequest("api/profile/change-language/" + language);
}

export async function status(): Promise<UserStatus> {
  const userStatus: UserStatus = await getRequest("api/status");
  return userStatus;
}

export async function dashboardData(): Promise<DashboardData> {
  return getRequest("api/dashboard");
}

export async function logout(): Promise<{ redirectUrl: string }> {
  return postRequest("api/logout");
}

export type CodeElement = {
  id: string;
  univ: Univ;
  active: boolean;
  tags: string[];
};

const codeBaseCache = new Map<string, Promise<CodeElement[]>>();

export async function getCodeElements(
  codeBaseId: string,
  portalOnly?: boolean
): Promise<CodeElement[]> {
  if (codeBaseCache.has(codeBaseId)) {
    return (await codeBaseCache.get(codeBaseId)) || [];
  } else {
    const res: Promise<CodeElement[]> = new Promise((resolve) => {
      getRequest<CodeElement[]>(
        Config.serverUrl +
          "api/code-elements?codeBase=" +
          codeBaseId +
          "&portalOnly=" +
          !!portalOnly
      ).then(resolve);
    });

    codeBaseCache.set(codeBaseId, res);
    return res;
  }
}

export async function getCodeElementUniv(
  codeBaseId: string,
  codeElementId: string
): Promise<Univ | undefined> {
  if (codeElementId && codeBaseId) {
    const codeElements = await getCodeElements(codeBaseId);
    if (codeElements) {
      const ce = codeElements.find((ce) => ce.id === codeElementId);
      if (ce) {
        return ce.univ;
      }
    }
  }
  return undefined;
}

export async function getUserCompanies(
  userId?: number
): Promise<SelectOption[]> {
  const userQueryParam = userId ? `?userId=${userId}` : "";
  return await getRequest<SelectOption[]>(
    Config.serverUrl + `api/companies` + userQueryParam
  );
}

export async function getUserCompanyDTOs(userId?: number): Promise<Company[]> {
  const userQueryParam = userId ? `?userId=${userId}` : "";
  return await getRequest<Company[]>(
    Config.serverUrl + `api/companyDTO` + userQueryParam
  );
}
