import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";
import { CalendarIcon } from "../../../../../../icons/Icons";
import { DatePickerComponent } from "../DatePicker/DatePicker";

import "./DateBetweenFilter.scss";

export const DateBetweenFilter = (
  { column: { filterValue = [], preFilteredRows, setFilter, id } }: any,
  isFees: boolean
) => {
  const { t } = useTranslation();
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: Row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div className={classNames("DateBetweenFilter")}>
      <label>
        <span>
          {isFees
            ? t("transactionDetails.filterField.dueDateFrom")
            : t("transactionDetails.filterField.valueDateFrom")}{" "}
        </span>
        <span className={classNames("DateBetweenFilter-datepicker--span")}>
          <DatePickerComponent
            handleDateChange={(date: any) => {
              const val = Date.parse(date);
              setFilter((old: any = []) => [val ? val : undefined, old[1]]);
            }}
            date={filterValue[0]}
          />
          <CalendarIcon />
        </span>
      </label>

      <label>
        <span>
          {isFees
            ? t("transactionDetails.filterField.dueDateTo")
            : t("transactionDetails.filterField.valueDateTo")}
        </span>
        <span className={classNames("DateBetweenFilter-datepicker--span")}>
          <DatePickerComponent
            handleDateChange={(date: any) => {
              const val = Date.parse(date);
              setFilter((old: any = []) => [old[0], val ? val : undefined]);
            }}
            date={filterValue[1]}
          />
          <CalendarIcon />
        </span>
      </label>
    </div>
  );
};
