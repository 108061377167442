import React, { ReactElement, useContext } from "react";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { LinkFieldProps } from "../types";

function LinkField(props: LinkFieldProps): ReactElement {
  const { lang } = useContext(AppContext);
  const { id, value, linkCaption, newTab } = props;

  return DecorateField(
    props,
    <a href={value} target={newTab ? "_blank" : ""}>
      {linkCaption ? linkCaption[lang] : "Missing link caption:" + id}{" "}
    </a>,
    lang
  );
}

export default LinkField;
