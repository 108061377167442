import classnames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { RenderLabel } from "..";
import { Language } from "../../../types";
import { FieldProps, FieldType } from "../types";

function DecorateField(
  fieldProps: FieldProps,
  labelContent: ReactElement,
  lang: Language,
  afterLabel?: ReactNode
): ReactElement {
  const { label, id, required, type } = fieldProps;
  if (label) {
    return (
      <div key={id} className={classnames("field", fieldProps.className)}>
        <div className="field-header">
          {type !== FieldType.boolean && RenderLabel(fieldProps, lang)}
          {afterLabel}
        </div>
        <div className="control">
          {type === FieldType.boolean &&
            RenderLabel(fieldProps, lang, labelContent)}
          {type !== FieldType.boolean && labelContent}
        </div>

        {
          // fieldProps.isTouched &&
          fieldProps.error && (
            <p className="help is-danger">{fieldProps.error}</p>
          )
        }
      </div>
    );
  } else if (required) {
    return (
      <div className="is-flex">
        {labelContent}
        <span className="asterisk"> *</span>
      </div>
    );
  } else {
    return labelContent;
  }
}

export default DecorateField;
