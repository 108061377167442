import * as React from "react";
import { useContext, useRef, useState } from "react";
import { Company, User } from "../../types";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { TabId } from "./assets/types";
import { ProfilePageContext } from "./assets/context";
import { BasicData, CompanyProfile } from "./assets";
import { PageHeader, RoundedButton, Tabs, Modal } from "../../components";

export default function ProfilePage(props: { user: User }) {
  const { t } = useTranslation();
  const { updateAvatar } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState<TabId>("basicData");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [nextTab, setNextTab] = useState<TabId | null>(null);
  const companyFormRef: React.MutableRefObject<
    FormikProps<Company> | undefined
  > = useRef<FormikProps<Company>>();
  const basicDataFormRef: React.MutableRefObject<
    FormikProps<User> | undefined
  > = useRef<FormikProps<User>>();

  function onTabChange(tabId: TabId, skipValidation?: boolean) {
    if (
      !skipValidation &&
      (basicDataFormRef.current?.dirty || companyFormRef.current?.dirty)
    ) {
      if (!showConfirmation) {
        setShowConfirmation(true);
        setNextTab(tabId);
        return false;
      }
    }

    setActiveTab(tabId);
    setShowConfirmation(false);
    return true;
  }

  const { user } = props;

  return (
    <ProfilePageContext.Provider value={{ setChanged: () => {} }}>
      <PageHeader title={t("menu.settings")} />
      <div className="profile-page page">
        {/*Ha módosítanak a form-on, akkor tab váltás előtt rá kell kérdezni, hogy biztosan akarja-e*/}
        {showConfirmation && (
          <Modal
            isOpened={showConfirmation}
            modalCardClass="confirm-dialog profile-confirm"
            hideCloseButton={true}
          >
            <div className="box">
              <div className="title is-6">
                {t("profile.profileDataConfirmation")}
              </div>
              <div className="portal-buttons">
                <RoundedButton
                  label={t("yes")}
                  onClick={() => nextTab && onTabChange(nextTab!, true)}
                />
                <RoundedButton
                  label={t("no")}
                  onClick={() => {
                    setShowConfirmation(false);
                    setNextTab(null);
                  }}
                />
              </div>
            </div>
          </Modal>
        )}

        <div className="container">
          <Tabs
            activeTab={activeTab}
            onTabChange={onTabChange}
            tabs={[
              {
                id: "basicData",
                label: t("profile.tabs.basicData"),
                render: () => (
                  <BasicData
                    formRef={basicDataFormRef}
                    user={user}
                    updateAvatar={updateAvatar}
                  />
                ),
              },
              {
                id: "companyProfile",
                label: t("profile.tabs.company"),
                render: () => (
                  <CompanyProfile
                    formRef={companyFormRef}
                    onChange={() => false}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </ProfilePageContext.Provider>
  );
}
