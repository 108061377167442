import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function NewMessageDivider() {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "auto",
      });
    }
  }, []);

  return (
    <div className="new-message-divider" ref={ref}>
      <span>{t("new")}</span>
      <div />
    </div>
  );
}

export default NewMessageDivider;
