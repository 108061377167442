import React, { useContext, useRef, useState } from "react";
import useOnClickOutside from "use-onclickoutside";
import { AppContext } from "../../../App";
import { Help } from "../../../icons/Icons";
import { FieldProps } from "../types";

function HelpComponent({ help }: FieldProps) {
  const { lang } = useContext(AppContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef<HTMLElement>(null);

  useOnClickOutside(ref, () => setShowTooltip(false));

  if (!help) {
    return null;
  }

  return (
    <span
      ref={ref}
      onClick={() => setShowTooltip(!showTooltip)}
      className="field-help"
    >
      <Help className="help-icon" />
      {showTooltip && help && <div className="help-tooltip">{help[lang]}</div>}
    </span>
  );
}

export default HelpComponent;
