import React, { useEffect, useMemo, useState } from "react";
import { useMounted } from "../../../hooks";
import { CodeElement } from "../../../api/portalApi";
import { CodeBaseFieldProps, CodeBaseOption } from "../types";
import * as API from "../../../api/portalApi";
import { SelectField2 } from "..";

function CodeBaseField(props: CodeBaseFieldProps) {
  const [codeElements, setCodeElements] = useState<CodeElement[]>([]);

  const mountedRef = useMounted();
  useEffect(() => {
    if (props.codeBaseId) {
      API.getCodeElements(props.codeBaseId, props.portalOnly).then((res) => {
        if (mountedRef.current) {
          return setCodeElements(res);
        }
      });
    } else if (mountedRef.current) {
      setCodeElements([]);
    }
  }, [props.codeBaseId]);

  const options: CodeBaseOption[] = useMemo(() => {
    let ceOptions = (codeElements || []).filter(item => item.active).map(
      (ce) =>
        ({ label: ce.univ, value: ce.id, tags: ce.tags } as CodeBaseOption)
    );
    if (props.optionsFilter) {
      ceOptions = props.optionsFilter(ceOptions);
    }

    return ceOptions;
  }, [codeElements, props.optionsFilter]);

  return <SelectField2 {...props} options={options} />;
}
export default CodeBaseField;
