import { useState } from "react";
import { FieldProps } from "../Fields/types";

export function useField(
  fieldProps: Omit<FieldProps, "setValue" | "setError" | "onChange">
): FieldProps {
  const [value, setValue] = useState(fieldProps.value);
  const [error, setError] = useState<string | null>(fieldProps.error || null);

  function onChange(e: React.ChangeEvent<any>): void {
    setValue(e.target.type === "checkbox" ? e.target.checked : e.target.value);
  }

  return {
    ...fieldProps,
    value,
    setValue,
    error: error || undefined,
    setError,
    onChange,
  };
}
