import React, { useEffect, useState } from "react";
import { CloseIcon, SearchIcon } from "../../../../icons/Icons";
import { HelpDocFilterComponentProps } from "../types";

function HelpDocFilterComponent(props: HelpDocFilterComponentProps) {
  const { placeholder, search, onChange } = props;
  const [searchValue, setSearchValue] = useState(search || null);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  function simpleSearch() {
    onChange(searchValue);
  }

  function clear() {
    setSearchValue("");
    onChange(null);
  }

  return (
    <div className="trade-finance-filter-component container">
      {
        <div className="simple-filter">
          <div className="search-box control has-icons-left has-icons-right">
            <input
              type="text"
              className={"input"}
              value={searchValue || ""}
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  simpleSearch();
                }
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <span className="icon search-icon" onClick={simpleSearch}>
              <SearchIcon />
            </span>
            {searchValue && (
              <span className="icon clear-icon" onClick={clear}>
                <CloseIcon />
              </span>
            )}
          </div>
        </div>
      }
    </div>
  );
}

export default HelpDocFilterComponent;
