import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentsTable } from "../../../../../../../../components";
import { ArrowDown } from "../../../../../../../../icons/Icons";
import { formatDate } from "../../../../../tradeFinanceUtils";
import { NotificationProps } from "../types";

function NotificationComponent({
  notification,
  tradeFinanceId,
}: NotificationProps) {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={"notification-row"} key={notification.id}>
      <div className={"notification-row-title is-flex"}>
        <strong className="notification-item">
          {formatDate(notification.createDate)}
          <span style={{ marginLeft: "12px" }}>{notification.subject}</span>
        </strong>

        <div className="actions-column">
          <div
            className={classnames("is-small", "action-button", {
              "rotate-img": isExpanded,
            })}
            onClick={() => setExpanded(!isExpanded)}
          >
            {t(isExpanded ? "close" : "expand")}
            <ArrowDown />
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="trade-finance-notification">
          <div>
            <strong>{t("notification.referenceNoShort")}: </strong>
            {notification.referenceNo}
          </div>
          <div>
            <strong>{t("notification.subject")}: </strong>
            {notification.subject}
          </div>
          <div>
            <strong>{t("notification.createDateTime")}: </strong>
            {formatDate(notification.createDate)}
          </div>
          <div className={"top-margin"}>
            <div
              dangerouslySetInnerHTML={{ __html: notification.finalContent }}
            />
          </div>

          <DocumentsTable
            documentHolder={notification}
            documentHolderType={"notification"}
            tradeFinanceId={tradeFinanceId}
          />
        </div>
      )}
    </div>
  );
}

export default NotificationComponent;
