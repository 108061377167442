import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as API from "../../../../api/tradeFinanceApi";
import { Action, StateComponent } from "..";
import { CurrencyNumberFormat, RoundedButton, Modal } from "../../..";
import {
  LetterOfCredit,
  TradeFinance,
  TransactionChange,
  TransactionDetails,
} from "../../../../types";
import { useModal } from "../../../ModalComponent/assets/hooks";
import { useAction } from "../../../../hooks";
import { getAmendmentAction } from "../../../../pages/TradeFinanceListPage/assets/helper";
import {
  formatDate,
  isCollection,
  isImportLetterOfCredit,
  isOutgoingTradeFinance,
} from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { FilePDF, Plus } from "../../../../icons/Icons";
import { CodeElementComponent } from "../../../../pages";

function TransactionChangesComp({
  transactionChanges,
  tradeFinance,
}: TransactionDetails & { tradeFinance: TradeFinance }) {
  const { t } = useTranslation();
  const history = useHistory();

  const id = history.location.pathname.split("/").pop() as string;
  const [modalIsOpened, openModal, closeModal] = useModal(false);
  const typeWithoutActionColumn = [
    "tradefin/receivedGuarantee",
    "tradefin/exportLC",
    "tradefin/incomingStandbyLC",
    "tradefin/incomingStandbyBG",
    "tradefin/importCollection",
  ];
  const noActionColumn = typeWithoutActionColumn.includes(
    tradeFinance.tradeFinanceType
  );
  const amendmentStates = ["activated", "opened", "advised"];
  const amendmentButtonNeeded = amendmentStates.includes(tradeFinance.state);

  const tradeFinanceType = tradeFinance.tradeFinanceType;
  const isReceivedGuarantee = tradeFinanceType === "tradefin/receivedGuarantee";
  const isIncomingStandBy =
    tradeFinanceType === "tradefin/incomingStandbyLC" ||
    tradeFinanceType === "tradefin/incomingStandbyBG";
  const isIssuedGuarantee = tradeFinanceType === "tradefin/IssuedGuarantee";
  const isOutgoingStandbyBG = tradeFinanceType === "tradefin/outgoingStandbyBG";
  const isExportLC = tradeFinanceType === "tradefin/exportLC";

  const amendmentAction = useAction<void>(async () => {
    await getAmendmentAction(id, openModal, history);
  });

  function downloadDocuments(change: TransactionChange) {
    const link = API.downloadPortalDocs(change.tradeFinanceId, change.eventId);
    window.open(
      window.location.origin + window.location.pathname + link,
      "_blank"
    );
  }

  return (
    <div className="table-container">
      <div className="transaction-changes">
        <div className="table--header">
          <div className="table--title">
            {t("transactionDetails.transactionChanges.title")}
          </div>
          {isOutgoingTradeFinance(tradeFinance) &&
            !isCollection(tradeFinance) &&
            amendmentButtonNeeded && (
              <div>
                <RoundedButton
                  label={t("tradeFinance.newAmendment")}
                  icon={<Plus />}
                  onClick={amendmentAction.run}
                  loading={amendmentAction.isLoading}
                  disabled={tradeFinance.state === "converted"}
                />
                <Modal isOpened={modalIsOpened} onClose={closeModal}>
                  <div className="modal-message">
                    {t("tradeFinance.amendmentValidationMessage")}
                  </div>
                </Modal>
              </div>
            )}
        </div>

        <table className="table portal-table is-striped is-fullwidth">
          <thead>
            <tr>
              <th className="col-name">
                {t("transactionDetails.transactionChanges.name")}
              </th>
              <th className="col-number">
                {t("transactionDetails.transactionChanges.number")}
              </th>
              <th className="col-state">{t("transactionDetails.state")}</th>
              <th className="col-amount" align="right">
                {t("transactionDetails.transactionChanges.amount")}
              </th>
              <th className="col-issueDate">
                {t("transactionDetails.transactionChanges.issueDate")}
              </th>
              <th className="col-expiryDate">
                {t(
                  isCollection(tradeFinance)
                    ? "transactionDetails.transactionChanges.dueDate"
                    : "transactionDetails.transactionChanges.expiryDate"
                )}
              </th>
              {(tradeFinance.tradeFinanceType.endsWith("exportLC") ||
                tradeFinance.tradeFinanceType.endsWith("incomingStandbyLC") ||
                tradeFinance.tradeFinanceType.endsWith(
                  "incomingStandbyBG"
                )) && (
                <th>{t("transactionDetails.transactionChanges.confirmed")}</th>
              )}
              {!noActionColumn && (
                <th className="col-actions">
                  {t("transactionDetails.actions")}
                </th>
              )}
              <th className="col-issuedDocument">
                {t("transactionDetails.issuedDocument")}
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionChanges.map((change, idx) => {
              return (
                <tr key={idx}>
                  <td>{change.name}</td>
                  <td>{change.number}</td>
                  <td>
                    <StateComponent
                      stateName={change.stateName}
                      state={change.state}
                    />
                  </td>
                  <td className="no-wrap" align="right">
                    <strong>
                      <CurrencyNumberFormat
                        displayType={"text"}
                        value={change.amount}
                        currency={change.currency}
                      />{" "}
                      {change.currency}
                    </strong>
                  </td>
                  <td>
                    {formatDate(
                      tradeFinance.tradeFinanceType.endsWith(
                        "importCollection"
                      ) && !change.issueDate
                        ? (tradeFinance as LetterOfCredit).dateOfIssue
                        : change.issueDate
                    )}
                  </td>
                  <td>
                    {formatDate(
                      isCollection(tradeFinance)
                        ? change.acceptanceDueDate
                        : change.expiryDate
                    )}
                  </td>
                  {(tradeFinance.tradeFinanceType.endsWith("exportLC") ||
                    tradeFinance.tradeFinanceType.endsWith(
                      "incomingStandbyLC"
                    ) ||
                    tradeFinance.tradeFinanceType.endsWith(
                      "incomingStandbyBG"
                    )) && (
                    <td>
                      <CodeElementComponent
                        codeBaseId={"tradefin/TF_Confirmed"}
                        codeElement={
                          change.name ===
                          t("transactionDetails.transactionChanges.amendment")
                            ? change.amendmentConfirmed
                            : (tradeFinance as LetterOfCredit).confirmed
                        }
                      />
                    </td>
                  )}
                  {!noActionColumn && (
                    <td>
                      {change.action && (
                        <Action
                          action={change.action}
                          tradeFinanceId={change.tradeFinanceId}
                          eventId={change.eventId}
                        />
                      )}
                    </td>
                  )}
                  <td>
                    {(change.state === "approved" ||
                      change.state === "pendingAcceptance" ||
                      (change.name ===
                        t("transactionDetails.transactionChanges.amendment") &&
                        (isImportLetterOfCredit(tradeFinance) ||
                          isReceivedGuarantee ||
                          isIssuedGuarantee ||
                          isOutgoingStandbyBG ||
                          isExportLC ||
                          isIncomingStandBy) &&
                        (change.state === "rejected" ||
                          change.state === "waiting_for_beneficiary" ||
                          change.state === "refused"))) && (
                      <>
                        <RoundedButton
                          label={t("download")}
                          buttonClass="is-borderless"
                          icon={<FilePDF />}
                          onClick={() => downloadDocuments(change)}
                          disabled={!change.hasDocuments}
                        />
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TransactionChangesComp;
