import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useCodeBaseAsSelectOptions,
  useLoadingAction,
} from "../../../../hooks";
import { SectionProps } from "../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";
import { getFieldProps } from "../../../../components/Form/helper";
import { activeProducts } from "../../helper";
import { FieldProps, SelectOption } from "../../../../components/Fields/types";
import {
  CheckBoxSelectionField,
  CodeBaseField,
  DateField,
  NumberField,
  SelectField2,
} from "../../../../components";

function ReportFiltersSection({ form, isEditable }: SectionProps) {
  const { t } = useTranslation();

  const loadPartnersAction = useLoadingAction(() => TFApi.getPartners());

  const productTypeOptions = useCodeBaseAsSelectOptions(
    "tradefin/TF_productTypePortal"
  );

  const defaultProps: Partial<FieldProps> = { disabled: !isEditable };
  const selectedProducts = getFieldProps(
    form,
    "selectedProducts",
    t("report.filters.selectedProducts"),
    defaultProps
  );
  const states = getFieldProps(
    form,
    "states",
    t("report.filters.selectedStates"),
    defaultProps
  );
  const beneficiaries = getFieldProps(
    form,
    "beneficiaries",
    t("report.filters.beneficiaries"),
    defaultProps
  );
  const amountFrom = getFieldProps(
    form,
    "amountFrom",
    t("report.filters.amountFrom"),
    defaultProps
  );
  const amountTo = getFieldProps(
    form,
    "amountTo",
    t("report.filters.amountTo"),
    defaultProps
  );
  const currencies = getFieldProps(
    form,
    "currencies",
    t("report.filters.currencies"),
    defaultProps
  );
  const currency = useCodeBaseAsSelectOptions("tradefin/CCB_Currency");
  const issueFrom = getFieldProps(
    form,
    "issueFrom",
    t("report.filters.issueFrom"),
    defaultProps
  );
  const issueTo = getFieldProps(
    form,
    "issueTo",
    t("report.filters.issueTo"),
    defaultProps
  );
  const expiryDateFrom = getFieldProps(
    form,
    "expiryDateFrom",
    t("report.filters.expiryDateFrom"),
    defaultProps
  );
  const lengthOfProduct = getFieldProps(
    form,
    "lengthOfProduct",
    t("report.filters.lengthOfProduct"),
    defaultProps
  );
  const expiryDateTo = getFieldProps(
    form,
    "expiryDateTo",
    t("report.filters.expiryDateTo"),
    defaultProps
  );
  const subtype = getFieldProps(
    form,
    "subtype",
    t("report.filters.subtype"),
    defaultProps
  );

  const stateOptions: SelectOption[] = [
    { label: t("report.filters.states.issued"), value: "issued" },
    { label: t("report.filters.states.closed"), value: "Closed" },
    { label: t("report.filters.states.rejected"), value: "Rejected" },
  ];

  const lengthOfProductOptions = useCodeBaseAsSelectOptions(
    "tradefin/timeCharacter"
  );

  const beneficiariesOptions = useMemo(
    () =>
      (loadPartnersAction.result || []).map((partner) => ({
        label: partner.name,
        value: partner.id,
      })),
    [loadPartnersAction.result]
  );

  const currencyOptions = useMemo(
    () =>
      (currency || []).map((c) => ({
        label: c.value,
        value: c.value,
      })),
    [currency]
  );

  const isGuarantee =
    form.values.selectedProducts?.filter((product) =>
      product.toLowerCase().includes("guarantee")
    ).length > 0;
  const isStandBy =
    form.values.selectedProducts?.filter((product) =>
      product.toLowerCase().includes("standby")
    ).length > 0;

  let containsPassiveProduct = form.values.selectedProducts
    ? form.values.selectedProducts.some((x) => !activeProducts.includes(x))
    : false;
  let containsActiveProduct = form.values.selectedProducts
    ? form.values.selectedProducts.some((x) => activeProducts.includes(x))
    : false;

  useEffect(() => {
    if (containsActiveProduct && !containsPassiveProduct) {
      form.setFieldValue("lengthOfProduct", [
        "shortTerm",
        "mediumTerm",
        "longTerm",
      ]);
    } else if (containsPassiveProduct && !containsActiveProduct) {
      form.setFieldValue("lengthOfProduct", ["undefined"]);
    } else {
      form.setFieldValue("lengthOfProduct", [
        "shortTerm",
        "mediumTerm",
        "longTerm",
        "undefined",
      ]);
    }
  }, [form.values.selectedProducts]);

  useEffect(() => {
    if (!isGuarantee && form.values.subtype?.length > 0) {
      form.setFieldValue("subtype", []);
    }
  }, [isGuarantee]);

  return (
    <div className="report-filters section box">
      <div className="section--title">{t("report.filtersTitle")}</div>
      <div className="section--content">
        <div className="columns">
          <div className="column">
            <SelectField2
              {...selectedProducts}
              options={productTypeOptions || []}
              required={false}
              multiSelect={true}
              placeholder={t("report.all")}
            />
          </div>
          {isGuarantee && (
            <div className="column">
              <CodeBaseField
                {...subtype}
                codeBaseId={"tradefin/TF_GuaranteeSubtype"}
                multiSelect={true}
                placeholder={t("report.all")}
              />
            </div>
          )}
        </div>

        <CheckBoxSelectionField
          {...states}
          options={stateOptions}
          required={true}
        />
        <SelectField2
          {...beneficiaries}
          options={beneficiariesOptions}
          multiSelect={true}
          placeholder={t("report.all")}
        />
        <div className="columns">
          <div className="column">
            <NumberField {...amountFrom} />
          </div>
          <div className="column">
            <NumberField {...amountTo} />
          </div>
          <div className="column">
            <SelectField2
              {...currencies}
              options={currencyOptions}
              multiSelect={true}
              placeholder={t("report.all")}
            />
          </div>
          <div className="column"></div>
        </div>
        <div className="columns">
          <div className="column">
            <DateField {...issueFrom} />
          </div>
          <div className="column">
            <DateField {...issueTo} />
          </div>
          <div className="column">
            <DateField {...expiryDateFrom} />
          </div>
          <div className="column">
            <DateField {...expiryDateTo} />
          </div>
        </div>
        <CheckBoxSelectionField
          {...lengthOfProduct}
          options={lengthOfProductOptions || []}
          required={true}
        />
      </div>
    </div>
  );
}

export default ReportFiltersSection;
