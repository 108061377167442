import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { CloseIcon } from "../../../icons/Icons";
import { DocumentFieldProps, FileProps } from "../types";

function DocumentField(props: DocumentFieldProps) {
  const { t } = useTranslation();
  const { setValue } = props;
  const { lang } = useContext(AppContext);

  function _setValue(files: FileList | null) {
    const val = files ? files[0] : null;
    return setValue(val);
  }

  function renderDeleteButton(file?: FileProps) {
    if (file) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className="button is-outlined"
          onClick={async (e) => {
            if (file.id && props.onDelete) {
              props.onDelete(file.id);
            }
            _setValue(null);
          }}
        >
          <span className="icon is-small">
            <CloseIcon />
          </span>
        </a>
      );
    } else {
      return null;
    }
  }

  function renderDocument() {
    const { id, value } = props;
    const file = value as FileProps;
    return (
      <div className="file has-name">
        <label className="file-label">
          <input
            id={id}
            className="file-input"
            type="file"
            name={id}
            onChange={(e) => {
              _setValue(e.target.files);
            }}
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload" />
            </span>
            <span className="file-label">{t("chooseADocument")}</span>
          </span>
          {file && <span className="file-name">{file.name}</span>}
        </label>
        {renderDeleteButton(file)}
      </div>
    );
  }

  return DecorateField(props, renderDocument(), lang);
}

export default DocumentField;
