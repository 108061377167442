import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as API from "../../api/tradeFinanceApi";
import { AppContext } from "../../App";
import { SaveIcon } from "../../icons/Icons";
import {
  ErrorMap,
  UnderwriterConfigItem,
  UpdateNewValueFn,
} from "./assets/types";
import {
  convertItemsToUnderWriters,
  convertToUnderwriterConfigItems,
  underwriterComparator,
  underwriterConfigItEquals,
  underwriterItemComparator,
} from "./assets/helper";
import NewValueSetter from "./assets/NewValueSetter/NewValueSetter";
import { UnderWriterConfigItemComponent } from "./assets";
import { PageHeader, RoundedButton } from "../../components";

export default function UnderwritersConfigPage() {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [items, setItems] = useState<UnderwriterConfigItem[] | null>(null);
  const [fetchCount, setFetchCount] = useState(0);
  const [limitErrors, setLimitErrors] = useState<ErrorMap>({});

  let loadUnderwritersAction = useQuery("underwriters", async () => {
    const result = await API.findUnderwriters();
    setFetchCount(fetchCount + 1);
    return result;
  });
  const saveUnderWriters = useMutation(
    async (items: UnderwriterConfigItem[]) => {
      const errors = validateUnderwriterConfigItems();

      if (Object.keys(errors).length === 0) {
        const underWriters = convertItemsToUnderWriters(items);
        await API.saveUnderwriters(underWriters);
        await queryClient.refetchQueries("underwriters");
      }

      return null;
    }
  );

  const isLoading = saveUnderWriters.isLoading;

  useEffect(() => {
    if (loadUnderwritersAction.data) {
      setItems(
        convertToUnderwriterConfigItems(
          (loadUnderwritersAction.data || []).sort(underwriterComparator)
        )
      );
      setLimitErrors({});
    }
  }, [loadUnderwritersAction.data]);

  function updateItem(
    id: string,
    field: "groupA" | "groupB" | "limit",
    value: number | null
  ) {
    items &&
      setItems(
        items.map((item, i) => {
          if (item.id === id) {
            const newItem = { ...item, [field]: value };
            return newItem;
          } else {
            return item;
          }
        })
      );
  }

  const updateNewValues: UpdateNewValueFn = (newValues) => {
    if (items) {
      setItems(
        items.map((item) => ({
          ...item,
          groupA: newValues.groupA,
          groupB: newValues.groupB,
        }))
      );
    }
  };

  function validateUnderwriterConfigItems() {
    let errorResult = {} as ErrorMap;

    const item_tmp_fn = () =>
      items?.forEach((item) => {
        const itemsWithSameLimit = items.filter(
          (i) =>
            i.product === item.product &&
            i.eventName === item.eventName &&
            i.limit === item.limit
        );

        itemsWithSameLimit.forEach((itemWithSameLimit, idx) => {
          if (idx > 0) {
            errorResult[itemWithSameLimit.id] = "limitValidation";
          }
        });

        if (!item.groupA && !item.groupB) {
          errorResult[item.id] = "signatureCountValidation";
        }
      });

    item_tmp_fn();

    setLimitErrors(errorResult);

    return errorResult;
  }

  function addItem(item: UnderwriterConfigItem) {
    if (items && !items.find((itm) => underwriterConfigItEquals(item, itm))) {
      const newItems = [...items];
      newItems.push(item);
      newItems.sort(underwriterItemComparator);
      setItems(newItems);
    }
  }

  function removeItem(item: UnderwriterConfigItem) {
    if (items) {
      const newItems = items.filter((i) => i.id !== item.id);
      setItems(newItems);
      const newLimitErrors = { ...limitErrors };
      delete newLimitErrors[item.id];
      setLimitErrors(newLimitErrors);
    }
  }

  if (!items || loadUnderwritersAction.status === "loading") return null;

  return (
    <div className="page underwriters-config-page">
      <PageHeader title={t("underwriter.underwriters")} />

      <div className="container box">
        <div className="table-container">
          <table className="table portal-table underwriters-table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>{t("underwriter.type")}</th>
                <th className={"has-right-text"}>{t("underwriter.event")}</th>
                <th className="field-col">{t("underwriter.limit")}</th>
                <th className={"number-col has-right-text"}>
                  {t("menu.signatures")} <br />
                  {t("underwriter.groupA")}
                </th>
                <th className={"number-col has-right-text"}>
                  {t("menu.signatures")} <br />
                  {t("underwriter.groupB")}
                </th>
                {user?.superUser && <th>{t("actions")}</th>}
              </tr>
            </thead>

            <tbody key={fetchCount}>
              {user?.superUser && (
                <NewValueSetter updateNewValue={updateNewValues} />
              )}

              {items.map((item, idx) => (
                <UnderWriterConfigItemComponent
                  key={item.product + item.eventName + idx}
                  item={item}
                  items={items}
                  idx={idx}
                  limitErrors={limitErrors}
                  setLimitErrors={setLimitErrors}
                  validateItems={validateUnderwriterConfigItems}
                  addItem={addItem}
                  removeItem={removeItem}
                  updateItem={updateItem}
                />
              ))}
            </tbody>
          </table>
        </div>

        {user?.superUser && (
          <div className="portal-buttons">
            <RoundedButton
              label={t("saveChanges")}
              onClick={() => saveUnderWriters.mutate(items)}
              icon={<SaveIcon />}
              disabled={isLoading}
              loading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}
