import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CodeBaseField } from "../../../../components";
import { CodeBaseOption } from "../../../../components/Fields/types";
import {
  isExportCollection,
  isExportLetterOfCredit,
  isGuarantee,
  isImportLetterOfCredit,
  isPreCheckEvent,
  isReceivedTradeFinance,
  isStandBy,
} from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";
import { DescriptionFieldProps } from "../../../MessagesComponents/assets/types";

function DocumentFileTypeField({
  tradeFinance,
  document,
  editable,
  required,
  updateDocument,
  error,
}: DescriptionFieldProps) {
  const { t } = useTranslation();
  const [touched, setTouched] = useState<boolean>(false);

  function fileTypeFilter(options: CodeBaseOption[]): CodeBaseOption[] {
    let enabledFileTypes: String[] = [];
    if (isExportCollection(tradeFinance)) {
      enabledFileTypes = ["documents", "other"];
    } else if (
      isStandBy(tradeFinance) &&
      !isReceivedTradeFinance(tradeFinance)
    ) {
      enabledFileTypes = ["contract", "documents", "other"];
    } else if (
      isGuarantee(tradeFinance) &&
      !isReceivedTradeFinance(tradeFinance)
    ) {
      enabledFileTypes = [
        "contract",
        "documents",
        "guarantee-Template",
        "guaranteeAmendment-Template",
        "other",
      ];
    } else if (
      !isReceivedTradeFinance(tradeFinance) &&
      (isStandBy(tradeFinance) || isImportLetterOfCredit(tradeFinance))
    ) {
      enabledFileTypes = ["lCDraft", "documents", "other"];
    } else if (
      isExportLetterOfCredit(tradeFinance) &&
      isPreCheckEvent(tradeFinance)
    ) {
      enabledFileTypes = ["lCDraft", "documents", "other"];
    }

    return options.filter((opt) => enabledFileTypes.includes(opt.value));
  }

  return (
    <CodeBaseField
      codeBaseId="wdp/fileType"
      id={"fileType"}
      label={t("document.type")}
      setValue={(val) => updateDocument({ ...document, fileType: val })}
      value={document.fileType}
      disabled={!editable}
      required={required}
      optionsFilter={fileTypeFilter}
      error={
        error ||
        (touched && !document.fileType ? t("mandatoryField") : undefined)
      }
      onBlur={() => setTouched(true)}
      setError={() => {}}
      onChange={(e) => {}}
    />
  );
}

export default DocumentFileTypeField;
