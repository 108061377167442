import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../../../App";
import { useAction } from "../../../../../../hooks";
import { TradFinanceRequestContext } from "../../../context";
import {
  getMissingSignatureCount,
  isClaimEvent,
  isLetterOfCredit,
} from "../../../tradeFinanceUtils";
import { SignStepProps, StepId } from "../../../type";
import * as TFAPi from "../../../../../../api/tradeFinanceApi";
import * as TFApi from "../../../../../../api/tradeFinanceApi";
import { signFunction } from "../../../apiFunctions";
import classnames from "classnames";
import { Step, UnderWritersBlock } from "..";
import {
  GetFinalDocumentModal,
  GetSignatureSentences,
  GetStepButtons,
} from "./assets";

function SignStep({ showFinalDocument, setShowFinalDocument }: SignStepProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    tradeFinance,
    underWritersInfo,
    reLoad,
    loadDocumentsAction,
    hasSignError,
  } = useContext(TradFinanceRequestContext);
  const letterOfCreditClaimEvent =
    isLetterOfCredit(tradeFinance) && isClaimEvent(tradeFinance);

  const { refreshStats, portalConfig } = useContext(AppContext);

  const signAction = useAction(async () => {
    await TFAPi.readyForSign(tradeFinance);

    if (!underWritersInfo?.hasRightToSign) {
      history.push("/portal/signatures");
    } else {
      const missingSignatureCount = getMissingSignatureCount(underWritersInfo);
      await signFunction(
        tradeFinance,
        history,
        portalConfig.caas,
        missingSignatureCount === 1
      );
    }
    refreshStats();
  });

  const deleteSignatureAction = useAction(async () => {
    await TFApi.deleteSignature(tradeFinance);
    await reLoad();
    await loadDocumentsAction.run();
  });

  if (!underWritersInfo) {
    return null;
  }

  return (
    <Step
      stepId={StepId.sign}
      title={t("tradeFinance.finishRequest")}
      stepClass={classnames({
        "claim-event-sign-step": letterOfCreditClaimEvent,
      })}
      state={
        tradeFinance.initiationAs
          ? t("tradeFinance.initiationAs." + tradeFinance.initiationAs)
          : null
      }
    >
      <div
        className={classnames("portal-buttons", {
          "is-flex": letterOfCreditClaimEvent,
        })}
      >
        <GetStepButtons
          deleteSignatureAction={deleteSignatureAction}
          signAction={signAction}
          letterOfCreditClaimEvent={letterOfCreditClaimEvent}
          setShowFinalDocument={setShowFinalDocument}
        />
      </div>

      {(signAction.error || hasSignError) && (
        <div className="top-margin bottom-margin error-message">
          {hasSignError
            ? t("tradeFinance.dateError")
            : t(
                ((signAction.error || signAction.error) as string) ||
                  "serverError"
              )}
        </div>
      )}
      <div className="sign-step container">
        {
          <GetSignatureSentences
            letterOfCreditClaimEvent={letterOfCreditClaimEvent}
            showFinalDocument={showFinalDocument}
          />
        }
        <div
          className={classnames("title", {
            "top-margin": !letterOfCreditClaimEvent,
          })}
        >
          {t(
            letterOfCreditClaimEvent
              ? "tradeFinance.signaturesRequiredClaim"
              : underWritersInfo.draft
              ? "tradeFinance.signaturesRequired_draft"
              : "tradeFinance.signaturesRequired"
          )}
        </div>
        {underWritersInfo ? (
          <UnderWritersBlock info={underWritersInfo} />
        ) : (
          <div>{t("underwriter.missingUnderWritersInfo")}</div>
        )}
      </div>

      {showFinalDocument && (
        <GetFinalDocumentModal
          signAction={signAction}
          showFinalDocument={showFinalDocument}
          setShowFinalDocument={setShowFinalDocument}
          letterOfCreditClaimEvent={letterOfCreditClaimEvent}
        />
      )}
    </Step>
  );
}

export default SignStep;
