import { NotificationAlertType, UserNotificationData } from "../../../types";

export function setNotificationData(
  data: UserNotificationData,
  _setNotificationData: React.Dispatch<
    React.SetStateAction<UserNotificationData>
  >,
  setUserNotifications: (
    info: NotificationAlertType,
    toDo: NotificationAlertType,
    signature: NotificationAlertType
  ) => void
) {
  _setNotificationData(data);

  /* Calculate and update the new user notification values */
  let notificationSignature: NotificationAlertType = "none";
  let notificationInformation: NotificationAlertType = "none";
  let notificationToDo: NotificationAlertType = "none";

  if (data.infoSMS && data.infoEmail) {
    notificationInformation = "smsAndEmail";
  } else if (data.infoSMS || data.infoEmail) {
    notificationInformation = data.infoSMS ? "sms" : "email";
  }

  if (data.signatureSMS && data.signatureEmail) {
    notificationSignature = "smsAndEmail";
  } else if (data.signatureSMS || data.signatureEmail) {
    notificationSignature = data.signatureSMS ? "sms" : "email";
  }

  if (data.toDoSMS && data.toDoEmail) {
    notificationToDo = "smsAndEmail";
  } else if (data.toDoSMS || data.toDoEmail) {
    notificationToDo = data.toDoSMS ? "sms" : "email";
  }

  setUserNotifications(
    notificationInformation,
    notificationToDo,
    notificationSignature
  );
}
