import classnames from "classnames";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Count } from "..";
import { ArrowDown } from "../../../../icons/Icons";
import { HeaderMenuItemProps } from "../types";

function HeaderMenuItem({
  label,
  icon,
  href,
  onClick,
  isActive,
  isMenu,
  count,
}: HeaderMenuItemProps) {
  const location = useLocation();
  const active =
    isActive || (href && href !== "/" && location.pathname.startsWith(href));

  if (href) {
    return (
      <div
        className={classnames("header-menu-item", {
          "is-active": active,
          "is-menu": isMenu,
        })}
        onClick={onClick}
      >
        <NavLink to={href}>
          {icon}
          <div className={"caption"}>
            {label}
            <Count value={count} />
            {isMenu && !isActive && <ArrowDown />}
          </div>
        </NavLink>
      </div>
    );
  }

  return (
    <div
      className={classnames("header-menu-item", {
        "is-active": active,
        "is-menu": isMenu,
      })}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {icon}
      <div className={"caption"}>
        {label}
        {isMenu && !isActive && <ArrowDown />}
      </div>
    </div>
  );
}

export default HeaderMenuItem;
