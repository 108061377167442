import React, { useState } from "react";
import { getCheckboxIcon } from "../helper";
import { CheckBoxProps } from "../types";

function CheckBox(props: CheckBoxProps) {
  const { checked, onChange, disabled } = props;
  const [isHovered, setIsHovered] = useState(!!props.hovered);
  const icon = getCheckboxIcon(props, props.hovered || isHovered);

  function toggle(e: any) {
    if (!disabled) {
      onChange(!checked);
    }
  }

  return (
    <div
      className="checkbox"
      onClick={toggle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {React.createElement(icon, null)}
    </div>
  );
}

export default CheckBox;
