import classnames from "classnames";
import React from "react";
import { RoundedButtonProps } from "../types";

const RoundedButton = React.forwardRef((props: RoundedButtonProps, ref) => {
  const {
    label,
    buttonClass,
    loading = false,
    inverse,
    large,
    transparent,
    icon,
    disabled = false,
    content,
    ...rest
  } = props;
  return (
    <button
      ref={ref as any}
      className={classnames("portal-button button is-outlined", buttonClass, {
        "is-loading": loading,
        "is-large": large,
        inverse: inverse,
        transparent: transparent,
      })}
      disabled={disabled || loading}
      {...rest}
    >
      <div className="portal-button-label is-flex">
        {icon} {label}
      </div>
      {content}
    </button>
  );
});

export default RoundedButton;
