import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

import './GlobalFilter.scss';

export const GlobalFilter = ({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }: any) => {
        const { t } = useTranslation();
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
    
        return (
            <div className={classNames("globalFilter")}>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={t("transactionDetails.fees.search")}/>
            </div>
        )
}