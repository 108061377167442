import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import './AmountBetweenFilter.scss';

export const AmountBetweenFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }: any) => {
    const { t } = useTranslation();
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach((row: Row) => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div className={classNames("AmountBetweenFilter")} >
            <label>
                <span>{t("transactionDetails.filterField.minAmount")}</span>
                <input
                    value={typeof filterValue[0] === 'number' ? filterValue[0] : ''}
                    type="number"
                    step={"any"}
                    onChange={e => {
                        const val = e.target.value
                        setFilter((old: any = []) => [val ? parseFloat(val) : undefined, old[1]])
                    }} />
            </label>
            <label>
                <span>{t("transactionDetails.filterField.maxAmount")}</span>
                <input
                    value={typeof filterValue[1] === 'number' ? filterValue[1] : ''}
                    type="number"
                    step={"any"}
                    onChange={e => {
                        const val = e.target.value
                        setFilter((old: any = []) => [old[0], val ? parseFloat(val) : undefined])
                    }}/>
            </label>
            
        </div>
    )
}