import React from "react";

function PagingComponent({
  page,
  totalPages,
  setPage,
}: {
  page: number;
  totalPages: number;
  setPage: (p: number) => void;
}) {
  return (
    <div className="paging">
      <button
        className="button"
        disabled={page === 0}
        onClick={() => setPage(page - 1)}
      >
        <span>{"<"}</span>
      </button>
      <span>
        {page + 1} / {totalPages}
      </span>
      <button
        className="button"
        disabled={page === totalPages - 1}
        onClick={() => setPage(page + 1)}
      >
        <span>{">"}</span>
      </button>
    </div>
  );
}

export default PagingComponent;
