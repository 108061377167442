import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Delete } from "../../../../icons/Icons";
import { ReportTemplate } from "../../types";
import * as TFApi from "../../../../api/tradeFinanceApi";

function DiscardReportButton({
  reportTemplate,
  onSuccess,
}: {
  reportTemplate: ReportTemplate;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();
  const discardReportFromTemplate = useMutation(
    async (reportTemplate: ReportTemplate) => {
      return await TFApi.discardReport(reportTemplate.id);
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );

  return (
    <div
      className={"card-button has-red-hover"}
      onClick={() => {
        discardReportFromTemplate.mutate(reportTemplate);
      }}
    >
      <Delete />
      <div className="button-label">{t("report.remove")}</div>
    </div>
  );
}

export default DiscardReportButton;
