import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../../../../App";
import { getFieldProps } from "../../../../../../../../components/Form/helper";
import { useLoadingAction } from "../../../../../../../../hooks";
import { TradFinanceRequestContext } from "../../../../../context";
import {
  businessEntityDescriptorRefName,
  isCancelEvent,
  isPreCheckEvent,
  isTradeFinanceEditable,
} from "../../../../../tradeFinanceUtils";
import * as TFApi from "../../../../../../../../api/tradeFinanceApi";
import { SwiftField } from "../../../../../../../../components";

function LetterOfCreditDocumentRequirements() {
  const { user } = useContext(AppContext);
  const tradFinanceRequestContext = useContext(TradFinanceRequestContext);
  const { tradeFinance, form } = tradFinanceRequestContext;
  const { t } = useTranslation();
  const requiredDocumentsField = getFieldProps(
    form,
    "requiredDocuments",
    t("tradeFinance.requiredDocuments")
  );
  const isEditable = isTradeFinanceEditable(tradFinanceRequestContext);
  const event = tradeFinance.event?.eventDescRef.split("/").pop();

  const loadClausesAction = useLoadingAction(() => {
    if (isEditable) {
      return TFApi.findActiveClauses(user!);
    }
  }, [isEditable]);

  const clauses = useMemo(() => {
    if (loadClausesAction.result && isEditable) {
      const actionResult = loadClausesAction.result;
      const clausesForField = actionResult.filter((clause) => {
        let dealTypeMatches = clause.dealType?.includes(
          businessEntityDescriptorRefName(
            tradeFinance.businessEntityDescriptorId
          )
        );
        let transactionTypeMatches =
          !clause.transactionType ||
          (!tradeFinance.event &&
            clause.transactionType?.includes("completeRequestEvent")) ||
          (event && clause.transactionType.includes(event as string));
        return (
          dealTypeMatches &&
          transactionTypeMatches &&
          clause.fieldId?.includes("requiredDocuments")
        );
      });
      return clausesForField;
    }
    return [];
  }, [loadClausesAction.result, isEditable]);

  const isDocumentRequirementEditable =
    isEditable &&
    !isPreCheckEvent(tradeFinance) &&
    !isCancelEvent(tradeFinance);

  return (
    <div>
      <SwiftField
        clauses={clauses}
        {...requiredDocumentsField}
        disabled={!isDocumentRequirementEditable}
        validationType="z65"
        lines={800}
      />
    </div>
  );
}

export default LetterOfCreditDocumentRequirements;
