import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import { useCodeBaseAsSelectOptions } from "../../../../hooks";
import { selectOptionsToString } from "../../helper";

function ProductsColumn({ products }: { products: string[] }) {
  const { lang } = useContext(AppContext);
  const { t } = useTranslation();
  const productTypeOptions = useCodeBaseAsSelectOptions(
    "tradefin/TF_productTypePortal"
  );

  if (!productTypeOptions) {
    return null;
  }

  return (
    <div className="product-columns">
      {!products || products.length === productTypeOptions.length
        ? t("report.allProducts")
        : selectOptionsToString(products, productTypeOptions, lang)}
    </div>
  );
}

export default ProductsColumn;
