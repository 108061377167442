import moment, { Moment } from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "../../icons/Icons";
import { range } from "../../utils";
import { MonthView } from "../../components/CalendarComponent/assets";
import { useCalendarEvents } from "../../components/CalendarComponent/assets/hooks";

function WholeCalendar() {
  const { t } = useTranslation();
  const [currentYear, setCurrentYear] = useState(moment().get("year"));
  const startOfYear = moment([currentYear]).startOf("year");
  const endOfYear = moment([currentYear]).endOf("year");
  const { data } = useCalendarEvents(startOfYear, endOfYear);
  const [selectedDay, setSelectedDay] = useState<Moment | null>();

  function today() {
    setCurrentYear(moment().get("year"));
  }

  function nextYear() {
    setCurrentYear(currentYear + 1);
  }

  function prevYear() {
    if (currentYear >= 2020) {
      setCurrentYear(currentYear - 1);
    }
  }

  return (
    <div className="container">
      <div className="calendar-whole">
        <div className="calendar-whole--header">
          <div className="current-year">{currentYear}</div>
          <div className="year-selector">
            {currentYear > 2020 && (
              <button onClick={prevYear}>
                <ArrowLeft /> {currentYear - 1}
              </button>
            )}
            <button onClick={today} className="today">
              {t("calendar.today")}
            </button>
            <button onClick={nextYear}>
              {currentYear + 1} <ArrowRight />
            </button>
          </div>
        </div>

        {data && (
          <div className="months-container">
            {range(12).map((month) => (
              <MonthView
                key={month}
                year={currentYear}
                month={month}
                selectedDay={selectedDay}
                onDaySelected={setSelectedDay}
                calendarEvents={data}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default WholeCalendar;
