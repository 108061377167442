import React from "react";
import * as API from "../../api/tradeFinanceApi";
import { useQuery } from "react-query";
import { TransactionDetailsComponentProps } from "./assets/types";
import {
  ClaimsComp,
  FinancialDataComp,
  PreChecksComp,
  ServicesComp,
  TransactionChangesComp,
} from "./assets";
import { ReactTable } from "..";

function TransactionDetailsComponent({
  tradeFinance,
}: TransactionDetailsComponentProps) {
  const tradeFinanceType = tradeFinance.tradeFinanceType;
  const tradeFinanceId = tradeFinance.id;
  const isReceivedGuarantee = tradeFinanceType === "tradefin/receivedGuarantee";
  const isIssuedGuarantee = tradeFinanceType === "tradefin/IssuedGuarantee";
  const isStandBy =
    tradeFinanceType === "tradefin/outgoingStandbyBG" ||
    tradeFinanceType === "tradefin/outgoingStandbyLC";
  const isExportLC = tradeFinanceType === "tradefin/exportLC";

  const transactionDetailsQuery = useQuery(
    tradeFinanceId && ["transaction-details", tradeFinanceId],
    (_) => {
      return API.getTransactionDetails(tradeFinanceId);
    }
  );

  if (!transactionDetailsQuery.data) {
    if (transactionDetailsQuery.isLoading) {
      return <div className={"button is-white is-loading is-large is-flex"} />;
    }
    return null;
  }
  const { drawingEvents, fees, transactionChanges } =
    transactionDetailsQuery.data;

  return (
    <div className="transaction-details">
      <TransactionChangesComp
        {...transactionDetailsQuery.data}
        tradeFinance={tradeFinance}
      />
      {(isIssuedGuarantee || isStandBy) && (
        <ClaimsComp {...transactionDetailsQuery.data} />
      )}
      {isReceivedGuarantee && (
        <ServicesComp
          {...transactionDetailsQuery.data}
          tradeFinanceId={tradeFinanceId}
        />
      )}
      {isExportLC && <PreChecksComp {...transactionDetailsQuery.data} />}
      {!!drawingEvents && (
        <ReactTable
          tradeFinance={tradeFinance}
          isFees={false}
          tradeFinanceType={tradeFinanceType}
        />
      )}
      {!!fees && (
        <ReactTable
          tradeFinance={tradeFinance}
          isFees
          tradeFinanceType={tradeFinanceType}
        />
      )}
      {/* {!!drawingEvents && <DrawingComp {...transactionDetailsQuery.data} tradeFinanceType={tradeFinanceType}/>} */}
      {/* {!!fees && <FeesComp {...transactionDetailsQuery.data}/>} */}
      {!!transactionChanges && (
        <FinancialDataComp
          {...transactionDetailsQuery.data}
          tradeFinanceType={tradeFinanceType}
        />
      )}
    </div>
  );
}

export default TransactionDetailsComponent;
