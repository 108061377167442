import React from "react";
import { FormLayout } from "../../../../../../../../components";
import { OpenedStateComponentType } from "../type";

function OpenedStateComponent({
  form,
  layout,
  formFields,
  buttons,
}: OpenedStateComponentType) {
  return (
    <div className="details-comp">
      {form.error && <div className="notification is-danger">{form.error}</div>}
      <div className="wizard">
        <FormLayout
          form={form}
          layout={layout}
          fields={formFields}
          buttons={buttons}
        />
      </div>
    </div>
  );
}

export default OpenedStateComponent;
