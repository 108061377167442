import { FinancialData } from "../../../types";
import { FinancialDataType } from "./interfaces";

export const StateCreateHandler = (
  value: string | undefined,
  financeType: string,
  financialData: FinancialDataType[] | undefined
) => {
  const areSettlementsAccounted = (
    settlements: FinancialData[] | FinancialDataType[]
  ) => {
    if (settlements) {
      for (let i = 0; i < settlements.length; i++) {
        if (settlements[i].state !== "accounted") {
          return false;
        }
      }
    }
    return true;
  };

  if (value === "" || value === undefined) return null;
  if (financeType === undefined) return null;

  if (value === "pendingAcceptance") {
    return "transactionDetails.drawings.stateEvents.pendingAcceptance";
  }
  if (value === "draft") {
    return "transactionDetails.drawings.stateEvents.draft";
  }
  if (value === "waiting_for_client") {
    return "transactionDetails.drawings.stateEvents.waiting_for_client";
  }
  if (value === "canceled") {
    return "transactionDetails.drawings.stateEvents.canceled";
  }
  if (value === "rejected") {
    return "transactionDetails.drawings.stateEvents.rejected";
  }
  if (value === "waiting_for_beneficiary") {
    return "transactionDetails.drawings.stateEvents.waiting_for_beneficiary";
  }
  if (value === "refused_waiting_for_client") {
    return "transactionDetails.drawings.stateEvents.refused_waiting_for_client";
  }
  if (value === "refused_waiting_for_acceptance") {
    return "transactionDetails.drawings.stateEvents.refused_waiting_for_acceptance";
  }
  if (value === "waiting_for_acceptance") {
    return "transactionDetails.drawings.stateEvents.waiting_for_acceptance";
  }
  if (value === "discrepant") {
    return "transactionDetails.drawings.stateEvents.discrepant";
  }
  if (value === "waiting_for_bank") {
    return "transactionDetails.drawings.stateEvents.waiting_for_bank";
  }
  if (value === "accepted") {
    return "transactionDetails.drawings.stateEvents.accepted";
  }

  if (
    ["registerPayments", "authorizationReview", "checkPayments"].includes(
      value
    ) ||
    (value === "approved" &&
      (!financialData || !areSettlementsAccounted(financialData)))
  ) {
    return "tradeFinance.eventState.accepted";
  }
  if (
    value === "approved" &&
    (!financialData || areSettlementsAccounted(financialData))
  ) {
    return "tradeFinance.eventState.paid";
  }
  if (value === "clientDecision") {
    let importLC = financeType === "tradefin/importLC";
    return importLC
      ? "tradeFinance.eventState.waitingForClientDecision"
      : "tradeFinance.eventState.waitingForDecision";
  }
  if (["refused", "underRefused", "refusalReview"].includes(value)) {
    return "tradeFinance.eventState.refused";
  }

  return null;
};

export const FormaterHelper = (value: number | undefined): string => {
  if (value === undefined) return "";
  return String(parseFloat(String(value)).toFixed(2));
};
