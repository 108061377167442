import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TradeFinanceSteps } from "..";
import {
  EventHistoryTab,
  MessagesTab,
  TransactionDetailsComponent,
  Tabs,
} from "../../../../components";
import { TabProps } from "../../../../components/Tabs/assets/types";
import { TradFinanceRequestContext } from "../context";
import { useIsTemplate } from "../hooks";
import {
  isConcept,
  isEvaluateRequest,
  showEventHistory,
} from "../tradeFinanceUtils";
import { TabId } from "../type";
import { NotificationsTab } from "./assets";

function TradeFinanceContentLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const defaultTab = location.hash ? location.hash.substr(1) : TabId.request;
  const { tradeFinance, loadNotificationsAction, loadCommentsAction } =
    useContext(TradFinanceRequestContext);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const isTemplate = useIsTemplate(tradeFinance);
  const tabs: TabProps[] = [
    {
      id: TabId.request,
      label: t("tradeFinance.tabs.details"),
      className: "",
      render: () =>
        tradeFinance.event ||
        isConcept(tradeFinance) ||
        isEvaluateRequest(tradeFinance) ? (
          <TradeFinanceSteps />
        ) : (
          <TransactionDetailsComponent tradeFinance={tradeFinance} />
        ),
    },
  ];

  function getMessagesTabLabel() {
    if (loadCommentsAction.result) {
      let unread = loadCommentsAction.result.filter(
        (c) => c.unReadByClient
      ).length;

      return (
        <span>
          {t("tradeFinance.tabs.messaging")}
          <span className={"message-counter"}>
            {loadCommentsAction.isLoading
              ? "..."
              : loadCommentsAction.result.length}
          </span>
        </span>
      );
    }
    return t("tradeFinance.tabs.messaging");
  }

  if (showEventHistory(tradeFinance)) {
    tabs.push({
      id: TabId.chat,
      label: getMessagesTabLabel(),
      className: "",
      render: () => (
        <div className={"container comments-page"}>
          <MessagesTab tradeFinance={tradeFinance} />
        </div>
      ),
    });

    tabs.push({
      id: TabId.history,
      label: t("tradeFinance.tabs.history"),
      className: "history-tab has-link-style",
      render: () => (
        <div className={"container"}>
          <EventHistoryTab tradeFinance={tradeFinance} />
        </div>
      ),
    });
  }

  if (
    loadNotificationsAction.result &&
    loadNotificationsAction.result.length > 0
  ) {
    tabs.push({
      id: TabId.notifications,
      label: t("tradeFinance.tabs.notifications"),
      className: "notification-tab has-link-style",
      render: () => (
        <NotificationsTab
          tradeFinance={tradeFinance}
          notifications={loadNotificationsAction.result!}
        />
      ),
    });
  }

  if (!tradeFinance.id || isTemplate) {
    return <TradeFinanceSteps />;
  } else {
    return <Tabs className="container" activeTab={activeTab} tabs={tabs} />;
  }
}

export default TradeFinanceContentLayout;
