import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Help } from "../../../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../../../context";
import { isExportLetterOfCredit } from "../../../../../tradeFinanceUtils";
import { GetSignatureSentencesType } from "../types";

function GetSignatureSentences({
  letterOfCreditClaimEvent,
  showFinalDocument,
}: GetSignatureSentencesType) {
  const { t } = useTranslation();
  const { tradeFinance, underWritersInfo } = useContext(
    TradFinanceRequestContext
  );
  return (
    <>
      {!letterOfCreditClaimEvent && !isExportLetterOfCredit(tradeFinance) && (
        <div
          className={classnames("step-hint is-flex", {
            "on-modal": showFinalDocument,
          })}
        >
          <Help />
          {!showFinalDocument ? (
            <>
              {underWritersInfo?.hasRightToSign ? (
                <div className={"is-block"}>
                  {!underWritersInfo?.signedByMe && (
                    <div>{t("tradeFinance.signatureHint")}</div>
                  )}
                  {underWritersInfo.signedByMe ? (
                    <div>{t("tradeFinance.alreadySigned")}</div>
                  ) : (
                    <span className={"is-bold"}>
                      {t("tradeFinance.permissionHint")}&nbsp;
                    </span>
                  )}
                  <span className={"is-bold"}>
                    {!underWritersInfo.draft &&
                      t("tradeFinance.numberOfSignatures", {
                        signaturesNum:
                          underWritersInfo.numberOfGroupA +
                          underWritersInfo.numberOfGroupB,
                      })}
                  </span>
                </div>
              ) : (
                <div className={"is-bold"}>
                  {t("tradeFinance.noPermissionHint")}
                </div>
              )}
            </>
          ) : (
            <div>{t("tradeFinance.signAndSendInformation")}</div>
          )}
        </div>
      )}
    </>
  );
}

export default GetSignatureSentences;
