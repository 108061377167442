import React from "react";
import { useTranslation } from "react-i18next";
import { Action, StateComponent } from "..";
import { TransactionDetails } from "../../../../types";

function PreChecksComp({ preCheckEvents }: TransactionDetails) {
  const { t } = useTranslation();

  if ((preCheckEvents || []).length === 0) {
    return null;
  }

  return (
    <div className={"pre-checks pre-checks-table"}>
      <div className="table--header">
        <div className="table--title">
          {t("transactionDetails.preChecks.title")}
        </div>
      </div>
      <div className="table-container">
        <table className="table portal-table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>{t("tradeFinance.preCheckDetails.invoiceNumber")}</th>
              <th align="right">{t("transactionDetails.state")}</th>
              <th>{t("transactionDetails.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {preCheckEvents.map((data, idx) => {
              return (
                <tr key={idx}>
                  <td>{data.invoiceNumber}</td>
                  <td align="right">
                    <StateComponent
                      stateName={data.stateName}
                      state={data.state}
                    />
                  </td>
                  <td>
                    {data.action && (
                      <Action
                        action={data.action}
                        tradeFinanceId={data.tradeFinanceId}
                        eventId={data.eventId}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PreChecksComp;
