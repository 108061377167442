import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { EventHistoryRowProps } from "../type";
import * as TFApi from "../../../../api/tradeFinanceApi";
import moment from "moment";
import { renderValue } from "../helper";
import { ActionButton, EnlargeHistoryTextField } from "./assets";
import { ArrowDown } from "../../../../icons/Icons";
import { formatDate } from "../../../../pages/TradeFinanceRequestDetailsPage/assets/tradeFinanceUtils";

function EventHistoryRow({
  tradeFinanceId,
  history,
  openedHistoryId,
  setOpenedHistoryId,
  showMessageEvents,
}: EventHistoryRowProps) {
  const { t } = useTranslation();
  let opened = openedHistoryId === history.id;
  const [isEnlarged, setEnlarged] = useState<boolean>(false);
  const [isFirstTabOpened, setFirstTabOpened] = useState<boolean>(true);
  const [origValue, setOrigValue] = useState<string>("");
  const [newValue, setNewValue] = useState<string>("");

  const historyChangesQuery = useQuery(
    [tradeFinanceId, history.id, showMessageEvents],
    async () => {
      return await TFApi.getTradeFinanceHistoryChanges(
        tradeFinanceId,
        history.id,
        showMessageEvents
      );
    },
    { cacheTime: 5 * 60 * 1000, enabled: opened }
  );

  function toggle() {
    if (!opened) {
      setOpenedHistoryId(history.id);
    } else {
      setOpenedHistoryId(null);
    }
  }

  return (
    <div className="event-history-row">
      <div className={"event-history-row--title is-flex"}>
        <div className={"event-name"}>{history.description}</div>

        <div className="actions-column">
          <div
            className="is-small action-button"
            onClick={() => {
              toggle();
            }}
          >
            {t("expand")} <ArrowDown />
          </div>
        </div>
      </div>

      {historyChangesQuery.isLoading && (
        <div className={"button is-white is-loading is-large is-flex"}></div>
      )}

      {opened && !historyChangesQuery.isLoading && (
        <div className={"has-grey-background"}>
          <div className="table-container">
            <table className="table portal-table is-striped is-bordered is-fullwidth">
              <thead>
                <tr>
                  <th align={"right"}>{t("tradeFinance.eventHistory.date")}</th>
                  <th>{t("tradeFinance.eventHistory.time")}</th>
                  <th>{t("tradeFinance.eventHistory.author")}</th>
                  <th style={{ maxWidth: "100px" }}>
                    {t("tradeFinance.eventHistory.action")}
                  </th>
                  {!showMessageEvents && (
                    <>
                      <th style={{ minWidth: "150px" }}>
                        {t("tradeFinance.eventHistory.field")}
                      </th>
                      <th style={{ minWidth: "150px" }}>
                        {t("tradeFinance.eventHistory.origValue")}
                      </th>
                      <th style={{ minWidth: "150px" }}>
                        {t("tradeFinance.eventHistory.newValue")}
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {historyChangesQuery.data?.map((change, idx) => {
                  return (
                    <tr key={idx}>
                      <td align={"right"}>{formatDate(change.date)}</td>
                      <td>{moment(change.date).format("HH:mm:ss")}</td>
                      <td>{change.author}</td>
                      <td className={"action-column"}>{change.action}</td>
                      {!showMessageEvents && (
                        <>
                          <td>
                            <strong>{change.fieldName}</strong>
                          </td>
                          {!isEnlarged && (
                            <ActionButton
                              originalValue={renderValue(change.origValue)}
                              newValue={renderValue(change.newValue)}
                              isOriginalValue={true}
                              setFirstTabOpened={setFirstTabOpened}
                              setEnlarged={setEnlarged}
                              setOrigValue={setOrigValue}
                              setNewValue={setNewValue}
                            />
                          )}
                          {!isEnlarged && (
                            <ActionButton
                              originalValue={renderValue(change.origValue)}
                              newValue={renderValue(change.newValue)}
                              isOriginalValue={false}
                              setFirstTabOpened={setFirstTabOpened}
                              setEnlarged={setEnlarged}
                              setOrigValue={setOrigValue}
                              setNewValue={setNewValue}
                            />
                          )}
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isEnlarged && (
              <EnlargeHistoryTextField
                value={isFirstTabOpened ? origValue : newValue}
                setEnlarged={setEnlarged}
                isFirstTabOpened={isFirstTabOpened}
                setFirstTabOpened={setFirstTabOpened}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventHistoryRow;
