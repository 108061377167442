import React from "react";
import { ClauseSelector } from "..";
import { Clause } from "../../../types";
import {
  TextAreaProps,
  TextAreaWithClausesProps,
  TextFieldProps,
} from "../types";

function ClauseSelectorFactory(props: TextAreaProps | TextFieldProps) {
  function clauseSelected(selectedClauses: Clause[]) {
    let rows = (props as TextAreaProps).rows;
    if (selectedClauses.length) {
      const newValue =
        (props.value ? props.value + (rows ? "\n" : " ") : "") +
        selectedClauses.map((c) => c.clauseText).join(rows ? "\n" : " ");
      props.setValue(newValue);
    }
  }

  return (
    <ClauseSelector
      {...(props as TextAreaWithClausesProps)}
      onSelect={clauseSelected}
    />
  );
}

export default ClauseSelectorFactory;
