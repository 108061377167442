import React from "react";
import { useTranslation } from "react-i18next";
import { FileExtensionIcon } from ".";
import { DownloadIcon } from "../../icons/Icons";
import { TFDocument } from "../../types";
import { DocumentsTableProps } from "./assets/types";

function DocumentsTable({
  documentHolder,
  documentHolderType,
  tradeFinanceId,
}: DocumentsTableProps) {
  const { t } = useTranslation();

  if ((documentHolder.documents || []).length === 0) {
    return null;
  }

  function getDownloadLink(doc: TFDocument) {
    return `api/trade-finance/${tradeFinanceId}/${documentHolderType}/${documentHolder.id}/document/${doc.id}`;
  }

  return (
    <div className="table-container top-margin">
      <table className="documents-table table is-striped is-bordered is-fullwidth">
        <tbody>
          {(documentHolder.documents || []).map((doc, i) => (
            <tr key={i}>
              {doc.fileName !== undefined ? (
                <td>
                  <div className="is-flex">
                    <FileExtensionIcon fileName={doc.fileName} />
                    {doc.fileName}
                  </div>
                </td>
              ) : null}

              {doc.description !== undefined ? (
                doc.description.length > 35 ? (
                  <td
                    className="description"
                    title={doc.description}
                    style={{ cursor: "help" }}
                  >
                    {doc.description.substring(0, 35).concat("...")}
                  </td>
                ) : (
                  <td className="description">{doc.description}</td>
                )
              ) : (
                <td></td>
              )}

              <td className="download">
                <div className={"is-flex"}>
                  <DownloadIcon className={"icon is-grey"} />
                  <a
                    className="document-details"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getDownloadLink(doc)}
                  >
                    {t("download")}
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DocumentsTable;
