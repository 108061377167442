import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Step } from "..";
import { RoundedButton } from "../../../../../../components";
import { TradFinanceRequestContext } from "../../../context";
import { useIsTemplate } from "../../../hooks";
import { StepId } from "../../../type";
import * as TFApi from "../../../../../../api/tradeFinanceApi";

function SaveTemplateStep({
  hasUnsavedChanges,
}: {
  hasUnsavedChanges: boolean;
}) {
  const { t } = useTranslation();
  const { tradeFinance, form } = useContext(TradFinanceRequestContext);
  const history = useHistory();

  async function save() {
    if (!tradeFinance.id) {
      await TFApi.saveTemplate(form.values);
    }

    history.push("/portal/templates");
  }

  if (!useIsTemplate(tradeFinance) || tradeFinance.id) {
    return null;
  }

  return (
    <Step stepId={StepId.documents} title={t("tradeFinance.finishRequest")}>
      <div className="portal-buttons">
        <RoundedButton label={t("save")} onClick={save} />
      </div>
    </Step>
  );
}

export default SaveTemplateStep;
