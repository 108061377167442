import React from "react";
import { TradeFinanceCardsProps } from "../types";
import {
  AdaptiveCardComponent,
  TradeFinanceCardsFooter,
  TradeFinanceCardsHeader,
} from "./assets";

const TradeFinanceCards = React.memo((props: TradeFinanceCardsProps) => {
  const { tradeFinances, copyFrom, templateMode /* downloadLink */ } = props;
  if (tradeFinances.length === 0) {
    return null;
  }

  return (
    <div className="list-cards">
      <TradeFinanceCardsHeader />
      {tradeFinances.map((tradeFinance, idx) => {
        return (
          <AdaptiveCardComponent
            key={idx + "_" + tradeFinance.id + "_" + tradeFinance.event?.id}
            tradeFinance={tradeFinance}
            templateMode={templateMode}
            copyFrom={copyFrom}
          />
        );
      })}
      <TradeFinanceCardsFooter {...props} />
    </div>
  );
});

export default TradeFinanceCards;
