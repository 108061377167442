import moment, { Moment } from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import * as API from "../../../api/tradeFinanceApi";

export function useCalendarEvents(fromDate: Moment, toDate: Moment) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState<Moment>(moment().startOf("day"));

  return useQuery(
    ["calendarEvents", fromDate],
    async (_) => {
      const calendarEvents = await API.getCalendarEvents(
        fromDate.toDate(),
        toDate.toDate()
      );
      return { ...calendarEvents, fromDate };
    },
    { staleTime: 1 * 60 * 1000, keepPreviousData: true }
  );
}
