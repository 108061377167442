import React, { useState, useRef } from "react";
import { Eye } from "../../icons/Icons";

export default function PasswordInput(props: any) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="control has-icons-right">
      <input
        ref={inputRef}
        {...props}
        type={showPassword ? "text" : "password"}
      />
      <span className="icon is-small is-right" onClick={toggleShowPassword}>
        <Eye
          className={
            showPassword
              ? "show-hide-password hide-password"
              : "show-hide-password"
          }
        />
      </span>
    </div>
  );
}
