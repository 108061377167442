import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionButton,
  ConfirmButton,
  RoundedButton,
} from "../../../../../../../../components";
import {
  Delete,
  SaveDraft,
  SignDraft,
  SignFinal,
} from "../../../../../../../../icons/Icons";
import { TradFinanceRequestContext } from "../../../../../context";
import {
  isAmendment,
  isCollection,
  isEventWaitingForClient,
  isImportLetterOfCreditInCancelEventWaitingForClient,
  isOutgoingTradeFinance,
  isPreCheckEvent,
} from "../../../../../tradeFinanceUtils";
import { StepId } from "../../../../../type";
import CreateTemplateButton from "../../../CreateTemplateButton/CreateTemplateButton";
import { ButtonsType } from "../types";

const Buttons = ({
  cancelAction,
  sendFinalAction,
  actionsEnabled,
  sendDraftAction,
  cancelPreCheckAction,
  loading,
  isNew,
}: ButtonsType) => {
  const { t } = useTranslation();
  const { tradeFinance, form, save } = useContext(TradFinanceRequestContext);
  if (isPreCheckEvent(tradeFinance)) {
    return (
      <>
        <ActionButton
          label={t("tradeFinance.sendFinal")}
          icon={<SignFinal />}
          action={sendFinalAction}
          buttonClass={"is-success"}
          disabled={!actionsEnabled}
        />

        <div className={"button-divider"}></div>

        <RoundedButton
          label={t("tradeFinance.saveDraft")}
          icon={<SaveDraft />}
          onClick={() => save(StepId.requestDetails)}
          disabled={
            form.isSubmitting ||
            sendFinalAction.isLoading ||
            sendDraftAction.isLoading
          }
        />

        {isEventWaitingForClient(tradeFinance) && (
          <ConfirmButton
            confirmation={t("tradeFinance.cancelPreCheckConfirmation")}
            button={
              <RoundedButton
                label={t("tradeFinance.discardRequest")}
                icon={<Delete />}
                onClick={cancelPreCheckAction.run}
                buttonClass={"is-danger"}
                loading={cancelPreCheckAction.isLoading}
              />
            }
          />
        )}
      </>
    );
  } else if (
    isImportLetterOfCreditInCancelEventWaitingForClient(tradeFinance)
  ) {
    return (
      <>
        <ActionButton
          label={t("tradeFinance.sendFinal")}
          icon={<SignFinal />}
          action={sendFinalAction}
          buttonClass={"is-success"}
          loading={sendFinalAction.isLoading}
        />
        <div className={"button-divider"}></div>
        <ConfirmButton
          confirmation={t("tradeFinance.cancelConfirmation")}
          button={
            <RoundedButton
              label={t("tradeFinance.discardRequest")}
              icon={<Delete />}
              onClick={cancelAction.run}
              buttonClass={"is-danger"}
              loading={cancelAction.isLoading}
            />
          }
        />
      </>
    );
  }

  return (
    <>
      <ActionButton
        label={t("tradeFinance.sendFinal")}
        icon={<SignFinal />}
        action={sendFinalAction}
        buttonClass={"is-success"}
        disabled={!actionsEnabled}
      />

      {!isCollection(tradeFinance) && (
        <ActionButton
          label={t("tradeFinance.sendDraft")}
          icon={<SignDraft />}
          action={sendDraftAction}
          buttonClass={"is-success"}
          disabled={!actionsEnabled}
        />
      )}

      <div className={"button-divider"}></div>
      <RoundedButton
        label={t("tradeFinance.saveDraft")}
        icon={<SaveDraft />}
        onClick={() => save(StepId.requestDetails)}
        disabled={loading}
      />

      {!isAmendment(tradeFinance) && (
        <>
          <CreateTemplateButton tradeFinance={tradeFinance} />
        </>
      )}

      {!isNew && isOutgoingTradeFinance(tradeFinance) && (
        <>
          <div className={"button-divider"}></div>
          <ConfirmButton
            confirmation={t("tradeFinance.cancelConfirmation")}
            button={
              <RoundedButton
                label={t("tradeFinance.discardRequest")}
                icon={<Delete />}
                onClick={cancelAction.run}
                buttonClass={"is-danger"}
                loading={cancelAction.isLoading}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default Buttons;
