import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CodeBaseField,
  DateField,
  NumberField,
  SelectField2,
  TextField,
  RoundedButton,
} from "../../../../components";
import { SelectOption } from "../../../../components/Fields/types";
import { useField } from "../../../Form/hooks";
import { useCodeBaseAsSelectOptions } from "../../../../hooks";
import { AdvancedFilterComponentProps } from "../types";

function AdvancedFilterComponent({
  onSearch,
  listType,
  searchParams,
}: AdvancedFilterComponentProps) {
  const { t } = useTranslation();

  const transactionTypeOptions: SelectOption[] = [
    { label: t("report.transactionTypes.exportLC"), value: "exportLC" },
    { label: t("report.transactionTypes.importLC"), value: "importLC" },
    {
      label: t("report.transactionTypes.IssuedGuarantee"),
      value: "IssuedGuarantee",
    },
    {
      label: t("report.transactionTypes.receivedGuarantee"),
      value: "receivedGuarantee",
    },
    {
      label: t("report.transactionTypes.exportCollection"),
      value: "exportCollection",
    },
    {
      label: t("report.transactionTypes.importCollection"),
      value: "importCollection",
    },
    {
      label: t("report.transactionTypes.incomingStandBy"),
      value: "incomingStandBy",
    },
    {
      label: t("report.transactionTypes.outgoingStandBy"),
      value: "outgoingStandBy",
    },
  ];

  const referenceNo = useField({
    id: "referenceNo",
    label: t("tradeFinance.referenceNo"),
    value: searchParams.referenceNo,
  });
  const identifier = useField({
    id: "identifier",
    label: t("tradeFinance.identifier"),
    value: searchParams.identifier,
  });
  const eventDescriptorId = useField({
    id: "eventDescriptorId",
    label: t("tradeFinance.filters.eventDescriptorId"),
    value: searchParams.eventDescriptorId,
  });
  const businessEntityDescriptorId = useField({
    id: "businessEntityDescriptorId",
    label: t("tradeFinance.businessEntityDescriptorId"),
    value: searchParams.businessEntityDescriptorId,
  });
  const otherPartnerName = useField({
    id: "otherPartnerName",
    label: t("tradeFinance.otherPartnerName"),
    value: searchParams.otherPartnerName,
  });

  const minAmount = useField({
    id: "minAmount",
    label: t("tradeFinance.filters.minAmount"),
    value: searchParams.minAmount,
  });
  const maxAmount = useField({
    id: "maxAmount",
    label: t("tradeFinance.filters.maxAmount"),
    value: searchParams.maxAmount,
  });
  const currency = useField({
    id: "currency",
    label: t("tradeFinance.currency"),
    value: searchParams.currency,
  });

  const minDateOfExpiry = useField({
    id: "minDateOfExpiry",
    label: t("tradeFinance.filters.minDateOfExpiry"),
    value: searchParams.minDateOfExpiry,
  });
  const maxDateOfExpiry = useField({
    id: "maxDateOfExpiry",
    label: t("tradeFinance.filters.maxDateOfExpiry"),
    value: searchParams.maxDateOfExpiry,
  });
  const subtype = useField({
    id: "subtype",
    label: t("guarantee.subtype"),
    value: searchParams.subType,
  });
  const currencies = useCodeBaseAsSelectOptions("tradefin/CCB_Currency");

  const currencyOptions = useMemo(
    () =>
      (currencies || []).map((c) => ({
        label: c.value,
        value: c.value,
      })),
    [currency]
  );

  function search() {
    onSearch({
      maxDateOfExpiry: maxDateOfExpiry.value,
      minDateOfExpiry: minDateOfExpiry.value,
      maxAmount: maxAmount.value,
      minAmount: minAmount.value,
      otherPartnerName: otherPartnerName.value,
      businessEntityDescriptorId: businessEntityDescriptorId.value,
      eventDescriptorId: eventDescriptorId.value,
      identifier: identifier.value,
      referenceNo: referenceNo.value,
      currency: currency.value,
      subType: subtype.value,
      chipsFilter: searchParams.chipsFilter,
      sortOption: searchParams.sortOption,
    });
  }

  //console.log(currencyOptions);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <TextField {...referenceNo} />
          <TextField {...otherPartnerName} />
        </div>

        <div className="column">
          <NumberField {...minAmount} />
          <NumberField {...maxAmount} />
          <SelectField2
            {...currency}
            options={currencyOptions}
            multiSelect={true}
          />
        </div>

        <div className="column">
          {(listType === "concept" || listType === "ongoing") && (
            <SelectField2
              {...businessEntityDescriptorId}
              options={transactionTypeOptions}
              multiSelect={true}
            />
          )}
          {listType !== "concept" && <TextField {...identifier} />}
          {listType === "ongoing" && (
            <CodeBaseField
              {...eventDescriptorId}
              codeBaseId={"tfportal/events"}
              multiSelect={true}
            />
          )}
          {listType === "guarantees" && (
            <>
              <CodeBaseField
                {...subtype}
                codeBaseId={"tradefin/TF_GuaranteeSubtype"}
                multiSelect={true}
              />
            </>
          )}

          {(listType === "guarantees" || listType === "letterOfCredit") && (
            <>
              <DateField {...minDateOfExpiry} />
              <DateField {...maxDateOfExpiry} />
            </>
          )}
        </div>
      </div>
      <div className={"search-button"}>
        <RoundedButton label={t("search")} onClick={search} />
      </div>
    </div>
  );
}

export default AdvancedFilterComponent;
