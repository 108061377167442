import classNames from "classnames";
import React, { useState } from "react";
import Modal from "react-modal";
import { CloseIcon } from "../../../../icons/Icons";
import { NotificationType } from "../interfaces";

import "./Notification.scss";

export const NotificationComponent = ({ notif, title }: NotificationType) => {
  const [modalISOpen, setModalIsOpen] = useState<boolean>(false);

  return notif.length < 10 ? (
    <div>{notif}</div>
  ) : (
    <>
      <div
        className={classNames("pointer")}
        onClick={() => setModalIsOpen(true)}
      >
        {notif
          ? notif.length >= 10
            ? notif.substring(0, 10) + "..."
            : notif
          : ""}
      </div>
      <Modal
        isOpen={modalISOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalIsOpen((prev) => !prev)}
        style={{
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1001,
            backgroundColor: "rgba(0,0,0,.32)",
          },
          content: {
            backgroundColor: "#fff",
            inset: 0,
            width: 500,
            maxHeight: 500,
            margin: "20vh auto",
            fontFamily: "Open Sans",
          },
        }}
      >
        <div className={classNames("Notification")}>
          <div className={classNames("Notification-header")}>
            <span className={classNames("Notification-title")}>{title} </span>
            <span
              className={classNames("Notification-icon")}
              onClick={() => setModalIsOpen(false)}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <div className={classNames("Notification-content")}>
          {notif !== undefined ? (
            notif.split(/\r?\n/).map((item, idx) => <p key={idx}>{item}</p>)
          ) : (
            <p>{notif}</p>
          )}
        </div>
      </Modal>
    </>
  );
};
