import classNames from 'classnames';
import React from 'react';
import { StateCompoenentProps } from '../interfaces';

export const StateComponent = (props: StateCompoenentProps) => {
    const { value } = props;

    return (
        <div className={classNames("cell-container--state")}>
            <div className={classNames("cell-border", "blue")}>
                {value}
            </div>
        </div>
    )

}