import React from "react";
import { useTranslation } from "react-i18next";
import { RoundedButton } from "../../../../../../components";
import { TradeFinanceCardsProps } from "../../../types";

function TradeFinanceCardsFooter({ downloadLink }: TradeFinanceCardsProps) {
  const { t } = useTranslation();

  return (
    <div className={"list-footer"}>
      <a href={downloadLink} target={"_blank"}>
        <RoundedButton label={t("downloadExcel")}></RoundedButton>
      </a>
    </div>
  );
}

export default TradeFinanceCardsFooter;
