import * as React from "react";
import { useEffect, useRef } from "react";
import classnames from "classnames";
import "simplebar";
import "simplebar/dist/simplebar.css";
import * as ReactDOM from "react-dom";
import { CloseIcon } from "../../icons/Icons";
import { ModalProps } from "./assets/typest";

const modalRoot: HTMLElement | null = document.getElementById("modal-root");

export default function Modal(props: ModalProps) {
  const { isOpened } = props;
  const modalElement = useRef<HTMLElement>(document.createElement("div"));

  useEffect(() => {
    if (modalElement.current) {
      modalRoot!.appendChild(modalElement.current);
    }

    return () => {
      if (modalElement.current) {
        modalRoot!.removeChild(modalElement.current);
      }
    };
  }, []);

  function close() {
    props.onClose && props.onClose();
  }

  if (!isOpened) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classnames("modal", props.classes, { "is-active": isOpened })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="modal-background" onClick={close}></div>

      <div className={classnames("modal-card", props.modalCardClass)}>
        <section className="modal-card-body" data-simplebar>
          {props.children}
        </section>
        {!props.hideCloseButton && (
          <div className="close-button" aria-label="close" onClick={close}>
            <CloseIcon />
          </div>
        )}
      </div>
    </div>,
    modalElement.current
  );
}
