// @ts-nocheck
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLoadingAction } from "../../hooks";
import * as API from "../../api/tradeFinanceApi";
import { GroupMember, PAGE_LIMIT } from "../../api/tradeFinanceApi";
import { useInfiniteQuery } from "react-query";
import { SignatureListPageProps } from "./assets/types";
import { GroupMembers, SignaturesTable } from "./assets";
import { EmptyList, PageHeader, ShowMoreButton } from "../../components";

export default function SignatureListPage(props: SignatureListPageProps) {
  const { t } = useTranslation();

  const {
    status,
    data,
    //isFetching,
    //isFetchingNextPage,
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteQuery(
    "signatures",
    async ({ pageParam = 0 }) => {
      return API.findSignatures(false, pageParam as number, PAGE_LIMIT);
    },
    {
      getNextPageParam: (lastGroup, allGroups) =>
        lastGroup.length < PAGE_LIMIT ? false : allGroups.length * PAGE_LIMIT,
    }
  );

  const groupMembersLoadingAction = useLoadingAction<GroupMember[]>(() =>
    API.getGroupMembers()
  );

  async function showMore() {
    fetchNextPage();
  }

  if (!data || !groupMembersLoadingAction.result) return null;

  return (
    <>
      <PageHeader title={t("signature.pageTitle")} />

      <div className="page card-list-page ongoing-applications-page">
        {data.pages.length === 0 && (
          <EmptyList message={t("signature.emptySignaturesList")} />
        )}

        {data.pages.length > 0 && (
          <>
            <GroupMembers groupMembers={groupMembersLoadingAction.result} />
            <SignaturesTable pages={data.pages} reLoad={refetch} />
            <ShowMoreButton
              isLoading={status === "loading"}
              showMore={showMore}
              hasMore={hasNextPage}
            />
          </>
        )}
      </div>
    </>
  );
}
