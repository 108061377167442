import {useEffect} from 'react';

type SubscriptionHandler<T> = (data: T) => void

type Subscriptions<T> = Map<string, Set<SubscriptionHandler<T>>>

const subscriptions: Subscriptions<any> = new Map();

export function subsScribe<T>(subscriptionId: string, handler: SubscriptionHandler<T>) {
    if (!subscriptionId) {
        throw Error("Subscription subscriptionId must be not null or empty")
    }

    if (!handler) {
        throw Error("Subscription handler must be not null")
    }

    let handlers = subscriptions.get(subscriptionId);
    if (!handlers) {
        handlers = new Set();
        subscriptions.set(subscriptionId, handlers);
    }

    handlers.add(handler);
    //console.log("Subscriptions:", "ON",subscriptionId)
}

export function unSubsScribe<T>(subscriptionId: string, handler: SubscriptionHandler<T>) {
    if (!subscriptionId) {
        throw Error("Subscription subscriptionId must be not null or empty")
    }

    if (!handler) {
        throw Error("Subscription handler must be not null")
    }

    let handlers = subscriptions.get(subscriptionId);
    if (handlers) {
        handlers.delete(handler)
    }

    //console.log("Subscriptions:", "OFF",subscriptionId)

}

export function notify<T>(subscriptionId: string, data: T) {
    let handlers = subscriptions.get(subscriptionId);
    if (handlers) {
        handlers.forEach(h => h(data));
    }
}

export function useSubscription<T>(subscriptionId:string,handler:SubscriptionHandler<T>) {

    useEffect(() => {
        subsScribe(subscriptionId, handler);
        return () => {
            unSubsScribe(subscriptionId,handler)
        }
    },[subscriptionId,handler]);
}