import { FormikConfig, FormikErrors, FormikProps } from "formik";
import { ReactNode } from "react";
import { Univ } from "../../types";
import { FieldProps } from "../Fields/types";

export enum ContainerType {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
  FORM = "FORM",
}

export enum CollapsibleState {
  CLOSED = "CLOSED",
  OPENED = "OPENED",
  DISABLED = "DISABLED",
}

export type ContainerDesc = LayoutContainer | FieldContainer;

export interface LayoutContainer {
  id: string;
  label?: Univ;
  type: ContainerType.VERTICAL | ContainerType.HORIZONTAL;
  content: ContainerDesc[];
  collapsible:
    | CollapsibleState.CLOSED
    | CollapsibleState.OPENED
    | CollapsibleState.DISABLED
    | undefined;
  className?: string;
  hidden?: boolean;
}

export interface FieldContainer {
  id: string;
  label?: Univ;
  type: ContainerType.FORM;
  content: FieldProps[];
  className?: string;
  collapsible:
    | CollapsibleState.CLOSED
    | CollapsibleState.OPENED
    | CollapsibleState.DISABLED
    | undefined;
  hidden?: boolean;
}

export type FormValues<T> = Partial<T>;
export type FormErrors<T> = FormikErrors<T>;

export type ContainerWithLabel = {
  container: ContainerDesc;
  content: ReactNode;
  idx?: number;
};

export type FormParams<T> = FormikProps<T>;

export type FormProps<T> = FormikConfig<T>;

export type FormError = string | Univ | null | undefined;

export type FieldMap<T> = { [K in keyof T]: FieldProps };

export interface FormLayoutProps<T> {
  layout: ContainerDesc | null;
  buttons?: ReactNode | null;
  form: FormikProps<T>;
  fields: FieldMap<T>;
  enhanceContainerProps?: (
    container: ContainerDesc,
    form: FormikProps<T>
  ) => ContainerDesc;
}
