import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { ProfileComponentProps } from "../types";
import * as API from "../../../../api/portalApi";
import { validateEmails } from "../helper";
import { CodeBaseField, SelectField2, TextField } from "../../../../components";
import { getFieldProps } from "../../../../components/Form/helper";
import { UserNotificationComponent } from "../../../../components";

export function ProfileComponent({
  form,
  setUserNotifications,
}: ProfileComponentProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isConfigPage = pathname === "/portal/userConfiguration";
  const loadCompaniesAction = useQuery([form.values.id], () =>
    API.getUserCompanies(form.values.id)
  );
  const companies = loadCompaniesAction.data || [];
  const notificationsTable = [
    form.values.notificationSignature,
    form.values.notificationToDo,
    form.values.notificationInformation,
  ];
  const smsNeeded =
    notificationsTable.includes("sms") ||
    notificationsTable.includes("smsAndEmail");
  const phoneNumberMaxLength = 9;

  function formatPhoneNumber(
    phoneNumber: string | undefined,
    plusEnabled?: boolean
  ) {
    if (phoneNumber) {
      if (plusEnabled) {
        let specialChar = "";
        if (phoneNumber[0] === "+") {
          specialChar = "+";
        }
        return specialChar + phoneNumber.replace(/\D/g, "").toString();
      } else {
        return phoneNumber.replace(/\D/g, "").toString();
      }
    } else {
      return "";
    }
  }

  function validatePhoneNumber(): string | undefined {
    let length = form.values.phoneNumber ? form.values.phoneNumber.length : 0;
    if (smsNeeded) {
      return length < phoneNumberMaxLength
        ? t("profile.phoneNumberLengthErrorMessage")
        : undefined;
    } else {
      return length !== 0 && length < phoneNumberMaxLength
        ? t("profile.phoneNumberLengthErrorMessage")
        : undefined;
    }
  }

  function addErrorToEmail(): string | undefined {
    let isValiedEmail = validateEmails(form.values.email);
    if (isValiedEmail) {
      return undefined;
    } else {
      return t("profile.emaiAddressValidation");
    }
  }

  return (
    <div className="basic-data columns">
      <div className="column">
        <TextField
          {...getFieldProps(form, "name", t("profile.basicData.name"))}
          disabled={isConfigPage}
          required={true}
        />
        <TextField
          {...getFieldProps(form, "email", t("profile.contactSettings.email"))}
          error={addErrorToEmail()}
          required={true}
        />
        <CodeBaseField
          {...getFieldProps(
            form,
            "countryCode",
            t("profile.contactSettings.countryCode")
          )}
          codeBaseId="tradefin/TF_PhoneCountryCode"
          required={smsNeeded}
          value={form.values.countryCode || "+420"}
        />
        <TextField
          {...getFieldProps(
            form,
            "phoneNumber",
            t("profile.contactSettings.phoneNumberContact")
          )}
          error={validatePhoneNumber()}
          required={smsNeeded}
          value={formatPhoneNumber(form.values.phoneNumber, false)}
          maxLength={9}
        />
        <TextField
          {...getFieldProps(
            form,
            "phoneNumberContact",
            t("profile.contactSettings.phone")
          )}
          required={true}
          value={formatPhoneNumber(form.values.phoneNumberContact, true)}
        />
        <CodeBaseField
          {...getFieldProps(
            form,
            "preferredLanguage",
            t("profile.contactSettings.preferredLanguage")
          )}
          codeBaseId="tfportal/language"
        />
      </div>
      <div className="column">
        <SelectField2
          {...getFieldProps(
            form,
            "initialDefaultCompany",
            t("profile.basicData.initialDefaultCompany")
          )}
          value={form.values.initialDefaultCompany}
          required={true}
          options={companies}
        />
        {isConfigPage && setUserNotifications && (
          <UserNotificationComponent
            user={form.values}
            setUserNotifications={setUserNotifications}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileComponent;
