// @ts-nocheck
import classnames from "classnames";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ActionMeta, ValueType } from "react-select/src/types";
import { DecorateField } from "..";
import { AppContext } from "../../../App";
import { reactSelectStyles } from "../helper";
import { SelectFieldProps, SelectOption } from "../types";

export function SelectField2(props: SelectFieldProps) {
  const { t } = useTranslation();
  const { lang } = useContext(AppContext);
  const {
    id,
    value,
    disabled,
    error,
    className,
    setValue,
    options,
    multiSelect = false,
    onClearValue,
    isClearable,
    placeholder,
  } = props;

  const memoizedOptions = useMemo(() => {
    function convertSelectOptions(
      options: SelectOption[]
    ): { label: string; value: string | number }[] {
      if (options && options.length) {
        return options.map(({ label, value }) => ({
          label: typeof label === "string" || !label ? label : label[lang]!,
          value,
        }));
      }
      return [];
    }

    return convertSelectOptions(options);
  }, [options, lang]);

  function findOption(value: any): SelectOption | null {
    if (value) {
      return memoizedOptions.find((opt) => opt.value === value) || null;
    } else {
      return null;
    }
  }

  const _value = Array.isArray(value)
    ? value.map(findOption)
    : findOption(value);

  function _setValue(
    value: ValueType<SelectOption>,
    actionMeta: ActionMeta
  ): void {
    if (Array.isArray(value)) {
      setValue(value.map((option) => option.value));
    } else if (value) {
      setValue((value as SelectOption).value);
    } else {
      setValue(null);
    }

    if (actionMeta?.action === "clear" && onClearValue) {
      onClearValue();
    }
  }

  function renderSelect() {
    return (
      <div
        className={classnames(
          "is-fullwidth",
          { "is-danger": !!error },
          className
        )}
      >
        <Select
          id={id}
          styles={reactSelectStyles}
          className="react-select"
          isMulti={multiSelect}
          options={memoizedOptions}
          isDisabled={disabled}
          value={_value as SelectOption}
          onChange={_setValue}
          onBlur={props.onBlur}
          isClearable={isClearable}
          placeholder={placeholder || t("select")}
        />
      </div>
    );
  }

  return DecorateField(props, renderSelect(), lang);
}

export default SelectField2;
