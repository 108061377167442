import React from "react";
import { useTranslation } from "react-i18next";

function ItemError({ error }: { error?: string }) {
  const { t } = useTranslation();
  if (!error) {
    return null;
  }
  return (
    <div
      className="dropdown-menu"
      id="limit-notification-dropdown"
      role="menu"
      style={{ minWidth: "400px" }}
    >
      <div className="dropdown-content">
        <div className="dropdown-item">
          <div className="limit-notification">{t("underwriter." + error)}</div>
        </div>
      </div>
    </div>
  );
}

export default ItemError;
