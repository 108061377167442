import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import { PowerButton } from "../../../../icons/Icons";
import { LogoutComponentProps } from "../types";

function LogoutButton(props: LogoutComponentProps) {
  const { userStatus } = useContext(AppContext);
  const { t } = useTranslation();

  if (!userStatus) {
    return null;
  }

  return (
    <div
      className="logout-button is-flex"
      title={t("logout")}
      onClick={async () => {
        props.onLogout();
      }}
    >
      <PowerButton />
      {props.hasCaption && (
        <span className={"has-text-danger is-bold"}>{t("logout")}</span>
      )}
    </div>
  );
}

export default LogoutButton;
