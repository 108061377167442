import {
  ChipsFilter,
  Collection,
  TradeFinance,
  TradeFinanceType,
  UnderWritersInfo,
  UserStatus,
} from "../../../types";
import * as i18next from "i18next";
import moment from "moment";
import { TradeFinanceRequestContextType } from "./type";
import { TradeFinanceRequestListType } from "../../TradeFinanceListPage/assets/types";
import { SelectOption } from "../../../components/Fields/types";

export function isTradeFinanceEditable({
  tradeFinance,
  underWritersInfo,
}: Pick<
  TradeFinanceRequestContextType,
  "tradeFinance" | "underWritersInfo"
>): boolean {
  let editable = false;
  if (tradeFinance) {
    if (underWritersInfo) {
      editable = false;
    } else if (tradeFinance.event) {
      editable = tradeFinance.event.eventState === "waitingForClient";
    } else {
      editable = ![
        "activated",
        "advised",
        "opened",
        "canceled",
        "evaluateRequest",
      ].includes(tradeFinance.state);
    }
  }

  return editable;
}

export function isCollection(tradeFinance: TradeFinance): boolean {
  return (
    tradeFinance.tradeFinanceType === "tradefin/importCollection" ||
    tradeFinance.tradeFinanceType === "tradefin/exportCollection"
  );
}

export function isExportCollection(tradeFinance: TradeFinance): boolean {
  return tradeFinance.tradeFinanceType === "tradefin/exportCollection";
}

export function isLetterOfCredit(tradeFinance: TradeFinance) {
  if (tradeFinance) {
    return (
      tradeFinance.tradeFinanceType === "tradefin/importLC" ||
      tradeFinance.tradeFinanceType === "tradefin/exportLC" ||
      tradeFinance.tradeFinanceType === "tradefin/incomingStandbyLC" ||
      tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyLC"
    );
  } else {
    return false;
  }
}

export function isImportLetterOfCredit(tradeFinance: TradeFinance) {
  return tradeFinance.tradeFinanceType === "tradefin/importLC";
}

export function isExportLetterOfCredit(tradeFinance: TradeFinance) {
  return tradeFinance.tradeFinanceType === "tradefin/exportLC";
}

export function isGuarantee(tradeFinance: TradeFinance) {
  return (
    tradeFinance.tradeFinanceType === "tradefin/IssuedGuarantee" ||
    tradeFinance.tradeFinanceType === "tradefin/receivedGuarantee" ||
    tradeFinance.tradeFinanceType === "tradefin/incomingStandbyBG" ||
    tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyBG"
  );
}

export function isOutgoingStandBy(tradeFinance: TradeFinance) {
  return tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyBG";
}

export function isOutgoingTradeFinance(tradeFinance: TradeFinance): boolean {
  return (
    [
      "tradefin/exportCollection",
      "tradefin/importLC",
      "tradefin/IssuedGuarantee",
      "tradefin/outgoingStandbyLC",
      "tradefin/outgoingStandbyBG",
    ] as TradeFinanceType[]
  ).includes(tradeFinance.tradeFinanceType);
}

export function isStandBy(tradeFinance: TradeFinance): boolean {
  return (
    [
      "tradefin/incomingStandbyBG",
      "tradefin/incomingStandbyLC",
      "tradefin/outgoingStandbyBG",
      "tradefin/outgoingStandbyLC",
    ] as TradeFinanceType[]
  ).includes(tradeFinance.tradeFinanceType);
}

export function isReceivedTradeFinance(tradeFinance: TradeFinance) {
  return (
    tradeFinance.tradeFinanceType === "tradefin/receivedGuarantee" ||
    tradeFinance.tradeFinanceType === "tradefin/exportLC" ||
    tradeFinance.tradeFinanceType === "tradefin/importCollection" ||
    tradeFinance.tradeFinanceType === "tradefin/incomingStandbyLC" ||
    tradeFinance.tradeFinanceType === "tradefin/incomingStandbyBG"
  );
}

export function showEventHistory(tradeFinance: TradeFinance): boolean {
  return (
    tradeFinance.id != null &&
    tradeFinance?.id !== "new" &&
    tradeFinance.state !== "concept" &&
    tradeFinance.state !== "template"
  );
}

export function isPreCheckEvent(tradeFinance: TradeFinance): boolean {
  return !!(
    tradeFinance &&
    tradeFinance.event &&
    tradeFinance.event.eventDescRef === "tradefin/exportLC/event/preCheckEvent"
  );
}

export function isClaimEvent(tradeFinance: TradeFinance) {
  return (
    tradeFinance &&
    tradeFinance.event &&
    tradeFinance.event.eventDescRef?.endsWith("/claimEvent")
  );
}

export function isPreCheckFinishable(tradeFinance: TradeFinance): boolean {
  return !!tradeFinance.originOfGoods;
}

export function isEventWaitingForClient(tradeFinance: TradeFinance) {
  return !!(
    tradeFinance &&
    tradeFinance.event &&
    ["waitingForClient", "clientDecision"].includes(
      tradeFinance.event.eventState
    )
  );
}

export function isFinishRequestEnabled(
  tradeFinance: TradeFinance,
  isTemplate: boolean,
  underWritersInfo?: UnderWritersInfo
): boolean {
  if (isTemplate) {
    return false;
  }

  if (underWritersInfo) {
    return false;
  }

  if (tradeFinance.state === "concept") {
    return true;
  }

  if (!isEventWaitingForClient(tradeFinance)) {
    return false;
  }

  if (isClaimEvent(tradeFinance)) {
    return false;
  }

  return true;
}

export function isImportLetterOfCreditInCancelEventWaitingForClient(
  tradeFinance: TradeFinance
) {
  return (
    tradeFinance &&
    isImportLetterOfCredit(tradeFinance) &&
    isEventWaitingForClient(tradeFinance) &&
    tradeFinance.event &&
    tradeFinance.event.eventDescRef?.endsWith("/cancelEvent")
  );
}

export function isAmendment(tradeFinance: TradeFinance) {
  return (
    tradeFinance &&
    tradeFinance.event &&
    tradeFinance.event.eventDescRef?.endsWith("/amendmentEvent")
  );
}

export function isCompleteRequestEvent(tradeFinance: TradeFinance) {
  return (
    tradeFinance &&
    tradeFinance.event &&
    tradeFinance.event.eventDescRef?.endsWith("/completeRequestEvent")
  );
}

export function isEvaluateRequest(tradeFinance: TradeFinance) {
  return tradeFinance.state === "evaluateRequest";
}

export function isCancelEvent(tradeFinance: TradeFinance) {
  return (
    tradeFinance &&
    tradeFinance.event &&
    tradeFinance.event.eventDescRef?.endsWith("/cancelEvent")
  );
}

export function businessEntityDescriptorRefName(
  businessEntityDescriptorId: string
): string {
  if (businessEntityDescriptorId) {
    return businessEntityDescriptorId.substr(
      businessEntityDescriptorId.indexOf("/") + 1
    );
  }
  return "";
}

export function validateTextArea(
  value: string,
  maxRows: number,
  maxColumns: number,
  t: i18next.TFunction
): string | null {
  if (value) {
    const lines = value.split("\n");
    if (lines.length > maxRows) {
      return t("beneficiary.addressValidation");
    } else if (lines.find((line) => line.length > maxColumns)) {
      return t("beneficiary.addressValidation");
    }
  }

  return null;
}

export function getCurrencyDecimals(
  userStatus: UserStatus | null,
  currency: string
): number {
  if (userStatus && currency) {
    let zeroDecimals = userStatus.zeroDecimalCurrencies.split(",");
    let threeDecimals = userStatus.threeDecimalCurrencies.split(",");

    if (zeroDecimals.includes(currency)) {
      return 0;
    } else if (threeDecimals.includes(currency)) {
      return 3;
    }
  }
  return 2;
}

export function getCoverTemplateId(
  userStatus: UserStatus,
  tradeFinance: TradeFinance
): string {
  let templateId = "";

  if (isImportLetterOfCredit(tradeFinance)) {
    templateId = userStatus!.importLCDocumentTemplate;
  } else if (isExportLetterOfCredit(tradeFinance)) {
    templateId = userStatus!.exportLCDocumentTemplate;
  } else if (isExportCollection(tradeFinance)) {
    templateId = userStatus!.exportCollectionDocumentTemplate;
  }

  return templateId || "";
}

export function getSortedFileExtensions() {
  const extensions =
    "TXT,PDF,DOC,JPEG,JPG,ZIP,TIF,RTF,MSG,DOCX,BMP,TIFF,XLS,XLSX";
  return extensions.split(",").sort();
}

export function getTradeFinanceShortName(
  tradeFinance: TradeFinance,
  t: i18next.TFunction
) {
  let tradeFinanceShortName = "";

  if (isStandBy(tradeFinance)) {
    tradeFinanceShortName = t("menu.standby");
  } else if (isGuarantee(tradeFinance)) {
    tradeFinanceShortName = t("guarantee.name");
  } else if (isLetterOfCredit(tradeFinance)) {
    tradeFinanceShortName = t("letterOfCredit.name");
  } else if (isCollection(tradeFinance)) {
    tradeFinanceShortName = t("collection.name");
  }
  return tradeFinanceShortName;
}

export function isMixedPayment(tradeFinance: TradeFinance) {
  return (
    isCollection(tradeFinance) &&
    (tradeFinance as Collection).conditionsOfDocumentDelivery === "mixedPayment"
  );
}

export function isConcept(tradeFinance: TradeFinance) {
  return tradeFinance && tradeFinance.state === "concept";
}

export function formatDate(date: Date | number | null) {
  const defaultDateFormat = "DD.MM.YYYY";
  return date ? moment(date).format(defaultDateFormat) : "";
}

export function getMissingSignatureCount(underWritersInfo: UnderWritersInfo) {
  const {
    numberOfGroupA,
    numberOfGroupB,
    underWritersInGroupA,
    underWritersInGroupB,
  } = underWritersInfo;
  return (
    numberOfGroupA -
    underWritersInGroupA.length +
    numberOfGroupB -
    underWritersInGroupB.length
  );
}

export function updateSortOptions(
  options: SelectOption[],
  type: TradeFinanceRequestListType,
  chipsFilter?: string
): SelectOption[] {
  return options.filter((option) => {
    let currentOption = option.value.toString();

    if (currentOption.includes("createDate")) {
      return type === "concept" || chipsFilter === ChipsFilter.drafts;
    }
    if (currentOption.includes("subtype")) {
      const chipsFilterAllowed =
        chipsFilter === ChipsFilter.drafts ||
        chipsFilter === ChipsFilter.ongoing;
      return (
        ["guarantees", "ongoing", "concept"].includes(type) ||
        chipsFilterAllowed
      );
    }
    return true;
  });
}
